import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ETAPaymentMethod } from '~/api/ETA';
import { PaymentSystem } from '~/api/Order';
import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { useGlobal } from '~/hooks/useGlobal';
import CARD_BRANDS from '~/pages/Checkout/cardBrands';
import { CardDisclaimer } from '~/pages/Checkout/CardDisclaimer';
import { CashPayment } from '~/pages/Checkout/CashPayment';
import StripeCardPayment from '~/pages/Checkout/StripeCardPayment';
import { useIsTablet } from '~/pages/Order/hooks';
import { catalogStore } from '~/stores/CatalogStore';
import AdditionalPaymentMethod from '~/stores/CheckoutStore/AdditionalPaymentMethod';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import MobilePaymentMethod from '~/stores/CheckoutStore/MobilePaymentMethod';
import PaymentMethod from '~/stores/CheckoutStore/PaymentMethod';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';

export const PaymentMethods = ({
  hideDisclaimer,
}: {
  hideDisclaimer?: boolean;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const isTablet = useIsTablet();

  const paymentsChange = (val: string) => {
    checkoutStore.setPaymentsMethodId(val);
    checkoutStore.setIsDisabled(false);
    orderStore.setDefaultPayment(val);
    mainStore.sendToRN('analytics', {
      name: 'Purchase: payment method filled in',
      params: {
        cart_id: undefined,
        payment_method: 'card',
        type: 'existing',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'add_payment_info',
      params: {
        currency: orderStore.currency.toUpperCase(),
        payment_type: 'credit card',
        value: mainStore.toFloat(catalogStore.finalPrice),
        items: catalogStore.cartForFirebase,
        coupon: catalogStore.promocode.value,
      },
    });
  };

  const methodItemContent: Record<PaymentMethod, JSX.Element> = {
    [MobilePaymentMethod.ApplePay]: (
      <>
        <Icon
          {...(isMobile
            ? { type: 'apay2', size: 38 }
            : { type: 'apay', size: 24 })}
        />
        <div className="panel-section__content-payment-type-desctiption">
          <span>Apple Pay</span>
        </div>
        <input
          type="radio"
          name="payment-type"
          checked={
            checkoutStore.activePaymentMethod === MobilePaymentMethod.ApplePay
          }
          onChange={() =>
            checkoutStore.setActivePaymentMethod(MobilePaymentMethod.ApplePay)
          }
        />
        <span className="panel-section__content-payment-type-check" />
      </>
    ),
    [MobilePaymentMethod.GooglePay]: (
      <>
        <Icon
          type="gpay"
          {...(isMobile
            ? { width: 46, height: 24 }
            : { width: 60, height: 32 })}
        />
        <div className="panel-section__content-payment-type-desctiption">
          <span>Google Pay</span>
        </div>
        <input
          type="radio"
          name="payment-type"
          checked={
            checkoutStore.activePaymentMethod === MobilePaymentMethod.GooglePay
          }
          onChange={() =>
            checkoutStore.setActivePaymentMethod(MobilePaymentMethod.GooglePay)
          }
        />
        <span className="panel-section__content-payment-type-check" />
      </>
    ),
    [ETAPaymentMethod.Card]: (
      <>
        <Icon type="add-card" size={isTablet ? 24 : 32} />
        <div className="panel-section__content-payment-type-desctiption">
          <span>{t('newCardOnline')}</span>
        </div>
        <input
          type="radio"
          name="payment-type"
          checked={checkoutStore.activePaymentMethod === ETAPaymentMethod.Card}
          onChange={() =>
            checkoutStore.setActivePaymentMethod(ETAPaymentMethod.Card)
          }
        />
        <span className="panel-section__content-payment-type-check" />
      </>
    ),
    [ETAPaymentMethod.Cash]: (
      <>
        <Icon type="cash" size={isTablet ? 24 : 32} />
        <div className="panel-section__content-payment-type-desctiption">
          <span>{t('cash')}</span>
        </div>
        <input
          type="radio"
          name="payment-type"
          checked={checkoutStore.activePaymentMethod === ETAPaymentMethod.Cash}
          onChange={() =>
            checkoutStore.setActivePaymentMethod(ETAPaymentMethod.Cash)
          }
        />
        <span className="panel-section__content-payment-type-check" />
      </>
    ),
    [AdditionalPaymentMethod.KNET]: (
      <div className="d-flex align-items-center">
        <img className="mr-6 flex-shrink-0" src={''} height="14" alt="" />
        {t('knet')}
      </div>
    ),
    [AdditionalPaymentMethod.VisaMastercard]: (
      <>
        {orderStore.paymentCards.map(
          ({ card: { brand: brandName, last4 }, id }) => {
            const cardBrand = brandName ? CARD_BRANDS[brandName] : '';
            const brandUi = cardBrand && (
              <>
                <img src={cardBrand.icon} alt="payment system" />
                <div className="panel-section__content-payment-type-desctiption">
                  <span>{cardBrand.title}</span>
                  <span className="panel-section__content-payment-type-desctiption-card">
                    •• {last4}
                  </span>
                </div>
              </>
            );
            return (
              <Fragment key={id}>
                {brandUi}
                <input
                  type="radio"
                  name="payment-type"
                  checked={
                    checkoutStore.activePaymentMethod ===
                      AdditionalPaymentMethod.VisaMastercard &&
                    id === checkoutStore.paymentsMethodId
                  }
                  onChange={() => {
                    checkoutStore.setActivePaymentMethod(
                      AdditionalPaymentMethod.VisaMastercard,
                    );
                    paymentsChange(id);
                  }}
                />
                <span className="panel-section__content-payment-type-check" />
              </Fragment>
            );
          },
        )}
      </>
    ),
  };

  useEffect(() => {
    checkoutStore.initActivePaymentMethod();
    const activePaymentMethod = checkoutStore.activePaymentMethod;

    if (
      activePaymentMethod &&
      !checkoutStore.availablePaymentMethods.includes(activePaymentMethod)
    ) {
      mainStore.pushAlert(
        'error',
        t('checkoutPage:optionDisabled', {
          option: t(
            activePaymentMethod === ETAPaymentMethod.Cash ? 'cash' : 'bankCard',
          ),
        }),
      );
    }
  }, [checkoutStore.availablePaymentMethods]);

  return (
    <div className="panel-section payment">
      {!isTablet && (
        <p className="panel-section__title side">
          {t('paymentMethod')}
          {!checkoutStore.activePaymentMethod && (
            <span className="c-red">*</span>
          )}
        </p>
      )}
      <div className="panel-section__content-payment">
        <div className="panel-section__content-payment-grid">
          {checkoutStore.availablePaymentMethods.map((method) => {
            return (
              <label
                className={'panel-section__content-payment-type'}
                key={method}
              >
                {methodItemContent[method]}
              </label>
            );
          })}
        </div>
        {company.showCardDisclaimer &&
          checkoutStore.activePaymentMethod !== ETAPaymentMethod.Cash && (
            <CardDisclaimer />
          )}
        <div className="panel-section__content-payment-guarantee">
          <Icon
            type="shield"
            size={24}
            className="panel-section__content-payment-guarantee-icon"
          />
          <p className="panel-section__content-payment-guarantee-description">
            {t('securePayment')}
          </p>
        </div>
        {checkoutStore.activePaymentMethod === ETAPaymentMethod.Cash && (
          <CashPayment />
        )}

        <div className="panel-section__content-payment-add">
          {orderStore.paymentSystem === PaymentSystem.stripe &&
            checkoutStore.activePaymentMethod === ETAPaymentMethod.Card &&
            !company.useAwsPaymentMethod && <StripeCardPayment />}
          {!hideDisclaimer && (
            <>
              {checkoutStore.activePaymentMethod !== ETAPaymentMethod.Cash &&
                !company.hideFreezeMoneyMessage && (
                  <p className="panel-section__content-payment-add-text">
                    {t('phrases:moneyFreeze')}
                  </p>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(PaymentMethods);
