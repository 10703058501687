import { addHours, isToday } from 'date-fns';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import { BundleBadge } from '~/components/BundleBadge';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import DeliveryDateLabel from '~/components/DeliveryDateLabel/DeliveryDateLabel';
import ExpressDeliveryBadge from '~/components/ExpressDeliveryBadge/ExpressDeliveryBadge';
import { MultibuyBadge } from '~/components/MultibuyBadge';
import { useProductCard } from '~/components/ProductCard/hooks/useProductCard';
import { PropVariants } from '~/components/PropVariants/PropVariants';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useGlobal } from '~/hooks/useGlobal';
import { Product, ProductState } from '~/stores/CategoriesStore';
import { userStore } from '~/stores/UserStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import Counter from '../../Counter';
import Icon from '../../Icon/Icon';
import TooltipSelector from '../../SmallSelector/SmallSelector';

import Rating from '../components/Rating/Rating';
import { formatVolume, pluralizePcLabel } from '../utils';

import styles from './ProductCard.module.scss';

interface ProductCardProps {
  product: Product;
  className?: string;
  isRatingShown?: boolean;
  hideButton?: boolean;
  productState?: ProductState;
}

const ProductCard = ({
  product,
  className,
  isRatingShown = true,
  hideButton = false,
  productState,
}: ProductCardProps) => {
  const anchorClass = 'product-card';
  const anchorName = 'product-card__name';
  const anchorBody = 'product-card__body';

  const { t } = useTranslation();
  const { handleClickProduct } = useCategoryAnalytics();
  const { isMobile } = useGlobal();
  const {
    currentItem,
    volumes,
    limit,
    image,
    leadTime,
    oldPrice,
    currentPrice,
    cartItemsCount,
    isMultiBuy,
    isExpress,
    isBackorderAvailable,
    isBundle,
    isFavorite,
    productHasManyVariants,
    colorVariants,
    selectOffer,
    toggleFavorite,
    changeCount,
    addMultiBuyProductToCart,
    openSelectSKUModal,
  } = useProductCard(
    productState?.data ?? product,
    productState?.selectedOffer,
  );

  const addButtonHandler = () => {
    productHasManyVariants && !isBundle
      ? openSelectSKUModal()
      : changeCount(cartItemsCount + 1, 'add');
  };

  let additionalInfo = null;
  if (!hideButton) {
    if (isBundle) {
      additionalInfo = (
        <p className={classes([styles.info, styles.infoVolume])}>
          {pluralizePcLabel(1)}
        </p>
      );
    } else if (colorVariants.length) {
      additionalInfo = (
        <PropVariants
          colorVariants={colorVariants}
          classNames={styles.additionalInfo}
        />
      );
    } else {
      additionalInfo = (volumes?.length > 1 && (
        <TooltipSelector
          onChange={selectOffer}
          options={volumes}
          id={currentItem.sku || ''}
          side={userStore.dir === 'rtl' ? 'left' : 'right'}
        />
      )) || (
        <p className={classes([styles.info, styles.infoVolume])}>
          {formatVolume(currentItem.properties)}
        </p>
      );
    }
  }

  return (
    <div
      className={classes([
        styles.root,
        anchorClass,
        styles['product-card'],
        className,
        hideButton && styles.smallCard,
      ])}
      data-company={company.name}
    >
      <div
        className={classes([
          styles.imageWrapper,
          isMultiBuy && styles.multibuyImageWr,
        ])}
      >
        <CloudflareResponseImage
          src={currentItem.images[0] ?? image ?? ''}
          srcParams={{ width: 207, height: 274 }}
          className={styles.image}
          alt=""
          loading="lazy"
        />
        <Link
          onClick={handleClickProduct(product)}
          to={
            `/p/${product?.slug}` +
            (hideButton ? `?sku=${currentItem.sku}` : '')
          }
          state={{ offerSku: currentItem.sku }}
          className={classes([styles.linkAbs, 'link-abs'])}
        />
        <div className={styles.infoBadges}>
          {isExpress ? (
            <ExpressDeliveryBadge />
          ) : isBackorderAvailable ? (
            <DeliveryDateLabel
              className={styles.deliveryDateLabel}
              deliveryDate={
                isToday(addHours(new Date(), leadTime))
                  ? null
                  : addHours(new Date(), leadTime)
              }
            />
          ) : null}
        </div>
        {isBundle && <BundleBadge className={styles.bundleBadge} />}
        {isMultiBuy && (
          <MultibuyBadge
            value={currentItem.promoRequiredQuantity}
            className={styles.multibuyCounter}
            onClick={addMultiBuyProductToCart}
          />
        )}
        <button
          className={classes([
            'button _no-padding',
            styles.fav,
            isFavorite && styles.favFill,
          ])}
          onClick={toggleFavorite}
        >
          <Icon type={isFavorite ? 'fav-fill' : 'fav'} size={20} />
        </button>
        <div className={styles.ratingContainer}>
          {!company.hideReviews && isRatingShown && (
            <Rating
              rating={product.ratingAverage}
              ratedClass={styles.rated}
              noRatingClass={styles.no_rating}
              noRatingText={t('noRatingShort')}
              noRatedStarClassName={styles.noRatedStarClassName}
            />
          )}
        </div>
      </div>
      <div className={classes([anchorBody, styles.body])}>
        <div className={classes([anchorName, styles.name])}>
          <Link
            to={
              `/p/${product.slug}` +
              (hideButton ? `?sku=${currentItem.sku}` : '')
            }
            onClick={handleClickProduct(product)}
            state={{ offerSku: currentItem.sku }}
          >
            {currentItem.name}
          </Link>
          {hideButton && isMobile && !company.hideReviews && isRatingShown && (
            <Rating
              rating={product.ratingAverage}
              ratedClass={styles.rated}
              noRatingClass={styles.no_rating}
              noRatingText={t('noRatingShort')}
              noRatedStarClassName={styles.noRatedStarClassName}
            />
          )}
          {additionalInfo}
        </div>
        <div className={styles.footer}>
          <div className={styles.price_wrap}>
            <div
              className={classes([
                styles.price,
                oldPrice && styles.goodPrice,
                isMultiBuy && styles.multibuyOldPrice,
              ])}
            >
              {formatPriceWithCurrency(currentPrice)}
              {isMultiBuy ? ' / 1x' : ''}
            </div>
            {oldPrice && (
              <div className={styles.oldPrice}>
                {formatPriceWithCurrency(+oldPrice)}
              </div>
            )}
          </div>
          {isMultiBuy && (
            <div className={styles.multibuyPriceWr}>
              <div className={classes([styles.price, styles.goodPrice])}>
                {formatPriceWithCurrency(
                  currentItem?.promoQuantityDiscountPriceFormatted || '0',
                )}
              </div>
              <div className={styles.multibuyFromRule}>
                {t('phrases:fromPc', {
                  length: pluralizePcLabel(currentItem?.promoRequiredQuantity),
                })}
              </div>
            </div>
          )}
          {!hideButton &&
            (cartItemsCount > 0 ? (
              <div className={styles.counter}>
                <Counter
                  count={cartItemsCount}
                  size={isMobile ? 's' : 'm'}
                  onClick={changeCount}
                  limit={limit}
                />
              </div>
            ) : (
              <button
                className={classes([
                  'button _primary _xs',
                  styles.buy,
                  isMobile && '_small',
                  limit === 0 && '_disabled',
                  styles.footerButton,
                ])}
                onClick={addButtonHandler}
              >
                {limit === 0 ? t('soldOut') : t('addToCart')}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default observer(ProductCard);
