import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { company } from '~/company/Company';
import { useModal } from '~/hooks/useModal';
import { userStore } from '~/stores/UserStore';

import { catalogStore } from '../../stores/CatalogStore';

import CartCard from '../CartCard/CartCard';
import { ModalType } from '../Modal/interface';
import Price from '../Price';

export default observer(({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const navigate = useNavigate();

  const handleNavigateCart = () => {
    if (!userStore.deliveryAddress) {
      openModal(ModalType.EnterDeliveryAddress);
    } else {
      navigate('/cart');
    }
    onClose?.();
  };

  return (
    <div className="cart-popover-container" data-company={company.name}>
      <div
        className="cart-popover"
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          e.preventDefault();
        }}
      >
        <div className="popover__title-container">
          <p className="popover__title">{t('yourCart')}</p>
          {company.showCartModalCount && (
            <p className="popover__count">
              {t('phrases:itemsCount', { count: catalogStore.totalCartCount })}
            </p>
          )}
        </div>
        <ul className="popover__list">
          {catalogStore.cart.map((offer) => (
            <li key={offer.sku}>
              <CartCard
                location="modalCart"
                className="popover__list-item"
                item={offer}
                size="s"
              />
            </li>
          ))}
        </ul>
        {/* {catalogStore.totalCartPrice.totalDeliveryPricePence > 0 && (
          <div className="popover__status">
            <div className="popover__status-description">
              <div className="popover__status-description-header">
                <div className="popover__status-description-header-text">
                  <p>{t('add')}</p>
                  <div className="popover__status-description-header-text-marked">
                    {formatPriceWithCurrency(catalogStore.leftUntilFreeDelivery[0])}
                  </div>
                  <p>{t('toGetFreeDelivery')}</p>
                </div>
              </div>
            </div>
            <div className="popover__status-line">
              <div
                className="popover__status-line-fill"
                style={{
                  width: catalogStore.leftUntilFreeDelivery[1],
                  transition: 'all 0.3s',
                }}
              />
            </div>
          </div>
        )} */}
        <div className="popover__button-container">
          <div
            className="button _primary popover__button"
            onClick={handleNavigateCart}
          >
            <span>{t('goToCart')}</span>
            {company.showCartModalCount && (
              <Price
                price={catalogStore.totalCartPrice.paidWithDiscount}
                classNameCurrentPrice="price"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
