/* eslint-disable */
import { observer } from 'mobx-react-lite';
import { classes } from 'html-classes';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import { catalogStore } from '~/stores/CatalogStore';
import { userStore } from '~/stores/UserStore';

import { ProductPropertiesValue, ProductReview } from '~/types/Product/interface';
import { SliderLayout } from '~/components/ProductImageSlider/interfaces';
import { ModalType } from '~/components/Modal/interface';

import MobileBottomContainer from './MobileBottomContainer';
import { ProductImageSlider } from '~/components/ProductImageSlider';
import { ReviewItem } from './ReviewItem';
import { MultibuyBadge } from '~/components/MultibuyBadge';

import Rating from '~/components/Rating';
import Section from '~/components/Section';
import SkeletonListRows from '~/components/Skeleton/SkeletonList';
import Text from '~/components/Text';
import Icon from '~/components/Icon/Icon';
import CollapsibleText from '~/components/CollapsibleText/CollapsibleText';

import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';

import BundleList from './BundleList';
import { company } from '~/company/Company';

import styles from '../Product.module.scss';
import '../styles.scss';
import ProductPrice from './ProductPrice';
import { Offer, Product } from '~/stores/CategoriesStore';
import { RefObject } from 'react';
import { ReviewsStore } from '~/stores/ReviewsStore';
import { ProductBadges } from '~/pages/Product/ProductBadges';
import ProductPropertyFilter from '~/components/ProductPropertyFilter/ProductPropertyFilter';
/* eslint-enable */

type Props = {
  productState?: Product | null;
  reviews: ReviewsStore;
  offer?: Offer;
  isExpress?: boolean | 0;
  deliveryToday: boolean | '';
  isBackorderAvailable?: boolean;
  backorderDate?: any;
  isBundle?: boolean;
  isMultiBuy?: boolean;
  currentItem?: Offer | Product | null;
  title?: string;
  volumes: {
    id: string;
    value: string;
  }[];
  currentPrice?: string;
  oldPrice: string | null;
  count: number;
  limit: number;
  bottomContainerRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  productFilters?: ProductPropertiesValue[];

  handleCopyCode: () => void;
  handleFavorite: () => void;
  addMultiBuyProductToCart: () => void;
  handleChangeSection: (sectionAlias: string) => void;
  handleChangeCount: (count: number, action: 'add' | 'remove') => void;
  handleChangeOffer: (sku: string) => () => void;
};

const ProductInfoLocalee = ({
  productState,
  reviews,
  offer,
  isExpress,
  deliveryToday,
  isBackorderAvailable,
  backorderDate,
  isBundle,
  isMultiBuy,
  currentItem,
  title,
  currentPrice,
  oldPrice,
  count,
  limit,
  bottomContainerRef,
  isLoading,
  productFilters = [],

  handleCopyCode,
  handleFavorite,
  addMultiBuyProductToCart,
  handleChangeSection,
  handleChangeOffer,
  handleChangeCount,
}: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { isMobile } = useGlobal();

  const bundleImages = productState?.images
    ? productState.images
    : [`${productState?.previewImageThumb}`].filter(Boolean);

  const TABS = {
    product: {
      alias: 'product',
      title: t('product'),
    },
    characteristics: {
      alias: 'characteristics',
      title: t('characteristics'),
    },
    reviews: {
      alias: 'reviews',
      title: t('reviews'),
    },
  };

  const shortInfo = (
    <div className={styles.shortInfo}>
      {!company.hideReviews &&
        (productState?.ratingAverage ? (
          <Rating
            rate={productState.ratingAverage}
            starSize={!company.customMobileRatingProductPage ? 13 : 16}
            showStars={!company.customMobileRatingProductPage}
            className={classes(
              company.customMobileRatingProductPage &&
                styles.customMobileRating,
            )}
            classNameText={classes(
              company.customMobileRatingProductPage &&
                styles.customMobileRatingText,
            )}
          />
        ) : (
          <div className={styles.no_rating}>
            <Icon
              type="star-fill"
              size={16}
              className={styles.no_rating__star}
            />
            {t('noRatingShort')}
          </div>
        ))}
      {!offer ? (
        <Skeleton className={styles.shortInfo__statusSkeleton} />
      ) : (
        limit > 0 &&
        !!isExpress && (
          <p className={styles.shortInfo__statusInStock}>{t('inStock')}</p>
        )
      )}
    </div>
  );

  return (
    <div className={styles.productContent} data-company={company.name}>
      <div
        className={classes([styles.slider, styles.imageContainer])}
        data-component={company.name}
      >
        {company.customMobileRatingProductPage && shortInfo}
        {!offer ? (
          <Skeleton className={styles.slider_image} />
        ) : (
          <>
            <div className={styles.slider__product_info}>
              <div className={styles.slider__product_info_bages}>
                {isExpress ? (
                  <div
                    className={classes([
                      styles.badge__container,
                      styles.desktop_hide,
                    ])}
                  >
                    <Icon
                      type="express-fill"
                      size={16}
                      className={styles.badge__icon}
                    />{' '}
                    {t('expressDelivery')}
                  </div>
                ) : isBackorderAvailable ? (
                  <div
                    className={classes([
                      styles.badge__container,
                      styles.desktop_hide,
                    ])}
                  >
                    {deliveryToday
                      ? t('deliveryTodayMobile')
                      : t('slotDeliveryInShort', { backorderDate })}
                  </div>
                ) : null}
              </div>
              {isBundle && (
                <div className={styles.bundleBadge} data-company={company.name}>
                  {t('bundle')}
                </div>
              )}
              {company.showActionButtonsMobileProductPage && (
                <div className={styles.slider__product_info_actions}>
                  <div className={styles.slider__product_info_actions}>
                    <button
                      className={classes([
                        'button',
                        styles.slider__product_info_actions_btn,
                      ])}
                      onClick={handleCopyCode}
                    >
                      <Icon size={24} type="share" />
                    </button>
                    <button
                      className={classes([
                        'button',
                        styles.slider__product_info_actions_btn,
                      ])}
                      onClick={handleFavorite}
                    >
                      <Icon
                        size={24}
                        type={
                          catalogStore.favorites[productState?.id || '']
                            ? 'fav-fill'
                            : 'fav'
                        }
                        className={classes(
                          catalogStore.favorites[productState?.id || ''] &&
                            '_fill',
                        )}
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
            <ProductImageSlider
              productTitle={productState?.name}
              images={isBundle ? bundleImages : offer.images}
              className={classes([
                styles.swiperContainer,
                isMultiBuy && styles.multiBuySlider,
              ])}
              layout={SliderLayout.MOBILE}
              badgesContent={
                isMultiBuy && (
                  <MultibuyBadge
                    className={styles.multiBuyBadge}
                    value={offer?.promoRequiredQuantity}
                    onClick={addMultiBuyProductToCart}
                  />
                )
              }
            />
          </>
        )}
      </div>
      <p className={styles.pageTitle}>{title ?? <Skeleton />}</p>
      {!company.customMobileRatingProductPage && shortInfo}

      <ProductPrice
        offer={offer}
        isMultiBuy={!!isMultiBuy}
        oldPrice={oldPrice}
        currentPrice={currentPrice}
      />

      <div className={styles.volume} data-company={company.name}>
        {!offer ? (
          <SkeletonListRows rows={5}>
            <div
              className={classes([
                styles.skeletonContainer,
                styles.volumeBubble,
              ])}
            >
              <Skeleton
                className={classes([styles.skeleton])}
                containerClassName={classes([styles.skeleton])}
              />
            </div>
          </SkeletonListRows>
        ) : (
          !isBundle && (
            <div className={styles.filtersContainer}>
              {productFilters
                .sort((a, b) => a.sorting - b.sorting || a.order - b.order)
                .map((filter, idx) => (
                  <ProductPropertyFilter
                    filter={filter}
                    handleChangeOffer={handleChangeOffer}
                    key={idx}
                    name="mobile"
                  />
                ))}
            </div>
          )
        )}
      </div>
      <ProductBadges data={offer?.badges} />
      {isBundle && <BundleList productState={productState} />}
      <ul className="content__list" data-company={company.name}>
        {currentItem?.description && (
          <li className="content__list-item product-property">
            <div className="product-property__item expendable">
              <CollapsibleText
                text={currentItem.description}
                isLoading={isLoading}
                className={styles.collapsibleTextDescription}
              />
            </div>
          </li>
        )}
        {company.showDescriptionReviewProductPage &&
          (offer?.properties ? (
            <>
              {offer.properties.producer && (
                <li className="content__list-item product-property">
                  <div className="product-property__item">
                    <Text className="product-property__item-name">
                      {t('producer')}
                    </Text>
                    <Text className="product-property__item-value">
                      {offer.properties.producer.value}
                    </Text>
                  </div>
                  {/* <img alt='' src={product.producer?.logo} className='product-property__addition'/> */}
                </li>
              )}
              {offer.properties.region && (
                <li className="content__list-item product-property">
                  <div className="product-property__item">
                    <Text className="product-property__item-name">
                      {t('region')}
                    </Text>
                    <Text className="product-property__item-value">
                      {offer.properties.region.value}
                    </Text>
                  </div>
                  {/* <Text className="product-property__addition">{t('showOnMap')}</Text> */}
                </li>
              )}

              {reviews.list.length > 0 &&
              company.showShortReviewListMobileProductPage ? (
                <div
                  className={classes([
                    'content-layout',
                    styles.shortListContainer,
                    styles.reviewContainer,
                  ])}
                >
                  <Section
                    title="Reviews"
                    subtitle={
                      <Rating
                        rate={productState?.ratingAverage ?? 0}
                        starSize={16}
                        showStars={true}
                        classNameText={styles.reviewRatingText}
                        className={styles.reviewRating}
                      />
                    }
                    className={classes([styles.reviewList, styles.shortList])}
                    headerClassName={styles.reviewSectionHeader}
                    horizontalHeader
                  >
                    <>
                      {userStore.isAuthorized && reviews.canCreateReview && (
                        <button
                          className={classes([
                            'button _primary',
                            styles.productInfoReviewsShortAddReviewButton,
                          ])}
                          onClick={() => {
                            catalogStore.feedbackProduct = productState ?? null;
                            openModal(ModalType.Feedback);
                          }}
                        >
                          {t('writeReview')}
                        </button>
                      )}
                      {reviews.list.slice(0, 2).map((review: ProductReview) => (
                        <ReviewItem
                          key={review.id}
                          {...review}
                          isMobile={isMobile}
                        />
                      ))}
                      {reviews.list.length > 2 && (
                        <div className={styles.shortListButtonContainer}>
                          <button
                            className={classes([
                              'button _bordered',
                              styles.shortListButton,
                            ])}
                            onClick={() =>
                              handleChangeSection(TABS.reviews.alias)
                            }
                          >
                            {t('readMoreReviews')}{' '}
                            <Icon
                              type="chevron-2-fill"
                              className={styles.shortListButtonIcon}
                              size={16}
                            />
                          </button>
                        </div>
                      )}
                    </>
                  </Section>
                </div>
              ) : (
                <div
                  className={classes([
                    'content-layout',
                    styles.noReviewsContainer,
                  ])}
                >
                  <div className={styles.noReviews}>
                    {userStore.isAuthorized && reviews.canCreateReview ? (
                      <>
                        <div className={styles.noReviewsContent}>
                          <p className={styles.noReviewsContentTitle}>
                            {t('noReviewsYet')}
                          </p>
                          <Rating
                            rate={productState?.ratingAverage ?? 0}
                            starSize={20}
                            hideText
                            showStars
                            type="star-fill"
                            classNameStart={styles.noReviewsStars}
                          />
                        </div>
                        <button
                          className={classes([
                            'button _primary',
                            styles.noReviewsButton,
                          ])}
                          onClick={() => {
                            catalogStore.feedbackProduct = productState ?? null;
                            openModal(ModalType.Feedback);
                          }}
                        >
                          {t('writeReview')}
                        </button>
                      </>
                    ) : (
                      <div className={styles.noReviewsContent}>
                        <p className={styles.noReviewsContentTitle}>
                          {t('noRating')}
                        </p>
                        <Rating
                          rate={productState?.ratingAverage ?? 0}
                          starSize={20}
                          hideText
                          showStars
                          type="star-fill"
                          classNameStart={styles.noReviewsStars}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <SkeletonListRows rows={5}>
              <li className="content__list-item product-property">
                <div className="product-property__item">
                  <Text className="product-property__item-name">
                    <Skeleton width="100px" />
                  </Text>
                  <Text className="product-property__item-value">
                    <Skeleton width="100px" />
                  </Text>
                </div>
              </li>
            </SkeletonListRows>
          ))}
      </ul>

      {offer && (
        <MobileBottomContainer
          ref={bottomContainerRef}
          offer={offer}
          isMultiBuy={isMultiBuy}
          currentPrice={currentPrice}
          oldPrice={oldPrice}
          count={count}
          handleChangeCount={handleChangeCount}
          limit={limit}
        />
      )}
    </div>
  );
};

export default observer(ProductInfoLocalee);
