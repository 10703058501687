import onboardingFrame1 from './onboarding/frame1.png';
import onboardingFrame2 from './onboarding/frame2.png';
import onboardingFrame3 from './onboarding/frame3.png';
import onboardingFrame4 from './onboarding/frame4.png';
import onboardingFrame5 from './onboarding/frame5.png';
import onboardingFrame6 from './onboarding/frame6.png';

export const OboardingImages = [
  onboardingFrame1,
  onboardingFrame2,
  onboardingFrame3,
  onboardingFrame4,
  onboardingFrame5,
  onboardingFrame6,
];
