import { IMAGES } from '../../../constants/images';

export default () =>
  [
    {
      position: 'bottom',
    },
    {
      position: 'bottom',
    },
    {
      position: 'top',
    },
    {
      position: 'top',
    },
    {
      position: 'top',
    },
    {
      position: 'top',
    },
    {
      position: 'top',
    },
  ].map((configItem, index) => ({
    ...configItem,
    img: IMAGES.onboarding?.[index] || '',
  }));
