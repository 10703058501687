import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { useGlobal } from '~/hooks/useGlobal';
import { getProductChoices } from '~/hooks/useProductChoices';
import { useProductLimit } from '~/hooks/useProductLimit';
import { useStoreInstance } from '~/hooks/useStoreInstance';
import { catalogStore } from '~/stores/CatalogStore';
import { ProductState } from '~/stores/CategoriesStore';
import { orderStore } from '~/stores/OrderStore';
import { isProductBundle } from '~/types/Product';

import { useModal } from '../../hooks/useModal';

import Counter from '../Counter';
import Icon from '../Icon/Icon';
import ProductCard from '../ProductCard/ProductCard';
import ProductPropertyFilter from '../ProductPropertyFilter/ProductPropertyFilter';
import SkeletonListRows from '../Skeleton/SkeletonList';

import styles from './ModalSelectProductSKU.module.scss';

const SelectProductSkeleton = () => {
  return (
    <div className={styles.form}>
      <p className={styles.title}>
        <Skeleton />
      </p>
      <div className={styles.formInfo}>
        <Skeleton
          className={classes([styles.product, styles.productSkeleton])}
        />
        <div className={styles.choices}>
          <SkeletonListRows rows={2}>
            <div className={styles.skeletonChoice}>
              <p className={styles.skeletonListTitle}>
                <Skeleton />
              </p>
              <div className={styles.skeletonList}>
                <SkeletonListRows rows={4}>
                  <Skeleton className={styles.skeletonListItem} />
                </SkeletonListRows>
              </div>
            </div>
          </SkeletonListRows>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.control}>
          <Skeleton className={styles.footerButton} />
        </div>
      </div>
    </div>
  );
};

export default observer(() => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const slug = catalogStore.selectedCatalogProduct;
  const product = useStoreInstance(ProductState, slug || '');
  const { isMobile } = useGlobal();

  const getProduct = async () => {
    if (slug) {
      await product.request.run({
        productId: slug || '',
        warehouse: orderStore.etaWarehouseCode,
      });
    }
  };

  useEffect(() => {
    slug && getProduct();
  }, [orderStore.etaWarehouseCode, slug]);

  if (!slug) {
    closeModal();
  }

  const handleChangeOffer = (sku: string) => () => {
    product.selectOffer(sku);
  };

  const currentProductFilters = getProductChoices(product);

  const selectablePropsNames = Object.values(currentProductFilters).map(
    (item) => item.name.toLocaleLowerCase(),
  );
  const formatedSelectablePropsNames = `${selectablePropsNames
    .slice(0, -1)
    .join(', ')}${selectablePropsNames.length > 1 ? ` ${t('and')} ` : ''}${
    selectablePropsNames.at(-1) ?? ''
  }`;

  const backHandler = () => {
    closeModal();
    catalogStore.setSelectedCatalogProduct(null);
  };

  const isBundle = product.data ? isProductBundle(product.data) : false;

  const currentItem = isBundle
    ? product.data
    : product.selectedOffer || product.data;

  const count = currentItem
    ? catalogStore.getCartItemCountById(currentItem)
    : 0;

  const { limit } = useProductLimit({
    offer: currentItem,
  });

  const handleChangeCount = (count: number, action: 'add' | 'remove') => {
    if (currentItem) {
      catalogStore.setCartItemCountByProduct(
        {
          ...currentItem,
          ratingAverage: currentItem?.ratingAverage ?? 0,
          ratingMarksCount: currentItem?.ratingMarksCount ?? 0,
        },
        count,
        action,
        'product_main',
      );
    }
  };

  let modalContent: ReactNode = null;

  if (!product.data) {
    modalContent = <SelectProductSkeleton />;
  } else {
    modalContent = (
      <div className={styles.form}>
        <p className={styles.title}>
          {t('select')} {formatedSelectablePropsNames}
        </p>
        <div className={styles.formInfo}>
          <div className={styles.product}>
            {product.data && (
              <ProductCard
                product={product.data}
                productState={product}
                hideButton
              />
            )}
          </div>
          <div className={styles.choices}>
            {Object.values(currentProductFilters)
              .sort((a, b) => a.sorting - b.sorting || a.order - b.order)
              .map((filter) => (
                <ProductPropertyFilter
                  filter={filter}
                  handleChangeOffer={handleChangeOffer}
                  key={filter.code}
                />
              ))}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.control}>
            {count > 0 ? (
              <div className={styles.counter}>
                <Counter
                  count={count}
                  size={'l'}
                  onClick={handleChangeCount}
                  limit={limit}
                />
              </div>
            ) : (
              <button
                className={classes([
                  'button _primary _xs',
                  limit === 0 && '_disabled',
                  styles.footerButton,
                ])}
                onClick={() => handleChangeCount(count + 1, 'add')}
              >
                {limit === 0 ? t('soldOut') : t('addToCart')}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <button
          className={classes([
            'button _no-padding _no-color input-form__close',
            styles.closeButton,
          ])}
          onClick={backHandler}
        >
          <Icon
            type="plus"
            className="icon__rotate-45"
            size={isMobile ? 28 : 36}
          />
        </button>

        {modalContent}
      </div>
    </div>
  );
});
