import { classes } from 'html-classes';
import { ChangeEvent, JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { SortingFieldName, SortingOrder } from '~/api/Catalog';
import { company } from '~/company/Company';
import { MobileActionDialog } from '~/components/MobileActionDialog';
import {
  CIRCLEK_SORTING_OPTIONS,
  SORTING_OPTIONS,
} from '~/components/ProductSorting/constants';

import { IMobileSortingProps } from './interfaces';
import styles from './MobileSorting.module.scss';

const MobileSorting = (props: IMobileSortingProps): JSX.Element | null => {
  const { className, open, onClose, value, onChange } = props;

  const { t } = useTranslation();

  const options = (
    company.isReviewPanelHidden ? CIRCLEK_SORTING_OPTIONS : SORTING_OPTIONS
  ).map((v) => ({
    label: t(`sorting:${v}`),
    value: v,
  }));

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!onChange) {
      return;
    }

    const [field, order] = e.target.value.split('.') as [
      SortingFieldName,
      SortingOrder,
    ];

    onChange([field, order]);
  };

  return (
    <MobileActionDialog
      open={open}
      onClose={onClose}
      className={classes([styles.content, className])}
      contentHeight="50vh"
      title={<h2 className={styles.title}>{t('sortBy')}</h2>} // TRANSLATE //
    >
      {options.map((item) => (
        <label
          className={classes(['control__radio', styles.checkboxItem])}
          key={item.value}
        >
          <input
            type="checkbox"
            checked={item.value.toString() === value}
            value={item.value}
            className="control__radio-input"
            onChange={(e) => onChangeValue(e)}
          />
          <span className="control__radio-mark" />
          {item.label}
        </label>
      ))}
    </MobileActionDialog>
  );
};

export default MobileSorting;
