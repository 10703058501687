import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppIcon from '~/assets/img/appIcon.png';
import { company } from '~/company/Company';
import Footer from '~/components/Footer/Footer';
import Icon from '~/components/Icon/Icon';
import { useGlobal } from '~/hooks/useGlobal';
import { mainStore } from '~/stores/MainStore';
import { isIOS } from '~/utils/platform';

import { BottomNavigationBar } from './BottomNavigationBar';
import styles from './MobileLayout.module.scss';

interface MobileLayoutProps {
  children: React.ReactElement;
  layoutRef: React.Ref<HTMLDivElement>;
}

export const MobileDownloadHeader = () => {
  const [visible, setVisible] = useState(true);
  const { t } = useTranslation();

  const handleCloseClick = () => {
    setVisible(false);
  };

  const handleDownloadClick = () => {
    setVisible(false);
    mainStore.setIsAppInstalled(true);
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.mobileHeaderContainer}>
      <div className={styles.mobileHeaderContentContainer}>
        <div onClick={handleCloseClick}>
          <Icon type="close" />
        </div>
        <img className={styles.mobileHeaderIcon} src={AppIcon} />
        <div className={styles.descriptionContainer}>{t('downloadApp')}</div>
      </div>

      <a
        href={
          isIOS()
            ? company.config.appStoreUrl.ios
            : company.config.appStoreUrl.android
        }
        target="_blank"
        className="button _primary product-card__buy small"
        rel="noreferrer"
        onClick={handleDownloadClick}
      >
        {mainStore.isAppInstalled
          ? t('phrases:openApp')
          : t('phrases:download')}
      </a>
    </div>
  );
};

const MobileLayout = observer(({ children, layoutRef }: MobileLayoutProps) => {
  const { isMobile } = useGlobal();

  return (
    <>
      <div className={classes([styles.mobileLayout])} ref={layoutRef}>
        {isMobile && !mainStore.isRN && company.showMobileDowloadPanel && (
          <MobileDownloadHeader />
        )}

        <div>{children}</div>
        {!mainStore.isRN && <Footer />}
      </div>
      <BottomNavigationBar />
    </>
  );
});

export default MobileLayout;
