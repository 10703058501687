import { observer } from 'mobx-react-lite';

import BannerPromoBlock from '~/components/CategoryBlocks/BanerPromoBlock/BannerPromoBlock';
import BannerMainBlock from '~/components/CategoryBlocks/BannerMainBlock/BannerMainBlock';
import { useGlobal } from '~/hooks/useGlobal';
import { Category } from '~/stores/CategoriesStore';

import BannerCategoryBlock from './BannerCategoryBlock/BannerCategoryBlock';
import BannerLinkBlock from './BannerLinkBlock/BannerLinkBlock';
import BannerProductBlock from './BannerProductBlock/BannerProductBlock';
import CategoriesCarouselSliderOneBlock from './CategoriesCarouselSliderOneBlock/CategoriesCarouselSliderOneBlock';
import CategoriesCarouselSliderThreeWBlock from './CategoriesCarouselSliderThreeWBlock/CategoriesCarouselSliderThreeWBlock';
import CategoriesCarouselSliderTwoSBlock from './CategoriesCarouselSliderTwoSBlock/CategoriesCarouselSliderTwoSBlock';
import CategoriesCarouselSquareThreeWBlock from './CategoriesCarouselSquareThreeWBlock/CategoriesCarouselSquareThreeWBlock';
import CategoriesGridOneSBlock from './CategoriesGridOneSBlock/CategoriesGridOneSBlock';
import CategoriesGridThreeMBlock from './CategoriesGridThreeMBlock/CategoriesGridThreeMBlock';
import CategoriesGridTwoMBlock from './CategoriesGridTwoMBlock/CategoriesGridTwoMBlock';
import ListOfCategoriesOneBlock from './ListOfCategoriesOneBlock/ListOfCategoriesOneBlock';
import ListOfCategoriesTwoBlock from './ListOfCategoriesTwoBlock/ListOfCategoriesTwoBlock';
import ProductsCarouselSliderOneBlock from './ProductsCarouselSliderOneBlock/ProductsCarouselSliderOneBlock';
import ProductsGridBlock from './ProductsGridBlock/ProductsGridBlock';
import { createCategoryUrl } from './utils';

export enum CategoryDisplayType {
  MAIN_BANNER = 'main_banner',
  BANNER_CATEGORY = 'banner_category',
  PRODUCTS_CAROUSEL_SLIDER_ONE = 'products_carousel_slider_1',
  BANNER_PRODUCT = 'banner_product',
  BANNER_LINK = 'banner_link',
  BANNER_PROMO = 'promo_banner',
  PRODUCTS_GRID = 'products_grid',
  LIST_OF_CATEGORIES_ONE = 'list_of_categories_1',
  LIST_OF_CATEGORIES_TWO = 'list_of_categories_2',
  CATEGORIES_CAROUSEL_SQUARE_ONE = 'categories_carousel_square',
  CATEGORIES_CAROUSEL_SLIDER_ONE = 'categories_carousel_slider_1',
  CATEGORIES_CAROUSEL_SLIDER_TWO = 'categories_carousel_slider_2',
  CATEGORIES_CAROUSEL_SLIDER_THREE = 'categories_carousel_slider_3',
  CATEGORIES_GRID_ONE = 'categories_grid_1',
  CATEGORIES_GRID_TWO = 'categories_grid_2',
  CATEGORIES_GRID_THREE = 'categories_grid_3_level',
}

interface UnknownCategoryProps {
  name: string;
  displayType: Nullable<string>;
}

const UnknownCategory = ({ name, displayType }: UnknownCategoryProps) => {
  return import.meta.env.DEV ? (
    <div>
      Here will be a block with name: {name} and displayType: {displayType}
    </div>
  ) : null;
};

const categoryToBanners = (data: Category[]) => {
  return data.map(({ name, previewImage, id }) => ({
    src: previewImage || '',
    alt: name,
    linkId: id,
  }));
};

const renderCategory = ({
  category,
  isMobile,
  externalLink,
}: {
  category: Category;
  isMobile: boolean;
  externalLink?: string;
}) => {
  switch (category.displayType) {
    case CategoryDisplayType.MAIN_BANNER: {
      return (
        <BannerMainBlock
          banners={categoryToBanners(category.subcategory)}
          isMobile={isMobile}
        />
      );
    }
    case CategoryDisplayType.BANNER_CATEGORY: {
      return (
        <BannerCategoryBlock
          banners={categoryToBanners(category.subcategory)}
          isMobile={isMobile}
        />
      );
    }
    case CategoryDisplayType.PRODUCTS_CAROUSEL_SLIDER_ONE: {
      return <ProductsCarouselSliderOneBlock category={category} />;
    }
    case CategoryDisplayType.BANNER_PRODUCT: {
      return <BannerProductBlock category={category} />;
    }
    case CategoryDisplayType.BANNER_LINK: {
      return <BannerLinkBlock category={category} />;
    }
    case CategoryDisplayType.BANNER_PROMO: {
      return <BannerPromoBlock category={category} />;
    }
    case CategoryDisplayType.PRODUCTS_GRID: {
      return <ProductsGridBlock category={category} />;
    }
    case CategoryDisplayType.LIST_OF_CATEGORIES_ONE: {
      return <ListOfCategoriesOneBlock category={category} />;
    }
    case CategoryDisplayType.LIST_OF_CATEGORIES_TWO: {
      return <ListOfCategoriesTwoBlock category={category} />;
    }
    case CategoryDisplayType.CATEGORIES_CAROUSEL_SLIDER_ONE: {
      return <CategoriesCarouselSliderOneBlock category={category} />;
    }
    case CategoryDisplayType.CATEGORIES_CAROUSEL_SLIDER_THREE: {
      return <CategoriesCarouselSliderThreeWBlock category={category} />;
    }
    case CategoryDisplayType.CATEGORIES_CAROUSEL_SQUARE_ONE: {
      return <CategoriesCarouselSquareThreeWBlock category={category} />;
    }
    case CategoryDisplayType.CATEGORIES_GRID_ONE: {
      const items = category.subcategory.map(({ name, previewImage, id }) => ({
        title: name,
        img: previewImage,
        id,
      }));

      return (
        <CategoriesGridOneSBlock
          items={items}
          title={category.name}
          description={category.previewText}
          backgroundColor={category.backgroundColor}
          link={externalLink ?? createCategoryUrl({ id: category.id })}
          isMobile={isMobile}
        />
      );
    }

    case CategoryDisplayType.CATEGORIES_GRID_TWO: {
      return <CategoriesGridTwoMBlock category={category} />;
    }
    case CategoryDisplayType.CATEGORIES_GRID_THREE: {
      return <CategoriesGridThreeMBlock category={category} />;
    }
    case CategoryDisplayType.CATEGORIES_CAROUSEL_SLIDER_TWO: {
      return <CategoriesCarouselSliderTwoSBlock category={category} />;
    }
    default:
      return (
        <UnknownCategory
          name={category.name}
          displayType={category.displayType}
        />
      );
  }
};

interface CategoryBlockProps {
  category: Category;
  externalLink?: string;
  className?: string;
}

const CategoryBlock = ({
  category,
  externalLink,
  className,
}: CategoryBlockProps) => {
  const { isMobile } = useGlobal();

  return (
    <div className={className}>
      {renderCategory({ category, isMobile, externalLink })}
    </div>
  );
};

export default observer(CategoryBlock);
