/* eslint-disable import/order */
import { JSX, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './Legals.module.scss';

import './styles.scss';

import LegalsViloEng from './LegalsViloEng';
import LegalsViloInd from './LegalsViloInd';

import { useGlobal } from '~/hooks/useGlobal';
import { userStore } from '~/stores/UserStore';

type Props = {
  handleLink: (anchor: string) => void;
};

const LEGALS_NAV_ENG = [
  {
    link: 'privacy-policy',
    title: 'Privacy policy',
  },
  {
    link: 'collection-of-personal-information',
    title: 'Collection of personal information',
  },
  {
    link: 'use-of-personal-information',
    title: 'Use of Personal Information',
  },
  {
    link: 'disclosure-of-personal-information',
    title: 'Disclosure of Personal Information',
  },
  {
    link: 'international-transfer-of-personal-information',
    title: 'International Transfer of Personal Information',
  },
  {
    link: 'security-of-personal-information',
    title: 'Security of Personal Information',
  },
  {
    link: 'storage-and-deletion-of-personal-information',
    title: 'Storage and Deletion of Personal Information',
  },
  {
    link: 'managing-and-updating-personal-information',
    title: 'Managing and Updating Personal Information',
  },
  {
    link: 'links-to-third-party-sites',
    title: 'Links to Third-Party Sites or Platforms',
  },
  {
    link: 'childrens-privacy',
    title: "Children's policy",
  },
  {
    link: 'cookies',
    title: 'Cookies',
  },
  {
    link: 'how-to-contact',
    title: 'How to contact Vilo Gelato',
  },
  {
    link: 'terms-and-conditions',
    title: 'Terms & Conditions',
  },
];

const LEGALS_NAV_IND = [
  {
    link: 'ind-privacy-policy',
    title: 'KEBIJAKAN PRIVASI VILO GELATO',
  },
  {
    link: 'ind-collection-of-personal-information',
    title: 'Pengumpulan Informasi Pribadi',
  },
  {
    link: 'ind-use-of-personal-information',
    title: 'Penggunaan Informasi Pribadi',
  },
  {
    link: 'ind-disclosure-of-personal-information',
    title: 'Pengungkapan Informasi Pribadi',
  },
  {
    link: 'ind-international-transfer-of-personal-information',
    title: 'Transfer Internasional Informasi Pribadi',
  },
  {
    link: 'ind-security-of-personal-information',
    title: 'Keamanan Informasi Pribadi',
  },
  {
    link: 'ind-storage-and-deletion-of-personal-information',
    title: 'Penyimpanan dan Penghapusan Informasi Pribadi',
  },
  {
    link: 'ind-managing-and-updating-personal-information',
    title: 'Mengelola dan Memperbarui Informasi Pribadi',
  },
  {
    link: 'ind-links-to-third-party-sites',
    title: 'Tautan ke Situs atau Platform Pihak Ketiga',
  },
  {
    link: 'ind-childrens-privacy',
    title: 'Privasi Anak-anak',
  },
  {
    link: 'ind-cookies',
    title: 'Cookies',
  },
  {
    link: 'ind-how-to-contact',
    title: 'Bagaimana cara menghubungi Vilo Gelato',
  },
];

const LEGALS_NAV = userStore.lang !== 'en' ? LEGALS_NAV_IND : LEGALS_NAV_ENG;

const LegalsVilo = ({ handleLink }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();

  useEffect(() => {
    setTimeout(() => {
      const anchor = window.location.hash.slice(1);
      handleLink(anchor);
    }, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      <MobileHeaderLayout
        showTopCart
        content={<MobileHeaderTitle text={t('legals')} />}
      />
      <div className="content-layout legals">
        <div className={styles.root}>
          {!isTablet && (
            <>
              <Breadcrumbs
                items={
                  [
                    {
                      text: t('home'),
                      link: '/',
                    },
                    {
                      text: t('legals'),
                      link: '/legals',
                    },
                  ].filter(Boolean) as BreadcrumbsItem[]
                }
              />

              <h2 className={styles.title}>{t('legals')}</h2>
            </>
          )}

          <div className={styles.page}>
            <div className={styles.page__links_container}>
              <ul className={styles.page__links}>
                {LEGALS_NAV.map((i) => (
                  <li className={styles.page__links_item} key={i.link}>
                    <a
                      href={`#${i.link}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleLink(i.link);
                      }}
                    >
                      {i.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.page__content}>
              {userStore.lang !== 'en' ? <LegalsViloInd /> : <LegalsViloEng />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalsVilo;
