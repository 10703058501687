import { t } from 'i18next';

import { IMAGES } from '../../../constants/images';

export default () =>
  [
    {
      textTitle: t('onboarding:swiftDelivery:title'),
      textDescription: t('onboarding:swiftDelivery:description'),
      position: 'top',
    },
    {
      textTitle: t('onboarding:spotTheLight:title'),
      textDescription: t('onboarding:spotTheLight:description'),
      position: 'top',
    },
    /*{
      img: IMAGES.onboarding.unlockComplementary,
      textTitle: t('onboarding:unlockComplementary:title'),
      textDescription: t('onboarding:unlockComplementary:description'),
    },*/
    {
      textTitle: t('onboarding:trackDelivery:title'),
      textDescription: t('onboarding:trackDelivery:description'),
      position: 'top',
    },
    {
      textTitle: t('onboarding:shareThoughts:title'),
      textDescription: t('onboarding:shareThoughts:description'),
      position: 'top',
    },
    {
      textTitle: t('onboarding:referFriend:title'),
      textDescription: t('onboarding:referFriend:description'),
      position: 'top',
    },
    {
      textTitle: t('onboarding:sign_up:title'),
      textDescription: t('onboarding:sign_up:description'),
      position: 'top',
    },
  ].map((configItem, index) => ({
    ...configItem,
    img: IMAGES.onboarding?.[index] || '',
  }));
