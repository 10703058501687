import { company } from '~/company/Company';

import styles from './Legals.module.scss';

const LegalsViloEng = () => (
  <>
    <section>
      <h2 id="privacy-policy">VILO GELATO PRIVACY POLICY</h2>
      <h3>Effective Date 9 August 2024</h3>
      <p>
        This Privacy Policy applies to the Vilo Gelato App and any other
        application or website associated with the Vilo Gelato Brands brand or
        product (the &quot;App&quot;) that directs the User to this Privacy
        Policy. PT Vilo Kreasi Rasa (&quot;Vilo Gelato&quot;, &quot;We&quot;,
        &quot;Us&quot;) builds the App as an application with business purposes
        in mind and is intended to be used as is.
      </p>
      <p>
        This Privacy Policy is used to inform Users of Vilo Gelato&apos;s policy
        with the collection, use, processing, storage, possession and disclosure
        of Personal Information if anyone decides to use Vilo Gelato Services
        and Applications.
      </p>
      <p>
        If the User chooses to use the Vilo Gelato Service and Application, then
        the User acknowledges that the User has read and understood this Privacy
        Policy and agreed to all its terms. In particular, the User agrees and
        gives consent to Vilo Gelato to collect, use, share, disclose, store,
        transfer, or process the User&apos;s Personal Information in accordance
        with this Privacy Policy.
      </p>
      <p>
        The Privacy Policy is effective as of the date stated in this Privacy
        Policy. Vilo Gelato may change, add, delete, correct and/or update this
        Privacy Policy from time to time to ensure that this Privacy Policy is
        consistent with Vilo Gelato&apos;s future developments, and/or changes
        in legal or regulatory requirements, by posting the revised Privacy
        Policy on the Application, where in the event of material changes, Vilo
        Gelato will replace the date at the top of this Privacy Policy; and the
        User waives any rights that the User may have to receive specific notice
        of such changes or modifications. Therefore, Vilo Gelato advises Users
        to review this Privacy Policy page from time to time to see if there are
        any changes. Such changes are effective immediately upon posting on the
        Privacy Policy page and the User&apos;s continued use of the Application
        after such changes are posted constitutes the User&apos;s acceptance of
        such revised Privacy Policy.
      </p>
      <p>
        Please read this Privacy Policy carefully to ensure that Users
        understand how Vilo Gelato processes data. Terms used in this Privacy
        Policy have the same meanings as in the App (as relevant) (&quot;Terms
        and Conditions&quot;, which means including any changes and variations
        thereto from time to time), which may be accessed in the App unless
        otherwise specified in this Privacy Policy.
      </p>
    </section>

    <section>
      <h2 id="collection-of-personal-information">
        Collection of Personal Information
      </h2>

      <p>
        When a User uses the Vilo Gelato App or visits a Vilo Gelato store, Vilo
        Gelato may collect information about the User and the Services that the
        User uses (&quot;Personal Information&quot;). Personal Information may
        include, but is not limited to, the User&apos;s personally identifiable
        details such as first and last name, username, password, e-mail address,
        telephone number, and financial account information such as credit card
        number, date and city of birth, demographic information and device data
        (such as the User&apos;s Android ID Number). Vilo Gelato may also
        collect certain additional Personal Information, among others, as
        follows:
      </p>
      <ol>
        <li>
          <p>
            domicile information for promotional purposes or other purposes as
            determined by Vilo Gelato from time to time;
          </p>
        </li>
        <li>
          <p>
            User transactions at Vilo Gelato outlets and on the Application,
            including products purchased by the User, how the User makes
            purchases, and any loyalty programs and promotions related to
            purchases, the User&apos;s subscription process, the User&apos;s
            participation process in any event carried out through the
            Application, processing and managing the User&apos;s loyalty points;
            to respond to any queries and requests related to the User&apos;s
            customer service; post User comments or statements on the Vilo
            Gelato site; send Users personalized promotions and offers; detect,
            prevent, and investigate security or fraud breaches; to develop,
            improve, and provide products or Services to provide Users with a
            better experience;
          </p>
        </li>
        <li>
          <p>
            (in the event of any error in the Application) Vilo Gelato may
            collect data and information (through Third Party products) called
            &quot;Log Data&quot;. This Log Data may include information such as
            the Internet Protocol (&quot;IP&quot;) Address of the user&apos;s
            device, device name, operating system version, Application
            configuration when using the Vilo Gelato Service, the time and date
            the Service is used by the User, and other statistics; and/or
          </p>
        </li>
        <li>
          <p>
            the location of the User&apos;s device, including GPS location, for
            the purpose of improving and/or facilitating the Vilo Gelato
            Services and enabling the functionality of the website or
            application to provide the User with information about Vilo Gelato
            stores near the User, enabling remote ordering and payment of Vilo
            Gelato products and services.
          </p>
        </li>
      </ol>
      <p>
        Some of the above Personal Information that we collect is sensitive. We
        collect this information only with your permission and/or in accordance
        with applicable law. Personal Information collected by Vilo Gelato will
        be stored and used as specified in this Privacy Policy.
      </p>
      <p>
        Vilo Gelato may also collect User Personal Information from Third
        Parties (including agents, vendors, contractors, partners and other
        parties who provide services to Vilo Gelato or perform tasks on behalf
        of Vilo Gelato, or with whom Vilo Gelato does business).
      </p>
      <p>
        This application may use Third-Party services that may collect
        information that is used to identify the User.
      </p>
      <p>
        In circumstances where the User provides to Vilo Gelato Personal
        Information relating to another individual (such as Personal Information
        relating to the User&apos;s spouse, family member, friend, or other
        party), the User represents and warrants that the User has obtained
        consent from such individual to, and hereby consents on behalf of such
        individual to, the collection, use, disclosure and processing of their
        Personal Information by Vilo Gelato.
      </p>
    </section>
    <section>
      <h2 id="use-of-personal-information">Use of Personal Information</h2>
      <p>
        In addition to other purposes of use of Personal Information set forth
        in this Privacy Policy, Vilo Gelato collects Personal Information and
        may use the Personal Information collected for the following purposes or
        other purposes determined by Vilo Gelato and permitted by applicable
        laws and regulations (&quot;Purpose of Use&quot;):
      </p>
      <ol>
        <li>
          <p>
            Maintain, develop, test, improve, personalize the App to meet the
            needs and preferences of users;
          </p>
        </li>
        <li>
          <p>
            Monitor and analyze the activities, behaviors and demographic data
            of Users including the habits and use of various services available
            on the Application;
          </p>
        </li>
        <li>
          <p>
            Identify and register Users and to administer, verify, deactivate or
            manage User Accounts;
          </p>
        </li>
        <li>
          <p>
            Processing and facilitating orders and payment transactions made by
            the User, including as applicable, transactions made through payment
            cards or accounts available on the Application;
          </p>
        </li>
        <li>
          <p>
            Notifying Users of transactions, activities, purchases, programs and
            services that occur in the Application or other systems connected to
            the Vilo Gelato Application;
          </p>
        </li>
        <li>
          <p>
            Send Users direct or focused product, event, marketing, advertising,
            voucher, survey, and information communications, and information
            about special offers or promotions;
          </p>
        </li>
        <li>
          <p>
            Process or manage the benefits, awards, achievements, and membership
            levels or equivalent to similar membership programs;
          </p>
        </li>
        <li>
          <p>
            Provide Users with information on the security of the product and
            notify the product recall (if any);
          </p>
        </li>
        <li>
          <p>Post User comments or statements on the Vilo Gelato website; </p>
        </li>
        <li>
          <p>Detect, prevent or investigate security breaches or fraud; </p>
        </li>
        <li>
          <p>
            Facilitate the functionality of the website or Application,
            including account and/or wallet linking, if the User performs or
            activates the linking of accounts and/or wallets between the
            User&apos;s Account and the Third Party platform;
          </p>
        </li>
        <li>
          <p>
            Communicate with Users and send Users information in connection with
            the use of the Application;
          </p>
        </li>
        <li>
          <p>
            Processing and responding to inquiries and suggestions received from
            Users;
          </p>
        </li>
        <li>
          <p>
            Notifying the User of any updates to the Application or changes to
            the Services provided;
          </p>
        </li>
        <li>
          <p>
            Offer or provide services from Vilo Gelato affiliates or Third
            Parties that cooperate with Vilo Gelato.
          </p>
        </li>
      </ol>
      <p>
        Vilo Gelato may also use the User&apos;s Personal Information more
        generally for the following purposes:
      </p>
      <ol>
        <li>
          <p>
            Monitor the use of the Application and manage, support and improve
            the efficiency of performance, development, User experience and
            functions of the Application;
          </p>
        </li>
        <li>
          <p>
            Provide assistance in connection with and to resolve technical
            difficulties or operational issues with the Application or Services;
          </p>
        </li>
        <li>
          <p>
            Generate statistical information and analytical data for the
            purposes of testing, research, analysis, product development,
            commercial partnerships, and cooperation;
          </p>
        </li>
        <li>
          <p>
            Prevent, detect and investigate any prohibited, illegal,
            unauthorized, or fraudulent activities;
          </p>
        </li>
        <li>
          <p>
            Facilitating business asset transactions (which may be in the form
            of mergers, acquisitions, or asset sales) involving Vilo Gelato
            and/or Vilo Gelato&apos;s affiliates; and
          </p>
        </li>
        <li>
          <p>
            Enable Vilo Gelato to comply with all obligations under applicable
            laws and regulations, including but not limited to responding to
            regulatory requests, investigations or directives, complying with
            filing, reporting and licensing requirements required by applicable
            regulations) and conducting audits, due diligence and
            investigations.
          </p>
        </li>
      </ol>
      <p>
        Vilo Gelato may create, use, license or disclose Personal Information,
        which is available, with a record,
      </p>
      <ol type="i">
        <li>
          <p>
            that all identifiable things have been removed so that the data,
            either alone or in combination with other available data, cannot be
            linked to or associated with or cannot identify an individual, and
          </p>
        </li>
        <li>
          <p>
            Similar data has been combined so that the original data forms part
            of a larger dataset.
          </p>
        </li>
      </ol>
    </section>
    <section>
      <h2 id="disclosure-of-personal-information">
        Disclosure of Personal Information
      </h2>
      <p>
        By using the In-App Services, the User hereby agrees that Vilo Gelato
        may share Personal Information between companies in the Vilo Gelato
        group, disclose, provide access to or share Personal Information with
        Service providers, individuals and other parties(including agents,
        vendors, suppliers, contractors, partners, financial parties (including
        payment processors and facilitators) and other parties who provide
        services to Vilo Gelato or the User, perform duties on behalf of Vilo
        Gelato, or the party with whom Vilo Gelato has commercial cooperation)
        (&quot;Third Party&quot;) for the Purpose of Use, the following purposes
        or any other purpose set out in this Privacy Policy or permitted by
        applicable laws and regulations:
      </p>
      <ol>
        <li>
          <p>
            To facilitate the Vilo Gelato Services, provide the Services on
            behalf of Vilo Gelato (including those performed by Third Party
            service providers);
          </p>
        </li>
        <li>
          <p>
            To assist Vilo Gelato in analyzing how the Vilo Gelato Services are
            used;
          </p>
        </li>
        <li>
          <p>
            To process the User&apos;s transactions or activities in the
            Application within the framework of cooperation between Vilo Gelato
            and a Third Party, which may include, among other things, allowing
            such Third Party to introduce or offer products or services to the
            User, authenticate the User or connect the User with the Account or
            carry out other activities including marketing, research, analysis,
            research, product development or other purposes as specified in the
            cooperation between Vilo Gelato and Third Parties. However, the
            Third Party is obliged not to disclose or use such User&apos;s
            Personal Information for purposes other than those agreed with Vilo
            Gelato;
          </p>
        </li>
        <li>
          <p>
            For the purposes as stated in the applicable government policies,
            regulations or laws and regulations (if instructed, requested,
            required or permitted by the competent government);
          </p>
        </li>
        <li>
          <p>
            For the purposes specified in the applicable laws and regulations
            (including but not limited to responding to questions related to
            regulations, investigations or guidelines, or complying with the
            requirements or conditions of filing and reporting under the law)
            and if such is required or authorized by the applicable laws and
            regulations;
          </p>
        </li>
        <li>
          <p>
            For the purposes of legal proceedings in any form between the User
            and Vilo Gelato, or between the User and other parties, in
            connection with, or related to the Service;
          </p>
        </li>
        <li>
          <p>
            For the purposes of a merger transaction, sale of corporate assets,
            consolidation or restructuring, financing or acquisition of all or
            part of the business of Vilo Gelato or its affiliates by or to
            another company (even if such transaction is subsequently
            discontinued); and/or
          </p>
        </li>
        <li>
          <p>
            For any other purposes that we will notify the User of at the time
            of obtaining the User&apos;s consent.
          </p>
        </li>
      </ol>
      <p>
        In situations related to the User&apos;s health or the public interest,
        Vilo Gelato may share Personal Information with the authorized
        government and/or other institutions that may be appointed by the
        authorized government or have cooperation with Vilo Gelato, for the
        purpose of contact tracing, supporting government initiatives, policies
        or programs, public health and other purposes as reasonably necessary.
      </p>
      <p>
        For the avoidance of doubt, Vilo Gelato will not sell or use Personal
        Information from Users for commercial purposes other than those
        mentioned above.
      </p>
    </section>
    <section>
      <h2 id="international-transfer-of-personal-information">
        International Transfer of Personal Information
      </h2>
      <p>
        Subject to applicable privacy laws, the User&apos;s Personal Information
        may be transferred, stored, or processed outside of the User&apos;s
        country for one or more Purposes of Use. The User agrees and grants
        permission to Vilo Gelato to transfer Personal Information outside of
        the User&apos;s country to a Third Party.
      </p>
      <p>
        When Vilo Gelato transfers the User&apos;s Personal Information outside
        of the User&apos;s country, Vilo Gelato will be subject to legal and
        regulatory obligations related to the User&apos;s Personal Information
        and place appropriate safeguards in place to ensure an adequate level of
        protection for the Personal Information. Vilo Gelato will also ensure
        that recipients in the relevant countries are obliged to protect the
        User&apos;s Personal Information with a standard of protection
        comparable to the protection under applicable privacy laws.
      </p>
    </section>
    <section>
      <h2 id="security-of-personal-information">
        Security of Personal Information
      </h2>
      <p>
        The confidentiality and security of Personal Information from Users is
        of utmost importance to Vilo Gelato. Vilo Gelato protects User
        information using technical, physical, and administrative security
        measures to reduce the risk of loss, misuse, unauthorized access,
        disclosure, or modification of Personal Information, including:
      </p>
      <ol>
        <li>
          <p>
            When a User transmits highly sensitive information (such as a credit
            card number) through the Vilo Gelato Application, Vilo Gelato
            encrypts the transmission of that information following the
            recommendations of the Secure Sockets Layer (SSL) protocol.
          </p>
        </li>
        <li>
          <p>
            Vilo Gelato reviews its information collection, storage, and
            processing practices, including physical security measures to avoid
            unauthorized access to Vilo Gelato&apos;s systems.
          </p>
        </li>
        <li>
          <p>
            Vilo Gelato restricts access to Personal Information to Vilo Gelato
            employees, contractors, and agents who need Personal Information to
            process it. Anyone with this procedure is subject to a duty of
            confidentiality and may be subject to sanctions or dismissal if they
            fail to comply with this obligation.
          </p>
        </li>
      </ol>
      <p>
        Although Vilo Gelato has used security technologies and processes to
        help safeguard the User&apos;s Personal Information, no method of
        transmission over the internet or method of electronic storage is 100%
        secure for the User nor warrants that such Personal Information will not
        be intercepted, accessed, disclosed, altered or destroyed by
        unauthorized third parties, due to factors beyond Vilo Gelato&apos;s
        control; therefore, Vilo Gelato cannot guarantee its absolute safety.
        The User is responsible for maintaining the confidentiality of the
        User&apos;s Account details, including the User&apos;s password and
        shall always maintain and be responsible for the security of the device
        used by the User.
      </p>
    </section>
    <section>
      <h2 id="storage-and-deletion-of-personal-information">
        Storage and Deletion of Personal Information
      </h2>
      <p>The User understands and acknowledges the following: </p>
      <ol>
        <li>
          <p>
            Personal Information from Users collected may be stored,
            transferred, or processed by Third Party service providers. Vilo
            Gelato will use all reasonable efforts to ensure that all such Third
            Party service providers provide a level of protection comparable to
            Vilo Gelato&apos;s commitments under this Privacy Policy.
          </p>
        </li>
        <li>
          <p>
            Vilo Gelato retains the User&apos;s Personal Information for the
            period necessary to fulfill the Purpose of Use, or any other purpose
            outlined in this Privacy Policy or as permitted by applicable laws
            and regulations unless a longer retention period is required or
            permitted by law. Once the User&apos;s Personal Information is no
            longer necessary for the Services, the Purpose of Use, or any other
            purpose, or Vilo Gelato no longer has a legal or business purpose
            for retaining the Personal Information, Vilo Gelato takes steps to
            delete, destroy, anonymize or prevent access to or use of such
            Personal Information for any purpose other than compliance with this
            Privacy Policy, or for safety purposes, security, fraud prevention
            and detection, in accordance with applicable legal requirements.
          </p>
        </li>
        <li>
          <p>
            The User understands that some Personal Information may be stored by
            other parties including government institutions in certain ways. In
            the event that Vilo Gelato shares Personal Information with
            authorized government institutions and/or other institutions that
            may be appointed by the authorized government or have cooperation
            with Vilo Gelato, the User agrees and acknowledges that the storage
            of Personal Information by the relevant institution will follow the
            data retention policies of each such institution.
          </p>
        </li>
      </ol>
      <p>
        Users can delete their personal data anytime and anywhere, such as name,
        email address, and activity history, by visiting the &quot;Delete
        Account&quot; feature on the application or website accessed.
      </p>
      <p>
        The User shall understand and acknowledge that after the deletion of
        Personal Information and account, the User will not be able to use the
        Application and services. The deletion of Personal Information and
        accounts by the User may lead to the termination of the User&apos;s
        account or contractual relationship with Vilo Gelato, with all rights
        and obligations arising from remaining fully reserved by law.
      </p>
    </section>
    <section>
      <h2 id="managing-and-updating-personal-information">
        Managing and Updating Personal Information
      </h2>
      <p>
        Subject to the applicable laws and regulations, the User can access
        and/or update the Personal Information in Vilo Gelato&apos;s possession
        and control by visiting the &quot;Delete Account&quot; feature on the
        application or website accessed.
      </p>
      <p>
        Vilo Gelato has the right to refuse the User&apos;s request to access,
        or to correct, part or all of the Personal Information that Vilo Gelato
        has or controls if permitted or necessary under applicable laws. This
        includes circumstances where the Personal Information may contain
        references to others or where a request for access or a request for
        correction is for reasons that Vilo Gelato considers irrelevant,
        unserious or difficult.
      </p>
    </section>
    <section>
      <h2 id="links-to-third-party-sites">
        Links to Third-Party Sites or Platforms
      </h2>
      <p>
        The Service may contain links to other third-party sites or platforms.
        If the User clicks on a third-party link, the User will be redirected to
        that site or platform. Please note that this external site or platform
        is not operated by Vilo Gelato. Therefore, Vilo Gelato strongly advises
        Users to review the privacy policies of such sites or platforms. Vilo
        Gelato has no control over and is not responsible for the content,
        privacy policies, or practices of any third-party sites, platforms or
        services.
      </p>
    </section>
    <section>
      <h2 id="childrens-privacy">Children&apos;s Privacy</h2>
      <p>
        Vilo Gelato does not intend for the website or the App&apos;s online
        Services to be used by anyone under the age of any applicable law and
        regulation, except under parental or guardian supervision. If the User
        is a parent or guardian and the User becomes aware that the User&apos;s
        child has provided Personal Information to Vilo Gelato, please contact
        Vilo Gelato so that Vilo Gelato can be able to take the necessary
        action.
      </p>
    </section>
    <section>
      <h2 id="cookies">Cookies</h2>
      <p>
        &quot;Cookies&quot; are files with small amounts of data that are
        typically used as anonymous unique identifiers. It is sent to the
        User&apos;s browser from the website that the User visits and is stored
        in the internal memory of the User&apos;s device.
      </p>
      <p>
        When Users use the Application and Services, certain information may be
        collected automatically by using Cookies. In addition, the Application
        may use Third Party code and libraries that use Cookies to collect
        information and improve the Service. Users can disable Cookies by
        clearing the cache of the User Application. If the User disables Vilo
        Gelato Cookies, the User may not be able to use some parts of the Vilo
        Gelato Service.
      </p>
    </section>
    <section>
      <h2 id="how-to-contact">How to contact Vilo Gelato</h2>
      <p>
        If you have any questions regarding this Privacy Policy or if you would
        like to gain access to your Personal Information, please contact{' '}
        <a href={`mailto:${company.config.feedbackEmail}`}>
          {company.config.feedbackEmail}
        </a>
      </p>
    </section>
    <section>
      <h2 id="terms-and-conditions">Terms & Conditions</h2>
      <ol className={styles.viloTAC}>
        <li>
          <h3>AGREEMENT TO TERMS</h3>
          <p>These terms and conditions govern your use of our website.</p>
          <p>
            This agreement between you and PT Vilo Kreasi Rasa (the
            &quot;Company&quot;, &quot;Vilo&quot;, &quot;we&quot;,
            &quot;us&quot;, or &quot;our&quot;) governs your use of our website
            and any related media. By using our website, you agree to these
            terms and conditions.
          </p>
          <p>
            Vilo may change these terms and conditions from time to time. We
            will notify you of any changes by updating the &quot;Last
            updated&quot; date. You should review the terms and conditions each
            time you use our website. Your continued use of our website after
            changes are made means you agree to the new terms and conditions.
          </p>
          <p>
            Our website is not intended for use in any jurisdiction where it
            would be illegal or subject us to registration requirements. If you
            access our website from outside of Indonesia, you are responsible
            for complying with local laws.
          </p>
          <p>
            Users/Customers under 18 are not allowed to use our website. We may
            require age verification to ensure compliance. Few of our special
            product contains alcohol. Additionally, customers are responsible
            for maintaining the confidentiality of their account information and
            notifying us immediately if they suspect unauthorized access. By
            agreeing to these terms, customers acknowledge their understanding
            of the age restrictions, alcohol product information, and their
            responsibility for responsible consumption.
          </p>
        </li>
        <li>
          <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
          <p>
            Vilo own the website and its content. <b>Unless</b> stated
            otherwise, Vilo own the website and everything on it, including the
            code, databases, design, audio, video, text, photos, and graphics.
            We also own the trademarks, service marks, and logos on the website.
            These things are protected by copyright and trademark laws. User can
            only use the website and its content for your personal,
            non-commercial use. User can&apos;t copy, reproduce, or sell any
            part of the website or its content without our permission.
          </p>
        </li>
        <li>
          <h3>USER REPRESENTATIONS</h3>
          <p>By using the Site, you represent and warrant that:</p>
          <ol className={'letters'}>
            <li>
              all registration information you submit will be true, accurate,
              current, and complete;
            </li>
            <li>
              you will maintain the accuracy of such information and promptly
              update such registration information as necessary;
            </li>
            <li>
              you have the legal capacity and you agree to comply with these
              Terms of Use;
            </li>
            <li>
              you are not a minor in the jurisdiction in which you reside, or if
              a minor, you have received parental permission to use the Site;
            </li>
            <li>
              you will not access the Site through automated or non-human means,
              whether through a bot, script or otherwise;
            </li>
            <li>
              you will not use the Site for any illegal or unauthorized purpose;
              and
            </li>
            <li>
              your use of the Site will not violate any applicable law or
              regulation.
            </li>
          </ol>
          <p>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Site (or any portion thereof).
          </p>
        </li>
        <li>
          <h3>USER REGISTRATION</h3>
          <p>
            User may be required to register with the Site. You agree to keep
            your password confidential and will be responsible for all use of
            your account and password. We reserve the right to remove, reclaim,
            or change a username you select if we determine, in our sole
            discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </p>
        </li>
        <li>
          <h3>PRODUCTS</h3>
          <p>
            Vilo try to show you the correct information about our products, but
            we can&apos;t guarantee that it&apos;s always perfect. We do our
            best to show you the colours, features, specifications, and
            ingredients of our products accurately. However, we can&apos;t
            guarantee that this information is always correct, complete,
            reliable, or up-to-date. Your screen might not show the colours and
            details exactly as they are. All products are available while
            supplies last, and we can stop selling any product at any time.
          </p>
        </li>
        <li>
          <h3>PURCHASES AND PAYMENT</h3>
          <p>Vilo accepts Visa, Mastercard, and e-wallets for payments.</p>
          <p>
            User must provide accurate information for their purchases. You
            should update your information if it changes. We will add sales tax
            to your purchases if necessary. We can change prices at any time.
          </p>
          <p>
            User agree to pay the price of their purchases and any shipping
            fees. We will charge your chosen payment method when you place your
            order. We can correct any mistakes in pricing, even if we&apos;ve
            already charged you.
          </p>
          <p>
            Vilo can refuse any order placed on our website. We can limit the
            number of items you can buy per person, household, or order. We can
            also limit or prohibit orders that we think are placed by dealers or
            resellers.
          </p>
        </li>
        <li>
          <h3>RETURN POLICY</h3>
          <p>
            All sales are final and a refund will be applied in certain cases
            where the damage is caused on the Vilo or the Logistic side of
            operations.
          </p>
        </li>
        <li>
          <h3>PROHIBITED ACTIVITIES</h3>
          <p>
            User may not access or use the Site for any purpose other than that
            for which we make the Site available. The Site may not be used in
            connection with any commercial endeavours except those that are
            specifically endorsed or approved by us.
          </p>
          <p>As a user of the Site, you agree not to:</p>
          <ol className={'letters'}>
            <li>
              Systematically retrieve data or other content from the Site to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
            </li>
            <li>
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user
              passwords.
            </li>
            <li>
              Circumvent, disable, or otherwise interfere with security-related
              features of the Site, including features that prevent or restrict
              the use or copying of any Content or enforce limitations on the
              use of the Site and/or the Content contained therein.
            </li>
            <li>
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or
              the Site.
            </li>
            <li>
              Use any information obtained from the Site in order to harass,
              abuse, or harm another person.
            </li>
            <li>
              Make improper use of our support services or submit false reports
              of abuse or misconduct.
            </li>
            <li>
              Use the Site in a manner inconsistent with any applicable laws or
              regulations.
            </li>
            <li>Engage in unauthorized framing of or linking to the Site.</li>
            <li>
              Upload or transmit (or attempt to upload or to transmit) viruses,
              Trojan horses, or other material, including excessive use of
              capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party&apos;s uninterrupted use and
              enjoyment of the Site or modifies, impairs, disrupts, alters, or
              interferes with the use, features, functions, operation, or
              maintenance of the Site.
            </li>
            <li>
              Engage in any automated use of the system, such as using scripts
              to send comments or messages, or using any data mining, robots, or
              similar data gathering and extraction tools.
            </li>
            <li>
              Delete the copyright or other proprietary rights notice from any
              Content.
            </li>
            <li>
              Attempt to impersonate another user or person or use the username
              of another user.
            </li>
            <li>
              Interfere with, disrupt, or create an undue burden on the Site or
              the networks or services connected to the Site.
            </li>
            <li>
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Site to you.
            </li>
            <li>
              Attempt to bypass any measures of the Site designed to prevent or
              restrict access to the Site, or any portion of the Site.
            </li>
            <li>
              Copy or adapt the Site&apos;s software, including but not limited
              to Flash, PHP, HTML, JavaScript, or other code.
            </li>
            <li>
              Except as permitted by applicable law, decipher, decompile,
              disassemble, or reverse engineer any of the software comprising or
              in any way making up a part of the Site.
            </li>
            <li>
              Except as may be the result of standard search engine or Internet
              browser usage, use, launch, develop, or distribute any automated
              system, including without limitation, any spider, robot, cheat
              utility, scraper, or offline reader that accesses the Site, or
              using or launching any unauthorized script or other software.
            </li>
            <li>
              Use a buying agent or purchasing agent to make purchases on the
              Site.
            </li>
            <li>
              Make any unauthorized use of the Site, including collecting
              usernames and/or email addresses of users by electronic or other
              means for the purpose of sending unsolicited email, or creating
              user accounts by automated means or under false pretenses.
            </li>
            <li>
              Use the Site as part of any effort to compete with us or otherwise
              use the Site and/or the Content for any revenue-generating
              endeavor or commercial enterprise.
            </li>
          </ol>
        </li>
        <li>
          <h3>CONTRIBUTION LICENSE</h3>
          <p>
            User agree that Vilo may access, store, process, and use any
            information and personal data that you provide following the terms
            of the Privacy Policy and your choices (including settings). By
            submitting suggestions or other feedback regarding the Site, you
            agree that we can use and share such feedback for any purpose
            without compensation to you.
          </p>
          <p>
            Vilo do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area on
            the Site. You are solely responsible for your Contributions to the
            Site and you expressly agree to exonerate us from any and all
            responsibility and to refrain from any legal action against us
            regarding your Contributions.
          </p>
        </li>
        <li>
          <h3>GUIDELINES FOR REVIEWS</h3>
          <p>If you leave a review, please follow these rules:</p>
          <ol className="letters">
            <li>
              You should have personal experience with the person or thing
              you&apos;re reviewing.
            </li>
            <li>
              Your reviews should not be offensive, racist, hateful, or
              discriminatory.
            </li>
            <li>Your reviews should not mention illegal activity.</li>
            <li>
              You should not post negative reviews if you work for a competitor.
            </li>
            <li>
              You should not make statements about the legality of something.
            </li>
            <li>You can&apos;t post false or misleading information.</li>
            <li>
              You can&apos;t organize a campaign to get people to post reviews.
            </li>
          </ol>
          <p>
            Vilo can decide whether to accept, reject, or remove reviews. We
            don&apos;t have to check reviews or delete them, even if someone
            thinks they&apos;re bad or wrong. Reviews don&apos;t represent our
            opinions or the opinions of our partners. We&apos;re not responsible
            for any reviews or for any problems that might happen because of a
            review.
          </p>
          <p>
            By posting a review, you give us permission to use it. When you post
            a review, you give us permission to use it in any way we want,
            including copying, modifying, translating, transmitting, displaying,
            performing, or distributing it.
          </p>
        </li>
        <li>
          <h3>SUBMISSIONS</h3>
          <p>
            Any information you give us becomes our property. If you give us any
            questions, comments, suggestions, ideas, feedback, or other
            information about our website or services, it becomes our property.
            We own all rights to this information, and we can use it for any
            purpose, commercial or otherwise, without giving you anything in
            return. You agree that we won&apos;t be responsible for any
            infringement or misappropriation of your rights related to this
            information.
          </p>
        </li>
        <li>
          <h3>SITE MANAGEMENT</h3>
          <p>
            Vilo can monitor and take action on our website. The monitoring of
            our website for people who violate our terms and conditions. We can
            take legal action against people who violate the law or our terms
            and conditions, including reporting them to the police. We can
            refuse access to or remove any of your content if we think it&apos;s
            necessary. We can remove any files or content that are too big or
            cause problems for our systems. We can manage our website in any way
            we think is necessary to protect our rights and property and to keep
            our website and services running smoothly.
          </p>
        </li>
        <li>
          <h3>PRIVACY POLICY</h3>
          <p>
            User submission of personal information through the store is
            governed by our Privacy Policy. Please see our Privacy Policy.
          </p>
        </li>
        <li>
          <h3>TERM AND TERMINATION</h3>
          <p>
            Vilo can stop you from using our website at any time. These terms
            and conditions continue to apply as long as you use our website. We
            can stop you from using our website or services, including by
            blocking your IP address, for any reason, even if you&apos;re not
            breaking any rules. We can delete your account and any content you
            posted at any time without warning.
          </p>
          <p>
            If we terminate your account, you can&apos;t create a new one. If we
            stop you from using our website, you can&apos;t create a new account
            under your name or someone else&apos;s name. We can also take legal
            action against you, including civil, criminal, or injunctive relief.
          </p>
        </li>
        <li>
          <h3>MODIFICATIONS AND INTERRUPTIONS</h3>
          <p>
            Vilo can change, update, or remove anything on our website at any
            time without telling you. We don&apos;t have to keep the information
            on our website up-to-date. We can also stop offering some or all of
            our services without notice. We won&apos;t be responsible if you
            can&apos;t use our website or services due to changes or
            interruptions.
          </p>
          <p>
            Vilo can&apos;t guarantee that our website and services will always
            be available. There might be technical problems or maintenance that
            cause interruptions. We can change, update, or stop our website or
            services at any time without prior information or notice. We
            won&apos;t be responsible for any problems caused by you not being
            able to use our website or services. We&apos;re not obligated to
            keep our website or services running or to provide updates or fixes.
          </p>
        </li>
        <li>
          <h3>GOVERNING LAW</h3>
          <p>
            These terms shall be governed by and defined following the laws of
            the Republic of Indonesia. Vilo Gelato and you irrevocably consent
            that the courts of the Republic of Indonesia shall have exclusive
            jurisdiction to resolve any dispute that may arise in connection
            with these terms.
          </p>
        </li>
        <li>
          <h3>DISPUTE RESOLUTION</h3>
          <p>
            Before resorting to formal legal proceedings, we will endeavour to
            resolve any disputes informally through negotiation. However, if a
            resolution cannot be reached, the parties agree to submit any
            unresolved disputes to binding arbitration in accordance with the
            rules of the Indonesian National Arbitration Board (BANI). The
            arbitration will be conducted in Jakarta, Indonesia. The decision of
            the arbitrator shall be final and binding upon both Parties.
          </p>
        </li>
        <li>
          <h3>CORRECTIONS</h3>
          <p>
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions that may relate to the
            Marketplace Offerings, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior
            notice.
          </p>
        </li>
        <li>
          <h3>DISCLAIMER</h3>
          <p>
            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
            THAT YOUR USE OF THE SITE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
            FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
            OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
            INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SITE&apos;S CONTENT OR THE CONTENT
            OF ANY WEBSITES LINKED TO THIS SITE AND WE WILL ASSUME NO LIABILITY
            OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SITE, (5) ANY BUGS, OR THE LIKE WHICH MAY BE TRANSMITTED
            TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
            OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
            ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
            TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
            WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
            PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
            THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
            APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
            NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
            TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
            SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
            MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
            EXERCISE CAUTION WHERE APPROPRIATE.
          </p>
        </li>
        <li>
          <h3>LIMITATIONS OF LIABILITY</h3>
          <p>
            Vilo are not responsible for any damages caused by using our
            website. We or our employees are not responsible for any direct,
            indirect, consequential, exemplary, incidental, special, or punitive
            damages, including lost profit, lost revenue, loss of data, or other
            damages caused by using our website, even if we knew it was
            possible.
          </p>
        </li>
        <li>
          <h3>INDEMNIFICATION</h3>
          <p>
            User agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys&apos;
            fees and expenses, made by any third party due to or arising out of:
          </p>
          <ol className="letters">
            <li>use of the Site;</li>
            <li>breach of these Terms of Use;</li>
            <li>
              any breach of your representations and warranties set forth in
              these Terms of Use;
            </li>
            <li>
              your violation of the rights of a third party, including but not
              limited to intellectual property rights; or
            </li>
            <li>
              any overt harmful act toward any other user of the Site with whom
              you connected via the Site. Notwithstanding the foregoing, we
              reserve the right, at your expense, to assume the exclusive
              defence and control of any matter for which you are required to
              indemnify us, and you agree to cooperate, at your expense, with
              our defence of such claims. We will use reasonable efforts to
              notify you of any such claim, action, or proceeding which is
              subject to this indemnification upon becoming aware of it.
            </li>
          </ol>
        </li>
        <li>
          <h3>USER DATA</h3>
          <p>
            Vilo will maintain certain data that you transmit to the Site for
            the purpose of managing the performance of the Site, as well as data
            relating to your use of the Site. Although we perform regular
            routine backups of data, you are solely responsible for all data
            that you transmit or that relates to any activity you have
            undertaken using the Site. You agree that we shall have no liability
            to you for any loss or corruption of any such data, and you hereby
            waive any right of action against us arising from any such loss or
            corruption of such data.
          </p>
        </li>
        <li>
          <h3>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h3>
          <p>
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email, and on the Site, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SITE. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction that require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </p>
        </li>
        <li>
          <h3>MISCELLANEOUS</h3>
          <p>
            These terms and any other rules we post govern your use of our
            website. They are the complete agreement between you and us. If we
            don&apos;t enforce a right, it doesn&apos;t mean we give it up.
            These terms are as strong as the law allows. We can transfer our
            rights and responsibilities to others. We won&apos;t be responsible
            for problems caused by things outside our control. If any part of
            these terms is illegal, it&apos;s removed, but the rest still
            applies. We&apos;re not partners, employees, or agents. These terms
            are interpreted as written, even if we wrote them. You agree to
            these terms, even though they&apos;re electronic and not signed.
          </p>
        </li>
        <li>
          <h3>CONTACT US</h3>
          <p>
            To resolve a complaint regarding the Site or to receive further
            information regarding the use of the Site, please contact us at:
          </p>
          <p>
            <b>
              PT VILO KREASI RASA (VILO GELATO)
              <br />
              Jakarta
              <br />
              Indonesia
              <br />
              Email:{' '}
              <a href={`mailto:${company.config.feedbackEmail}`}>
                {company.config.feedbackEmail}
              </a>
            </b>
          </p>
        </li>
      </ol>
    </section>
  </>
);

export default LegalsViloEng;
