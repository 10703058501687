import { classes } from 'html-classes';
import { MouseEvent, useMemo } from 'react';

import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';

import CircleIcons from '../../assets/circlekIcons/icons.svg';
import Icons from '../../assets/img/icons.svg';
import JiffyIcons from '../../assets/jiffyIcons/icons.svg';
import LocaleeIcons from '../../assets/localeeIcons/icons.svg';
import KidsIcons from '../../assets/ourkidsIcons/icons.svg';
import ViloIcons from '../../assets/viloIcons/icons.svg';

import { ICON_TYPES_NOT_TRL, IconTypes } from './IconTypes';

export interface IconProps {
  type: IconTypes;
  className?: string;
  size?: number;
  width?: number;
  height?: number;
  onClick?(event: MouseEvent): void;
}

const Icon = ({
  className,
  size = 20,
  type,
  height,
  width,
  onClick,
}: IconProps) => {
  const IconsToRender = useMemo(() => {
    switch (company.name) {
      case CompanyName.Localee:
        return LocaleeIcons;
      case CompanyName.CircleK:
        return CircleIcons;
      case CompanyName.Vilo:
        return ViloIcons;
      case CompanyName.Jiffy:
        return JiffyIcons;
      case CompanyName.Kids:
        return KidsIcons;
      default:
        return Icons;
    }
  }, []);

  return (
    <svg
      role="icon"
      width={width || size}
      height={height || size}
      onClick={onClick}
      className={classes([
        `icon icon-${type}`,
        ICON_TYPES_NOT_TRL.includes(type) && 'not_rotate',
        className,
      ])}
    >
      <use
        href={`${IconsToRender}#${type}`}
        width={width || size}
        height={height || size}
      />
    </svg>
  );
};

export default Icon;
