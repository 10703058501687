import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon/Icon';

interface ProductBadgesProps {
  data?: string[];
}

export const ProductBadges = ({ data = [] }: ProductBadgesProps) => {
  const { t } = useTranslation();
  const badges = data
    .map(prepareBadges)
    .filter((item): item is NonNullable<ReturnType<typeof prepareBadges>> =>
      Boolean(item),
    );
  return (
    badges.length > 0 && (
      <div className="product-badges">
        {badges.map(({ color, key }) => (
          <div key={key} className="product-badges__item">
            <Icon size={26} type={key as never} />
            <div style={{ color }}>{t(key)}</div>
          </div>
        ))}
      </div>
    )
  );
};

const prepareBadges = (badge: string) => {
  switch (badge) {
    case 'Vegan':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#66BA48',
      };
    case 'Kosher':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#837E7F',
      };
    case 'Sugar free':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#68A8BE',
      };
    case 'Low fat':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#A17D57',
      };
    case 'GMO free':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#837E7F',
      };
    case 'Halal':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#4F4F4F',
      };
    case 'Organic':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#9AC719',
      };
    case 'Vegetarian':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#017F00',
      };
    case 'Only for adults':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#EA2B2B',
      };
    case 'May contain nuts':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#D4AA12',
      };
    case 'Gluten free':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#EF9500',
      };
    case 'Dairy free':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#7DA9B3',
      };
    case 'Allergen free':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#EF9500',
      };
    case 'No added sugar':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#AA263E',
      };
    case 'Fairtrade':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#837E7F',
      };
    case 'Suitable for freezing':
      return { key: 'suitable-for-freezing', color: '#7DA9B3' };
    case 'Contains soya':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#D4AA12',
      };
    case 'Contains sulphites':
      return {
        key: badge.toLowerCase().replaceAll(' ', '-'),
        color: '#EA2B2B',
      };

    default:
      return null;
  }
};
