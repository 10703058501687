import isNil from 'lodash-es/isNil';
import isNumber from 'lodash-es/isNumber';
import { runInAction } from 'mobx';

import { CatalogRequests, ProductAPI, Variant } from '~/api/Catalog';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';
import { parseProperties, parseVariants } from '~/types/Product';
import { ProductProperties } from '~/types/Product/interface';
import {
  calcDiscountPercentage,
  convertPenceToPounds,
} from '~/utils/formaters';

import { Offer } from './Offer';

export interface LoadProductParams {
  warehouse: string;
  productId: string | number;
  lang?: string;
}

export class Product {
  public readonly id: number;
  public readonly productId: number;
  public readonly name: string;
  public readonly priceFormatted: string;
  public readonly discountPriceFormatted: string;
  public readonly salesQuantity: number;
  public readonly ratingAverage: number;
  public readonly ratingMarksCount: number;
  public readonly productType: 'product' | 'bundle';
  public readonly sku: Nullable<string>;
  public readonly code: Nullable<string>;
  public readonly slug: string;
  public readonly sellableWarehouses: Record<string, string>;
  public readonly categoryId: number;
  public readonly categoryName: string;
  public readonly parentCategoryId: number;
  public readonly description: string;
  public readonly productSlug: string;
  public readonly isBackorderAvailable: boolean;
  public readonly createdAt: string;
  public readonly metadataTitle: Nullable<string>;
  public readonly metadataDescription: Nullable<string>;
  public readonly variants: Variant[];
  public readonly properties: ProductProperties;
  public readonly offers: Offer[];
  public readonly previewImage: string;
  public readonly previewImageThumb: string;
  public readonly images: string[];
  public readonly backorderLeadTime: number;
  public readonly promoQuantityDiscountPrice: number;
  public readonly promoQuantityDiscountPriceFormatted: string;
  public readonly promoRequiredQuantity: number;
  public readonly pricePerUnit: string;
  public readonly discountPercentage: string;

  public price: number;
  public discountPrice: number;
  public sellable: number;

  constructor(product: ProductAPI) {
    this.id = product.id;
    this.productId = product.id;
    this.name = product.name;
    this.price = product.price || 0;
    this.priceFormatted = convertPenceToPounds(this.price);
    this.salesQuantity = product.salesQuantity || 0;
    this.discountPrice = product.discountPrice || 0;
    this.discountPriceFormatted = convertPenceToPounds(this.discountPrice);
    this.ratingAverage = product.ratingAverage || 0;
    this.ratingMarksCount = product.ratingMarksCount || 0;
    this.productType = product.productType as 'product' | 'bundle';
    this.sku = product.code;
    this.code = product.code;
    this.slug = product.slug;
    this.sellable = product.sellable || 0;
    this.sellableWarehouses = product.sellableWarehouses || {};
    this.categoryId = product.categoryId || -1;
    this.categoryName = product.categoryName || '';
    this.parentCategoryId = product.parentCategoryId || -1;
    this.description = product.description || '';
    this.productSlug = product.slug;
    this.isBackorderAvailable = !!product.isBackorderAvailable;
    this.createdAt = product.createdAt || '';
    this.metadataTitle = product.metadataTitle;
    this.metadataDescription = product.metadataDescription;
    this.categoryName = product.categoryName;
    this.parentCategoryId = product.parentCategoryId;
    this.variants = product.variants || [];
    this.properties = product.offerProperties?.length
      ? parseProperties(product.offerProperties)[0]
      : parseVariants(product.variants || []);
    this.offers = (product.offers || [])
      .filter((data) => Offer.validate(data, this))
      .map((data) => new Offer(data, this));

    this.images = (product.images || [])
      .map(({ url, thumb }) => url || thumb || '')
      .filter(Boolean);
    this.previewImage = product.previewImage?.url || this.images[0] || '';
    this.previewImageThumb =
      product.previewImageThumb?.thumb ||
      product.previewImageThumb?.url ||
      product.previewImage?.thumb ||
      product.previewImageThumb?.url ||
      this.images[0] ||
      '';

    const firstOffer = this.offers[0];
    const offersBackorderLeadTime = this.offers
      .map(({ backorderLeadTime }) => backorderLeadTime)
      .filter(isNumber);

    this.backorderLeadTime = offersBackorderLeadTime.length
      ? Math.max(...offersBackorderLeadTime)
      : product.backorderLeadTime || 0;
    this.promoQuantityDiscountPrice =
      firstOffer?.promoQuantityDiscountPrice ||
      product.minBasketDiscountPrice ||
      0;
    this.promoQuantityDiscountPriceFormatted = convertPenceToPounds(
      this.promoQuantityDiscountPrice,
    );
    this.promoRequiredQuantity =
      firstOffer?.promoRequiredQuantity ||
      product.minBasketDiscountQuantity ||
      0;
    this.pricePerUnit = firstOffer?.pricePerUnit || '';
    const discount = calcDiscountPercentage(this.price, this.discountPrice);
    this.discountPercentage = discount ? `-${discount}%` : '';
  }

  public static compareId(id: string | number, product: ProductAPI | Product) {
    return String(id) === product.slug || Number(id) === product.id;
  }

  public static validate(product: ProductAPI) {
    return (
      !isNil(product.id) &&
      Boolean(product.name) &&
      !isNil(product.price) &&
      product.price > 0
    );
  }

  public static async requestById(
    productId: string | number,
  ): Promise<Product> {
    const { data } = await getProductById({
      productId,
      warehouse: orderStore.etaWarehouseCode,
    });

    return new Product(data);
  }

  public static async loadOffer(product: Product, sku: string) {
    const { data } = await CatalogRequests.getOffers({
      warehouseCode: orderStore.etaWarehouseCode,
      productId: product.id,
      sku,
    });

    const offersMap = new Map(
      product.offers.map((offer) => [offer.sku, offer]),
    );

    data.forEach((item) => offersMap.set(item.code, new Offer(item, product)));

    runInAction(() => {
      Object.assign(product, { offers: Array.from(offersMap.values()) });
    });

    return offersMap;
  }
}

export const getProductById = (
  { productId, warehouse }: LoadProductParams,
  lang?: string,
) => {
  return CatalogRequests.getProduct(
    String(productId),
    {
      warehouseCode: warehouse,
      deviceId: userStore.deviceId,
      customerId: userStore.isAuthorized
        ? userStore.personalData.id || undefined
        : undefined,
    },
    lang,
  );
};
