import 'swiper/css';
import 'swiper/css/pagination';

import { classes } from 'html-classes';
import { debounce } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { Children, isValidElement, ReactNode, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { company } from '~/company/Company';
import { useGlobal } from '~/hooks/useGlobal';
import { userStore } from '~/stores/UserStore';

import { SwiperWithSlidesPerView } from '../CategoryBlocks/BannerProductBlock/interfaces';
import SkeletonListRows from '../Skeleton/SkeletonList';

import styles from './Swiper.module.scss';
import SwiperErrorBoundary from './SwiperErrorBoundary';
import SwiperIcon, { SwiperIconProps } from './SwiperIcon';

type Props = {
  children: ReactNode[];
  arrows?: boolean;
  spaceBetween?: number;
  slidesPerViewProp?: number | 'auto';
  isPagination?: boolean;
  isAutoplay?: boolean;
  slideClassName?: string;
  sliderClassName?: string;
  skeletonClassName?: string;
  paginationClassName?: string;
  scrollAuto?: boolean;
  leftArrowClassName?: string;
  rightArrowClassName?: string;
  containerClassName?: string;
  skeletonComponent?: ReactNode;
  skeletonCount?: number;
  slideVariant?: SwiperIconProps['variant'];
  slidesOffsetAfter?: number;
  slidesOffsetBefore?: number;
};

const SwiperComponent = ({
  children,
  spaceBetween = 20,
  slidesPerViewProp = 'auto',
  isPagination,
  isAutoplay,
  slideClassName,
  sliderClassName,
  skeletonClassName,
  paginationClassName,
  scrollAuto = false,
  leftArrowClassName,
  rightArrowClassName,
  containerClassName,
  skeletonComponent,
  skeletonCount,
  slideVariant = 'fill',
  arrows = true,
  slidesOffsetAfter = 0,
  slidesOffsetBefore = 0,
}: Props) => {
  const { isMobile } = useGlobal();

  const [slidesPerView, setSlidesPerView] = useState<number>(1);
  const [, setActiveSwiperIndex] = useState(0);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);
  const swiperRef = useRef<SwiperRef>(null);

  const initializeSwiperArrowsDebouncedRef = useRef(
    debounce((swiper: SwiperWithSlidesPerView) => {
      if (!swiper.slides?.length) {
        setSlidesPerView(1);
        setIsBeginning(false);
        setIsEnd(false);
        return;
      }

      const slidesPerView =
        (slidesPerViewProp === 'auto' && swiper.slidesPerViewDynamic?.()) || 1;

      setSlidesPerView(slidesPerView);
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }, 100),
  );

  const isArrowsAllowed = (): boolean => {
    const swiper = swiperRef.current?.swiper;

    if (!swiper) {
      return false;
    }

    return (
      arrows &&
      !isMobile &&
      swiper.slides.length >
        (slidesPerViewProp === 'auto' ? slidesPerView : slidesPerViewProp) // FIXME: > or >= ?
    );
  };

  const isLooped = (): boolean => {
    const swiper = swiperRef.current?.swiper;

    if (!swiper) {
      return false;
    }

    return (
      arrows &&
      swiper.slides.length >
        (slidesPerViewProp === 'auto' ? slidesPerView : slidesPerViewProp) &&
      Boolean(isAutoplay)
    );
  };

  const isPrevArrowVisible = () =>
    isArrowsAllowed() && (!isBeginning || isLooped());

  const isNextArrowVisible = () => isArrowsAllowed() && (!isEnd || isLooped());

  if (!children || children.length === 0) {
    return (
      <div
        className={styles.skeletonContainer}
        style={{ gap: `${spaceBetween}px` }}
      >
        <SkeletonListRows rows={skeletonCount ?? 9}>
          {skeletonComponent ?? <Skeleton className={skeletonClassName} />}
        </SkeletonListRows>
      </div>
    );
  }

  return (
    <SwiperErrorBoundary>
      <div
        className={classes([
          styles.container,
          containerClassName,
          'banner-carousel carousel',
        ])}
      >
        {isPrevArrowVisible() && (
          <SwiperIcon
            position="left"
            variant={slideVariant}
            className={leftArrowClassName}
            onClick={() => {
              swiperRef.current?.swiper.slidePrev();
              setIsEnd(false);
              if (swiperRef.current?.swiper.isBeginning) {
                setIsBeginning(true);
              }
            }}
          />
        )}
        <Swiper
          ref={swiperRef}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerViewProp}
          slidesPerGroupAuto={scrollAuto}
          pagination={{
            enabled: Boolean(isPagination),
            horizontalClass: classes([styles.pagination, paginationClassName]),
          }}
          autoplay={
            isAutoplay && {
              delay: 5000,
              disableOnInteraction: false,
            }
          }
          loop={isAutoplay}
          modules={[Pagination, Autoplay]}
          onSlideChange={(swiper) => setActiveSwiperIndex(swiper.activeIndex)}
          onSlidesLengthChange={initializeSwiperArrowsDebouncedRef.current}
          className={classes([styles.swiper, sliderClassName])}
          dir={userStore.dir}
          key={userStore.dir}
          slidesOffsetAfter={slidesOffsetAfter}
          slidesOffsetBefore={slidesOffsetBefore}
        >
          {Children.map(children, (child, id) => {
            const key =
              isValidElement(child) &&
              child.key !== null &&
              child.key !== undefined
                ? child.key
                : id;

            return (
              <SwiperSlide
                data-company={company.name}
                key={key}
                className={slideClassName}
              >
                {child}
              </SwiperSlide>
            );
          })}
        </Swiper>
        {isNextArrowVisible() && (
          <SwiperIcon
            position="right"
            variant={slideVariant}
            className={rightArrowClassName}
            onClick={() => {
              swiperRef.current?.swiper.slideNext();
              setIsBeginning(false);
              if (swiperRef.current?.swiper.isEnd) {
                setIsEnd(true);
              }
            }}
          />
        )}
      </div>
    </SwiperErrorBoundary>
  );
};

export default observer(SwiperComponent);
