import { isToday, isTomorrow } from 'date-fns';
import { format as formatTZ } from 'date-fns-tz';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { useDateLang } from '~/hooks/useDateLang';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

interface DateItemProps {
  timeStamp: string;
  isDisabled: boolean;
  isChecked: boolean;
  onChange: (value: string) => void;
}

const DateItem = ({
  timeStamp,
  isDisabled,
  isChecked,
  onChange,
}: DateItemProps) => {
  const { t } = useTranslation();
  const inputId = useId();
  const { locale } = useDateLang();

  let day = formatTZ(new Date(+timeStamp), 'd MMMM', {
    timeZone: company.config.timeZone,
    locale,
  });
  if (isToday(+timeStamp)) {
    day = t('today');
  }
  if (isTomorrow(+timeStamp)) {
    day = t('tomorrow');
  }

  const order = checkoutStore.slotSelectionModalStore.selectedOrder;

  if (!order) {
    throw new Error('Order not found');
  }

  const cartPrice = +mainStore.convertPenceToPounds(order.base_total);
  const deliveries = checkoutStore.deliverySlots[timeStamp] || [];
  const { min, max } = deliveries.reduce(
    (acc, { delivery_price, free_delivery_threshold }) => {
      const deliveryPrice = +mainStore.convertPenceToPounds(delivery_price);
      const freeDeliveryThreshold = +mainStore.convertPenceToPounds(
        free_delivery_threshold,
      );
      const price = cartPrice >= freeDeliveryThreshold ? 0 : deliveryPrice;

      if (price > acc.max) {
        acc.max = price;
      }

      if (price < acc.min) {
        acc.min = price;
      }

      return acc;
    },
    { min: Infinity, max: -Infinity },
  );
  const isFree = max <= 0;

  return (
    <li className="list-item">
      <input
        type="radio"
        value={timeStamp}
        id={inputId}
        name="date"
        checked={isChecked}
        onChange={({ target }) => {
          onChange(target.value);
        }}
        disabled={isDisabled}
      />
      <label htmlFor={inputId}>
        <span className="text-primary">{day}</span>
        {checkoutStore.deliverySlots?.[timeStamp] && !isDisabled && (
          <span className="text-secondary">
            {isFree
              ? t('deliveryFreeShort')
              : t('priceFrom', {
                  price: formatPriceWithCurrency(min),
                })}
          </span>
        )}
      </label>
    </li>
  );
};

export default DateItem;
