import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link, useLocation } from 'react-router-dom';

import { company, DEFAULT_COMPANIES } from '~/company/Company';
import { MultibuyBadge } from '~/components/MultibuyBadge';
import { ProductImageSlider } from '~/components/ProductImageSlider';
import { SliderLayout } from '~/components/ProductImageSlider/interfaces';
import { Recommendations } from '~/components/Recommendations';
import { useGlobal } from '~/hooks/useGlobal';
import { getProductChoices } from '~/hooks/useProductChoices';
import NotFound from '~/pages/NotFound/NotFound';
import { ProductViewProps } from '~/pages/Product/Product';
import { ProductBadges } from '~/pages/Product/ProductBadges';
import { catalogStore } from '~/stores/CatalogStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';

import Icon from '../../../components/Icon/Icon';
import Text from '../../../components/Text';

import MobileHeaderLayout from '../../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../../Main/MobileHeader/MobileHeaderTitle';

import BundleList from './components/BundleList';
import ProductCharacteristics from './components/ProductCharacteristics';
import ProductHeader from './components/ProductHeader';
import ProductInfo from './components/ProductInfo';
import ProductInfoReviews from './components/ProductInfoReviews';
import ProductPrice from './components/ProductPrice';
import ProductPriceInfo from './components/ProductPriceInfo';
import ProductReviewMobile from './components/ProductReviewMobile';
import styles from './Product.module.scss';

const ProductPageLocalee = ({
  tabs,
  product,
  isExpress,
  deliveryToday,
  backorderDate,
  isBackorderAvailable,
  isBundle,
  isMultiBuy,
  volumes,
  currentItem,
  currentPrice,
  oldPrice,
  count,
  title,
  limit,
  tab,
  propertyList,
  similarProducts,
  matchingProducts,
  bundleImages,
  handleCopyCode,
  handleFavorite,
  addMultiBuyProductToCart,
  handleChangeSection,
  handleChangeCount,
  handleChangeOffer,
}: ProductViewProps) => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const { pathname } = useLocation();
  const mobileBottomContainer = useRef<HTMLDivElement | null>(null);
  const currentProductFilters = getProductChoices(product);

  const getMobileBody = () => {
    if (DEFAULT_COMPANIES.includes(company.name)) {
      return (
        <>
          <ProductInfo
            productState={product.data}
            reviews={product.reviews}
            offer={product.selectedOffer}
            isExpress={isExpress}
            deliveryToday={deliveryToday}
            backorderDate={backorderDate}
            isBackorderAvailable={!!isBackorderAvailable}
            isBundle={isBundle}
            isMultiBuy={isMultiBuy}
            volumes={volumes}
            currentItem={currentItem}
            currentPrice={currentPrice}
            oldPrice={oldPrice}
            count={count}
            title={title}
            handleCopyCode={handleCopyCode}
            handleFavorite={handleFavorite}
            limit={limit}
            bottomContainerRef={mobileBottomContainer}
            isLoading={product.request.isLoading}
            addMultiBuyProductToCart={addMultiBuyProductToCart}
            handleChangeSection={handleChangeSection}
            handleChangeCount={handleChangeCount}
            handleChangeOffer={handleChangeOffer}
            productFilters={Object.values(currentProductFilters)}
          />
          <ProductCharacteristics
            currentItem={currentItem}
            propertyList={propertyList}
          />
          {!company.hideReviews && (
            <ProductReviewMobile
              productState={product.data}
              reviews={product.reviews}
            />
          )}
        </>
      );
    } else {
      switch (tab?.alias) {
        case tabs.characteristics.alias:
          return (
            <ProductCharacteristics
              currentItem={currentItem}
              propertyList={propertyList}
            />
          );
        case tabs.reviews.alias:
          if (company.hideReviews) {
            handleChangeSection(tabs.product.alias);
            return null;
          }

          return (
            <ProductReviewMobile
              productState={product.data}
              reviews={product.reviews}
            />
          );
        default:
        case tabs.product.alias:
          return (
            <ProductInfo
              productState={product.data}
              reviews={product.reviews}
              offer={product.selectedOffer}
              isExpress={isExpress}
              deliveryToday={deliveryToday}
              backorderDate={backorderDate}
              isBackorderAvailable={!!isBackorderAvailable}
              isBundle={isBundle}
              isMultiBuy={isMultiBuy}
              volumes={volumes}
              currentPrice={currentPrice}
              oldPrice={oldPrice}
              count={count}
              title={title}
              currentItem={currentItem}
              handleCopyCode={handleCopyCode}
              handleFavorite={handleFavorite}
              limit={limit}
              isLoading={product.request.isLoading}
              addMultiBuyProductToCart={addMultiBuyProductToCart}
              handleChangeSection={handleChangeSection}
              handleChangeCount={handleChangeCount}
              handleChangeOffer={handleChangeOffer}
            />
          );
      }
    }
  };

  if (product.notFound) {
    return <NotFound />;
  }

  const badge = isBundle ? (
    <div className={styles.bundleBadge} data-company={company.name}>
      {t('bundle')}
    </div>
  ) : (
    isMultiBuy && (
      <MultibuyBadge
        className={styles.multiBuyBadge}
        value={currentItem?.promoRequiredQuantity}
        onClick={addMultiBuyProductToCart}
      />
    )
  );

  const {
    message: expressDeliveryLabel,
    args: expressDeliveryLabelArgs,
    showFlashIcon,
  } = orderStore.getDeliveryLabel({ minLabel: t('min') });

  const pageTitle =
    product.selectedOffer?.metadataTitle || product.data?.metadataTitle || '';
  const pageDescription =
    product.selectedOffer?.metadataDescription ||
    product.data?.metadataDescription ||
    '';
  const canonical = company.config.canonical + (pathname ?? '');

  return (
    <div
      className={classes([styles.root, 'product-page'])}
      data-company={company.name}
    >
      <Helmet>
        <title>
          {pageTitle && `${pageTitle} - `}
          {company.config.name}
        </title>
        <meta name="description" content={pageDescription} />
        <meta data-react-helmet="true" name="robots" content="index, follow" />
        <link data-react-helmet="true" rel="canonical" href={canonical} />
      </Helmet>
      {/* TODO: correct link */}
      <MobileHeaderLayout
        showCart
        showSearch={company.showSearchHeaderProductPage}
        toolbarContentClassName={classes([
          styles.toolbarContent,
          styles.toolbarContentHorizontal,
        ])}
        content={
          <>
            <MobileHeaderTitle text={product.data?.categoryName ?? ''} />
            {company.showShowShareButtonsProductPage && (
              <div className={styles.toolbarContentButtonContainer}>
                <button
                  className={styles.toolbarContentButton}
                  onClick={handleCopyCode}
                >
                  <Icon size={24} type="share" />
                </button>
                <button
                  className={styles.toolbarContentButton}
                  onClick={handleFavorite}
                >
                  <Icon
                    size={24}
                    type={
                      catalogStore.favorites[product.data?.id || '']
                        ? 'fav-fill'
                        : 'fav'
                    }
                    className={classes(
                      catalogStore.favorites[product.data?.id || ''] &&
                        styles.toolbarContentButtonFaved,
                    )}
                  />
                </button>
              </div>
            )}
          </>
        }
      />
      <div className={classes(['content-layout', styles.productMobileLayout])}>
        {company.showSectionsMobileProductPage && (
          <div className={classes([styles.tab_filter, styles.content_type])}>
            <div className={styles.tab_filter__inner}>
              {Object.values(tabs).map((i) => (
                <Link
                  to="#"
                  key={i.alias}
                  onClick={() => handleChangeSection(i.alias)}
                >
                  <div
                    className={classes([
                      styles.tab_filter__tab,
                      i.alias === tab?.alias && styles.tab__selected,
                    ])}
                  >
                    <Text size="14" weight="700" className={styles.tab__inner}>
                      {i.title}
                    </Text>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
        {getMobileBody()}
      </div>
      <div className="page-product content-layout product-desktop-layout grid-product">
        <div className={classes([styles.slider, styles.imageContainer])}>
          <div className={styles.slider__product_info}>
            <div className={styles.slider__product_info_bages}>
              {!!isExpress && company.showExpressBadgeInSliderProductPage && (
                <div className={styles.badge__container}>
                  <Icon
                    type="express-fill"
                    size={16}
                    className={styles.badge__icon}
                  />{' '}
                  {t('expressDelivery')}
                </div>
              )}
            </div>
          </div>
          {product.selectedOffer && !isMobile ? (
            <ProductImageSlider
              productTitle={product.data?.name}
              images={isBundle ? bundleImages : product.selectedOffer.images}
              className={classes([
                styles.swiperContainer,
                isMultiBuy && styles.multiBuySlider,
                (!isBundle || product.selectedOffer.images.length === 0) &&
                  styles.single,
              ])}
              layout={SliderLayout.DESKTOP}
              badgesContent={badge}
            />
          ) : (
            <Skeleton className="skeleton" height="60%" />
          )}
        </div>
        <div className="product-info" data-company={company.name}>
          <ProductHeader
            title={title}
            productState={product.data}
            offer={product.selectedOffer}
            isBundle={isBundle}
            volumes={volumes}
            handleChangeOffer={handleChangeOffer}
            isExpress={!!isExpress}
            priceElement={
              <ProductPrice
                offer={product.selectedOffer}
                isMultiBuy={isMultiBuy}
                oldPrice={oldPrice}
                currentPrice={currentPrice}
              />
            }
            productFilters={Object.values(currentProductFilters)}
          />
          <ProductPriceInfo
            offer={product.selectedOffer}
            productState={product.data}
            count={count}
            isMultiBuy={isMultiBuy}
            oldPrice={oldPrice}
            currentPrice={currentPrice}
            isExpress={isExpress}
            showFlashIcon={showFlashIcon}
            expressDeliveryLabel={expressDeliveryLabel}
            expressDeliveryLabelArgs={expressDeliveryLabelArgs}
            isBackorderAvailable={!!isBackorderAvailable}
            deliveryToday={deliveryToday}
            backorderDate={backorderDate}
            limit={limit}
            handleChangeCount={handleChangeCount}
            handleFavorite={handleFavorite}
            handleCopyCode={handleCopyCode}
          />
          <ProductBadges data={product.selectedOffer?.badges} />

          {isBundle && <BundleList productState={product.data} />}
          {currentItem?.description && (
            <div
              className="description"
              data-company={company.name}
              dangerouslySetInnerHTML={{ __html: currentItem.description }}
            />
          )}
          {!isBundle && (
            <ProductCharacteristics
              currentItem={currentItem}
              propertyList={propertyList}
              isDesktop
            />
          )}
        </div>
      </div>
      {!company.hideReviews && (
        <ProductInfoReviews
          productState={product.data}
          reviews={product.reviews}
        />
      )}
      <div
        style={
          {
            '--value': `${mobileBottomContainer?.current?.offsetHeight || 0}px`,
          } as CSSProperties
        }
        className={classes([
          'content-layout',
          styles.recommendationsWr,
          mainStore.isRN && styles.rnBottomSpace,
        ])}
      >
        <Recommendations
          title="matchingProducts"
          className="carousel-scroll1"
          products={matchingProducts}
          isLoading={catalogStore.productRecommendationsMeta.isLoading}
        />
        <Recommendations
          title="similarProducts"
          className="carousel-scroll1"
          products={similarProducts}
          isLoading={catalogStore.productRecommendationsMeta.isLoading}
        />
      </div>
    </div>
  );
};

export default observer(ProductPageLocalee);
