import { createContext, useContext } from 'react';

export interface GlobalContext {
  redirectUrl?: string;
  isMobile: boolean;
  isTablet: boolean;
  isSSR?: boolean;
  serverLang?: string;
  serverWarehouse?: string;
}

export const GlobalContext = createContext<GlobalContext>({
  isMobile: false,
  isTablet: false,
  isSSR: false,
});

export const useGlobal = (): GlobalContext => {
  return useContext(GlobalContext);
};
