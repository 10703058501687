import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';

import { DEFAULT_COMPANIES, company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import ProductPropertyFilter from '~/components/ProductPropertyFilter/ProductPropertyFilter';
import Rating from '~/components/Rating';
import { Offer, Product } from '~/stores/CategoriesStore';
import { userStore } from '~/stores/UserStore';
import { ProductPropertiesValue } from '~/types/Product/interface';

import styles from '../Product.module.scss';

type Props = {
  title?: string;
  productState?: Product | null;
  offer?: Offer;
  isBundle: boolean;
  volumes: {
    id: string;
    value: string;
  }[];
  handleChangeOffer: (sku: string) => () => void;
  priceElement: ReactNode;
  isExpress?: boolean;
  productFilters?: ProductPropertiesValue[];
};

const ProductHeaderLocalee = ({
  title,
  productState,
  offer,
  isBundle,
  volumes,
  handleChangeOffer,
  priceElement,
  isExpress,
  productFilters = [],
}: Props) => {
  const { t } = useTranslation();

  const componentBody = () => {
    if (DEFAULT_COMPANIES.includes(company.name)) {
      return (
        <>
          <div className={styles.statusContainer}>
            {!company.hideReviews && productState && (
              <div
                className={styles.ratingContainer}
                data-company={company.name}
              >
                {productState?.ratingAverage ? (
                  <>
                    <div className={styles.ratingRate}>
                      {productState.ratingAverage}
                    </div>
                    <Rating
                      rate={productState.ratingAverage}
                      showStars={true}
                      hideText={true}
                      starSize={16}
                    />
                  </>
                ) : (
                  <div className={styles.no_rating}>
                    <Icon
                      type="star-fill"
                      size={16}
                      className={styles.no_rating__star}
                    />
                    {t('noRatingShort')}
                  </div>
                )}
              </div>
            )}
            {!!isExpress && (
              <p className={styles.shortInfo__statusInStock}>{t('inStock')}</p>
            )}
          </div>
          <h1
            className={classes([styles.productName])}
            data-company={company.name}
          >
            {title ? title : <Skeleton />}
          </h1>
          {priceElement}
          {offer ? (
            !isBundle && (
              <div className={styles.volume} data-company={company.name}>
                <div className={styles.filtersContainer}>
                  {productFilters
                    .sort((a, b) => a.sorting - b.sorting || a.order - b.order)
                    .map((filter, idx) => (
                      <ProductPropertyFilter
                        filter={filter}
                        handleChangeOffer={handleChangeOffer}
                        key={idx}
                        name="desk"
                      />
                    ))}
                </div>
              </div>
            )
          ) : (
            <div className={styles.volume_skeleton}>
              <Skeleton width="100%" className={styles.skeleton} />
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <h1 className="product-title title" data-company={company.name}>
            {title ? title : <Skeleton />}
          </h1>
          {productState && (
            <div className={styles.ratingContainer} data-company={company.name}>
              {productState?.ratingAverage ? (
                <>
                  <div className={styles.ratingRate}>
                    {productState.ratingAverage}
                  </div>
                  <Rating
                    rate={productState.ratingAverage}
                    showStars={true}
                    hideText={true}
                    starSize={16}
                  />
                </>
              ) : (
                <div className={styles.no_rating}>
                  <Icon
                    type="star-fill"
                    size={16}
                    className={styles.no_rating__star}
                  />
                  {t('noRatingShort')}
                </div>
              )}
            </div>
          )}
          {offer ? (
            !isBundle && (
              <div className={styles.volume} data-company={company.name}>
                <Swiper
                  slidesPerGroup={4}
                  slidesPerView="auto"
                  className={styles.swiper}
                  dir={userStore.dir}
                  key={userStore.dir}
                >
                  {volumes?.map((item) => (
                    <SwiperSlide className={styles.swiperItem} key={item.id}>
                      <div
                        key={item.id}
                        onClick={handleChangeOffer(item.id)}
                        className={classes([
                          styles.volumeBubble,
                          offer.sku === item.id && styles.selected,
                        ])}
                      >
                        {item.value}
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )
          ) : (
            <div className={styles.volume_skeleton}>
              <Skeleton width="100%" className={styles.skeleton} />
            </div>
          )}
        </>
      );
    }
  };

  return componentBody();
};

export default observer(ProductHeaderLocalee);
