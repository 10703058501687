import { hoursToSeconds, millisecondsToSeconds } from 'date-fns';
import { format } from 'date-fns-tz';
import { mapValues } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { DeliverySlot } from '~/api/ETA';
import { company } from '~/company/Company';
import DateItem from '~/components/ModalSlotsSelectDate/DateItem';
import ExpressDateItem from '~/components/ModalSlotsSelectDate/ExpressDateItem';
import { useDateLang } from '~/hooks/useDateLang';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import Icon from '../Icon/Icon';

import { truncateSlots } from './utils';

const ModalSlotsSelectDate = () => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const { closeModal } = useModal();
  const { locale } = useDateLang();

  const swiperRef = useRef<SwiperRef>(null);

  const currentDelivery = checkoutStore.slotSelectionModalStore.selectedSlot;

  const currentItems = catalogStore.selectedCartItems.filter(
    ({ sku }) => currentDelivery?.itemsIds.includes(sku),
  );
  const isExpressDeliveryAvailable = currentItems.every(
    ({ count, sellableWarehouses }) => {
      const currentWHStock = Number(
        sellableWarehouses[`${orderStore.etaCalculation?.warehouse.code}`] || 0,
      );

      return count <= currentWHStock;
    },
  );

  const latestBackorderLeadTime =
    currentItems.sort((a, b) => a.backorderLeadTime - b.backorderLeadTime).at(0)
      ?.backorderLeadTime ?? 0;

  const now = millisecondsToSeconds(Date.now());
  const latestBackorderTime = now + hoursToSeconds(latestBackorderLeadTime);

  const deliverySlots = truncateSlots(
    mapValues(checkoutStore.deliverySlots, (slots) =>
      slots.filter(
        ({ delivery_min_time }) => delivery_min_time >= latestBackorderTime,
      ),
    ),
  );

  const [selectedSlotId, setSelectedSlotId] = useState<string | undefined>();
  const [selectedDate, setSelectedDate] = useState<string | undefined>();

  useEffect(() => {
    if (selectedSlotId || selectedDate) {
      return;
    }

    const setFirstSlot = () => {
      const slot = checkoutStore.sortedDeliverySlotsList?.at(0);

      if (!slot) {
        return;
      }

      setSelectedSlotId(slot.schedule_slot_id);
      setSelectedDate(`${slot.current_date * 1000}`);
    };

    if (!currentDelivery) {
      setFirstSlot();
      return;
    }

    if (!currentDelivery.slot) {
      return;
    }

    const existedSlot =
      checkoutStore.slotSelectionModalStore.getSlotByIdAndDate(
        currentDelivery.slot.schedule_slot_id,
        `${currentDelivery.slot.current_date}`,
      );

    if (!existedSlot) {
      setFirstSlot();
      return;
    }

    setSelectedSlotId(existedSlot.schedule_slot_id);
    setSelectedDate(`${existedSlot.current_date * 1000}`);
  }, []);

  const lastBackorder =
    checkoutStore.isDeliverTogether && !isExpressDeliveryAvailable
      ? catalogStore.selectedCartItems.reduce((acc, { backorderLeadTime }) => {
          const backorderTime = now + hoursToSeconds(backorderLeadTime);

          return Math.max(acc, backorderTime);
        }, 0)
      : 0;

  const timeStamps =
    lastBackorder > 0
      ? Object.keys(deliverySlots).filter((i) => +i > lastBackorder * 1000)
      : Object.keys(deliverySlots);

  const initPosition = selectedDate ? timeStamps.indexOf(selectedDate) : 0;

  const handleSetExpressDelivery = () => {
    setSelectedDate(undefined);
    setSelectedSlotId(undefined);
  };

  const dateCards = (
    <>
      {isExpressDeliveryAvailable && orderStore.isExpressAvailableNow && (
        <SwiperSlide className="banner-carousel__item1" key={0}>
          <ExpressDateItem
            onChange={handleSetExpressDelivery}
            isChecked={!selectedDate}
          />
        </SwiperSlide>
      )}
      {/* TODO: start cards from backorder lead time */}
      {timeStamps.map((timeStamp) => {
        return (
          <SwiperSlide className="banner-carousel__item1" key={timeStamp}>
            <DateItem
              timeStamp={timeStamp}
              onChange={setSelectedDate}
              isChecked={selectedDate === timeStamp}
              isDisabled={!deliverySlots[timeStamp]?.length}
            />
          </SwiperSlide>
        );
      })}
    </>
  );

  const dateCardSelector = isMobile ? (
    <Swiper
      ref={swiperRef}
      spaceBetween={8}
      slidesPerView="auto"
      modules={[Navigation]}
      initialSlide={initPosition}
      dir={userStore.dir}
      key={userStore.dir}
    >
      {dateCards}
    </Swiper>
  ) : (
    dateCards
  );

  const handleSelectSlot = () => {
    if (!checkoutStore.deliveries || !currentDelivery) {
      throw new Error('Something went wrong');
    }

    if (
      currentDelivery.slotDeliveryDetails?.scheduleSlotId === selectedSlotId
    ) {
      closeModal();
      checkoutStore.slotSelectionModalStore.setSelectedSlot(null, null);
      return;
    }

    checkoutStore.slotSelectionModalStore.updateDelivery(
      selectedSlotId,
      selectedDate,
    );
    closeModal();
    checkoutStore.slotSelectionModalStore.setSelectedSlot(null, null);
  };

  const formButton = (
    <button className="button _primary _med" onClick={handleSelectSlot}>
      {t('deliverByThisTime')}
    </button>
  );

  if (!currentDelivery) {
    closeModal();
    return null;
  }

  const order = checkoutStore.slotSelectionModalStore.selectedOrder;

  if (!order) {
    throw new Error('Order not found');
  }

  const cartPrice = +mainStore.convertPenceToPounds(order.base_total);
  const deliveryPrice = order?.delivery_info.paid_price
    ? formatPriceWithCurrency(
        mainStore.convertPenceToPounds(order.delivery_info.paid_price),
      )
    : '0';

  const getSlotPrice = ({
    delivery_price,
    free_delivery_threshold,
  }: DeliverySlot) => {
    const deliveryPrice = +mainStore.convertPenceToPounds(delivery_price);
    const freeDeliveryThreshold = +mainStore.convertPenceToPounds(
      free_delivery_threshold,
    );
    const price = cartPrice >= freeDeliveryThreshold ? 0 : deliveryPrice;

    return price > 0 ? formatPriceWithCurrency(price) : t('deliveryFreeShort');
  };

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <button
          className="button _no-padding _no-color input-form__close"
          onClick={() => {
            closeModal();
            checkoutStore.slotSelectionModalStore.setSelectedSlot(null, null);
          }}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        <div className="input-form__form">
          <div className="input-form__form-header">
            <p className="input-form__form-header-title">
              {t('scheduleDelivery')}
            </p>
            <div className="input-form__input-container">
              <div className="">
                <ul className="input-form__input-container-days slider2">
                  {dateCardSelector}
                </ul>
                {!isMobile && formButton}
              </div>
              {!selectedDate ? (
                <ul className="input-form__input-container-time">
                  <li className="list-item">
                    <input type="radio" name="time" checked={true} readOnly />
                    <label>
                      <span className="mark" />
                      <span className="list-item__content">
                        <span className="list-item__content-primary">
                          {t('phrases:expressDeliveryInMin', {
                            // FIXME: correct eta calculation
                            time: orderStore.etaCalculation?.duration.range,
                          })}
                          <span>
                            {' '}
                            (&nbsp;
                            {deliveryPrice === '0'
                              ? t('deliveryFreeShort')
                              : deliveryPrice}
                            &nbsp;)
                          </span>
                        </span>
                        <Icon type="express-fill" size={isMobile ? 16 : 24} />
                      </span>
                    </label>
                  </li>
                </ul>
              ) : (
                <ul className="input-form__input-container-time">
                  {checkoutStore.deliverySlots[selectedDate]?.map(
                    (
                      {
                        schedule_slot_id,
                        delivery_min_time,
                        delivery_max_time,
                      },
                      index,
                      slots,
                    ) => (
                      <li className="list-item" key={schedule_slot_id}>
                        <input
                          type="radio"
                          name="time"
                          value={schedule_slot_id}
                          id={schedule_slot_id}
                          checked={schedule_slot_id === selectedSlotId}
                          onChange={() => setSelectedSlotId(schedule_slot_id)}
                        />
                        <label htmlFor={schedule_slot_id}>
                          <span className="mark" />
                          <span className="list-item__content">
                            <span className="list-item__content-primary">
                              {format(
                                new Date(delivery_min_time * 1000),
                                'hh:mm aaaa',
                                { timeZone: company.config.timeZone, locale },
                              )}
                              -
                              {format(
                                new Date(delivery_max_time * 1000),
                                'hh:mm aaaa',
                                { timeZone: company.config.timeZone, locale },
                              )}
                            </span>
                            <span className="list-item__content-secondary">
                              (&nbsp;
                              {getSlotPrice(slots[index]!)}
                              &nbsp;)
                            </span>
                          </span>
                        </label>
                      </li>
                    ),
                  )}
                </ul>
              )}
            </div>
          </div>
          {isMobile && (
            <div className="input-form__form-footer">
              <div className="input-form__form-footer-buttons">
                {formButton}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ModalSlotsSelectDate);
