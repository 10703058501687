import { classes } from 'html-classes';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import Icon from '../../../components/Icon/Icon';

import styles from './MobileHeaderTitle.module.scss';

interface MobileHeaderTitleProps {
  text: string;
  classNames?: string;
  onBackClick?: () => void;
}

const MobileHeaderTitle = ({
  text,
  onBackClick,
  classNames,
}: MobileHeaderTitleProps) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
      return;
    }

    if (typeof window === 'undefined') {
      return;
    }

    window.history.length === 1 ? navigate('/') : navigate(-1);
  };

  return (
    <div className={classes([styles.container, classNames])}>
      <div onClick={handleBackClick} role="button">
        <Icon type="arrow" size={24} className={styles.icon} />
      </div>
      <h2 className={styles.header}>
        {text || <Skeleton className={styles.skeleton} />}
      </h2>
    </div>
  );
};

export default MobileHeaderTitle;
