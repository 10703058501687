import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import { useModal } from '~/hooks/useModal';
import { mainStore } from '~/stores/MainStore';

interface ShareBlockProps {
  orderId?: string;
}

const ShareBlock = ({ orderId }: ShareBlockProps) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  return (
    <div className="share-block" data-company={company.name}>
      <div className="share-block_content">
        <p className="share-block_content-title">{t('shareLove')}</p>
        {company.isShareBlockCaptionVisible && (
          <p className="share-block_content-text">{t('referFriend')}</p>
        )}
      </div>
      {company.match({
        citydrinks: (
          <Link
            to="/cabinet/referral"
            className="button _primary"
            onClick={() => {
              mainStore.sendAnalytics(['BI', 'analytics'], {
                name: 'Invite friends now',
                params: { orderId },
              });
            }}
          >
            {company.isShareBlockButtonIconVisible && <Icon type="refferal" />}
            {t('inviteFriends')}
          </Link>
        ),
        default: (
          <button
            className="button _primary"
            onClick={() => openModal(ModalType.ReferralProgram)}
          >
            <Icon type="refferal" size={24} />
            {t('inviteFriends')}
          </button>
        ),
      })}
    </div>
  );
};

export default ShareBlock;
