import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { company } from '~/company/Company';
import { ModalType } from '~/components/Modal/interface';
import { MODAL_EDIT_ADDRESS_PARAM, useModal } from '~/hooks/useModal';
import { userStore } from '~/stores/UserStore';

import Icon from '../../components/Icon/Icon';
import MainSidebar from '../../components/MainSidebar/MainSidebar';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './Address.module.scss';
import AddressItem from './AddressItem';
import AddressItemsSkeleton from './AddressSkeleton';
import EmptyAddresses from './EmptyAddresses';

const AddressPage = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const [activeAddressIndex, setActiveAddressIndex] = useState<number | null>(
    null,
  );
  const [, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const handleDeliveryAddress = () => openModal(ModalType.DeliveryAddress);

  const fetchAddressList = async () => {
    setIsLoading(true);
    await userStore.fetchAddresses();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAddressList();
  }, [userStore.addressList.length]);

  useEffect(() => {
    const { addressId } = userStore.deliveryAddress ?? {};
    const activeIndex = userStore.addressList.findIndex(({ id }) => {
      return id === addressId;
    });

    if (activeIndex !== -1) {
      setActiveAddressIndex(activeIndex);
      return;
    }
  }, [userStore.deliveryAddress?.addressId, userStore.addressList.length]);

  return (
    <>
      <MobileHeaderLayout
        content={<MobileHeaderTitle text={t('deliveryAddresses')} />}
      />
      <div className="content address" data-company={company.name}>
        <aside className="sidebar">
          <MainSidebar />
        </aside>

        <main className={classes(['container', styles.container])}>
          <div className={styles.titleContainer}>
            <h1 className="page-title">{t('deliveryAddresses')}</h1>
            {company.showButtonInTitleAddress &&
              userStore.addressList.length > 0 && (
                <button
                  className="button _primary btn-wrapper"
                  onClick={handleDeliveryAddress}
                >
                  <Icon type="plus" size={24} />
                  {t('addAddress')}
                </button>
              )}
          </div>
          {userStore.addressList.length > 0 || isLoading ? (
            <>
              <div className="top-block">
                {!company.showButtonInTitleAddress && (
                  <section className="header">
                    <button
                      className="button _primary btn-wrapper"
                      onClick={handleDeliveryAddress}
                    >
                      <Icon type="plus" size={24} />
                      {t('addAddress')}
                    </button>
                  </section>
                )}
                <section className="address-items">
                  {userStore.addressList.length > 0 ? (
                    userStore.addressList.map((address, i) => (
                      <AddressItem
                        key={address.id}
                        address={address}
                        isActive={i === activeAddressIndex}
                        isEditingList={true}
                        isShowDeleteBtn={true}
                        onDelete={() => {
                          userStore.setAddressToDelete(address.id);
                          openModal(ModalType.DeleteAddress);
                        }}
                        onEdit={() => {
                          const searchParams = openModal(
                            ModalType.DeliveryAddress,
                          );
                          searchParams.set(
                            MODAL_EDIT_ADDRESS_PARAM,
                            address.id,
                          );
                          setSearchParams(searchParams, { replace: true });
                        }}
                      />
                    ))
                  ) : (
                    <AddressItemsSkeleton />
                  )}
                </section>
              </div>
              <div className="bottom-block">
                <button
                  className="button _bordered"
                  onClick={handleDeliveryAddress}
                >
                  <Icon type="plus" size={24} />
                  <div className="btn">{t('addMore')}</div>
                </button>
              </div>
            </>
          ) : (
            <EmptyAddresses />
          )}
        </main>
      </div>
    </>
  );
};

export default observer(AddressPage);
