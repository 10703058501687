import htmlClasses, { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { PICKUP_ALCOHOL_COUPON_CODE } from '~/stores/constants';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { ApplyPromocodeCallerSource } from '~/stores/PromotionsStore/interfaces';

import Icon from '../../Icon/Icon';

import styles from './Promocode.module.scss';

const Promocode = observer(() => {
  const { t } = useTranslation();
  const [promocodeVal, setPromocodeVal] = useState(
    catalogStore.promocode.value || '',
  );
  const [warningMsg, setWarningMsg] = useState('');

  const handlePromocodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPromocodeVal(e.currentTarget.value.trim());
  };
  const handleDelPromocode = () => {
    setPromocodeVal('');
    catalogStore.promotionStore.resetPromocode();
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    checkPromocode(promocodeVal);
  };

  const checkPromocode = (val: string) => {
    if (!val.length) {
      return;
    }

    catalogStore.promotionStore
      .applyPromocode(val, ApplyPromocodeCallerSource.CART)
      .then(() => {
        if (catalogStore.promotionStore.promocode.deliveryMethod) {
          checkoutStore.setDeliveryMethod(
            catalogStore.promotionStore.promocode.deliveryMethod,
          );
        }
      });
  };

  useEffect(() => {
    if (
      !catalogStore.totalCartPrice.amountLeftToDiscount ||
      mainStore.isZero(catalogStore.totalCartPrice.amountLeftToDiscount)
    ) {
      setWarningMsg('');
      return;
    }

    if (
      !catalogStore.isPromoCodeApplied &&
      catalogStore.promocode.coupon?.type
    ) {
      let msg =
        catalogStore.promocode.coupon?.code === PICKUP_ALCOHOL_COUPON_CODE
          ? 'errors:promocodePriceLessNoAlcohol'
          : 'errors:promocodePriceLess';

      if (!company.isExceptionAlcohol) {
        msg = 'errors:promocodePriceLessNoAlcohol';
      }

      setWarningMsg(
        t(msg, {
          amountLeft: mainStore.addCurrencySymbol(
            catalogStore.totalCartPrice.amountLeftToDiscount,
          ),
          discount: catalogStore.formatPromocodeDiscountAmount,
        }),
      );
    } else {
      setWarningMsg('');
    }
    //eslint-disable-next-line
  }, [catalogStore.totalCartPrice]);

  useEffect(() => {
    if (!catalogStore.promocode.value) {
      return;
    }
    checkPromocode(catalogStore.promocode.value);
  }, [JSON.stringify(orderStore.etaCalculation?.cost)]);

  return (
    <form
      className={classes(['promocode__form', styles.root])}
      onSubmit={handleSubmit}
    >
      <div
        className={htmlClasses([
          'promocode__form-control',
          styles.promocode__formControl,
          promocodeVal.length &&
            catalogStore.promocode.success === null &&
            styles._filled,
          catalogStore.promocode.errorType === 'error' && styles._error,
          (catalogStore.promocode.errorType === 'warning' || warningMsg) &&
            styles._warning,
          catalogStore.promocode.success === true &&
            !warningMsg &&
            styles._success,
        ])}
      >
        <div
          className={classes([
            'checkout-summary__promocode-content',
            styles.checkoutSummary__promocodeContent,
            styles.promocodeContent,
          ])}
        >
          {catalogStore.promocode.success === true && (
            <Icon
              type="check-circled"
              size={24}
              className={styles.successIcon}
            />
          )}
          <input
            className={classes([styles.input])}
            placeholder={t('phrases:enterPromocode')}
            value={promocodeVal}
            onChange={handlePromocodeChange}
          />
          {promocodeVal &&
            promocodeVal !== catalogStore.promocode.value &&
            company.promocodeInInputResetCheckout && (
              <button
                className={classes([
                  'button',
                  '_no-padding',
                  '_no-color',
                  'button-icon',
                  styles.buttonIcon,
                ])}
                onClick={handleDelPromocode}
                type="button"
              >
                <Icon type="burger-close" size={24} className={styles.icon} />
              </button>
            )}
          {catalogStore.promocode.success !== null &&
          promocodeVal === catalogStore.promocode.value ? (
            <button
              className={classes(['button', styles.button, styles.remove])}
              type="submit"
              onClick={handleDelPromocode}
            >
              {t('remove')}
            </button>
          ) : (
            promocodeVal && (
              <button
                className={classes(['button', styles.button, styles.accept])}
                type="submit"
              >
                {t('accept')}
              </button>
            )
          )}
        </div>
        {catalogStore.promocode.message && (
          <p
            className={classes([
              'promocode__form-control-result',
              styles.promocode__formControlResult,
            ])}
          >
            {catalogStore.promocode.message}
          </p>
        )}
      </div>
      {warningMsg && (
        <div className="alert _warning mt-16 z-1">{warningMsg}</div>
      )}
    </form>
  );
});

export default Promocode;
