import { makeAutoObservable } from 'mobx';

import {
  CatalogRequests,
  CategoriesResponse,
  LandingPage,
} from '~/api/Catalog';
import { company } from '~/company/Company';
import { Category } from '~/stores/CategoriesStore/Category';
import { ShopCategories } from '~/stores/CategoriesStore/ShopCategories';
import { mainStore } from '~/stores/MainStore';
import { Pagination } from '~/stores/shared/Pagination';
import toSpliced from '~/utils/toSpliced';

const PAGE_SIZE = 6;

export class HomeCategories {
  private readonly shopCategories: ShopCategories;

  constructor(
    shopCategories: ShopCategories,
    serverWarehouse?: string,
    serverLang?: string,
  ) {
    this.shopCategories = shopCategories;

    if (serverWarehouse) {
      this.init(serverWarehouse, serverLang);
    }

    makeAutoObservable(this);
  }

  public static preloadedData = new Map<string, CategoriesResponse>();

  public static async preload(warehouse: string, serverLang?: string) {
    const data = await this.request(1, warehouse, serverLang);

    this.preloadedData.set(this.getCategoriesId(warehouse, serverLang), data);

    return data;
  }

  public static removePreloadedData(id: string) {
    this.preloadedData.delete(id);
  }

  public static getCategoriesId(warehouse: string, serverLang?: string) {
    return `${warehouse}.${serverLang}`;
  }

  private static request(page: number, warehouse: string, serverLang?: string) {
    return CatalogRequests.getCategories(
      {
        warehouseCode: warehouse,
        landingPage: [LandingPage.HOME],
        includes: { products: 15 },
        page: { size: PAGE_SIZE, current: page },
      },
      serverLang,
    );
  }

  public get list() {
    const data = this.paginationRequest.result;

    if (!data.length) {
      return [];
    }

    const startShopping = this.shopCategories.startShoppingCategory;

    return startShopping && company.showStartShopping
      ? toSpliced(data, 2, 0, [startShopping])
      : data;
  }

  public readonly paginationRequest = new Pagination(
    async (page: number, warehouse: string) => {
      try {
        const response = await HomeCategories.request(page, warehouse);

        return this.convert(response);
      } catch (error) {
        return mainStore.errorHandler(error as never, 'requestCategories');
      }
    },
  );

  private init(serverWarehouse: string, serverLang?: string) {
    const isSSR = typeof window === 'undefined';
    const preloadedData =
      (!isSSR ? window.__INITIAL_STATE__?.homeCategories : null) ||
      HomeCategories.preloadedData.get(
        HomeCategories.getCategoriesId(serverWarehouse, serverLang),
      );

    if (!preloadedData) {
      return;
    }

    this.paginationRequest.setState(this.convert(preloadedData));
  }

  private convert({
    data,
    pagination,
  }: Awaited<ReturnType<typeof CatalogRequests.getCategories>>) {
    return {
      pagination,
      data: data.filter(Category.validate).map((data) => new Category(data)),
    };
  }
}
