import { useCallback, useEffect, useState, useMemo } from 'react';

import { Variant } from '~/api/Catalog';
import { ModalType } from '~/components/Modal/interface';
import { formatVolume } from '~/components/ProductCard/utils';
import { useModal } from '~/hooks/useModal';
import { useProductLimit } from '~/hooks/useProductLimit';
import { catalogStore } from '~/stores/CatalogStore';
import { Offer, Product } from '~/stores/CategoriesStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { isProductBundle } from '~/types/Product';

export const useProductCard = (
  product: Product,
  currentOffer?: Offer | null,
) => {
  const [offer, setOffer] = useState(currentOffer ?? product.offers[0]);

  useEffect(() => {
    if (currentOffer) {
      setOffer(currentOffer);
    }
  }, [currentOffer]);

  const isFavorite = !!catalogStore.favorites[product.id];
  const isBundle = isProductBundle(product);
  const { openModal } = useModal();

  const currentItemWithoutProperties = isBundle ? product : offer || product;
  const propertyFromVariant = product.variants.find(
    ({ sku }) => sku === currentItemWithoutProperties.sku,
  );
  const propertiesFromVariant = {};
  if (propertyFromVariant) {
    propertiesFromVariant[`${propertyFromVariant.property}`] = {
      ...currentItemWithoutProperties.properties[propertyFromVariant.property],
      value: propertyFromVariant.value,
    };
  }
  const currentItem = {
    ...currentItemWithoutProperties,
    properties: {
      ...currentItemWithoutProperties.properties,
      ...propertiesFromVariant,
    },
  };
  const [image, setImage] = useState(currentItem.previewImageThumb);
  const { limit, currentWhSellable, parentWhSellable } = useProductLimit({
    offer: currentItem,
  });

  const currentPrice = currentItem.discountPrice
    ? currentItem.discountPriceFormatted
    : currentItem.priceFormatted;
  const oldPrice = currentItem.discountPrice
    ? currentItem.priceFormatted
    : null;
  const cartItemsCount = catalogStore.getCartItemCountById(currentItem);

  const isExpress =
    currentItem &&
    currentWhSellable &&
    currentWhSellable >= cartItemsCount &&
    orderStore.isExpressAvailableNow;
  const isBackorderAvailable =
    currentItem &&
    currentItem.isBackorderAvailable &&
    !!currentItem.backorderLeadTime &&
    parentWhSellable > 0;
  const isMultiBuy = !isBundle && Boolean(currentItem.promoRequiredQuantity);

  const leadTime = currentItem.backorderLeadTime
    ? currentItem.backorderLeadTime
    : 0;

  const productHasVariants = useMemo(() => {
    return product.variants.length
      ? product.variants.map(({ sku, value }) => ({ id: sku, value }))
      : product.offers.map((item) => ({
          id: item.sku,
          value: formatVolume(item.properties),
        }));
  }, [product.variants, product.offers]).length;

  const productHasManyVariants = productHasVariants > 1;

  const colorVariants = useMemo(
    () =>
      Object.values(
        product.variants.reduce((acc: Record<string, Variant>, item) => {
          if (item.property === 'color' && !acc[item.value]) {
            acc[item.value] = item;
          }
          return acc;
        }, {}),
      ).sort((a, b) => a.sorting - b.sorting),
    [product.variants],
  );

  const volumes = useMemo(() => {
    return product.variants.length
      ? product.variants
          .filter(({ property }) => property === 'volume')
          .map(({ sku, value }) => ({ id: sku, value }))
      : product.offers.map((item) => ({
          id: item.sku,
          value: formatVolume(item.properties),
        }));
  }, [product.variants, product.offers]);

  const selectOffer = useCallback(
    (sku: string) => {
      const newOffer = product.offers.find((item) => item.sku === sku);

      if (newOffer) {
        setImage(newOffer.previewImageThumb);
        return setOffer(newOffer);
      }

      const variant = product.variants.find((variant) => variant.sku === sku);

      if (!variant) {
        return;
      }

      const { thumb, url } = (variant.images || []).reduce<{
        thumb: string;
        url: string;
      }>(
        (acc, { thumb, url }) => {
          if (thumb && !acc.thumb) {
            acc.thumb = thumb;
          }

          if (url && !acc.url) {
            acc.url = url;
          }

          return acc;
        },
        { thumb: '', url: '' },
      );

      if (thumb || url) {
        setImage(thumb || url);
      }

      Product.loadOffer(product, sku).then((offersMap) => {
        const newOffer = offersMap.get(sku);
        if (newOffer) {
          setImage(newOffer.previewImageThumb);
          setOffer(newOffer);
        }
      });
    },
    [product],
  );

  const toggleFavorite = useCallback(() => {
    catalogStore.toggleFavorite(product, 'product_main');
    mainStore.sendToRN('hapticFeedback', {
      count: 1,
    });
  }, [product]);

  const changeCount = useCallback(
    (count: number, action: 'add' | 'remove') => {
      catalogStore.setCartItemCountByProduct(
        {
          ...currentItem,
          variants: product.variants,
          ratingAverage: currentItem?.ratingAverage ?? 0,
          ratingMarksCount: currentItem?.ratingMarksCount ?? 0,
        },
        count,
        action,
        'product_main',
      );
    },
    [currentItem],
  );

  const addMultiBuyProductToCart = useCallback(() => {
    const targetCount = currentItem.promoRequiredQuantity - cartItemsCount;

    if (targetCount <= 0 || limit < cartItemsCount + targetCount) {
      return;
    }

    changeCount(cartItemsCount + targetCount, 'add');
  }, [currentItem, changeCount, cartItemsCount, limit]);

  const openSelectSKUModal = useCallback(() => {
    catalogStore.setSelectedCatalogProduct(product.slug);
    openModal(ModalType.SelectSKU);
  }, [product]);

  useEffect(() => {
    if (product) {
      setOffer(product.offers[0]);
      setImage(currentItem.previewImageThumb);
    }
  }, [product]);

  return {
    currentItem,
    volumes,
    limit,
    image,
    leadTime,
    oldPrice,
    currentPrice,
    cartItemsCount,
    isMultiBuy,
    isExpress,
    isBackorderAvailable,
    isBundle,
    isFavorite,
    productHasVariants,
    productHasManyVariants,
    colorVariants: [...new Set(colorVariants)],
    selectOffer,
    toggleFavorite,
    changeCount,
    addMultiBuyProductToCart,
    openSelectSKUModal,
  };
};
