const LegalsViloInd = () => (
  <>
    <section>
      <h2 id="ind-privacy-policy">KEBIJAKAN PRIVASI VILO GELATO</h2>
      <h3>Berlaku sejak 9 Agustus 2024 </h3>
      <p>
        Kebijakan Privasi ini berlaku untuk Aplikasi Vilo Gelato dan aplikasi
        atau situs web lain yang terkait dengan merek atau produk Vilo Gelato
        Brands (“Aplikasi”) yang mengarahkan Pengguna ke Kebijakan Privasi ini.
        PT Vilo Kreasi Rasa (“Vilo Gelato” atau “Kami”) membangun Aplikasi
        sebagai aplikasi dengan tujuan bisnis dan dimaksudkan untuk digunakan
        apa adanya.
      </p>
      <p>
        Kebijakan Privasi ini digunakan untuk memberi tahu Pengguna mengenai
        kebijakan Vilo Gelato dengan pengumpulan, penggunaan, pengolahan,
        penyimpanan, penguasaan dan pengungkapan Informasi Pribadi jika ada yang
        memutuskan untuk menggunakan Layanan dan Aplikasi Vilo Gelato.
      </p>
      <p>
        Jika Pengguna memilih untuk menggunakan Layanan dan Aplikasi Vilo
        Gelato, maka Pengguna mengakui bahwa Pengguna telah membaca dan memahami
        Kebijakan Privasi ini dan menyetujui segala ketentuannya. Secara khusus,
        Pengguna setuju dan memberikan persetujuan kepada Vilo Gelato untuk
        mengumpulkan, menggunakan, membagikan, mengungkapkan, menyimpan,
        mentransfer, atau mengolah Informasi Pribadi Pengguna sesuai dengan
        Kebijakan Privasi ini.
      </p>
      <p>
        Kebijakan Privasi berlaku efektif pada tanggal yang tercantum di
        Kebijakan Privasi ini. Vilo Gelato dapat mengubah, menambah, menghapus,
        mengoreksi dan/atau memperbarui Kebijakan Privasi ini dari waktu ke
        waktu untuk memastikan bahwa Kebijakan Privasi ini konsisten dengan
        perkembangan Vilo Gelato di masa depan, dan/atau perubahan persyaratan
        hukum atau peraturan, dengan memposting Kebijakan Privasi yang telah
        direvisi di Aplikasi, di mana apabila terdapat perubahan material, Vilo
        Gelato akan mengganti tanggal pada bagian atas Kebijakan Privasi ini;
        dan Pengguna melepaskan hak apapun yang mungkin Pengguna miliki untuk
        menerima pemberitahuan khusus tentang perubahan atau modifikasi
        tersebut. Oleh karena itu, Vilo Gelato menyarankan Pengguna untuk
        meninjau halaman Kebijakan Privasi ini dari waktu ke waktu untuk melihat
        adanya perubahan bilamana ada. Perubahan tersebut berlaku segera setelah
        diposting di halaman Kebijakan Privasi dan penggunaan Pengguna yang
        berkelanjutan atas Aplikasi setelah perubahan tersebut diposting
        merupakan penerimaan Pengguna terhadap Kebijakan Privasi yang direvisi
        tersebut.
      </p>
      <p>
        Mohon baca Kebijakan Privasi ini dengan saksama untuk memastikan bahwa
        Pengguna memahami bagaimana proses pengolahan data Vilo Gelato. Istilah
        yang digunakan dalam Kebijakan Privasi ini memiliki arti yang sama
        seperti dalam Aplikasi (sebagaimana relevan) (“Syarat dan Ketentuan”,
        pengertian mana termasuk setiap perubahan dan variasi terhadapnya dari
        waktu ke waktu), yang dapat diakses di Aplikasi kecuali ditentukan lain
        dalam Kebijakan Privasi ini.
      </p>
    </section>
    <section>
      <h2 id="ind-collection-of-personal-information">
        Pengumpulan Informasi Pribadi
      </h2>
      <p>
        Saat Pengguna menggunakan Aplikasi Vilo Gelato atau mengunjungi toko
        Vilo Gelato, Vilo Gelato dapat mengumpulkan informasi tentang Pengguna
        dan Layanan yang Pengguna gunakan (“Informasi Pribadi”). Informasi
        Pribadi dapat termasuk, namun tidak terbatas pada, detail identifikasi
        pribadi Pengguna seperti nama depan dan belakang, nama pengguna, kata
        sandi, alamat e-mail, nomor telepon, dan informasi akun keuangan seperti
        nomor kartu kredit, tanggal dan kota lahir, informasi demografi dan data
        perangkat (seperti Nomor ID Android Pengguna). Vilo Gelato juga dapat
        mengumpulkan Informasi Pribadi tambahan tertentu, antara lain, sebagai
        berikut:
      </p>
      <ol>
        <li>
          informasi domisili untuk tujuan promosi atau tujuan lain yang
          ditentukan oleh Vilo Gelato dari waktu ke waktu;
        </li>
        <li>
          Transaksi Pengguna di outlet Vilo Gelato dan di Aplikasi, termasuk
          produk-produk yang dibeli oleh Pengguna, bagaimana Pengguna membuat
          pembelian, dan program loyalitas dan promosi apapun yang terkait
          dengan pembelian, proses berlangganan Pengguna, proses partisipasi
          Pengguna pada acara apapun yang dilaksanakan melalui Aplikasi,
          memproses dan mengelola poin loyalitas Pengguna; untuk menanggapi
          pertanyaan dan permintaan apapun berkaitan dengan layanan pelanggan
          Pengguna; memposting komentar atau pernyataan Pengguna di situs Vilo
          Gelato; mengirim Pengguna promosi dan tawaran yang dipersonalisasi;
          mendeteksi, mencegah, dan menyelidiki pelanggaran keamanan atau
          penipuan; untuk mengembangkan, meningkatkan, dan menyediakan produk
          atau Layanan untuk memberi Pengguna pengalaman yang lebih baik;
        </li>
        <li>
          (dalam hal terjadi kesalahan apapun dalam Aplikasi) Vilo Gelato dapat
          mengumpulkan data dan informasi (melalui produk Pihak Ketiga) yang
          disebut “Data Log”. Data Log ini dapat mencakup informasi seperti
          Alamat Protokol Internet (“IP”) perangkat pengguna, nama perangkat,
          versi sistem operasi, konfigurasi Aplikasi saat menggunakan Layanan
          Vilo Gelato, waktu dan tanggal Layanan digunakan oleh Pengguna, dan
          statistik lain; dan/atau{' '}
        </li>
        <li>
          lokasi perangkat Pengguna, termasuk lokasi GPS, untuk tujuan
          meningkatkan dan/atau memfasilitasi Layanan Vilo Gelato dan
          mengaktifkan fungsionalitas situs web atau aplikasi untuk memberi
          Pengguna dengan informasi tentang toko Vilo Gelato di dekat Pengguna,
          memungkinkan pemesanan jarak jauh dan pembayaran produk dan layanan
          Vilo Gelato.
        </li>
      </ol>
      <p>
        Beberapa dari Informasi Pribadi diatas yang kami kumpulkan bersifat
        sensitif. Kami mengumpulkan informasi ini hanya dengan izin anda
        dan/atau sesuai dengan undang-undang yang berlaku. Informasi Pribadi
        yang dikumpulkan oleh Vilo Gelato akan disimpan dan digunakan
        sebagaimana ditentukan dalam Kebijakan Privasi ini.
      </p>
      <p>
        Vilo Gelato juga dapat mengumpulkan Informasi Pribadi Pengguna dari
        Pihak Ketiga (termasuk agen, vendor, kontraktor, mitra dan pihak lainnya
        yang memberikan layanan kepada Vilo Gelato atau melakukan tugas atas
        nama Vilo Gelato, atau dengan siapa Vilo Gelato melakukan kerja sama).
      </p>
      <p>
        Aplikasi ini dapat menggunakan layanan Pihak Ketiga yang dapat
        mengumpulkan informasi yang digunakan untuk mengidentifikasi Pengguna.
      </p>
      <p>
        Dalam keadaan di mana Pengguna memberikan kepada Vilo Gelato Informasi
        Pribadi yang berkaitan dengan individu lain (seperti Informasi Pribadi
        yang berkaitan dengan pasangan Pengguna, anggota keluarga, teman, atau
        pihak lain), Pengguna menyatakan dan menjamin bahwa Pengguna telah
        memperoleh persetujuan dari individu tersebut untuk, dan dengan ini
        menyetujui atas nama individu tersebut untuk, pengumpulan, penggunaan,
        pengungkapan dan pengolahan Informasi Pribadi mereka oleh Vilo Gelato.
      </p>
    </section>
    <section>
      <h2 id="ind-use-of-personal-information">Penggunaan Informasi Pribadi</h2>
      <p>
        Sebagai tambahan tujuan penggunaan Informasi Pribadi lain yang diatur
        dalam Kebijakan Privasi ini, Vilo Gelato mengumpulkan Informasi Pribadi
        dan dapat menggunakan Informasi Pribadi yang dikumpulkan untuk tujuan
        sebagai berikut atau tujuan lain yang ditentukan oleh Vilo Gelato dan
        diizinkan oleh peraturan perundang-undangan yang berlaku (“Tujuan
        Penggunaan”):
      </p>
      <ol>
        <li>
          Memelihara, mengembangkan, menguji, meningkatkan, mempersonalisasikan
          Aplikasi untuk memenuhi kebutuhan dan preferensi pengguna;
        </li>
        <li>
          Memantau dan menganalisis aktivitas, perilaku dan data demografis
          Pengguna termasuk kebiasaan dan penggunaan berbagai layanan yang
          tersedia di Aplikasi;
        </li>
        <li>
          Mengidentifikasi dan mendaftarkan Pengguna dan untuk
          mengadministrasikan, memverifikasi, menonaktifkan atau mengelola Akun
          Pengguna;
        </li>
        <li>
          Mengolah dan memfasilitasi pesanan dan transaksi pembayaran yang
          Pengguna lakukan, termasuk sebagaimana berlaku, transaksi yang
          dilakukan melalui kartu pembayaran atau rekening yang tersedia pada
          Aplikasi;
        </li>
        <li>
          Memberitahu Pengguna atas transaksi, aktivitas, pembelian, program dan
          layanan yang terjadi dalam Aplikasi atau sistem lain yang terhubung
          dengan Aplikasi Vilo Gelato; 21. Mengirimkan Pengguna komunikasi
          produk, acara, pemasaran, iklan, voucher, survei, dan informasi secara
          langsung atau terfokus, dan informasi tentang penawaran atau promosi
          khusus;
        </li>
        <li>
          Memproses atau mengelola manfaat, penghargaan, prestasi, dan level
          keanggotaan atau yang setara dengan program keanggotaan lainnya.
        </li>
        <li>
          Memberikan Pengguna mengenai keamanan informasi keamanan dari produk
          dan memberitahukan penarikan kembali produk (apabila ada);
        </li>
        <li>
          Memposting komentar atau pernyataan Pengguna di situs web Vilo Gelato;
          25. Mendeteksi, mencegah atau melakukan investigasi terhadap
          pelanggaran keamanan atau penipuan;
        </li>
        <li>
          Memfasilitasi fungsi dari situs website atau Aplikasi, termasuk
          pentautan akun dan/atau wallet, jika Pengguna melakukan atau
          mengaktifkan pentautan akun dan/atau wallet antara Akun Pengguna
          dengan platform Pihak Ketiga;
        </li>
        <li>
          Berkomunikasi dengan Pengguna dan mengirimkan Penggguna informasi
          sehubungan dengan penggunaan Aplikasi;
        </li>
        <li>
          Mengolah dan menanggapi pertanyaan dan saran yang diterima dari
          Pengguna;
        </li>
        <li>
          Memberitahu Pengguna mengenai segala pembaruan pada Aplikasi atau
          perubahan pada Layanan yang disediakan;
        </li>
        <li>
          Menawarkan atau menyediakan layanan dari afiliasi Vilo Gelato atau
          Pihak Ketiga yang bekerjasama dengan Vilo Gelato.
        </li>
      </ol>
      <p>
        Vilo Gelato juga dapat menggunakan Informasi Pribadi Pengguna secara
        lebih umum untuk tujuan tujuan sebagai berikut:
      </p>
      <ol>
        <li>
          Memantau penggunaan Aplikasi dan mengelola, mendukung serta
          meningkatkan efisiensi kinerja, perkembangan, pengalaman Pengguna dan
          fungsi-fungsi Aplikasi;
        </li>
        <li>
          Memberikan bantuan sehubungan dengan dan untuk menyelesaikan kesulitan
          teknis atau masalah operasional dengan Aplikasi atau Layanan;
        </li>
        <li>
          Menghasilkan informasi statistik dan data analitik untuk tujuan
          pengujian, penelitian, analisis, pengembangan produk, kemitraan
          komersial, dan kerja sama;
        </li>
        <li>
          Mencegah, mendeteksi dan menyelidiki segala kegiatan yang dilarang,
          ilegal, tidak sah, atau curang;
        </li>
        <li>
          Memfasilitasi transaksi aset bisnis (yang dapat berupa penggabungan,
          akuisisi, atau penjualan aset) yang melibatkan Vilo Gelato dan/atau
          afiliasi Vilo Gelato; dan
        </li>
        <li>
          Memungkinkan Vilo Gelato mematuhi semua kewajiban berdasarkan
          peraturan perundang undangan yang berlaku, termasuk namun tidak
          terbatas pada menanggapi permintaan, penyelidikan, atau arahan
          peraturan, mematuhi persyaratan pengarsipan, pelaporan, dan
        </li>
      </ol>
      <p>
        perizinan yang disyaratkan oleh peraturan yang berlaku) dan melakukan
        pemeriksaan audit, uji tuntas dan penyelidikan.
      </p>
      <p>
        Vilo Gelato dapat membuat, menggunakan, melisensikan atau mengungkapkan
        Informasi Pribadi, yang tersedia, dengan catatan,
      </p>
      <ul>
        <li>
          iii. bahwa semua hal yang dapat mengidentifikasi telah dihapus
          sehingga data, baik sendiri atau dikombinasi dengan data lain yang
          tersedia, tidak dapat dihubungkan dengan atau dikaitkan dengan atau
          tidak dapat mengidentifikasi suatu individu, dan
        </li>
        <li>
          iv. data serupa telah digabungkan sehingga data asli membentuk bagian
          dari kumpulan data yang lebih besar.
        </li>
      </ul>
    </section>
    <section>
      <h2 id="ind-disclosure-of-personal-information">
        Pengungkapan Informasi Pribadi
      </h2>
      <p>
        Dengan menggunakan Layanan dalam Aplikasi, Pengguna dengan ini setuju
        bahwa Vilo Gelato dapat berbagi Informasi Pribadi antara perusahaan di
        grup Vilo Gelato, mengungkapkan, memberikan akses atau membagikan
        Informasi Pribadi dengan penyedia Layanan, individu dan pihak lain
        (termasuk agen, vendor, pemasok, kontraktor, mitra, pihak keuangan
        (termasuk pemroses dan fasilitator pembayaran) dan pihak lain yang
        memberikan layanan kepada Vilo Gelato atau Pengguna, melakukan tugas
        atas nama Vilo Gelato, atau pihak dengan siapa Vilo Gelato mengadakan
        kerja sama komersial) (“Pihak Ketiga”) untuk Tujuan Penggunaan, tujuan
        sebagai berikut atau tujuan lain yang diatur dalam Kebijakan Privasi ini
        atau diizinkan oleh peraturan perundang-undangan yang berlaku:
      </p>
      <ol>
        <li>
          Untuk memfasilitasi Layanan Vilo Gelato, menyediakan Layanan atas nama
          Vilo Gelato (termasuk yang dilakukan oleh Pihak Ketiga penyedia
          layanan);
        </li>
        <li>
          Untuk membantu Vilo Gelato dalam menganalisis bagaimana Layanan Vilo
          Gelato digunakan; 11. Untuk memproses transaksi atau aktivitas
          Pengguna dalam Aplikasi dalam rangka kerja sama antara Vilo Gelato
          dengan Pihak Ketiga, yang dapat mencakup, antara lain,
          diperbolehkannya Pihak Ketiga tersebut untuk memperkenalkan atau
          menawarkan produk atau layanan kepada Pengguna, melakukan autentikasi
          terhadap Pengguna atau menghubungkan Pengguna dengan Akun atau
          melakukan kegiatan lain termasuk pemasaran, penelitian, analisis,
          riset, pengembangan produk atau tujuan lain sebagaimana ditentukan
          dalam kerjasama antara Vilo Gelato dan Pihak Ketiga. Namun, Pihak
          Ketiga berkewajiban untuk tidak mengungkapkan atau menggunakan
          Informasi Pribadi Pengguna tersebut untuk tujuan selain yang
          disepakati dengan Vilo Gelato;
        </li>
        <li>
          Untuk tujuan sebagaimana disebutkan dalam kebijakan pemerintah,
          peraturan atau peraturan perundang-undangan yang berlaku (apabila
          diinstruksikan, diminta, disyaratkan atau diperbolehkan oleh
          pemerintah yang berwenang);
        </li>
        <li>
          Untuk tujuan yang ditentukan dalam peraturan perundang-undangan yang
          berlaku (termasuk namun tidak terbatas pada menanggapi pertanyaan
          terkait regulasi, penyelidikan atau pedoman, atau mematuhi persyaratan
          atau ketentuan pengarsipan dan pelaporan berdasarkan undang-undang)
          dan apabila jika hal tersebut disyaratkan atau diotorisasikan oleh
          peraturan perundang-undangan yang berlaku;
        </li>
        <li>
          Untuk keperluan proses hukum dalam bentuk apapun antara Pengguna
          dengan Vilo Gelato, atau antara Pengguna dengan pihak lain, sehubungan
          dengan, atau terkait dengan Layanan; 15. Untuk keperluan transaksi
          penggabungan, penjualan aset perusahaan, konsolidasi atau
          restrukturisasi, pembiayaan atau akuisisi semua atau sebagian dari
          bisnis Vilo Gelato atau afiliasinya oleh atau ke perusahaan lain
          (bahkan jika kemudian transaksi tersebut tidak dilanjutkan); dan/atau
        </li>
        <li>
          Untuk setiap tujuan lain yang akan kami beritahukan kepada Pengguna
          pada saat memperoleh persetujuan Pengguna.
        </li>
      </ol>
      <p>
        Dalam situasi terkait kesehatan Pengguna atau kepentingan umum, Vilo
        Gelato dapat membagikan Informasi Pribadi kepada pemerintah yang
        berwenang dan/atau institusi lainnya yang dapat ditunjuk oleh pemerintah
        yang berwenang atau memiliki kerja sama dengan Vilo Gelato, untuk tujuan
        pelacakan kontak, mendukung inisiatif, kebijakan atau program
        pemerintah, kesehatan publik dan tujuan lainnya sebagaimana dibutuhkan
        secara wajar.
      </p>
      <p>
        Untuk menghindari keraguan, Vilo Gelato tidak akan menjual atau
        menggunakan Informasi Pribadi dari Pengguna untuk kepentingan komersial
        selain sebagaimana disebutkan di atas.
      </p>
    </section>
    <section>
      <h2 id="ind-international-transfer-of-personal-information">
        Transfer Internasional Informasi Pribadi
      </h2>
      <p>
        Tunduk terhadap undang-undang privasi yang berlaku, Informasi Pribadi
        Pengguna dapat dialihkan, disimpan, atau diolah di luar negara Pengguna
        untuk satu atau lebih Tujuan Penggunaan. Pengguna setuju dan memberikan
        izin ke Vilo Gelato untuk mentransfer Informasi Pribadi keluar negara
        Pengguna ke Pihak Ketiga.
      </p>
      <p>
        Saat Vilo Gelato mentransfer Informasi Pribadi Pengguna ke luar negara
        Pengguna, Vilo Gelato akan tunduk dengan kewajiban hukum dan peraturan
        terkait dengan Informasi Pribadi Pengguna dan menempatkan perlindungan
        yang sesuai untuk memastikan tingkat perlindungan yang memadai untuk
        Informasi Pribadi. Vilo Gelato juga akan memastikan bahwa penerima di
        negara terkait wajib melindungi Informasi Pribadi Pengguna dengan
        standar perlindungan yang sebanding dengan perlindungan berdasarkan
        undang-undang privasi yang berlaku.
      </p>
    </section>
    <section>
      <h2 id="ind-security-of-personal-information">
        Keamanan Informasi Pribadi{' '}
      </h2>
      <p>
        Kerahasiaan dan keamanaan Informasi Pribadi dari Pengguna adalah hal
        yang terpenting bagi Vilo Gelato. Vilo Gelato melindungi informasi
        Pengguna menggunakan langkah-langkah keamanan teknis, fisik, dan
        administratif untuk mengurangi risiko kehilangan, penyalahgunaan, akses
        tidak sah, pengungkapan, atau modifikasi Informasi Pribadi, termasuk:
      </p>
      <ol>
        <li>
          Saat Pengguna mengirimkan informasi yang sangat sensitif (seperti
          nomor kartu kredit) melalui Aplikasi Vilo Gelato, Vilo Gelato
          mengenkripsi transmisi informasi tersebut mengikuti anjuran dari
          protokol Secure Sockets Layer (SSL).
        </li>
        <li>
          Vilo Gelato meninjau praktik pengumpulan, penyimpanan, dan pemrosesan
          informasinya, termasuk langkah-langkah keamanan fisik untuk
          menghindari akses tidak sah ke sistem Vilo Gelato.
        </li>
        <li>
          Vilo Gelato membatasi akses Infromasi Pribadi ke karyawan Vilo Gelato,
          kontraktor, dan agen yang membutuhkan Informasi Pribadi untuk
          memprosesnya. Siapapun dengan akes ini tunduk kepada kewajiban
          kerahasiaan dan dapat dikenakan sanksi atau diberhentikan jika mereka
          gagal untuk memenuhi kewajiban ini.
        </li>
      </ol>
      <p>
        Meskipun Vilo Gelato telah menggunakan teknologi dan proses keamanan
        untuk membantu menjaga Informasi Pribadi Pengguna, tidak ada metode
        transmisi melalui internet atau metode penyimpanan elektronik yang 100%
        aman bagi Pengguna atau menjamin bahwa Informasi Pribadi tersebut tidak
        akan dicegat, diakses, diungkapkan, diubah atau dihancurkan oleh pihak
        ketiga yang tidak berwenang, karena faktor-faktor di luar kendali Vilo
        Gelato; oleh karena itu, Vilo Gelato tidak dapat menjamin keamanan
        mutlaknya. Pengguna bertanggung jawab untuk menjaga kerahasiaan detail
        Akun Pengguna, termasuk kata sandi Pengguna dan harus selalu menjaga dan
        bertanggung jawab atas keamanan perangkat yang Pengguna gunakan.
      </p>
    </section>
    <section>
      <h2 id="ind-storage-and-deletion-of-personal-information">
        Penyimpanan dan Penghapusan Informasi Pribadi
      </h2>
      <p>Pengguna memahami dan mengakui hal-hal sebagai berikut:</p>
      <ol>
        <li>
          Informasi Pribadi dari Pengguna yang dikumpulkan dapat disimpan,
          ditransfer, atau diolah oleh penyedia layanan Pihak Ketiga. Vilo
          Gelato akan menggunakan semua upaya yang wajar untuk memastikan bahwa
          semua penyedia layanan Pihak Ketiga tersebut memberikan tingkat
          perlindungan yang sebanding dengan komitmen Vilo Gelato berdasarkan
          Kebijakan Privasi ini.
        </li>
        <li>
          Vilo Gelato menyimpan Informasi Pribadi milik Pengguna untuk periode
          yang diperlukan untuk memenuhi Tujuan Penggunaan, atau tujuan lain
          yang diuraikan dalam Kebijakan Privasi ini atau yang diizinkan oleh
          peraturan perundang-undangan yang berlaku kecuali diperlukan periode
          penyimpanan yang lebih lama atau diizinkan oleh hukum. Setelah
          Informasi Pribadi Pengguna tidak lagi diperlukan untuk Layanan, Tujuan
          Penggunaan, atau tujuan lain, atau Vilo Gelato tidak lagi memiliki
          tujuan hukum atau bisnis untuk menyimpan Informasi Pribadi, Vilo
          Gelato mengambil langkah-langkah untuk menghapus, menghancurkan,
          menganonimkan atau mencegah akses atau penggunaan Informasi Pribadi
          tersebut untuk setiap tujuan selain dari kepatuhan terhadap Kebijakan
          Privasi ini, atau untuk tujuan keselamatan, keamanan, pencegahan dan
          deteksi penipuan, sesuai dengan persyaratan hukum yang berlaku.
        </li>
        <li>
          Pengguna memahami bahwa beberapa Informasi Pribadi dapat disimpan oleh
          pihak lain termasuk institusi pemerintah dengan cara tertentu. Dalam
          hal Vilo Gelato membagikan Informasi Pribadi kepada institusi
          pemerintah yang berwenang dan/atau institusi lainnya yang dapat
          ditunjuk oleh pemerintah yang berwenang atau memiliki kerja sama
          dengan Vilo Gelato, Pengguna menyetujui dan mengakui bahwa penyimpanan
          Informasi Pribadi oleh institusi terkait akan mengikuti kebijakan
          penyimpanan data masing-masing institusi tersebut.
        </li>
      </ol>
      <p>
        Pengguna dapat menghapus data pribadinya kapan saja dan di mana saja,
        seperti nama, alamat email, dan riwayat aktivitas, dengan mengunjungi
        fitur &quot;Hapus Akun&quot; pada aplikasi atau situs web yang diakses.
      </p>
      <p>
        Pengguna harus memahami dan mengakui bahwa setelah pengapusan Informasi
        Pribadi dan akun, Pengguna tidak akan bisa menggunakan Applikasi dan
        layanan. Penghapusan Informasi Pribadi dan akun oleh Pengguna dapat
        menyebabkan berakhirnya akun Pengguna atau hubungan kontraktual dengan
        Vilo Gelato, dengan semua hak dan kewajiban yang timbul tetap sepenuhnya
        dilindungi undang-undang.
      </p>
    </section>
    <section>
      <h2 id="ind-managing-and-updating-personal-information">
        Mengelola dan Memperbarui Informasi Pribadi
      </h2>
      <p>
        Dengan tunduk pada peraturan perundang-undangan yang berlaku, Pengguna
        dapat mengakses dan/atau memperbarui Informasi Pribadi yang berada dalam
        kepemilikan dan penguasaan Vilo Gelato dengan mengunjungi fitur
        &quot;Hapus Akun&quot; pada aplikasi atau situs web yang diakses.
      </p>
      <p>
        Vilo Gelato berhak menolak permintaan Pengguna untuk mengakses, atau
        untuk memperbaiki, sebagian atau semua Informasi Pribadi yang Vilo
        Gelato miliki atau kuasai jika diizinkan atau diperlukan berdasarkan
        perundang-undangan yang berlaku. Hal tersebut termasuk dalam keadaan di
        mana Informasi Pribadi tersebut dapat berisi referensi kepada orang lain
        atau di mana permintaan untuk mengakses atau permintaan untuk mengoreksi
        adalah untuk alasan yang Vilo Gelato anggap tidak relevan, tidak serius,
        atau menyulitkan.
      </p>
    </section>
    <section>
      <h2 id="ind-links-to-third-party-sites">
        Tautan ke Situs atau Platform Pihak Ketiga
      </h2>
      <p>
        Layanan ini mungkin berisi tautan ke situs atau platform lain milik
        pihak ketiga. Jika Pengguna mengklik tautan pihak ketiga, Pengguna akan
        diarahkan ke situs atau platform itu. Perhatikan bahwa situs atau
      </p>
      <p>
        platform eksternal ini tidak dioperasikan oleh Vilo Gelato. Oleh karena
        itu, Vilo Gelato sangat menyarankan Pengguna untuk meninjau kebijakan
        privasi situs atau platform tersebut. Vilo Gelato tidak memiliki kendali
        atas dan tidak bertanggung jawab atas konten, kebijakan privasi, atau
        praktik situs, platform atau layanan pihak ketiga mana pun.
      </p>
    </section>
    <section>
      <h2 id="ind-childrens-privacy">Privasi Anak-anak</h2>
      <p>
        Vilo Gelato tidak bermaksud agar situs web atau Layanan online Aplikasi
        digunakan oleh siapa pun yang berusia di bawah umur menurut
        undang-undang dan peraturan yang berlaku, kecuali dalam pengawasan orang
        tua atau wali. Jika Pengguna adalah orang tua atau wali dan Pengguna
        mengetahui bahwa anak Pengguna telah memberikan Informasi Pribadi kepada
        Vilo Gelato, harap hubungi Vilo Gelato agar Vilo Gelato dapat mampu
        melakukan tindakan yang diperlukan.
      </p>
    </section>
    <section>
      <h2 id="ind-cookies">Cookies</h2>
      <p>
        “Cookies” adalah file dengan sejumlah kecil data yang biasanya digunakan
        sebagai pengidentifikasi unik anonim. Ini dikirim ke browser Pengguna
        dari situs web yang Pengguna kunjungi dan disimpan di memori internal
        perangkat Pengguna.
      </p>
      <p>
        Pada saat Pengguna menggunakan Aplikasi dan Layanan, informasi tertentu
        dapat dikumpulkan secara otomatis dengan menggunakan Cookies. Selain
        itu, Aplikasi dapat menggunakan kode dan perpustakaan Pihak Ketiga yang
        menggunakan Cookies untuk mengumpulkan informasi dan meningkatkan
        Layanan. Pengguna dapat menonaktifkan Cookies dengan membersihkan cache
        dari Aplikasi Pengguna. Jika Pengguna menonaktifkan Cookies Vilo Gelato,
        Pengguna mungkin tidak dapat menggunakan beberapa bagian dari Layanan
        Vilo Gelato.
      </p>
    </section>
    <section>
      <h2 id="ind-how-to-contact">Bagaimana cara menghubungi Vilo Gelato</h2>
      <p>
        Jika anda mempunyai pertanyaan terkait Kebijakan Privasi ini atau anda
        ingin mendapatkan akses ke Informasi Pribadi Anda, mohon hubungi
        feedback@vilogelato.com.
      </p>
    </section>
  </>
);

export default LegalsViloInd;
