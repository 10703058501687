import { CLOUDFLARE_IMAGE_RESIZE_ENABLED } from '~/api/constants';

import { CompanyConfig } from '../interface';
// TODO: need update!!!
export const CircleKConfig: CompanyConfig = {
  id: '844a9e71-ed42-4ea9-a2c2-5a58b1db0a13',
  lang: 'ar',
  langs: ['en', 'ar'],
  name: 'CircleK',
  currency: 'EGP',
  officialName: 'CircleK',
  email: 'info@circlekegypt.com',
  feedbackEmail: 'info@circlekegypt.com',
  phonePlaceholder: '+20',
  defaultPhoneCode: '+20',
  phoneNumber: '16108',
  ageRestricted: 18,
  timeZone: 'Africa/Cairo',
  minimalOrderFeeThreshold: 700,
  address: 'Teaneck Rd, Ridgefield Park, NJ 07660, USA',
  canonical: '',
  warehouse: {
    default: '112',
    minimalOrderFeeWarehouseCodes: [],
    location: {
      lat: 30.1009644,
      lng: 31.353858,
    },
  },
  map: {
    center: { lat: 30.1009644, lng: 31.353858 },
    zoom: 12,
  },
  links: {
    cookie: '',
    deliveryZones: '',
    forRiders: '',
    whatsapp: '',
    facebook: 'https://www.facebook.com/circlekegypt/',
    instagram: 'https://www.instagram.com/circlekegypt/',
    tiktok: 'https://www.tiktok.com/@circlek.egypt',
    youtube: 'https://www.youtube.com/channel/UCJ305kOIr01zZ_pGB7wxHLQ',
    linkedin: 'https://www.linkedin.com/company/circle-k-egypt/',
    affiliateProgram: import.meta.env.REACT_APP_AFFILIATE_PROGRAM_LINK,
    legals: {
      cookiePolicy: {
        label: 'cookiePolicy',
        link: '/legals#cookie-policy',
      },
      paymentAndDeliveryPolicy: {
        label: 'paymentAndDeliveryPolicy',
        link: '/legals#payment-and-delivery',
      },
      privacyPolicy: {
        label: 'privacyPolicy',
        link: '/legals#privacy-policy',
      },
      salesAndReturnPolicy: {
        label: 'salesAndReturnPolicy',
        link: '/legals#sales-and-returns-policies-and-consumer',
      },
      generalTradingPolicy: {
        label: 'generalTradingPolicy',
        link: '/legals#terms-of-service',
      },
    },
  },
  referral: {
    code: 'SWIFFTUCDAVIS',
    discount: '15%',
    minBasket: '15',
    downloadAppLink: 'https://click.jiffy.shop/PZKk/f11c621',
  },
  points: {
    value: '1=1c.',
    pointsPerUnit: 15,
    icon: 'coins',
  },
  appStoreUrl: {
    ios: '',
    android: '',
  },
  apis: {},
  countryCode: 'EG',
  cloudflareImages: {
    enabled: CLOUDFLARE_IMAGE_RESIZE_ENABLED,
    domain: import.meta.env.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
  },
  license: {
    name: 'CircleK',
    numberPrinary: 'License Number',
  },
};
