import '~/styles/common.scss';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { company } from '~/company/Company';
import { ModalStockChanged } from '~/components/ModalStockChanged';
import { StoresProvider } from '~/hooks/useStore';

import {
  ProductAPI,
  ProductsPagedResponse,
  CategoriesResponse,
  CategoryListAPI,
} from './api/Catalog';
import { ReviewsResponse } from './api/Customer';
import { ETACalculation } from './api/ETA';
import { KYCStatus } from './api/KYCStatus';
import { BREAKPOINTS } from './breakpoints';
import Alerts from './components/Alert/Alert';
import ModalList from './components/ModalList/ModalList';
import PromocodeNotifications from './components/PromocodeNotifications/PromocodeNotifications';
import GlobalHooks from './hooks/GlobalHooks';
import { GlobalContext } from './hooks/useGlobal';
import { useLang } from './hooks/useLang';
import useMediaQuery from './hooks/useMediaQuery';
import { useNavigationAfterVerification } from './hooks/useNavigationAfterVerification';
import useSommelierButtonScript from './hooks/useSommelierButtonScript';
import { useStoreRedirect } from './hooks/useStoreRedirect';
import Onboarding from './pages/Onboarding/Onboarding';
import Root from './pages/Root/Root';
import { ISnap } from './stores/interfaces/Midtrans';
import { mainStore } from './stores/MainStore';
import { userStore } from './stores/UserStore';

import 'react-loading-skeleton/dist/skeleton.css';

declare global {
  interface Window {
    snap: ISnap;
    __INITIAL_STATE__?: {
      context: GlobalContext;
      product?: ProductAPI;
      etaData?: ETACalculation;
      reviews?: ReviewsResponse;
      catalogCategory?: ProductsPagedResponse;
      homeCategories?: CategoriesResponse;
      shopCategories: CategoryListAPI[];
    };
  }
}

interface AppProps {
  globalContext?: Partial<GlobalContext>;
  serverLang?: string;
}

const App = ({ globalContext = {}, serverLang }: AppProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const { i18n } = useTranslation();
  useLang();

  const global: GlobalContext = {
    ...globalContext,
    isSSR: typeof window === 'undefined',
    isMobile: useMediaQuery(
      `(max-width: ${BREAKPOINTS.sm}px)`,
      !!globalContext.isMobile,
    ),
    isTablet: useMediaQuery(
      `(max-width: ${BREAKPOINTS.tablet}px)`,
      !!globalContext.isTablet,
    ),
  };

  if (serverLang) {
    i18n.changeLanguage(serverLang);
  }

  useNavigationAfterVerification();
  useStoreRedirect();
  useSommelierButtonScript({
    isMobile: globalContext?.isMobile,
    isTablet: globalContext?.isTablet,
  });

  useEffect(() => {
    //TODO: remove in 2-3 moths
    mainStore.sendToRN('removeTag', 'warehouse_id');

    if (
      !mainStore.onboardingSeen &&
      mainStore.isRN &&
      location.pathname !== '/legals'
    ) {
      navigate('/onboarding', { replace: true });
    }
  }, [
    mainStore.onboardingSeen,
    userStore.personalData.isAdult,
    location.pathname,
  ]);

  useEffect(() => {
    if (!search || import.meta.env.REACT_APP_ENV === 'production') {
      return;
    }
    const companyName = new URLSearchParams(search)
      .get('company')
      ?.toLowerCase();
    if (!companyName) {
      return;
    }
    if (companyName === 'none') {
      sessionStorage.removeItem('companyName');
    }
    if (company.isCompanyNameExist(companyName)) {
      sessionStorage.setItem('companyName', companyName);
    }
    window.history.back();
  }, [search]);

  if (!mainStore.isAllStoresSynchronized) {
    return <></>;
  }

  return (
    <GlobalContext.Provider value={global}>
      <StoresProvider>
        <GlobalHooks />
        {/* For all pages noindex nofollow */}
        <Helmet>
          <title>{company.config.name}</title>
          <meta name="description" content={company.config.name} />
          <meta
            data-react-helmet="true"
            name="robots"
            content="noindex, nofollow"
          />
          <meta
            name="msvalidate.01"
            content={
              import.meta.env.INDEXNOW_KEY || '99c601bc3999477ca8484b82b5e83496'
            }
          />
          <link
            data-react-helmet="true"
            rel="canonical"
            href={company.config.canonical + (location.pathname ?? '')}
          />
        </Helmet>
        <div dir={userStore.dir}>
          <Routes>
            {mainStore.isRN && (
              <Route path="/onboarding" element={<Onboarding />} />
            )}
            <Route path="*" element={<Root />} />
          </Routes>
          <ModalList />
          {/* This modal placed here 'cuz it should open independent of the `modal` query param */}
          <ModalStockChanged />
          <Alerts />
          {userStore.isAuthorized &&
            userStore.personalData.kycStatus === KYCStatus.Verified && (
              <PromocodeNotifications />
            )}
        </div>
      </StoresProvider>
    </GlobalContext.Provider>
  );
};

export default observer(App);
