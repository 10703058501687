import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import { MODAL_EDIT_ADDRESS_PARAM, useModal } from '~/hooks/useModal';
import { DeliveryAddress, userStore } from '~/stores/UserStore';

import styles from './MobileHeaderAddress.module.scss';

type Props = {
  handleDeliveryAddress: () => void;
  contentText: string;
  headerColor?: boolean;
};

const MobileHeaderAddressCD = observer(
  ({ handleDeliveryAddress, contentText }: Props) => (
    <div
      className={styles.container}
      onClick={handleDeliveryAddress}
      data-company={company.name}
    >
      <span className={styles.contentText}>{contentText}</span>
      <Icon type="chevron" size={16} className={styles.icon} />
    </div>
  ),
);

const MobileHeaderAddressLocalee = observer(
  ({ handleDeliveryAddress, contentText, headerColor }: Props) => (
    <div
      className={classes([styles.container, headerColor && styles.headerColor])}
      onClick={handleDeliveryAddress}
      data-company={company.name}
    >
      {company.showLocationIconMobileCabinetPage && (
        <Icon
          type={company.match({
            circlek: 'location-fill-pin',
            default: 'location',
          })}
          size={20}
          className={styles.iconLocation}
        />
      )}
      <span className={styles.contentText}>{contentText}</span>
      <Icon type="chevron" size={16} className={styles.icon} />
    </div>
  ),
);

const COMPONENT_TYPE = {
  [CompanyName.CityDrinks]: MobileHeaderAddressCD,
  [CompanyName.Localee]: MobileHeaderAddressLocalee,
  [CompanyName.CircleK]: MobileHeaderAddressLocalee,
  [CompanyName.Vilo]: MobileHeaderAddressLocalee,
  [CompanyName.Jiffy]: MobileHeaderAddressLocalee,
  [CompanyName.Kids]: MobileHeaderAddressLocalee,
};

const formatDeliveryAddress = (address: DeliveryAddress) =>
  `${address.address1} ${address.address2}`;

const MobileHeaderAddress = ({ headerColor }: { headerColor?: boolean }) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const [, setSearchParams] = useSearchParams();

  const handleDeliveryAddress = () => {
    const searchParams = openModal(ModalType.DeliveryAddress);
    searchParams.set(
      MODAL_EDIT_ADDRESS_PARAM,
      userStore?.deliveryAddress?.addressId || '',
    );
    setSearchParams(searchParams, { replace: true });
  };

  const contentText = userStore.deliveryAddress
    ? formatDeliveryAddress(userStore.deliveryAddress)
    : t('selectAddressPlaceholder');

  const Component = COMPONENT_TYPE[company.name];

  return (
    <Component
      handleDeliveryAddress={handleDeliveryAddress}
      contentText={contentText}
      headerColor={headerColor}
    />
  );
};

export default observer(MobileHeaderAddress);
