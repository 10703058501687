import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { JSX, useEffect, useState, useRef, CSSProperties } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
  Link,
  NavigationType,
  useLocation,
  useNavigationType,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { FilterAPI, SortingFieldName, SortingOrder } from '~/api/Catalog';
import { company } from '~/company/Company';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import EmptyWithFilters from '~/components/EmptyWithFilters/EmptyWithFilters';
import Icon from '~/components/Icon/Icon';
import { MobileFilters } from '~/components/MobileFilters';
import MobileSorting from '~/components/MobileSorting/MobileSorting';
import Pagination from '~/components/Pagination/Pagination';
import ProductBadgesList from '~/components/ProductBadge/ProductBadgesList';
import ProductCard from '~/components/ProductCard/ProductCard';
import ProductFilter from '~/components/ProductFilter/ProductFilter';
import ProductSorting from '~/components/ProductSorting/ProductSorting';
import SkeletonListRows from '~/components/Skeleton/SkeletonList';
import { useDisclosure } from '~/hooks/useDisclosure';
import { useGlobal } from '~/hooks/useGlobal';
import { useHeaderSize } from '~/hooks/useHeaderSize';
import { useOnWarehouseChange } from '~/hooks/useOnWarehouseChange';
import { useSticky } from '~/hooks/useSticky';
import { useStoreInstance } from '~/hooks/useStoreInstance';
import { DescriptionBlock } from '~/pages/Catalog/DescriptionBlock';
import EmptyState from '~/pages/Catalog/EmptyState';
import { InfinityScrollLoader } from '~/pages/Catalog/InfinityScrollLoader';
import { GetProductFilterOptions } from '~/pages/Catalog/interfaces';
import NotFound from '~/pages/NotFound/NotFound';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { ProductPropertyFilterCode, catalogStore } from '~/stores/CatalogStore';
import { CatalogCategory } from '~/stores/CategoriesStore';
import { orderStore } from '~/stores/OrderStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './Catalog.module.scss';
import { CategorySkeleton } from './CatalogSkeleton';
import FilterSkeleton from './FilterSkeleton';

const Catalog = () => {
  const { t } = useTranslation();
  const { subCat2Id } = useParams<{ subCat2Id: string }>();
  const navType: NavigationType = useNavigationType();
  const category = useStoreInstance(CatalogCategory, subCat2Id || '');
  const { isMobile } = useGlobal();
  const [seoIndexed, setSeoIndexed] = useState(true);
  const mobileSort = useDisclosure();
  const mobileFilters = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const innerAsideRef = useRef<HTMLDivElement>(null);
  const outerAsideRef = useRef<HTMLDivElement>(null);
  const headerSize = useHeaderSize();

  useSticky({
    topSpacing: headerSize,
    outerRef: outerAsideRef,
    innerRef: innerAsideRef,
  });

  const {
    pathname,
    search,
    state,
  }: {
    pathname: string;
    search: string;
    state: {
      source?: string;
      isDeeplink?: boolean;
      clean_filters?: boolean;
      parent?: string;
      backLink?: string;
    };
  } = useLocation();

  const parent = state?.parent;
  const backLink = state?.backLink;
  const setQueryParams = () => {
    const modal = searchParams.get('modal');
    const filters = JSON.parse(JSON.stringify(catalogStore.currentFilters));
    const page = category.paginationRequest.currentPage;

    const combined = new URLSearchParams({
      ...(catalogStore.currentSorting[0] === 'salesQuantity' &&
      catalogStore.currentSorting[1] === 'DESC'
        ? {}
        : {
            sorting: catalogStore.currentSorting[0],
            sortingDir: catalogStore.currentSorting[1],
          }),
      ...(filters.price?.from &&
      filters.price.from !== catalogStore.pricesminmax.priceMinPounds
        ? { priceFrom: filters.price?.from }
        : {}),
      ...(filters.price?.to &&
      filters.price?.to !== catalogStore.pricesminmax.priceMaxPounds
        ? { priceTo: filters.price?.to }
        : {}),
      ...filters.properties,
      ...(modal ? { modal } : {}),
      ...(page > 1 && company.variant({ '2': true }) ? { page } : {}),
    });
    setSearchParams(combined, {
      replace: true,
      state: { source: pathname, parent: state?.parent, backLink },
    });
  };

  useEffect(() => {
    if (category.data) {
      analyticsEventsEmitter.emit(EventsName.VIEW_ITEM_LIST, {
        item_list_name: category.data.name,
        item_list_id: category.data.id,
      });
    }
  }, [category.data]);

  useEffect(() => {
    analyticsEventsEmitter.emit(EventsName.CATALOG_CATEGORY_VISITED, {
      id: subCat2Id || '',
      source: 'homepage',
    });
  }, []);

  useEffect(() => {
    const { sorting, filters } =
      CatalogCategory.parseSearchParams(searchParams);
    const { price, properties } = filters || {};
    const { from: priceFrom, to: priceTo } = price || {};

    if (sorting) {
      catalogStore.applyCatalogSorting(sorting);
    }

    catalogStore.setCurrentFilters({
      properties: {
        ...(navType !== 'POP' ? catalogStore.currentFilters.properties : {}),
        ...properties,
      },
      // will be fixed by backend params
      ...((priceFrom &&
        +priceFrom !== catalogStore.pricesminmax.priceMinPounds) ||
      (priceTo && +priceTo !== catalogStore.pricesminmax.priceMinPounds)
        ? {
            price: {
              from: +(
                priceFrom ??
                catalogStore.pricesminmax.priceMinPounds ??
                0
              ),
              to: +(priceTo ?? catalogStore.pricesminmax.priceMaxPounds ?? 0),
            },
          }
        : {}),
    });

    setQueryParams();
  }, [category.data?.id, category.data?.subcategory, pathname, search]);

  useEffect(() => {
    if (
      !state?.clean_filters &&
      !search.includes('modal') &&
      state?.source !== pathname &&
      !['POP', 'REPLACE'].includes(navType)
    ) {
      catalogStore.resetSorting();
      catalogStore.setCurrentFilters({ properties: {} });
      catalogStore.page.current = 1;
      setQueryParams();
    }
  }, [JSON.stringify(catalogStore.currentFilters), state]);

  useEffect(() => {
    setQueryParams();
  }, [
    JSON.stringify(catalogStore.currentSorting),
    category.paginationRequest.currentPage,
  ]);

  const requestPage = (page: number) => {
    if (category.paginationRequest.isLoading) {
      return;
    }

    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    category.paginationRequest.loadPage(page, orderStore.etaWarehouseCode);
  };

  const loadMore = () => {
    category.paginationRequest.loadMore(orderStore.etaWarehouseCode);
  };

  useEffect(() => {
    const page = searchParams.get('page');
    if (page && +page !== 1) {
      catalogStore.page.current = +page;
    }
  }, []);

  const handleResetFilters = (options?: { mobile?: boolean }): void => {
    const { mobile } = options || {};
    catalogStore.resetCatalogFilters();

    if (!mobile) {
      setSearchParams({}, { replace: true, state: { source: pathname } });
      category.paginationRequest.debounceLoadPage(
        1,
        orderStore.etaWarehouseCode,
      );
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  const handleChangeFilter = (
    code: ProductPropertyFilterCode,
    value?: any,
    mobile?: boolean,
  ) => {
    catalogStore.applyCatalogFilter(code, value);

    if (!mobile) {
      setQueryParams();
      category.paginationRequest.debounceLoadPage(
        1,
        orderStore.etaWarehouseCode,
      );
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  const handleApplyMobileFilters = () => {
    catalogStore.applyFiltersEventEmitter.emit('submit');
    setQueryParams();
    category.paginationRequest.debounceLoadPage(1, orderStore.etaWarehouseCode);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    mobileFilters.close();
  };

  const handleApplySorting = (sort: [SortingFieldName, SortingOrder]) => {
    catalogStore.applyCatalogSorting(sort);
    category.paginationRequest.debounceLoadPage(1, orderStore.etaWarehouseCode);
  };

  const handleApplyBadges = (
    id: string,
    code: ProductPropertyFilterCode,
    value: any,
  ) => {
    catalogStore.addCatalogBadge(id, code, value);
    category.paginationRequest.debounceLoadPage(1, orderStore.etaWarehouseCode);
  };

  const handleRemoveBadges = (id: string, code: ProductPropertyFilterCode) => {
    catalogStore.removeCatalogBadge(id, code);
    category.paginationRequest.debounceLoadPage(1, orderStore.etaWarehouseCode);
  };

  const getCanonicalPathname = () => {
    if (!pathname) {
      return company.config.canonical;
    }

    const type = searchParams.get('type');

    if (!type) {
      return `${company.config.canonical}${pathname}`;
    }
    return `${company.config.canonical}${pathname}?type=${type}`;
  };

  useEffect(() => {
    setSeoIndexed(!search.length);
  }, [search]);

  useOnWarehouseChange(
    (_, isChanged) => {
      if (isChanged || !category.data) {
        category.paginationRequest.loadPage(1, orderStore.etaWarehouseCode);
      }
    },
    [category],
  );

  const filters = (category.data?.filters || []).filter((filter) => {
    if (filter.code === 'express_delivery') {
      return orderStore.isExpressAvailableNow;
    }
    return true;
  });

  const expressDeliveryFilterItem = filters.find(
    (item) => item.code === 'express_delivery',
  );
  const getAppliedFilters = () => {
    let counter = 0;

    if (
      catalogStore.currentFilters.price ||
      catalogStore.currentFilters.discountPrice
    ) {
      counter++;
    }

    counter += Object.keys(catalogStore.currentFilters.properties).length;

    return counter;
  };

  const getProductFilters = (
    filters: FilterAPI[],
    options?: GetProductFilterOptions,
  ): JSX.Element => {
    const { className, noCollapsible, mobile } = options || {};

    return (
      <>
        {filters.map((filter) => (
          <ProductFilter
            key={filter.code}
            onChange={(code, value) => handleChangeFilter(code, value, mobile)}
            filter={filter}
            selectedFilters={catalogStore.currentFilters}
            className={className}
            noCollapsible={noCollapsible}
          />
        ))}
      </>
    );
  };

  const title = category.data?.metadataTitle
    ? `${category.data.metadataTitle} - `
    : '';
  const desc = category.data?.metadataDescription
    ? `${category.data.metadataDescription} - `
    : '';
  const pageTitle = title;
  const pageDescription = desc;
  const canonical = getCanonicalPathname();
  const robots = seoIndexed ? 'index, follow' : 'noindex, nofollow';

  return (
    <div
      className={classes(['page-catalog', styles.pageCatalog])}
      data-company={company.name}
    >
      <Helmet>
        <title>
          {pageTitle}
          {company.config.name}
        </title>
        <meta name="description" content={pageDescription} />
        <meta data-react-helmet="true" name="robots" content={robots} />
        <link data-react-helmet="true" rel="canonical" href={canonical} />
      </Helmet>
      <MobileHeaderLayout
        showCart
        content={
          <MobileHeaderTitle
            text={category.data?.name ?? ''}
            classNames={styles.mobileHeader}
          />
        }
        toolbarClassName={styles.toolbarContent}
      />
      <div className="content-layout">
        {category.isNotFound ? (
          <NotFound embedded />
        ) : (
          <div className="two-columns">
            <div ref={outerAsideRef}>
              <aside className="two-columns__filters" ref={innerAsideRef}>
                <>
                  {(parent || backLink) && (
                    <Link
                      to={parent ? `/c/${parent}` : `${backLink}`}
                      className="two-columns__filters-back"
                    >
                      <Icon type="arrow" size={16} />
                      {t('back')}
                    </Link>
                  )}
                  {!!category.types.length && (
                    <>
                      {!!category.types.length && (
                        <>
                          <p className="two-columns__filters-title">
                            {category.data?.name}
                          </p>
                          <div className="two-columns__filters-types">
                            {category.types.map(({ value, label }) => (
                              <Link
                                key={label}
                                state={{ parent: category.data?.id }}
                                to={`/c/${value}`}
                                className="two-columns__filters-link"
                              >
                                {label}
                              </Link>
                            ))}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
                <p className="two-columns__filters-header">{t('filter')}</p>
                {getProductFilters([
                  {
                    code: 'price',
                    name: 'Price',
                    showExpanded: true,
                    type: 'selector',
                    values: [],
                  },
                ])}
                {category.data?.filters.length ? (
                  getProductFilters(filters)
                ) : category.paginationRequest.isLoading ? (
                  <FilterSkeleton />
                ) : null}
                <div className={styles.resetAllButtonContainer}>
                  <button
                    type="button"
                    className={classes([
                      'button _bordered',
                      styles.resetAllButton,
                    ])}
                    onClick={() => handleResetFilters()}
                  >
                    {t('resetAll')}
                  </button>
                </div>
              </aside>
            </div>
            <div className="two-columns__content">
              {company.variant({
                '2': (
                  <div className={styles.breadcrumbs}>
                    {!category.data ? (
                      <div className={styles.breadcrumbsSkeletonContainer}>
                        <Skeleton className={styles.breadcrumbsSkeleton} />
                      </div>
                    ) : (
                      <Breadcrumbs items={category.data?.breadcrumbs ?? []} />
                    )}
                  </div>
                ),
              })}
              {!isMobile && category.data?.description && (
                <DescriptionBlock
                  title={category.data.name}
                  text={category.data.description}
                />
              )}
              <div className={classes(['block-title-wrap', styles.title])}>
                <div className="container-flex _v-baseline">
                  {!category.data?.description && (
                    <h1 className={classes(['block-title', styles.pageTitle])}>
                      {category.data?.name}
                    </h1>
                  )}
                  <div
                    className={classes([
                      'block-title__sup',
                      styles.resultsCount,
                    ])}
                  >
                    {category.paginationRequest.total ?? 0} {t('results')}
                  </div>
                </div>
                <ProductSorting
                  value={`${catalogStore.currentSorting[0]}.${catalogStore.currentSorting[1]}`}
                  onChange={handleApplySorting}
                />
              </div>
              {!isMobile ? (
                <ProductBadgesList
                  badgesViewedCount={5}
                  onSelect={handleApplyBadges}
                  onUnselect={handleRemoveBadges}
                  currentBadges={catalogStore.currentBadges}
                />
              ) : category.data ? (
                category.types.length > 1 && (
                  <div className="filter-bages-container">
                    <div className="filter-bages">
                      <div className={styles.types} data-company={company.name}>
                        {category.types.map(({ label, value }) => (
                          <Link
                            key={value}
                            state={{ parent: category.data?.id }}
                            to={`/c/${value}`}
                            className={styles.mobileTypeLink}
                          >
                            {label}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="filter-bages-container">
                  <div className="filter-bages">
                    <SkeletonListRows rows={5}>
                      <Skeleton className="control__radio skeleton" />
                    </SkeletonListRows>
                  </div>
                </div>
              )}
              <div className={styles.mobileCatalogFilterTriggers}>
                {expressDeliveryFilterItem && (
                  <ProductFilter
                    onChange={handleChangeFilter}
                    filter={expressDeliveryFilterItem}
                    selectedFilters={catalogStore.currentFilters}
                    className={styles.mobileExpressDelivery}
                    isCompact
                  />
                )}
                <div className={styles.mobileFilterRightCorner}>
                  <span
                    className={classes([
                      styles.mobileFilterTrigger,
                      getAppliedFilters() > 0 && styles.active,
                    ])}
                    role="button"
                    onClick={mobileFilters.open}
                  >
                    <Icon
                      type="filters"
                      size={24}
                      className={classes(
                        getAppliedFilters() > 0 && styles.activeIcon,
                      )}
                    />
                    {getAppliedFilters() > 0 && (
                      <div className={styles.filterCounter}>
                        {getAppliedFilters()}
                      </div>
                    )}
                  </span>
                  <span
                    className={styles.mobileFilterTrigger}
                    role="button"
                    onClick={mobileSort.open}
                  >
                    <Icon type="sort" size={24} />
                  </span>
                </div>
              </div>
              {isMobile && category.data?.description && (
                <DescriptionBlock
                  title={category.data.name}
                  text={category.data.description}
                  results={`${category.paginationRequest.total || 0} results`}
                />
              )}

              {category.data && category.paginationRequest.isLoading && (
                <div
                  className="loader"
                  style={
                    { '--header-height': `${headerSize}px` } as CSSProperties
                  }
                >
                  <div className="spinner" />
                </div>
              )}

              {!category.data ? (
                <div data-company={company.name} className="block-products">
                  <CategorySkeleton num={16} />
                </div>
              ) : category.isNotFound ? (
                <EmptyState />
              ) : category.products.length === 0 ? (
                <EmptyWithFilters onReset={() => handleResetFilters()} />
              ) : (
                <>
                  <div data-company={company.name} className="block-products">
                    {category.products.map((product, index) => (
                      <ProductCard
                        key={`${product.id}${index}`}
                        product={product}
                      />
                    ))}
                  </div>
                  {company.variant({
                    '1': category.paginationRequest.canLoadMore && (
                      <InfinityScrollLoader
                        onLoadMore={() => {
                          category.paginationRequest.debounceLoadMore(
                            orderStore.etaWarehouseCode,
                          );
                          category.paginationRequest.setStatus('fulfilled');
                        }}
                      />
                    ),
                    '2': (
                      <Pagination
                        showButton
                        showPaginator
                        pageTotal={category.paginationRequest.pageTotal}
                        pageCurrent={category.paginationRequest.currentPage}
                        requestPage={requestPage}
                        loadMore={loadMore}
                      />
                    ),
                  })}
                </>
              )}
            </div>

            <MobileFilters
              open={mobileFilters.visible}
              onClose={mobileFilters.close}
              onApply={handleApplyMobileFilters}
              onReset={() => handleResetFilters({ mobile: true })}
              results={category.paginationRequest.total ?? 0}
            >
              <>
                {getProductFilters(
                  [
                    {
                      code: 'price',
                      name: 'Price',
                      showExpanded: true,
                      type: 'selector',
                      values: [],
                    },
                  ],
                  {
                    className: styles.mobileFiltersRow,
                    mobile: true,
                  },
                )}
                {getProductFilters(
                  filters.filter(
                    (item) =>
                      item.code !== 'express_delivery' &&
                      isMobile &&
                      item.code !== 'type',
                  ) || [],
                  {
                    className: styles.mobileFiltersRow,
                    mobile: true,
                  },
                )}
              </>
            </MobileFilters>

            <MobileSorting
              open={mobileSort.visible}
              onClose={mobileSort.close}
              value={`${catalogStore.currentSorting[0]}.${catalogStore.currentSorting[1]}`}
              onChange={handleApplySorting}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Catalog);
