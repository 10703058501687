import 'swiper/css';
import 'swiper/css/pagination';

import { classes } from 'html-classes';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import Swiper from '~/components/Swiper/Swiper';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { userStore } from '~/stores/UserStore';

import { createCategoryUrl } from '../utils';

import styles from './BannerCategoryBlock.module.scss';

interface Banner {
  src: string;
  alt: string;
  linkId: number | string;
}

interface BannerCategoryBlockProps {
  banners: Banner[];
  isMobile: boolean;
}

const BannerCategoryBlock = ({
  banners,
  isMobile,
}: BannerCategoryBlockProps) => {
  const { handleClickCategory } = useCategoryAnalytics();

  if (!banners || banners.length === 0) {
    return (
      <div className={classes([styles.container, styles.skeleton])}>
        <div
          className={classes([
            styles.skeleton__container,
            isMobile && styles.mobile,
          ])}
        >
          <Skeleton className={classes([styles.skeleton__item])} />
        </div>
        {!isMobile && (
          <div className={styles.skeleton__container}>
            <Skeleton className={styles.skeleton__item} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classes([styles.container, 'banner-carousel carousel'])}>
      <Swiper
        slideClassName="banner-carousel__item"
        containerClassName={styles.swiperContainer}
        sliderClassName={styles.swiper}
        slidesPerViewProp={isMobile ? 1 : 2}
        isAutoplay
        dir={userStore.dir}
        key={userStore.dir}
        loopAdditionalSlides={1}
      >
        {banners.map(({ src, alt, linkId }) => (
          <Link
            onClick={handleClickCategory(linkId)}
            to={createCategoryUrl({ id: linkId })}
            key={src}
          >
            <CloudflareResponseImage
              src={src}
              srcSetParams={defaultSrcSetParams}
              alt={alt}
              loading="lazy"
            />
          </Link>
        ))}
      </Swiper>
    </div>
  );
};

export default BannerCategoryBlock;
