import { classes } from 'html-classes';
import { useTranslation } from 'react-i18next';

import { KYCStatus } from '~/api/KYCStatus';
import { company } from '~/company/Company';
import { VERIFICATION } from '~/constants/verification';
import { catalogStore } from '~/stores/CatalogStore';
import { userStore } from '~/stores/UserStore';

import { SubtotalInfoProps } from '../../../types';

import { CartCostItems } from '../../CartCostItems';

import styles from './SubtotalInfo.module.scss';

const SubtotalInfo = ({
  isWarehouseClosed,
  isLoading,
  onCheckout,
  onNavigateToCheckout,
  stickyWrapperStyles,
  subtotalWrapperRef,
}: SubtotalInfoProps) => {
  const { t } = useTranslation();

  const renderGoToCheckoutdButton = () => {
    const goToCheckout = (
      <button
        type="button"
        onClick={onNavigateToCheckout}
        className={classes([
          'button _primary _med',
          styles.subtotalInfo__button,
          styles.goToCheckoutButton,
          (isLoading || isWarehouseClosed) && '_disabled',
        ])}
        disabled={catalogStore.calculationProcess.isLoading}
      >
        {isLoading && <span className="spinner _white" />}
        {t('goToCheckout')}
      </button>
    );

    // TODO: hide temporaryly in cart - JS-7631
    // if (
    //   (!company.needVerification ||
    //     (userStore.isAuthorized &&
    //       userStore.personalData.kycStatus === KYCStatus.Verified)) &&
    //   catalogStore.totalCartPrice.minimal_order_value_error?.length &&
    //   catalogStore.totalCartPrice.minimal_order_value_error[0]
    // ) {
    //   return (
    //     <>
    //       <div
    //         onClick={onCheckout}
    //         className={classes([
    //           styles.goToCheckoutButton,
    //           'button _primary _med _disabled _center',
    //           styles.subtotalInfo__button,
    //         ])}
    //       >
    //         {t('errors:minCartError', {
    //           amount: formatPriceWithCurrency(
    //             catalogStore.totalCartPrice.minimal_order_value_error[0]
    //               ?.minimalOrderValue / 100,
    //           ),
    //         })}
    //         <br />
    //         {t('errors:minCartErrorDetails', {
    //           amount: formatPriceWithCurrency(
    //             (catalogStore.totalCartPrice.minimal_order_value_error[0]
    //               ?.minimalOrderValue -
    //               catalogStore.totalCartPrice.minimal_order_value_error[0]
    //                 ?.orderPriceWithoutDelivery) /
    //               100,
    //           ),
    //         })}
    //       </div>
    //     </>
    //   );
    // }
    if (!company.needVerification) {
      return userStore.isAuthorized ? (
        goToCheckout
      ) : (
        <>
          <div
            onClick={onCheckout}
            className={classes([
              'button _primary _med',
              styles.subtotalInfo__button,
            ])}
          >
            {t('logInToCheckout')}
          </div>
        </>
      );
    }

    switch (userStore.personalData.kycStatus) {
      case KYCStatus.Verified:
        return goToCheckout;
      case KYCStatus.InProgress:
      case KYCStatus.ResubmissionRequested:
      case KYCStatus.Started: {
        const statusConfig = VERIFICATION[userStore.personalData.kycStatus];
        const isDisabled =
          (userStore.personalData.kycStatus === KYCStatus.InProgress ||
            userStore.personalData.kycStatus === KYCStatus.Started) &&
          '_disabled';
        return (
          <>
            <div
              onClick={onCheckout}
              className={classes([
                `button _primary _med`,
                isDisabled,
                styles.subtotalInfo__button,
              ])}
            >
              {statusConfig.title}
            </div>
          </>
        );
      }
      default:
        return (
          <>
            <div
              onClick={onCheckout}
              className={classes([
                'button _primary _med',
                styles.subtotalInfo__button,
              ])}
            >
              {userStore.isAuthorized ? t('verifyId') : t('logInToCheckout')}
            </div>
          </>
        );
    }
  };

  return (
    <div className="subtotal-info" data-company={company.name}>
      <div
        className={styles.container}
        style={stickyWrapperStyles}
        ref={subtotalWrapperRef}
      >
        {catalogStore.selectedItemsTotalCount > 0 ? (
          renderGoToCheckoutdButton()
        ) : (
          <>
            <button
              className={classes([
                'button _primary _disabled _med',
                styles.subtotalInfo__button,
                styles.goToCheckoutButton,
              ])}
            >
              {t('goToCheckout')}
            </button>
            <p className={styles.termsAndConditionText}>
              {t('selectProductsToContinue')}
            </p>
          </>
        )}
        <div className={styles.subtotalInfo}>
          {catalogStore.selectedItemsTotalCount > 0 && (
            <div className={styles.subtotalWr}>
              <CartCostItems />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubtotalInfo;
