/* eslint-disable @typescript-eslint/no-unused-vars */

import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { sortBy } from 'lodash-es';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import { v4 as uuidv4 } from 'uuid';

import {
  DeliveryCostMechanicType,
  DeliverySlot,
  DeliverySlotsResponse,
  ETACalculation,
  ETACost,
  ETAPaymentMethod,
  ETARequests,
  ETAWarehouse,
  MechanicType,
} from '~/api/ETA';
import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import {
  AwsPayment,
  FillFeedbackRequest,
  GiftItem,
  GiftMechanism,
  NewOrderCartItemOffer,
  NewOrderResponse,
  Order,
  OrderPublicStatus,
  OrderRequests,
  OrderShort,
  OrdersResponse,
  OrderStatus,
  Payment,
  PaymentSystem,
} from '~/api/Order';
import { ApiErrorResponse } from '~/api/Requests';
import { company } from '~/company/Company';
import { Offer } from '~/stores/CategoriesStore';
import AdditionalPaymentMethod from '~/stores/CheckoutStore/AdditionalPaymentMethod';
import MobilePaymentMethod from '~/stores/CheckoutStore/MobilePaymentMethod';
import OrderData from '~/stores/CheckoutStore/OrderData';
import { deleteCookie, setCookie } from '~/utils/cookies';

import { catalogStore } from './CatalogStore';
import { checkoutStore } from './CheckoutStore/CheckoutStore';
import { CURRENCY_SYMBOL } from './constants';
import { storage as localStorage } from './LocalStorage';
import { mainStore } from './MainStore';
import { userStore } from './UserStore';

export type OrderStatusStore =
  | 'none'
  | 'accepted'
  | 'picking'
  | 'readyToShip'
  | 'readyForPickup'
  | 'inDelivery'
  | 'delivered'
  | 'failed'
  | 'cancelled';

export type RequestETAResponse = {
  oldPrice: string;
  newPrice: string;
  etaCalculation: ETACalculation;
};

export interface RateInfo {
  rating: number;
  deliveryRating?: number;
  productVarietyRating?: number;
  productQualityRating?: number;
  comment?: string;
}

export interface ExpressDeliveryLabelResult {
  message: string;
  args: Record<string, string>;
  showFlashIcon: boolean;
  expressDelivery?: ETAWarehouse['availability']['deliveryMechanics']['ON_DEMAND'];
  slot?: DeliverySlot;
}

const DEFAULT_FEES_COST: ETACost = {
  shipping: 0,
  threshold: 0,
  shippingPounds: '0',
  thresholdPounds: '0',
  serviceFee: 0,
  serviceFeeThreshold: 0,
  serviceFeeThresholdPounds: '0',
  serviceFeeShippingPounds: '0',
};

class OrderStore {
  etaCalculation: ETACalculation | null = null;
  etaSlotCalculation: ETACalculation | null = null;
  orderStatus: OrderStatusStore = 'none';
  orderList: OrderShort[] | null = null;
  isFirstOrder: Nullable<boolean> = null;
  currentOrdersPage = 1;
  activeOrderID = '';
  activeOrderBonuses: number | null = null;
  lastRatedOrderID = '';
  activeOrderProductCount = 0;
  paymentCards: Payment[] = [];
  activeGift: GiftItem | null = null;
  gift: GiftMechanism | null = null;
  isAuthInCart = false;
  tipAmount = '';
  freezeETAExpired = 0;
  idempotencyKey = '';
  isRegularTips = false;
  regularTipsValue = '';
  isCalculateTips = false;
  currency = 'aed';
  paymentSystem: PaymentSystem | undefined | null = undefined;
  isETALoading = false;
  isLoading = false;
  downloadIsPossible = false;
  url3Ds = '';

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'OrderStore',
      properties: [
        'etaCalculation',
        'paymentCards',
        'activeGift',
        'gift',
        'lastRatedOrderID',
        'idempotencyKey',
        'regularTipsValue',
        'isRegularTips',
        'currency',
      ],
      storage: localStorage,
    }).catch((error) => error && console.error(error));

    if (
      typeof window !== 'undefined' &&
      window.__INITIAL_STATE__ &&
      window.__INITIAL_STATE__.etaData?.warehouse
    ) {
      this.setEtaCalculation(window.__INITIAL_STATE__.etaData);
    }
  }

  get orderTipsValue(): string {
    if (
      checkoutStore.deliveryMethod === ETADeliveryMethodType.ClickAndCollect
    ) {
      return '';
    }

    if (!this.isCalculateTips) {
      return '';
    }

    return this.regularTipsValue;
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  getWHCode(): string | null {
    return this.etaCalculation?.warehouse.code || null;
  }

  get fee(): ETACost {
    return (
      orderStore.etaSlotCalculation?.cost[checkoutStore.deliveryMethod] ??
      orderStore.etaCalculation?.cost[checkoutStore.deliveryMethod] ??
      DEFAULT_FEES_COST
    );
  }

  get cartPriceForGift(): string {
    if (!this.gift) {
      return '';
    }
    if (!this.gift.minimum_order_amount) {
      return '';
    }
    const diff =
      mainStore.convertPoundsToPence(this.gift.minimum_order_amount) -
      mainStore.convertPoundsToPence(catalogStore.finalPrice);
    return diff <= 0 ? '' : mainStore.convertPenceToPounds(diff);
  }

  get etaWarehouseCode(): string {
    if (!this.etaCalculation) {
      return company.config.warehouse.default;
    }
    return (
      this.etaCalculation.warehouse.code || company.config.warehouse.default
    );
  }

  get isStoreAvailable() {
    return this.etaCalculation?.warehouse.availability.availableNow;
  }

  get whCode(): string {
    return this.etaCalculation?.warehouse.code ?? '';
  }

  get isPickupAvailable(): boolean {
    return !!orderStore.etaCalculation?.warehouse.deliveryMethods.find(
      ({ type }) => type === ETADeliveryMethodType.ClickAndCollect,
    );
  }

  get currencySymbol(): string {
    return CURRENCY_SYMBOL[this.currency] ?? '';
  }

  get isExpressAvailableNow(): boolean {
    return !!this.etaCalculation?.warehouse?.availability?.deliveryMechanics
      ?.ON_DEMAND?.availableNow;
  }

  get finishedOrders() {
    return (
      this.orderList?.filter((i) =>
        ['delivered', 'failed', 'cancelled'].includes(i.public_status),
      ) ?? []
    );
  }

  get activeOrders() {
    return (
      this.orderList?.filter(
        (i) => !['delivered', 'failed', 'cancelled'].includes(i.public_status),
      ) ?? []
    );
  }
  get slotDeliverySchedule() {
    const sortedSlots = sortBy(Object.entries(checkoutStore.deliverySlots), [
      ([time]) => Number(time),
    ])
      .map((entry) => toJS(entry[1]))
      .filter((slots) => Boolean(slots.length));
    const { min_time, max_time, min_time_string, max_time_string } =
      sortedSlots.reduce(
        (acc, slots) => {
          slots.forEach(
            ({
              delivery_min_time,
              delivery_max_time,
              delivery_min_time_string,
              delivery_max_time_string,
            }) => {
              const min_time = delivery_min_time % (60 * 60 * 24);
              const max_time = delivery_max_time % (60 * 60 * 24);
              const minDay = Math.floor(delivery_min_time / (60 * 60 * 24));
              const maxDay = Math.floor(delivery_max_time / (60 * 60 * 24));
              //Use time of first available slot
              if (acc.min_time === Infinity && min_time < acc.min_time) {
                acc.min_time = min_time;
                acc.min_day = minDay;
                acc.min_time_string =
                  delivery_min_time_string.split(',')[2]?.trim() || '';
              }
              //Use latest time of same day
              if (maxDay === acc.min_day && max_time > acc.max_time) {
                acc.max_time = max_time;
                acc.max_time_string =
                  delivery_max_time_string.split(',')[2]?.trim() || '';
              }
            },
          );

          return acc;
        },
        {
          min_time: Infinity,
          max_time: -Infinity,
          min_day: Infinity,
          max_day: Infinity,
          min_time_string: '00:00',
          max_time_string: '23:59',
        },
      );

    return {
      opening: min_time_string,
      closing: max_time_string,
    };
  }
  get closestSlot() {
    return sortBy(Object.entries(checkoutStore.deliverySlots), [
      ([time]) => Number(time),
    ])
      .map((entry) => entry[1])
      .find((slots) => Boolean(slots.length))?.[0];
  }

  get expressDelivery() {
    return this.etaCalculation?.warehouse.availability.deliveryMechanics?.[
      MechanicType.ON_DEMAND
    ];
  }

  get scheduledDelivery() {
    return this.etaCalculation?.warehouse.availability.deliveryMechanics?.[
      MechanicType.SLOT
    ];
  }

  getDeliveryLabel(): ExpressDeliveryLabelResult {
    const defaultMessage = 'phrases:expressDeliveryBannerTitle';
    const defaultArgs = { time: '30' };
    const defaultLabel: ExpressDeliveryLabelResult = {
      message: defaultMessage,
      args: defaultArgs,
      showFlashIcon: true,
    };

    if (!this.etaCalculation) {
      return defaultLabel;
    }

    const onDemandDeliveryMechanic = this.expressDelivery;

    const isExpressDeliveryAvailable = Boolean(
      onDemandDeliveryMechanic && onDemandDeliveryMechanic.availableNow,
    );

    const closestDeliverySlot = this.closestSlot;

    if (!isExpressDeliveryAvailable && closestDeliverySlot) {
      const slot = closestDeliverySlot;

      if (!slot) {
        return defaultLabel;
      }

      const minTimeSlices = slot.delivery_min_time_string.split(', ');
      const maxTimeSlices = slot.delivery_max_time_string.split(', ');

      if (!minTimeSlices.length || !maxTimeSlices.length) {
        return defaultLabel;
      }

      return {
        message: 'phrases:slotDeliveryBannerTitle',
        args: {
          time: `${minTimeSlices[1]}\u00a0${minTimeSlices[2]}\u00a0-\u00a0${maxTimeSlices[2]}`,
        },
        showFlashIcon: false,
        slot,
      };
    }

    return {
      message: defaultMessage,
      args: {
        time: this.etaCalculation.duration.range,
      },
      showFlashIcon: true,
      expressDelivery: onDemandDeliveryMechanic,
    };
  }

  // Setters
  setIsFirstOrder(flag: boolean) {
    this.isFirstOrder = flag;
  }

  setEtaCalculation(val: ETACalculation | null) {
    this.etaCalculation = val;

    if (typeof window !== 'undefined') {
      val?.warehouse.code
        ? setCookie('warehouse', val.warehouse.code, { 'max-age': 2678400 })
        : deleteCookie('warehouse');
    }
  }

  setEtaSlotCalculation(val: ETACalculation | null) {
    this.etaSlotCalculation = val;
  }

  setIsLoading(val: boolean) {
    this.isLoading = val;
  }

  setOrderStatus(val: OrderPublicStatus | 'none') {
    let status: OrderStatusStore;
    switch (val) {
      case 'in_delivery':
        status = 'inDelivery';
        break;
      case 'ready_to_ship':
        status = 'readyToShip';
        break;
      case 'ready_for_pickup':
        status = 'readyForPickup';
        break;
      default:
        status = val;
        break;
    }
    this.orderStatus = status;
  }

  setActiveOrderID(val: string) {
    this.activeOrderID = val;
  }

  setActiveOrderBonuses(val: number | null) {
    this.activeOrderBonuses = val;
  }

  setLastRatedOrderID(val: string) {
    this.lastRatedOrderID = val;
  }

  setActiveOrderProductCount(val: number) {
    this.activeOrderProductCount = val;
  }

  setPaymentCards(arr: Payment[]) {
    this.paymentCards = arr;
  }

  setGift(val: GiftMechanism | null) {
    this.gift = val;
  }

  setActiveGift(val: GiftItem | null) {
    this.activeGift = val;
  }

  setIsAuthInCart(flag: boolean) {
    this.isAuthInCart = flag;
  }

  setCurrentOrdersPage(val: number) {
    this.currentOrdersPage = val;
  }
  setDownloadIsPossible(val: boolean) {
    this.downloadIsPossible = val;
  }

  setTipAmount(val: string) {
    this.tipAmount = val;
  }

  setFreezeETAExpired(val: number) {
    this.freezeETAExpired = val;
  }

  setCurrency(val: string) {
    this.currency = (val || 'aed').toLowerCase();
  }

  setPaymentSystem(val: PaymentSystem) {
    this.paymentSystem = val || null;
  }

  setIsETALoading(val: boolean) {
    this.isETALoading = val;
  }

  set3dsUrl(val: string) {
    this.url3Ds = val;
  }

  // Actions
  getOrderById(id: string): OrderShort | null {
    if (!this.orderList) {
      return null;
    }
    for (let i = 0; i < this.orderList.length; i++) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      if (this.orderList[i].id === id) {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        return this.orderList[i];
      }
    }
    return null;
  }

  calcOrderItems(order: OrderShort): OrderShort {
    const itemsCount = {
      actual: 0,
      requested: 0,
    };
    for (let i = 0; i < order.items.length; i++) {
      itemsCount.actual +=
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        order.items[i].actual_quantity ?? order.items[i].requested_quantity;
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      itemsCount.requested += order.items[i].requested_quantity;
    }
    order.itemsCount = itemsCount;
    return order;
  }

  // Requests API
  fetchETA(
    pos: string,
    deliveryMethod?: ETADeliveryMethodType,
  ): Promise<ETACalculation | null> {
    return ETARequests.getETA({
      pos,
      deliveryMethod,
      seller: 'JIFFY',
    }).then(({ data }) => {
      runInAction(() => {
        mainStore.sendToRN('sendTags', {
          warehouse_code: data.warehouse.code,
        });
      });

      return this.etaCalculationResponse(data);
    });
  }

  async updateOrdersAnalytics() {
    if (!userStore.personalData.id) {
      return;
    }
    try {
      const { orders, total_count } = await OrderRequests.getOrders({
        filter: {
          customer_id: userStore.personalData.id,
        },
      });
      let successfulOrders: OrdersResponse;
      if (total_count) {
        successfulOrders = await OrderRequests.getOrders({
          filter: {
            customer_id: userStore.personalData.id,
            status: [
              'created',
              'reserved',
              'confirmed',
              'delivery_created',
              'wh_created',
              'picking',
              'ready_to_ship',
              'in_delivery',
              'delivered',
              'delivered_partially',
              'finished',
            ],
          },
        });
      }
      runInAction(() => {
        mainStore.analytics.totalSpent = mainStore.convertPenceToPounds(
          (successfulOrders?.orders || []).reduce(
            (sum, order) => sum + (order.paid_total || 0),
            0,
          ),
        );
        mainStore.analytics.totalOrders = total_count;
        mainStore.analytics.totalSuccessfulOrders =
          successfulOrders?.total_count || 0;
        if (orders.length) {
          // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          mainStore.analytics.lastOrderCreatedAt = orders[0].created_at;
          mainStore.analytics.firstOrderCreatedAt =
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            orders[orders.length - 1].created_at;
        }
        mainStore.sendToRN('setUserProperties', {
          'Commerce: total spent': mainStore.analytics.totalSpent,
        });
        mainStore.sendToRN('setUserProperties', {
          'Commerce: total orders': total_count,
        });
        if (orders.length) {
          mainStore.sendToRN('setUserProperties', {
            'Commerce: first order date':
              mainStore.analytics.firstOrderCreatedAt,
          });
        }
      });
    } catch (error) {
      error && console.error(error);
    }
  }

  etaCalculationResponse(data: ETACalculation) {
    const paymentMethods = data.warehouse.deliveryMethods.reduce(
      (acc, { type, paymentMethods }) => ({ ...acc, [type]: paymentMethods }),
      {} as ETACalculation['paymentMethods'],
    );

    const cost: ETACalculation['cost'] = data.warehouse.deliveryMethods.reduce(
      (acc, { cost, type }) => {
        if (acc[type]) {
          return acc;
        }

        const deliveryMethodCost: ETACost = {
          ...cost,
          shippingPounds: mainStore.convertPenceToPounds(cost.shipping),
          thresholdPounds: mainStore.convertPenceToPounds(cost.threshold),
          serviceFeeThresholdPounds: mainStore.convertPenceToPounds(
            cost.serviceFeeThreshold,
          ),
          serviceFeeShippingPounds: mainStore.convertPenceToPounds(
            cost.serviceFee,
          ),
        };

        if (!deliveryMethodCost.shipping || deliveryMethodCost.shipping < 0) {
          deliveryMethodCost.shipping = 0;
          deliveryMethodCost.shippingPounds = '0';
        }

        if (!deliveryMethodCost.threshold || deliveryMethodCost.threshold < 0) {
          deliveryMethodCost.threshold = 0;
          deliveryMethodCost.thresholdPounds = '0';
        }

        if (
          !deliveryMethodCost.serviceFee ||
          (deliveryMethodCost.serviceFee || -1) < 0
        ) {
          deliveryMethodCost.serviceFee = 0;
          deliveryMethodCost.serviceFeeShippingPounds = '0';
        }

        if (
          !deliveryMethodCost.serviceFeeThreshold ||
          (deliveryMethodCost.serviceFeeThreshold || -1) < 0
        ) {
          deliveryMethodCost.serviceFeeThreshold = 0;
          deliveryMethodCost.serviceFeeThresholdPounds = '0';
        }

        return { ...acc, [type]: deliveryMethodCost };
      },
      {} as ETACalculation['cost'],
    );

    const etaCalculation: ETACalculation = {
      cost,
      paymentMethods,
      distance: data.distance,
      duration: {
        ...data.duration,
        range: `${data.duration?.min || 0} - ${data.duration?.max || 0}`,
      },
      highDemand: data.highDemand,
      meta: data.meta,
      warehouse: data.warehouse,
      deliverySlots: data.deliverySlots,
    };

    return etaCalculation;
  }

  async requestETA(): Promise<RequestETAResponse> {
    this.setIsETALoading(true);

    const { lat, lng } = userStore.deliveryAddress
      ? userStore.deliveryAddress.coordinates
      : company.config.warehouse.location;
    const { data } = await ETARequests.getETA({
      pos: `${lat},${lng}`,
      seller: 'JIFFY',
      deliveryMethod: checkoutStore.deliveryMethod,
    });

    const etaCalculation: ETACalculation = this.etaCalculationResponse(data);

    const output: RequestETAResponse = {
      oldPrice:
        this.etaCalculation?.cost[checkoutStore.deliveryMethod]
          ?.shippingPounds ?? '0',
      newPrice:
        etaCalculation.cost[checkoutStore.deliveryMethod]?.shippingPounds ??
        '0',
    };

    checkoutStore.setDeliverySlots(data.deliverySlots ?? null);

    if (
      this.freezeETAExpired - Date.now() > 0 &&
      data.warehouse.code === this.etaCalculation?.warehouse.code
    ) {
      if (this.etaCalculation) {
        this.setEtaCalculation({
          ...this.etaCalculation,
          paymentMethods: etaCalculation.paymentMethods,
          warehouse: data.warehouse,
        });
      }
      this.setIsETALoading(false);
      return Promise.resolve(output);
    }

    if (!etaCalculation || !Object.keys(etaCalculation.cost).length) {
      this.setIsETALoading(false);
      return Promise.reject({});
    }

    runInAction(() => {
      if (
        this.etaCalculation?.highDemand !== etaCalculation.highDemand ||
        this.etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]
          ?.shipping !==
          etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]?.shipping ||
        this.etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]
          ?.threshold !==
          etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]?.threshold ||
        this.etaCalculation.duration.range !== etaCalculation.duration.range
      ) {
        mainStore.sendAnalytics(['BI', 'analytics'], {
          name: 'General: eta changed',
          params: {
            eta_min: etaCalculation.duration.min ?? 0,
            eta_max: etaCalculation.duration.max ?? 0,
            delivery_fee:
              etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]
                ?.shippingPounds ?? 0,
            threshold:
              etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]
                ?.thresholdPounds ?? 0,
            is_surger: etaCalculation.highDemand ?? false,
          },
        });
      }
      this.setEtaCalculation(etaCalculation);
      checkoutStore.setDeliveryPrices(output);
      if (output.oldPrice !== output.newPrice) {
        checkoutStore.setIsUpdatedDeliveryPopover(true);
      }
      mainStore.sendToRN('sendTags', {
        warehouse_code: data.warehouse.code,
      });
      mainStore.sendToRN('setUserProperties', {
        'Address: warehouse code': data.warehouse.id,
      });
    });

    this.setIsETALoading(false);
    return {
      ...output,
      etaCalculation: {
        ...this.etaCalculation,
        paymentMethods: etaCalculation.paymentMethods,
        warehouse: data.warehouse,
      },
    };
  }

  requestETASlot(): Promise<RequestETAResponse> {
    if (!userStore.deliveryAddress) {
      return Promise.reject();
    }
    this.setIsETALoading(true);
    return ETARequests.getETASlot({
      pos: `${userStore.deliveryAddress.coordinates.lat},${userStore.deliveryAddress.coordinates.lng}`,
      seller: 'JIFFY',
      ...(checkoutStore.selectedDeliverySlot?.schedule_slot_id
        ? {
            deliverySlotId:
              checkoutStore.selectedDeliverySlot?.schedule_slot_id,
          }
        : {
            scheduleSlotId:
              checkoutStore.selectedDeliverySlot?.schedule_slot_id,
            currentDate: +(checkoutStore.selectedDeliveryDate ?? 0) / 1000,
          }),
    })
      .then(({ data }) => {
        const etaCalculation: ETACalculation =
          this.etaCalculationResponse(data);
        const output: RequestETAResponse = {
          oldPrice:
            this.etaCalculation?.cost[checkoutStore.deliveryMethod]
              ?.shippingPounds ?? '0',
          newPrice:
            etaCalculation.cost[checkoutStore.deliveryMethod]?.shippingPounds ??
            '0',
        };

        if (this.freezeETAExpired - Date.now() > 0) {
          if (this.etaCalculation) {
            this.setEtaSlotCalculation({
              ...this.etaCalculation,
              paymentMethods: etaCalculation.paymentMethods,
            });
          }

          return Promise.resolve(output);
        }

        if (!etaCalculation || !Object.keys(etaCalculation.cost).length) {
          return Promise.reject({});
        }

        runInAction(() => {
          if (
            this.etaCalculation?.highDemand !== etaCalculation.highDemand ||
            this.etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]
              ?.shipping !==
              etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]
                ?.shipping ||
            this.etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]
              ?.threshold !==
              etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]
                ?.threshold ||
            this.etaCalculation.duration.range !== etaCalculation.duration.range
          ) {
            mainStore.sendAnalytics(['BI', 'analytics'], {
              name: 'General: eta changed',
              params: {
                eta_min: etaCalculation.duration.min ?? 0,
                eta_max: etaCalculation.duration.max ?? 0,
                delivery_fee:
                  etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]
                    ?.shippingPounds ?? 0,
                threshold:
                  etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]
                    ?.thresholdPounds ?? 0,
                is_surger: etaCalculation.highDemand ?? false,
              },
            });
          }
          this.setEtaSlotCalculation(etaCalculation);
          // checkoutStore.setDeliverySlots(data.deliverySlots || null);
          checkoutStore.setDeliveryPrices(output);
          if (output.oldPrice !== output.newPrice) {
            checkoutStore.setIsUpdatedDeliveryPopover(true);
          }
          mainStore.sendToRN('sendTags', {
            warehouse_code: data.warehouse.code,
          });
          mainStore.sendToRN('setUserProperties', {
            'Address: warehouse code': data.warehouse.id,
          });

          if (!checkoutStore.selectedDeliveryDate) {
            checkoutStore.setSelectedDate('0');
            checkoutStore.setSelectedSlot(null);
          } else if (checkoutStore.selectedDeliveryDate !== '0') {
            const selectedDay =
              checkoutStore.deliverySlots[checkoutStore.selectedDeliveryDate];

            if (selectedDay) {
              let selectedSlot = selectedDay.find(
                (slot) =>
                  slot.schedule_slot_id ===
                  checkoutStore.selectedDeliverySlot?.schedule_slot_id,
              );
              if (!selectedSlot) {
                selectedSlot = checkoutStore.selectedDeliverySlot
                  ? selectedDay.find(
                      (slot) =>
                        slot.delivery_min_time >=
                        checkoutStore.selectedDeliverySlot!.delivery_max_time,
                    )
                  : selectedDay[0];
              }
            } else {
              const defaultDay = Object.entries(
                checkoutStore.deliverySlots || {},
              ).find(
                (i) =>
                  +i[0] > +(checkoutStore.selectedDeliveryDate || 0) &&
                  i[1].length > 0,
              );
              if (defaultDay) {
                checkoutStore.setSelectedDate(defaultDay[0]);
                // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                checkoutStore.setSelectedSlot(defaultDay[1][0]);
              }
            }
          }
        });
        return Promise.resolve(output);
      })
      .catch(async (error) => {
        console.error(error);
        if (
          !error.response &&
          (error.code === 'ECONNABORTED' || error.message === 'Network Error')
        ) {
          return Promise.reject();
        }

        await this.requestETA();
        return Promise.reject();
      })
      .finally(() => this.setIsETALoading(false));
  }

  async newOrder(data: OrderData): Promise<NewOrderResponse | null> {
    if (!data.phone) {
      return null;
    }

    this.isLoading = true;
    /**
     * Code below provides bonuses info for backend
     * "paidBonuses", "promocodeDiscountAmount" are required fields for new order request,
     * and they should be presented in each cart item
     * */
    const calculatedItemsMap: Record<string, Record<string, number>> = {};
    const calculatedItems =
      catalogStore.deliveriesOrders.flatMap(({ items }) => items) ?? [];
    for (
      let calculatedItemIndex = 0;
      calculatedItemIndex < calculatedItems.length;
      calculatedItemIndex += 1
    ) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      const sku = calculatedItems[calculatedItemIndex].sku;
      calculatedItemsMap[sku] = {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        paidBonuses: calculatedItems[calculatedItemIndex].paid_bonuses,
        promocodeDiscountAmount:
          // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          calculatedItems[calculatedItemIndex].promocode_discount_amount,
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        paidPrice: calculatedItems[calculatedItemIndex].paid_price,
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        discountAmount: calculatedItems[calculatedItemIndex].discount_amount,
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        basePrice: calculatedItems[calculatedItemIndex].base_price,
      };
    }

    const payment_method = Object.values(MobilePaymentMethod).includes(
      checkoutStore.activePaymentMethod as MobilePaymentMethod,
    )
      ? ETAPaymentMethod.Card
      : checkoutStore.activePaymentMethod === AdditionalPaymentMethod.KNET ||
        checkoutStore.activePaymentMethod ===
          AdditionalPaymentMethod.VisaMastercard
      ? ETAPaymentMethod.Card
      : (checkoutStore.activePaymentMethod as ETAPaymentMethod);

    const payment_method_id =
      checkoutStore.activePaymentMethod === AdditionalPaymentMethod.KNET
        ? 1
        : checkoutStore.activePaymentMethod ===
          AdditionalPaymentMethod.VisaMastercard
        ? 2
        : undefined;

    const session_id =
      !payment_method_id && payment_method === ETAPaymentMethod.Card
        ? data.sessionId
        : undefined;

    try {
      if (!this.idempotencyKey) {
        this.idempotencyKey = uuidv4();
      }

      const deliveries =
        checkoutStore.deliveries?.map(
          ({ slotDeliveryDetails, itemsIds }, index) => {
            const order = catalogStore.deliveriesOrders[index];

            if (!order) {
              throw new Error('Order not found');
            }

            const {
              base_total,
              discount_total,
              is_delivery_free,
              paid_bonuses,
              paid_total,
              promocode,
              promocodeError,
              promocode_discount,
              service_fee,
              should_use_bonuses,
              delivery_info: { paid_price },
            } = order;

            const items: NewOrderCartItemOffer[] = [];

            itemsIds.forEach((id) => {
              const cartItem = catalogStore.selectedCartItems.find(
                (item) => item.sku === id,
              );

              if (!cartItem) {
                return;
              }

              const deliveryItem = order.items.find(
                ({ sku }) => sku === (cartItem as Offer).sku,
              );

              if (!deliveryItem) {
                return;
              }

              const {
                base_price,
                discount_amount,
                image,
                name,
                paid_bonuses,
                paid_price,
                product_id,
                promocode_discount_amount,
                requested_quantity,
                sku,
                weight,
              } = deliveryItem;

              items.push({
                base_price,
                discount_amount,
                image,
                name,
                paid_bonuses,
                paid_price,
                promocode_discount_amount,
                requested_quantity,
                sku,
                weight,
                product_id: `${product_id}`,
                price_per_unit: cartItem.pricePerUnit,
              });
            });

            const result = {
              base_total,
              discount_total,
              is_delivery_free,
              paid_bonuses,
              paid_total,
              promocode: (!promocodeError && promocode) || null,
              promocode_discount,
              service_fee,
              should_use_bonuses,
              mechanic: MechanicType.ON_DEMAND,
              items,
              ...(order.bundles?.length
                ? {
                    bundles: order.bundles.map((i) => ({
                      id: i.id,
                      requested_quantity: i.requested_quantity,
                    })),
                  }
                : {}),

              // FIXME: where to get? ETA slot?
              delivery_price: paid_price,
              tips_amount: 0,
            };

            if (slotDeliveryDetails) {
              return {
                ...result,
                mechanic: MechanicType.SLOT,
                slot_delivery_details: {
                  current_date: slotDeliveryDetails.currentDate,
                  schedule_slot_id: slotDeliveryDetails.scheduleSlotId,
                },
              };
            }

            return result;
          },
        ) ?? [];
      Sentry.withScope((scope) => {
        scope.setExtras({
          context: 'newOrder',
          error: 'REQUEST DATA NEW ORDER',
          data: {
            deliveiesInStore: checkoutStore.deliveries,
            calculateOrders: catalogStore.deliveriesOrders,
            order: {
              customer_id: userStore.personalData.id?.toString() ?? '',
              currency: this.currency,
              warehouse_code: orderStore.etaWarehouseCode,
              delivery_method: data.deliveryMethod,
              client_source: 'App',
              save_card: data.saveCard,
              payment_method,
              session_id,
              deliveries,
            },
            recipient: {
              full_name: data.fullName,
              email: data.email.trim(),
              phone: data.phone,
            },
            address: {
              city: userStore.deliveryAddress?.city ?? '',
              country_id: userStore.deliveryAddress?.country ?? '',
              region: userStore.deliveryAddress?.region ?? '',
              address_1: data.address ?? '',
              address_2: data.address2 ?? '',
              latitude: userStore.deliveryAddress?.coordinates.lat ?? null,
              longitude: userStore.deliveryAddress?.coordinates.lng ?? null,
              zip: userStore.deliveryAddress?.zip ?? '',
              comment: data.comment,
              instructions: data.instructions ?? null,
              notes: data.notes ?? '',
            },
          },
        });
        Sentry.captureMessage('[Checkout] PREREQUEST NEW ORDER', 'warning');
      });

      const cardId =
        orderStore.paymentSystem === 'amazon' &&
        !checkoutStore.isAddNewCard &&
        checkoutStore.activePaymentMethod !== ETAPaymentMethod.Cash
          ? checkoutStore.paymentsMethodId
          : undefined;

      const newOrderData = {
        order: {
          customer_id: userStore.personalData.id?.toString() ?? '',
          currency: this.currency,
          warehouse_code: orderStore.etaWarehouseCode,
          delivery_method: data.deliveryMethod,
          client_source: 'App',
          save_card: data.saveCard,
          session_id,
          deliveries,
          payment: {
            payment_method,
            card_id: cardId,
            pay_by_saved_card: !!cardId || undefined,
          },
        },
        recipient: {
          full_name: data.fullName,
          email: data.email.trim(),
          phone: data.phone,
        },
        address: {
          city: userStore.deliveryAddress?.city ?? '',
          country_id: userStore.deliveryAddress?.country ?? '',
          region: userStore.deliveryAddress?.region ?? '',
          address_1: data.address ?? '',
          address_2: data.address2 ?? '',
          latitude: userStore.deliveryAddress?.coordinates.lat ?? null,
          longitude: userStore.deliveryAddress?.coordinates.lng ?? null,
          zip: userStore.deliveryAddress?.zip ?? '',
          comment: data.comment,
          instructions: data.instructions ?? null,
          notes: data.notes ?? '',
        },
      };

      const response = await OrderRequests.newOrder(
        newOrderData,
        this.idempotencyKey,
      );

      runInAction(() => {
        this.idempotencyKey = '';
      });

      this.isLoading = false;
      return response;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          context: 'newOrder first error',
          error: (error && (error as any)['response']) || error,
        });
        Sentry.captureMessage(
          '[Checkout] Failed create new order TOP ERROR NOT AXIOS',
          'warning',
        );
      });
      //mainStore.pushAlert('error', i18n.t('errors:unableCreateOrder'));
      if (error instanceof AxiosError) {
        await this.reCalculateDeliveries();

        Sentry.withScope((scope) => {
          scope.setExtras({
            context: 'newOrder',
            error: (error && (error as any)['response']) || error,
          });
          Sentry.captureMessage(
            '[Checkout] Failed create new order',
            'warning',
          );
        });

        const { response } = error;

        if (!response) {
          this.isLoading = false;
          return null;
        }

        const { data } = response;
        if (data?.errors?.length) {
          if (
            data.errors[0] &&
            data.errors[0].toLowerCase() === 'gift out of stock'
          ) {
            mainStore.setIsShowGiftOutStockPopover(true);
            this.isLoading = false;
            return null;
          }
          if (data.errors[0] === 'Promocode is not valid') {
            runInAction(() => {
              catalogStore.promocode.message =
                data.data?.payload?.err_code ===
                'DISCOUNT.COUPONS.ONLY_FIRST_ORDER_ALLOWED'
                  ? '' //i18n.t('cartPage:notFirstOrderPromocodeFreeDelivery')
                  : ''; //i18n.t('cartPage:promocodeNotFoundText');
              catalogStore.promocode.coupon = null;
              catalogStore.promocode.errorType = 'error';
              catalogStore.promocode.success = false;
              mainStore.setIsInvalidPromocodePopover(true);
            });
            this.isLoading = false;
            return null;
          }
        }
      }
    } finally {
      this.isLoading = false;
    }
    return null;
  }

  async reCalculateDeliveries() {
    checkoutStore.slotsChangedEventStore.preserveDeliveryInfo(
      this.etaCalculation?.duration.range,
    );
    await orderStore.requestETA();
    const result = checkoutStore.checkSlotExpirationOfExistingDeliveries(
      checkoutStore.sortedDeliverySlotsList,
    );
    checkoutStore.slotsChangedEventStore.checkDifferenceWithPreviousDeliveries();

    return result;
  }

  async check3dsStatus(orderId: string) {
    const res = await OrderRequests.get3dsStatus(orderId);

    this.set3dsUrl(res['3ds_url']);

    return res;
  }

  async checkOrderPayment(orderId: string): Promise<{
    status: OrderStatus | null;
    received_bonuses: number | null;
  }> {
    try {
      const {
        order: { status, received_bonuses },
      } = await OrderRequests.getOrder(orderId);
      return { status, received_bonuses };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        if (!response) {
          return { status: null, received_bonuses: null };
        }
        Sentry.withScope((scope) => {
          scope.setExtras({
            context: 'checkOrderPayment',
            error: response,
          });
          Sentry.captureMessage(
            '[Checkout] Failed create new order',
            'warning',
          );
        });
      }
    }
    return { status: null, received_bonuses: null };
  }

  async orderCancel(orderId: string) {
    try {
      await OrderRequests.orderCancel(orderId);
    } catch (e) {
      console.error(e);
    }
  }

  async orderIdempotencyCancel(): Promise<boolean> {
    if (!this.idempotencyKey || !userStore.isAuthorized) {
      return true;
    }

    try {
      await OrderRequests.orderIdempotencyCancel({
        idempotency_key: this.idempotencyKey,
      });
      runInAction(() => {
        this.idempotencyKey = '';
      });

      return true;
    } catch (e) {
      console.error(e);
    }

    return false;
  }

  async requestOrders(page = 1): Promise<boolean> {
    if (!userStore.personalData.id) {
      return false;
    }
    try {
      this.isLoading = true;
      const { orders, total_count } = await OrderRequests.getOrders({
        filter: {
          customer_id: userStore.personalData.id,
        },
        options: {
          page,
        },
      });
      const totalPages = Math.ceil(total_count / 20);
      runInAction(() => {
        const newOrders = orders
          .filter((order) => order.cancel_reason !== 'CANCEL_BY_USER')
          .map((order) => this.calcOrderItems(order));
        if (page === 1) {
          this.orderList = newOrders;
          this.currentOrdersPage = 1;
        } else {
          if (totalPages >= page) {
            this.orderList = [...(this.orderList || []), ...newOrders];
          } else if (!this.orderList) {
            this.orderList = [];
          }
        }
      });
      this.isLoading = false;
      return totalPages > page;
    } catch (error) {
      this.isLoading = false;
      error && console.error(error);
    }
    return false;
  }

  async fetchOrders() {
    if (!userStore.personalData.id) {
      return;
    }
    try {
      const { orders, total_count } = await OrderRequests.getOrders({
        filter: {
          customer_id: userStore.personalData.id,
        },
      });
      let successfulOrders: OrdersResponse;
      if (total_count) {
        successfulOrders = await OrderRequests.getOrders({
          filter: {
            customer_id: userStore.personalData.id,
            status: [
              'created',
              'reserved',
              'confirmed',
              'delivery_created',
              'wh_created',
              'picking',
              'ready_to_ship',
              'in_delivery',
              'delivered',
              'delivered_partially',
              'finished',
            ],
          },
        });
      }
      runInAction(() => {
        this.orderList = orders
          .filter((order) => order.cancel_reason !== 'CANCEL_BY_USER')
          .map((order) => this.calcOrderItems(order));
        mainStore.analytics.totalSpent = mainStore.convertPenceToPounds(
          (successfulOrders?.orders || []).reduce(
            (sum, order) => sum + (order.paid_total || 0),
            0,
          ),
        );
        mainStore.analytics.totalOrders = total_count;
        mainStore.analytics.totalSuccessfulOrders =
          successfulOrders?.total_count || 0;
        if (orders.length) {
          // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          mainStore.analytics.lastOrderCreatedAt = orders[0].created_at;
          mainStore.analytics.firstOrderCreatedAt =
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            orders[orders.length - 1].created_at;
        }
        mainStore.sendToRN('setUserProperties', {
          'Commerce: total spent': mainStore.analytics.totalSpent,
        });
        mainStore.sendToRN('setUserProperties', {
          'Commerce: total orders': total_count,
        });
        if (orders.length) {
          mainStore.sendToRN('setUserProperties', {
            'Commerce: first order date':
              mainStore.analytics.firstOrderCreatedAt,
          });
        }
      });
    } catch (error) {
      error && console.error(error);
    }
  }

  async requestOrder(id: string): Promise<Order | OrderShort | null> {
    if (!id) {
      return null;
    }
    if (this.orderList && this.orderList.length) {
      const order = this.getOrderById(id);
      if (order && order.public_status === 'delivered' && order.has_feedback) {
        return order;
      }
    }
    try {
      const { order } = await OrderRequests.getOrder(id);
      return this.calcOrderItems(order);
    } catch (e) {
      await this.errorHandler(
        e as AxiosError<ApiErrorResponse>,
        'requestOrder',
      );
    }
    return null;
  }

  async requestPayments(): Promise<boolean> {
    if (!userStore.isAuthorized) {
      return false;
    }
    try {
      const res = await OrderRequests.getPayments();
      let cardsToSet = res;

      if (company.useAwsPaymentMethod) {
        cardsToSet = this.prepearAmazonPayments(res as AwsPayment[]);
      }

      this.setPaymentCards(cardsToSet as Payment[]);
      return true;
    } catch (e) {
      await this.errorHandler(
        e as AxiosError<ApiErrorResponse>,
        'requestPayments',
      );
    }
    return false;
  }

  prepearAmazonPayments(amazonCards: AwsPayment[]): Payment[] {
    return amazonCards.map((item) => ({
      card: {
        brand: '',
        checks: {
          address_line1_check: null,
          address_postal_code_check: null,
          cvc_check: null,
        },
        country: '',
        exp_month: 0,
        exp_year: 0,
        fingerprint: '',
        last4: item.public_identifier.slice(-4),
      },
      id: item.id,
      paymentToken: item.payment_system_id,
      metadata: {
        is_default: `${item.is_default}`,
      },
    }));
  }

  createIntent(): Promise<Payment | AxiosError> {
    return OrderRequests.createIntent().catch((e) =>
      this.errorHandler(e, 'createIntent'),
    );
  }

  addPayment(id: string): Promise<Payment | AxiosError> {
    return OrderRequests.addPayment({ payment_method_id: id }).catch((e) =>
      this.errorHandler(e, 'addPayment'),
    );
  }

  deletePayment(id: string): Promise<Payment | AxiosError> {
    return OrderRequests.deletePayment({ payment_method_id: id }).catch((e) =>
      this.errorHandler(e, 'deletePayment'),
    );
  }

  setDefaultPayment(id: string): Promise<Payment | AxiosError> {
    return OrderRequests.setDefaultPayment({ payment_method_id: id }).catch(
      (e) => this.errorHandler(e, 'setDefaultPayment'),
    );
  }

  async addFeedback(orderId: string, data: RateInfo) {
    const ratingRate = data.rating;

    if (!ratingRate) {
      return;
    }

    const feedBackID = await this.newFeedback(orderId, ratingRate);

    if (feedBackID && Object.keys(data).length > 1) {
      return this.fillFeedback(orderId, feedBackID, {
        rating: ratingRate,
        delivery_rating: data.deliveryRating ?? -1,
        product_quality_rating: data.productQualityRating ?? -1,
        product_variety_rating: data.productVarietyRating ?? -1,
        comment: data.comment ?? '',
      });
    }

    return true;
  }

  async newFeedback(orderId: string, rating: number): Promise<string> {
    try {
      const { id } = await OrderRequests.newFeedback({
        rating,
        order_id: orderId,
      });

      mainStore.sendToRN('analytics', {
        name: 'Rate: order general',
        params: {
          order_id: orderId,
          score: rating,
        },
      });

      mainStore.sendToRN('firebaseAnalytics', {
        name: 'rate_order_general',
        params: {
          order_id: orderId,
          score: rating,
        },
      });

      return id;
    } catch (e) {
      await this.errorHandler(e as AxiosError<ApiErrorResponse>, 'newFeedback');
    }
    return '';
  }

  async fillFeedback(
    orderId: string,
    feedbackId: string,
    data: FillFeedbackRequest,
  ): Promise<boolean> {
    const {
      delivery_rating,
      product_variety_rating,
      product_quality_rating,
      comment,
      rating,
    } = data;

    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order rating': rating,
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order delivery rating': delivery_rating,
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order product variety rating': product_variety_rating,
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order product quality rating': product_quality_rating,
    });
    mainStore.sendToRN('analytics', {
      name: 'Rate: order detailed',
      params: {
        order_id: orderId,
        delivery: delivery_rating,
        product_variety: product_variety_rating,
        product_quality: product_quality_rating,
        comment_provided: !!comment.length,
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'rate_order_detailed',
      params: {
        order_id: orderId,
        delivery: delivery_rating,
        product_variety: product_variety_rating,
        product_quality: product_quality_rating,
        comment_provided: !!comment.length,
      },
    });

    try {
      await OrderRequests.fillFeedback(feedbackId, data);

      return true;
    } catch (e) {
      await this.errorHandler(
        e as AxiosError<ApiErrorResponse>,
        'fillFeedback',
      );
    }

    return false;
  }

  async fetchGifts(): Promise<boolean> {
    try {
      const { data } = await OrderRequests.getGifts(
        this.etaCalculation?.warehouse.code || '',
      );
      if (!data) {
        this.setGift(null);
        if (this.activeGift) {
          mainStore.setIsShowGiftOutStockPopover(true);
          return false;
        }
        return true;
      }
      const isMinimumOrderAmountDiff = !!(
        this.gift &&
        this.gift.minimum_order_amount !== data.minimum_order_amount
      );
      this.setGift(data);
      this.setIsAuthInCart(false);
      if (this.activeGift) {
        if (
          (isMinimumOrderAmountDiff &&
            mainStore.convertPoundsToPence(catalogStore.finalPrice) <
              mainStore.convertPoundsToPence(data.minimum_order_amount)) ||
          !data.gift_items.some((item) => item.id === this.activeGift?.id)
        ) {
          mainStore.setIsShowGiftOutStockPopover(true);
          return false;
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        if (!response) {
          return false;
        }
        if (
          this.isAuthInCart &&
          response.data?.errors?.length &&
          response.data.errors[0] &&
          response.data.errors[0].toLowerCase() === 'gift already received'
        ) {
          mainStore.setIsShowGiftAlreadyReceivedPopover(true);
        }
        this.setGift(null);
        if (this.activeGift) {
          mainStore.setIsShowGiftOutStockPopover(true);
          return false;
        }
      }
    }
    return true;
  }

  async payTip(
    orderId: string,
    payAmount: string,
    isSaveCard: boolean,
  ): Promise<string> {
    try {
      const { client_secret } = await OrderRequests.payTip(orderId, {
        amount: mainStore.convertPoundsToPence(payAmount),
        save_card: isSaveCard,
        currency: this.currency,
        email: userStore.personalData.email || '',
      });
      return client_secret || '';
    } catch (error) {
      error && console.error(error);
    }
    return '';
  }

  async checkPayedTip(orderId: string): Promise<boolean> {
    if (!userStore.isAuthorized) {
      return true;
    }
    try {
      const { status } = await OrderRequests.checkPayedTip(orderId);
      return status === 'received';
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          return false;
        }
        error && console.error(error);
      }
    }
    return true;
  }

  getDeliveryCost(): Promise<RequestETAResponse> {
    if (!userStore.isAuthorized) {
      return Promise.reject();
    }
    const deliveryType = {
      [ETADeliveryMethodType.ClickAndCollect]: 1,
      [ETADeliveryMethodType.JiffyDelivery]: 2,
    };
    const deliveryMechanic = orderStore.isExpressAvailableNow
      ? DeliveryCostMechanicType.ON_DEMAND
      : DeliveryCostMechanicType.SLOT;
    return ETARequests.getDeliveryCost({
      wh_code: orderStore.etaWarehouseCode,
      latitude: userStore.deliveryAddress?.coordinates.lat || '',
      longitude: userStore.deliveryAddress?.coordinates.lng || '',
      seller: 'jiffy',
      force: true,
      delivery_type: deliveryType[checkoutStore.deliveryMethod],
      deliveryMechanic,
    })
      .then((e) => {
        const output: RequestETAResponse = {
          oldPrice:
            this.etaCalculation?.cost[checkoutStore.deliveryMethod]
              ?.shippingPounds || '0',
          newPrice: mainStore.convertPenceToPounds(e.shipping),
        };
        runInAction(() => {
          if (!this.etaCalculation) {
            return;
          }
          this.setFreezeETAExpired(new Date(e.ttl).getTime());
          this.etaCalculation.cost[checkoutStore.deliveryMethod] = {
            ...this.etaCalculation.cost[checkoutStore.deliveryMethod],
            ...e,
            shippingPounds: mainStore.convertPenceToPounds(e.shipping),
            thresholdPounds: mainStore.convertPenceToPounds(e.threshold),
          } as ETACost;
          checkoutStore.setDeliveryPrices(output);
          if (output.oldPrice !== output.newPrice) {
            checkoutStore.setIsUpdatedDeliveryPopover(true);
          }
        });
        return Promise.resolve(output);
      })
      .catch((error) => {
        runInAction(() => {
          this.setFreezeETAExpired(0);
        });
        error && console.error(error);
        return Promise.reject();
      });
  }

  async checkIsFirstOrder(isForce?: boolean) {
    if (this.isFirstOrder !== null && !isForce) {
      return;
    }

    if (!userStore.personalData.id) {
      this.setIsFirstOrder(true);

      return;
    }

    try {
      // gets all statuses exclude canceled, delivery_failed, because such statuses are ignored on backend
      const { total_count } = await OrderRequests.getOrders({
        filter: {
          customer_id: userStore.personalData.id ?? '',
          status: [
            'created',
            'reserved',
            'confirmed',
            'delivery_created',
            'wh_created',
            'picking',
            'ready_to_ship',
            'in_delivery',
            'delivered',
            'delivered_partially',
            'finished',
          ],
        },
        options: {
          page: 1,
          limit: 1,
        },
      });

      this.setIsFirstOrder(total_count === 0);
      return total_count === 0;
    } catch (e) {
      this.setIsFirstOrder(true);
    }
  }

  requestCurrency() {
    OrderRequests.getCurrency()
      .then(({ data: { currency = '', digits_after_coma = 2 } }) => {
        this.setCurrency(currency);
        mainStore.setDigitsAfterComma(digits_after_coma);
      })
      .catch((e) => this.errorHandler(e, 'requestCurrency'));
  }

  requestPriorityPaymentSystem() {
    if (!userStore.isAuthorized) {
      return;
    }

    OrderRequests.getPriorityPaymentSystem()
      .then((system) => {
        this.setPaymentSystem(system);
      })
      .catch((e) => {
        // TODO:
        this.setPaymentSystem(PaymentSystem.stripe);
        this.errorHandler(e, 'priorityPaymentSystem').catch(() => void 0);
      });
  }

  // Errors
  errorHandler = (
    error: AxiosError<ApiErrorResponse>,
    context: string,
  ): Promise<AxiosError> => mainStore.errorHandler(error, context);
}

export const orderStore = new OrderStore();
