import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import { getCloudflareUrl } from '~/components/CloudflareResponsiveImage/utils';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useGlobal } from '~/hooks/useGlobal';

import { catalogStore } from '../../../stores/CatalogStore';
import { Category } from '../../../stores/CategoriesStore';

import Icon from '../../Icon/Icon';

import { createCategoryUrl } from '../utils';

import styles from './CategoryElement.module.scss';

const CategoryElement = ({
  category,
  nonCloseable,
}: {
  category: Category;
  nonCloseable?: boolean;
}) => {
  const { handleClickCategory } = useCategoryAnalytics();
  const { t } = useTranslation();

  const { isMobile } = useGlobal();
  const imgSrc = encodeURI(category.previewImage || '');
  const [isOpen, setIsOpen] = useState(false);

  const cfSrcForImage = (src: string) =>
    getCloudflareUrl(src, company.config.cloudflareImages.domain, {
      width: 285,
      height: 182,
    });

  // const subcategory = catalogStore.productList[category.id]?.subcategory ?? [];
  const subcategory = category?.subcategory ?? [];
  const CategoryElementComponent = isMobile ? 'div' : 'a';

  const getLinkProps = (subcategory: Category) => {
    const id = subcategory.id;

    return {
      to: createCategoryUrl({ id }),
      state: { clean_filters: true },
      onClick: () => {
        if (!nonCloseable) {
          handleClickCategory(id)();
          catalogStore.resetCatalogFilters();
        }
      },
    };
  };

  return (
    <div className={styles.container} data-company={company.name}>
      <CategoryElementComponent
        href={createCategoryUrl({ id: category.id })}
        className={styles.categoryElement}
        style={{
          backgroundImage: `url('${cfSrcForImage(imgSrc)}')`,
        }}
        onClick={
          isMobile
            ? () => {
                handleClickCategory(category.id)();
                setIsOpen((isOpen) => !isOpen);
              }
            : handleClickCategory(category.id)
        }
      >
        <div
          className={styles.img}
          style={{
            backgroundImage: `url('${cfSrcForImage(imgSrc)}')`,
          }}
        />
        <h3 className={styles.title}>{category.name}</h3>
        <Icon
          // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          className={classes([styles.icon, { [styles.active]: isOpen }])}
          type="chevron"
          size={16}
        />
      </CategoryElementComponent>
      {(isOpen || nonCloseable) && (
        <>
          <div className={styles.subcategories}>
            {subcategory.map(
              ({ name, previewImage, id }, index, subcategories) => (
                <Link key={id} {...getLinkProps(subcategories[index]!)}>
                  <div
                    className={styles.subcategory}
                    style={{
                      backgroundImage: `url('${encodeURI(
                        cfSrcForImage(previewImage || ''),
                      )}')`,
                    }}
                  >
                    <h4 className={styles.subcategoryTitle}>{name}</h4>
                  </div>
                </Link>
              ),
            )}
          </div>
          {subcategory.length > 1 && (
            <p className={styles.viewAll}>
              <Link
                to={createCategoryUrl({ id: category.id })}
                className={styles.link}
              >
                {t('viewAll')}
                <Icon type="arrow" size={16} />
              </Link>
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default observer(CategoryElement);
