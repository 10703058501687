import { addHours, isToday } from 'date-fns';
import { format } from 'date-fns-tz';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company, DEFAULT_COMPANIES } from '~/company/Company';
import DeliveryDateLabel from '~/components/DeliveryDateLabel/DeliveryDateLabel';
import Icon from '~/components/Icon/Icon';
import { formatVolume, pluralizePcLabel } from '~/components/ProductCard/utils';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useDateLang } from '~/hooks/useDateLang';
import { useProductLimit } from '~/hooks/useProductLimit';
import { CartItem, CartItemOffer, catalogStore } from '~/stores/CatalogStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { isProductBundle } from '~/types/Product';
import { formatPriceWithCurrency } from '~/utils/formaters';

import Checkbox from '../CheckboxCustom/Checkbox';
import CloudflareResponseImage from '../CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '../CloudflareResponsiveImage/constants';
import Counter from '../Counter';
import Price from '../Price';
import Tile from '../Tile';

import styles from './CartCard.module.scss';
import { RestrictionBlock } from './components/RestrictionBlock/RestrictionBlock';
import CartCardVariant1 from './Variant1/CartCard';

interface CartCardProps {
  item: CartItem;
  size?: 's' | 'm' | 'l';
  location?: 'modalCart' | 'productPreview';
  className?: string;
  showCheckbox?: boolean;
}

const CartCard = ({
  item,
  size = 's',
  className,
  location,
  showCheckbox,
}: CartCardProps) => {
  const { t } = useTranslation();
  const { handleClickProduct } = useCategoryAnalytics();
  const { limit, currentWhSellable, parentWhSellable, laterCount } =
    useProductLimit({
      offer: item,
    });
  const { locale } = useDateLang();

  const isBundle = isProductBundle(item);

  if (DEFAULT_COMPANIES.includes(company.name)) {
    return (
      <CartCardVariant1
        item={item}
        location={location}
        size={size}
        className={className}
        showCheckbox={showCheckbox}
      />
    );
  }

  const handleChangeCount = (count: number, action: 'add' | 'remove') => {
    if (item) {
      catalogStore.setCartItemCountByProduct(
        item,
        count,
        action,
        'product_main',
      );
    }
  };

  const isMultiBuy = !isBundle && Boolean(item.promoRequiredQuantity);
  const isMultibuyActivated =
    isMultiBuy && item.count >= item.promoRequiredQuantity;
  const isExpress =
    item &&
    currentWhSellable &&
    currentWhSellable >= item.count &&
    orderStore.isExpressAvailableNow;
  const isBackorderAvailable =
    item.isBackorderAvailable && item.backorderLeadTime && parentWhSellable > 0;

  const volume = isBundle
    ? `• ${item.bundleQuantity ?? 1}x`
    : formatVolume(item.properties, { prefix: '•', defaultVolume: '1x' });

  const leadTime = item.backorderLeadTime ? item.backorderLeadTime : 0;

  const price =
    !isMultiBuy && item.discountPrice ? item.discountPrice : item.price;

  const renderPriceComponent = () => {
    if (isMultiBuy && item.count >= item.promoRequiredQuantity) {
      return (
        <Price
          price={mainStore.convertPenceToPounds(
            item.promoQuantityDiscountPrice * item.count,
          )}
          discount={mainStore.convertPenceToPounds(price * item.count)}
          classNameCurrentPrice={styles['cart-card__info-footer-price']}
        />
      );
    }

    if (item.discountPrice) {
      return (
        <Price
          price={mainStore.convertPenceToPounds(price * item.count)}
          discount={mainStore.convertPenceToPounds(item.price * item.count)}
          classNameCurrentPrice={styles['cart-card__info-footer-price']}
        />
      );
    }

    return (
      <Price
        price={mainStore.convertPenceToPounds(price * item.count)}
        classNameCurrentPrice={styles['cart-card__info-footer-price']}
      />
    );
  };

  let selectablePropsList = null;
  if (!isBundle && Object.keys(item.selectableProperties ?? {}).length > 0) {
    selectablePropsList = (
      <ul className={styles.propsList}>
        {Object.values((item as CartItemOffer).selectableProperties).map(
          (item, id) => (
            <li key={id} className={styles.propsListItem}>
              <span className={styles.propsListItemName}>{item.name}:</span>{' '}
              <span className={styles.propsListItemValue}>{item.value}</span>
            </li>
          ),
        )}
      </ul>
    );
  }

  return (
    <div className={styles.container}>
      <div className={classes([styles['cart-card'], `_${size}`, className])}>
        <Link
          onClick={handleClickProduct(item)}
          to={`/p/${item.slug}` + (isBundle ? '' : `?sku=${item.sku}`)}
          state={{ ...(isProductBundle(item) ? {} : { offerSku: item.sku }) }}
        >
          <Tile className={styles['cart-card__tile']}>
            {isExpress ? (
              <div className={styles.expressIcon}>
                <Icon type="express-fill" size={16} />
              </div>
            ) : isBackorderAvailable ? (
              <DeliveryDateLabel
                className={styles.deliveryDateLabel}
                deliveryDate={
                  isToday(addHours(new Date(), leadTime))
                    ? null
                    : addHours(new Date(), leadTime)
                }
              />
            ) : null}
            <CloudflareResponseImage
              src={item.previewImageThumb || ''}
              srcParams={{ width: 100, height: 140 }}
              srcSetParams={defaultSrcSetParams}
              alt=""
              className={styles['cart-card__tile__image']}
              loading="lazy"
            />
          </Tile>
        </Link>
        <div className={styles['cart-card__info']}>
          <div className={styles['cart-card__info-header']}>
            <div className={styles['cart-card__info-header-content']}>
              <Link
                onClick={handleClickProduct(item)}
                to={`/p/${item.slug}` + (isBundle ? '' : `?sku=${item.sku}`)}
                state={{
                  ...(isProductBundle(item) ? {} : { offerSku: item.sku }),
                }}
              >
                <p className={styles.itemName}>{item.name}</p>
              </Link>
              <p
                className={classes([
                  styles['cart-card__info-header-content-unit'],
                  !isMultiBuy && item.discountPrice > 0 && styles.hasDiscount,
                  isMultibuyActivated && styles.hasDiscount,
                ])}
              >
                {formatPriceWithCurrency(
                  mainStore.convertPenceToPounds(
                    isMultiBuy && item.count >= item.promoRequiredQuantity
                      ? item.promoQuantityDiscountPrice
                      : price,
                  ),
                )}
                {((!isMultiBuy && item.discountPrice > 0) ||
                  isMultibuyActivated) && (
                  <span className={styles.oldPrice}>
                    {formatPriceWithCurrency(
                      mainStore.convertPenceToPounds(item.price),
                    )}
                  </span>
                )}
                <span
                  className={classes([
                    styles.volume,
                    !isMultiBuy &&
                      item.discountPrice > 0 &&
                      styles.discountedValue,
                    isMultibuyActivated && styles.discountedValue,
                  ])}
                >
                  &nbsp;
                  {volume}
                </span>
              </p>
              {isBundle && (
                <div className={styles.specialOffer}>
                  {t('specialOffer')} -{' '}
                  <span className={styles.type}>{t('bundle')}</span>
                </div>
              )}
            </div>
            {showCheckbox && (
              <Checkbox
                onChange={() => catalogStore.handleSelectedCartItem(item.sku)}
                checked={item.selected}
              />
            )}
          </div>
          {selectablePropsList}
          {isMultiBuy && (
            <div className={styles.multiBuySpecialOfferWr}>
              <span className={styles.multiBuySpecialOffer}>
                {`${t('phrases:specialOffer')}: `}
                <span className={styles.multiBuySpecialOffer__bold}>
                  {t('phrases:multiBuySpecialOffer', {
                    cost: formatPriceWithCurrency(
                      item.promoQuantityDiscountPriceFormatted,
                    ),
                    length: pluralizePcLabel(item.promoRequiredQuantity),
                  })}
                </span>
              </span>
            </div>
          )}
          <div className={styles['cart-card__info-footer']}>
            {renderPriceComponent()}
            <Counter
              count={item.count}
              onClick={handleChangeCount}
              size={size}
              limit={limit}
            />
          </div>
        </div>
      </div>
      {currentWhSellable > 0 && item.count > currentWhSellable ? (
        <RestrictionBlock
          date={format(addHours(new Date(), leadTime), 'd MMMM', {
            timeZone: company.config.timeZone,
            locale,
          })}
          currentCount={currentWhSellable}
          laterCount={laterCount}
        />
      ) : null}
    </div>
  );
};

export default observer(CartCard);
