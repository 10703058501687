import { createContext, PropsWithChildren, useContext } from 'react';

import { useGlobal } from '~/hooks/useGlobal';
import { useStoreInstance } from '~/hooks/useStoreInstance';
import { HomeCategories, ShopCategories } from '~/stores/CategoriesStore';

export interface Stores {
  shopCategories: ShopCategories;
  homeCategories: HomeCategories;
}

export const StoresContext = createContext<Nullable<Stores>>(null);

export const useStores = (): Stores => {
  const stores = useContext(StoresContext);

  if (!stores) {
    throw new Error('Use stores before initialization');
  }

  return stores;
};

export const StoresProvider = ({ children }: PropsWithChildren) => {
  const { serverWarehouse, serverLang } = useGlobal();

  const shopCategories = useStoreInstance(
    ShopCategories,
    serverWarehouse,
    serverLang,
  );

  const homeCategories = useStoreInstance(
    HomeCategories,
    shopCategories,
    serverWarehouse,
    serverLang,
  );

  return (
    <StoresContext.Provider value={{ shopCategories, homeCategories }}>
      {children}
    </StoresContext.Provider>
  );
};
