import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { company, DEFAULT_COMPANIES } from '~/company/Company';
import { CompanyName } from '~/company/interface';
import SkeletonListRows from '~/components/Skeleton/SkeletonList';
import { useGlobal } from '~/hooks/useGlobal';

import MainSidebar from '../../components/MainSidebar/MainSidebar';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { orderStore } from '../../stores/OrderStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import EmptyOrders from './EmptyOrders';
import { OrdersTab } from './interfaces';
import OrderItem from './OrderItem';
import OrderItemSkeleton from './OrderItemSkeleton';
import styles from './Orders.module.scss';

const Orders = () => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const navigate = useNavigate();
  const refLoaderTrigger = useRef<HTMLDivElement>(null);
  const { isObservableElementVisible, stopObserver, runObserver } =
    useIntersectionObserver();
  const { tab = OrdersTab.ACTIVE } = useParams<{ tab?: OrdersTab }>();
  const noTabs = DEFAULT_COMPANIES.includes(company.name) && !isMobile;

  useEffect(() => {
    orderStore.requestOrders(orderStore.currentOrdersPage).then((e) => {
      orderStore.setDownloadIsPossible(e);

      if (orderStore.downloadIsPossible && orderStore.currentOrdersPage === 1) {
        runObserver(refLoaderTrigger);
      } else if (!orderStore.downloadIsPossible) {
        stopObserver();
      }
    });
  }, [orderStore.currentOrdersPage]);

  useEffect(() => {
    if (
      orderStore.isLoading ||
      !isObservableElementVisible ||
      !orderStore.downloadIsPossible
    ) {
      /**
       * No need to do something if:
       * - orders is loading
       * - user did not scroll down to "fetch more" hidden component
       * - if downloading new items is denied, it could be in case when user achieved end of list
       * */
      return;
    }

    orderStore.setCurrentOrdersPage(orderStore.currentOrdersPage + 1);
  }, [isObservableElementVisible]);

  const renderActiveOrders = () => {
    return orderStore.activeOrders.length > 0 ? (
      <section className="order-history-items">
        <div className="order-items">
          {orderStore.activeOrders.map((order) => (
            <OrderItem order={order} key={order.id} isActive={true} />
          ))}
        </div>
      </section>
    ) : (
      <EmptyOrders orderType={t('active')} />
    );
  };

  const renderOrdersHistory = () => {
    return orderStore.finishedOrders.length > 0 ? (
      <section className="order-history-items">
        <div className="order-items">
          {orderStore.finishedOrders.map((order) => (
            <OrderItem order={order} key={order.id} />
          ))}
        </div>
      </section>
    ) : (
      <EmptyOrders orderType={t('completed')} />
    );
  };

  const getPageContent = () => {
    if (
      orderStore.isLoading &&
      ((tab === OrdersTab.ACTIVE && orderStore.activeOrders.length === 0) ||
        (tab === OrdersTab.HISTORY && orderStore.finishedOrders.length === 0))
    ) {
      return (
        <section className="order-history-items">
          <div className="order-items">
            <SkeletonListRows rows={8}>
              <OrderItemSkeleton />
            </SkeletonListRows>
          </div>
        </section>
      );
    }

    if (noTabs) {
      return (
        <>
          {renderActiveOrders()}
          <div className="history-title">{t('orderHistory')}</div>
          {renderOrdersHistory()}
        </>
      );
    }

    switch (tab) {
      case OrdersTab.ACTIVE:
        return renderActiveOrders();
      case OrdersTab.HISTORY:
        return renderOrdersHistory();
      default:
        return <></>;
    }
  };
  const onBackClick = () => {
    navigate(
      company.match({
        default: '/cabinet',
      }),
    );
  };
  return (
    <>
      <MobileHeaderLayout
        content={
          <MobileHeaderTitle text={t('orders')} onBackClick={onBackClick} />
        }
      />
      <div className="content orders" data-company={company.name}>
        <aside className="sidebar">
          <MainSidebar />
        </aside>

        <main
          className={classes(['container', styles.container])}
          data-company={company.name}
        >
          <h1 className="page-title">
            {t('orders')} <Counter value={orderStore.activeOrders.length} />
          </h1>
          {!noTabs && (
            <section className="filters">
              <div className="right-block">
                <div className="years-wrapper">
                  <Link
                    to="/cabinet/orders"
                    replace={true}
                    className={classes([
                      'year',
                      tab === OrdersTab.ACTIVE && 'current',
                    ])}
                  >
                    {t('activeOrders')}
                  </Link>
                  <Link
                    to="/cabinet/orders/history"
                    replace={true}
                    className={classes([
                      'year',
                      tab === OrdersTab.HISTORY && 'current',
                    ])}
                  >
                    {t('orderHistory')}
                  </Link>
                </div>
              </div>
            </section>
          )}
          {getPageContent()}
          <div ref={refLoaderTrigger} />
        </main>
      </div>
    </>
  );
};

const Counter = ({ value }: { value: string | number }) => {
  if (company.name === CompanyName.CityDrinks) {
    return null;
  }

  return <span className="count">{value}</span>;
};

export default observer(Orders);
