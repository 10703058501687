import { makeAutoObservable, runInAction } from 'mobx';

import { CatalogRequests, CategoryListAPI, LandingPage } from '~/api/Catalog';
import { Category } from '~/stores/CategoriesStore/Category';
import { mainStore } from '~/stores/MainStore';
import { Task } from '~/stores/shared/Task';
import { copyWith } from '~/utils/copy';

export class ShopCategories {
  public readonly request = new Task(this.loadCategories.bind(this));

  private data: Category[] = [];

  constructor(serverWarehouse?: string, serverLang?: string) {
    if (serverWarehouse) {
      this.init(serverWarehouse, serverLang);
    }

    makeAutoObservable(this);
  }

  public static preloadedData = new Map<string, CategoryListAPI[]>();

  public static async preload(warehouse: string, serverLang?: string) {
    const data = await this.request(warehouse, serverLang);

    this.preloadedData.set(this.getCategoriesId(warehouse, serverLang), data);

    return data;
  }

  public static removePreloadedData(id: string) {
    this.preloadedData.delete(id);
  }

  public static getCategoriesId(warehouse: string, serverLang?: string) {
    return `${warehouse}.${serverLang}`;
  }

  private static request(warehouse: string, serverLang?: string) {
    return CatalogRequests.getCategories(
      {
        warehouseCode: warehouse,
        landingPage: [LandingPage.SHOP],
        depth: 3,
      },
      serverLang,
    ).then(({ data }) => data);
  }

  public get list(): Readonly<Category[]> {
    return this.data;
  }

  get startShoppingCategory() {
    const category = this.data.find(({ name }) =>
      name.toLowerCase().includes('start shopping'),
    );

    if (!category) {
      return null;
    }

    return copyWith(category, {
      externalLink: '/shop',
      displayType: 'categories_grid_1',
    });
  }

  private async loadCategories(warehouse: string) {
    try {
      const data = await ShopCategories.request(warehouse);

      runInAction(() => {
        this.data = data
          .filter(Category.validate)
          .map((data) => new Category(data));
      });

      return data;
    } catch (error) {
      return mainStore.errorHandler(error as never, 'requestCategories');
    }
  }

  private init(serverWarehouse: string, serverLang?: string) {
    const isSSR = typeof window === 'undefined';
    const preloadedData =
      (!isSSR ? window.__INITIAL_STATE__?.shopCategories : null) ||
      ShopCategories.preloadedData.get(
        ShopCategories.getCategoriesId(serverWarehouse, serverLang),
      );

    if (!preloadedData) {
      return;
    }

    this.data = preloadedData
      .filter(Category.validate)
      .map((data) => new Category(data));
  }
}
