import 'swiper/css';

import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { Order, OrderShort } from '~/api/Order';
import { DEFAULT_COMPANIES, company } from '~/company/Company';
import { ActiveOrderCard } from '~/components/ActiveOrderCard';
import ActiveOrderCard1 from '~/components/ActiveOrderCard/Variant1/ActiveOrderCard';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import Icon from '~/components/Icon/Icon';
import MainSidebar from '~/components/MainSidebar/MainSidebar';
import { ModalType } from '~/components/Modal/interface';
import { ReferralProgramMenuItem } from '~/components/ReferralProgramMenuItem';
import SkeletonListRows from '~/components/Skeleton/SkeletonList';
import SwiperComponent from '~/components/Swiper/Swiper';
import SwiperIcon from '~/components/Swiper/SwiperIcon';
import { useDateLang } from '~/hooks/useDateLang';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import { useRewardedPromocodesStatus } from '~/hooks/useRewardedPromocodesStatus';
import NoOrders from '~/pages/Cabinet/NoOrders';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';
import { getTimeAgo } from '~/utils/getTimeAgo';
import { normalizeOrderId } from '~/utils/normalizeOrderId';
import { pollingBuilder } from '~/utils/pollingBuilder';

import MobileHeaderAddress from '../Main/MobileHeader/MobileHeaderAddress';
import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';

import styles from './Cabinet.module.scss';
import { swiperProps } from './config';
import {
  ACTIVE_ORDER_STATUS_CRITERIA,
  MAX_LAST_ORDER_LENGTH,
} from './constants';

const Cabinet = observer(() => {
  const { t } = useTranslation();
  const activeOrdersSwiperRef = useRef<SwiperRef>(null);
  const orderHistorySwiperRef = useRef<SwiperRef>(null);

  const { openModal } = useModal();
  const { isMobile } = useGlobal();

  const handleDeliveryAddress = () => openModal(ModalType.Logout);

  const { orderList } = orderStore;

  const lastOrders =
    orderList
      ?.filter(({ public_status }) => public_status === 'delivered')
      .slice(0, MAX_LAST_ORDER_LENGTH) ?? [];

  const activeOrders =
    orderList?.filter((item) =>
      ACTIVE_ORDER_STATUS_CRITERIA.includes(item.public_status),
    ) ?? [];
  useEffect(() => {
    orderStore.requestOrders();

    return pollingBuilder({
      promiseBuilder: () => orderStore.requestOrders(),
      conditionFn: () => {
        const onlyActiveOrders =
          orderStore.orderList?.filter((order) =>
            ACTIVE_ORDER_STATUS_CRITERIA.includes(order.public_status),
          ) || [];

        return !!onlyActiveOrders.length;
      },
    });
  }, []);

  useRewardedPromocodesStatus();

  const currentProps = swiperProps[company.name];

  const activeOrdersSwiper = () => {
    if (DEFAULT_COMPANIES.includes(company.name)) {
      return (
        <SwiperComponent {...currentProps} slidesPerView={isMobile ? 1 : 2}>
          {activeOrders.map((order) => (
            <ActiveOrderCard1 key={order.id} order={order} />
          ))}
        </SwiperComponent>
      );
    } else {
      return (
        <Swiper
          ref={activeOrdersSwiperRef}
          spaceBetween={8}
          slidesPerView="auto"
          modules={[Navigation]}
        >
          {activeOrders.map((order) => (
            <SwiperSlide className="banner-carousel__item1" key={order.id}>
              <ActiveOrderCard key={order.id} order={order} />
            </SwiperSlide>
          ))}
        </Swiper>
      );
    }
  };

  const swipeOrdersLeft = () => {
    orderHistorySwiperRef.current?.swiper.slidePrev();
  };

  const swipeOrdersRight = () => {
    orderHistorySwiperRef.current?.swiper.slideNext();
  };

  return (
    <>
      <MobileHeaderLayout
        content={<MobileHeaderAddress />}
        showPersonalData={company.showPersonalDataButtonMobileCabinetPage}
      />
      <div className="content cabinet content-layout">
        <aside className="sidebar">
          <MainSidebar />
        </aside>

        <main className="container">
          <section className="header">
            <div className="title">{t('cabinet')}</div>
          </section>

          {!orderStore.isLoading ? (
            activeOrders.length > 0 ? (
              <>
                <section className="active-orders list">
                  <div className="title">{t('activeOrders')}</div>
                  {activeOrders.map((order) => (
                    <ActiveOrderCard key={order.id} order={order} />
                  ))}
                </section>
                <section className="active-orders slider">
                  <div className="title">{t('activeOrders')}</div>
                  <div className="slider2 active-orders">
                    <div className="slider2-items">{activeOrdersSwiper()}</div>
                  </div>
                </section>
              </>
            ) : (
              <NoOrders className={styles.noOrders} />
            )
          ) : (
            <div className="skeleton-container">
              <Skeleton className="title" />
              <SkeletonListRows rows={10}>
                <Skeleton className="skeleton-container__item" />
              </SkeletonListRows>
            </div>
          )}
          {lastOrders.length > 0 && (
            <section className="last-orders">
              <div className="title">{t('lastOrders')}</div>
              <div className="slider2">
                <SwiperIcon
                  onClick={swipeOrdersLeft}
                  position="left"
                  variant="white"
                />
                <div className="slider2-items">
                  <Swiper
                    ref={orderHistorySwiperRef}
                    spaceBetween={8}
                    slidesPerView={'auto'}
                    modules={[Navigation]}
                    dir={userStore.dir}
                    key={userStore.dir}
                  >
                    {lastOrders.map((order) => (
                      <SwiperSlide
                        className="banner-carousel__item1"
                        key={order.id}
                      >
                        <Link to={`/order/${order.id}`}>
                          <OrderItem order={order} />
                        </Link>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <SwiperIcon
                  onClick={swipeOrdersRight}
                  position="right"
                  variant="white"
                />
              </div>
            </section>
          )}

          <div className="sidebar-section-container mobile">
            <section className="sidebar-section">
              <Link
                to="/cabinet/personal-data"
                className={classes(['sidebar-section-item'])}
              >
                <Icon type="filter" size={24} />
                <p className="sidebar-section-item__text">
                  {company.match({
                    citydrinks: t('cabinet'),
                    default: t('personalData'),
                  })}
                </p>
                <Icon type="chevron" size={16} className="icon__rotate-270" />
              </Link>
              <Link
                to="/cabinet/orders"
                className={classes(['sidebar-section-item'])}
              >
                <Icon type="orders" size={24} />
                <p className="sidebar-section-item__text">{t('orders')}</p>
                <Icon type="chevron" size={16} className="icon__rotate-270" />
              </Link>
              <Link
                to="/cabinet/address"
                className={classes(['sidebar-section-item'])}
              >
                <Icon
                  type={company.match({
                    circlek: 'location-fill-pin',
                    default: 'location',
                  })}
                  size={24}
                />
                <p className="sidebar-section-item__text">
                  {t('deliveryAddress')}
                </p>
                <Icon type="chevron" size={16} className="icon__rotate-270" />
              </Link>
              <Link
                to="/cabinet/payment-method"
                className={classes(['sidebar-section-item'])}
              >
                <Icon type="cards" size={24} />
                <p className="sidebar-section-item__text">
                  {t('paymentMethod')}
                </p>
                <Icon type="chevron" size={16} className="icon__rotate-270" />
              </Link>
            </section>
            <section className="sidebar-section">
              <ReferralProgramMenuItem
                className="sidebar-section-item"
                labelClassName="sidebar-section-item__text"
              >
                <Icon type="chevron" size={16} className="icon__rotate-270" />
              </ReferralProgramMenuItem>
              {!company.hideAboutUs && (
                <Link
                  to="/our-story"
                  className={classes(['sidebar-section-item'])}
                >
                  <Icon type="link" size={24} />
                  <p className="sidebar-section-item__text">{t('aboutUs')}</p>
                  <Icon type="chevron" size={16} className="icon__rotate-270" />
                </Link>
              )}
              {company.match({
                circlek: (
                  <Link
                    to={`tel:${company.config.phoneNumber}`}
                    className={classes(['sidebar-section-item'])}
                  >
                    <Icon type="message" size={24} />
                    <p className="sidebar-section-item__text">
                      {t('contactUs')}
                    </p>
                    <Icon
                      type="chevron"
                      size={16}
                      className="icon__rotate-270"
                    />
                  </Link>
                ),
                default: !company.hideAboutUs && (
                  <Link
                    to="/contact-us"
                    className={classes(['sidebar-section-item'])}
                  >
                    <Icon type="edit" size={24} />
                    <p className="sidebar-section-item__text">
                      {t('contactUs')}
                    </p>
                    <Icon
                      type="chevron"
                      size={16}
                      className="icon__rotate-270"
                    />
                  </Link>
                ),
              })}
              {/*<Link to="#" className={classes(['sidebar-section-item'])}>*/}
              {/*  <Icon type="star" size={24} />*/}
              {/*  <p className="sidebar-section-item__text">{t('rateUs')}</p>*/}
              {/*  <Icon type="chevron" size={16} className="icon__rotate-270" />*/}
              {/*</Link>*/}
              <Link
                to="/legals"
                state={{ source: 'cabinet' }}
                className={classes(['sidebar-section-item'])}
              >
                <Icon type="document" size={24} />
                <p className="sidebar-section-item__text">
                  {t(
                    company.isDefaultCompany ? 'legals' : 'termsAndConditions',
                  )}
                </p>
                <Icon type="chevron" size={16} className="icon__rotate-270" />
              </Link>
              {/* <a
                href={company.config.links.affiliateProgram}
                target="blank"
                className="sidebar-section-item"
              >
                <Icon type="people" size={24} />
                <p className="sidebar-section-item__text">{t('affiliates')}</p>
                <Icon type="chevron" size={16} className="icon__rotate-270" />
              </a> */}
            </section>
            <section className="sidebar-section">
              <button
                className="button no-padding item sidebar-section__logout"
                onClick={handleDeliveryAddress}
              >
                <Icon type="logout" size={24} />
                {t('logOut')}
              </button>
            </section>
          </div>
        </main>
      </div>
    </>
  );
});

const OrderItem = observer(({ order }: { order: Order | OrderShort }) => {
  const { locale } = useDateLang();

  const productItems = order.items.filter((i) => !i.bundle_id);
  const bundleItems = order.bundles ?? [];
  const len = productItems.length + bundleItems.length - 4;
  const timeAgo = getTimeAgo(new Date(order.sla.deliver_by_max), locale);

  return (
    <div className="item">
      <div className="number-wrapper">
        <div className="number">#{normalizeOrderId(order.order_id)}</div>
        <div className="ago">{timeAgo}</div>
      </div>
      <div className="buttle-wrapper">
        {[...bundleItems, ...productItems].map((item) => (
          <div className="buttle" key={item.id}>
            <CloudflareResponseImage
              src={item.image ?? ''}
              srcParams={{ width: 48, height: 48 }}
              srcSetParams={defaultSrcSetParams}
              alt={item.name}
              loading="lazy"
            />
          </div>
        ))}
        {len > 0 && (
          <div className="buttle">
            <div className="count">+{len}</div>
          </div>
        )}
      </div>
    </div>
  );
});

export default Cabinet;
