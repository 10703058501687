import onboardingFrame1 from './onboarding/frame1.jpg';
import onboardingFrame2 from './onboarding/frame2.jpg';
import onboardingFrame3 from './onboarding/frame3.jpg';
import onboardingFrame4 from './onboarding/frame4.jpg';
import onboardingFrame5 from './onboarding/frame5.jpg';
import onboardingFrame6 from './onboarding/frame6.jpg';
import onboardingFrame7 from './onboarding/frame7.jpg';

export const OboardingImages = [
  onboardingFrame1,
  onboardingFrame2,
  onboardingFrame3,
  onboardingFrame4,
  onboardingFrame5,
  onboardingFrame6,
  onboardingFrame7,
];
