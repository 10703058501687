import { classes } from 'html-classes';
import { MouseEvent } from 'react';

import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';

import Icon from '../Icon/Icon';

type Props = {
  count?: number;
  onClick?: (count: number, action: 'add' | 'remove', e: MouseEvent) => void;
  size: 's' | 'm' | 'l';
  iconSize?: 's' | 'm' | 'l';
  limit: number;
  containerClassName?: string;
  buttonClassName?: string;
  countClassName?: string;
  customSize?: number;
};

const SIZES = {
  s: company.name === CompanyName.CityDrinks ? 16 : 20,
  m: 24,
  l: 30,
};

const Counter = ({
  count = 0,
  onClick,
  size,
  iconSize,
  limit,
  containerClassName,
  buttonClassName,
  countClassName,
  customSize,
}: Props) => {
  return (
    <div
      data-company={company.name}
      className={classes(['counter', `_${size}`, containerClassName])}
    >
      <button
        type="button"
        className={classes(['button counter__button', buttonClassName])}
        onClick={(e) => onClick && onClick(count - 1, 'remove', e)}
      >
        <Icon type="minus" size={SIZES[iconSize || size]} />
      </button>
      <p className={classes(['counter__number', countClassName])}>{count}</p>
      <button
        type="button"
        className={classes([
          'button counter__button',
          buttonClassName,
          count >= limit && '_disabled',
        ])}
        onClick={(e) => onClick && onClick(count + 1, 'add', e)}
      >
        <Icon type="plus" size={customSize ?? SIZES[iconSize || size]} />
      </button>
    </div>
  );
};

export default Counter;
