import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface TabItem {
  alias: string;
  title: string;
}

interface TabsOptions {
  defaultTab?: TabItem;
  searchParamName?: string;
}

export const useTabs = <T extends TabItem[] | Record<string, TabItem>>(
  tabs: T,
  options: TabsOptions = {},
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamName = options.searchParamName || 'section';
  const defaultTab = options.defaultTab || Object.values(tabs)[0] || null;
  const section = searchParams.get(searchParamName);
  const tab =
    Object.values(tabs).find((tab) => tab.alias === section) || defaultTab;

  const setTab = useCallback(
    (alias: Nullable<string>) => {
      setSearchParams(
        (searchParams) => {
          alias
            ? searchParams.set(searchParamName, alias)
            : searchParams.delete(searchParamName);

          return searchParams;
        },
        { replace: true },
      );
    },
    [setSearchParams, searchParamName],
  );

  return [tab, setTab, tabs] as const;
};
