import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { IconTypes } from '~/components/Icon/IconTypes';
import { ModalType } from '~/components/Modal/interface';
import { useModal } from '~/hooks/useModal';
import { BottomNavigationType } from '~/pages/Root/BottomNavigationBar/interfaces';
import { catalogStore } from '~/stores/CatalogStore';
import { userStore } from '~/stores/UserStore';

import styles from './BottomNavigationBar.module.scss';
import { BOTTOM_NAVIGATION_ITEMS } from './constants';

interface BottomNavigationBarIconType {
  type: IconTypes;
  title: string;
  link: string;
  className?: string;
}

const BottomNavigationBarIcon = observer(
  ({ type, title, link, className }: BottomNavigationBarIconType) => {
    const { openModal } = useModal();
    const handleClick = (event) => {
      if (link === '/cabinet') {
        if (!userStore.isAuthorized) {
          openModal(ModalType.Auth, 'footer', { redirect: '/cabinet' });
          event.preventDefault();
        }
      }
      if (link === '/cart') {
        if (!userStore.deliveryAddress) {
          openModal(ModalType.EnterDeliveryAddress, 'footer', {
            redirect: '/cart',
          });
          event.preventDefault();
        }
      }
    };
    return (
      <Link
        className={classes([styles.icon, className])}
        to={link}
        onClick={handleClick}
      >
        <Icon type={type} size={24} />
        <div className={styles.title}>{title}</div>
      </Link>
    );
  },
);

const BottomNavigationBar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const icons = BOTTOM_NAVIGATION_ITEMS[company.name];

  return (
    <div className={styles.container} data-company={company.name}>
      {icons.map((navItem) => {
        if (navItem.type === BottomNavigationType.LINK) {
          const { key, link, title, iconType } = navItem;
          return link === '/cart' ? (
            <div className={styles.iconContainer} key={title}>
              <BottomNavigationBarIcon
                type={
                  pathname === link
                    ? (`${iconType}-fill` as IconTypes)
                    : iconType
                }
                className={classes(pathname === link && styles.selected)}
                title={t(title)}
                link={link}
                key={key}
              />
              {catalogStore.totalCartCount > 0 && (
                <div className={styles.cartCounter}>
                  {catalogStore.totalCartCount}
                </div>
              )}
            </div>
          ) : (
            <BottomNavigationBarIcon
              type={
                pathname === link ? (`${iconType}-fill` as IconTypes) : iconType
              }
              className={classes(pathname === link && styles.selected)}
              title={t(title)}
              link={link}
              key={key}
            />
          );
        }

        const { node, key } = navItem;
        return <Fragment key={key}>{node}</Fragment>;
      })}
    </div>
  );
};

export default observer(BottomNavigationBar);
