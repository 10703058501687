import { classes } from 'html-classes';

import { DEFAULT_COMPANIES, company } from '~/company/Company';

import Icon from '../Icon/Icon';

import styles from './ExpressDeliveryBadge.module.scss';

type ExpressDeliveryBadgeProps = {
  iconName?: 'express-fill';
  className?: string;
};
const ExpressDeliveryBadge = ({
  iconName = 'express-fill',
  className,
}: ExpressDeliveryBadgeProps) => {
  const anchorClass = DEFAULT_COMPANIES.includes(company.name)
    ? ''
    : 'product-card__express';
  return (
    <div
      data-company={company.name}
      className={classes([anchorClass, styles.express, className])}
    >
      <Icon type={iconName} size={16} />
    </div>
  );
};

export default ExpressDeliveryBadge;
