import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { DEFAULT_COMPANIES, company } from '~/company/Company';
import { CartNotification } from '~/components/Header/components/CartNotification';
import { IconTypes } from '~/components/Icon/IconTypes';
import { useModal } from '~/hooks/useModal';
import { catalogStore } from '~/stores/CatalogStore';
import { userStore } from '~/stores/UserStore';

import Icon from '../../Icon/Icon';
import { ModalType } from '../../Modal/interface';
import ModalCart from '../../ModalCart/ModalCart';

import styles from './NavButtons.module.scss';

export const NavButtons = observer(() => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [showCartModal, setShowCartModal] = useState(false);

  const handleAuth = (e: unknown) => {
    if (userStore.isAuthorized) {
      return;
    }
    (e as MouseEvent).preventDefault();
    openModal(ModalType.Auth, 'header', {
      redirect: company.match({
        citydrinks: '/cabinet',
        default: '/cabinet/personal-data',
      }),
    });
  };

  const handleOrders = (e: unknown) => {
    if (userStore.isAuthorized) {
      return;
    }
    (e as MouseEvent).preventDefault();
    openModal(ModalType.Auth, 'header', {
      redirect: company.match({
        default: '/cabinet/orders',
      }),
    });
  };

  const handleNavigateCart = () => {
    if (showCartModal) {
      setShowCartModal(false);
    }

    if (!userStore.deliveryAddress) {
      openModal(ModalType.EnterDeliveryAddress);
    } else {
      navigate('/cart');
    }
  };

  const icons: Record<string, IconTypes> = company.match({
    circlek: {
      profile: 'menu-personal',
      orders: 'orders',
      fav: 'nav-bar-fav-fill',
      cart: 'bag-fill',
    },
    default: {
      profile: 'profile',
      orders: 'orders',
      fav: 'fav',
      cart: 'bag',
    },
  });

  if (DEFAULT_COMPANIES.includes(company.name)) {
    return (
      <div className={styles.header_btn_group} data-company={company.name}>
        <Link
          to={company.match({
            citydrinks: '/cabinet',
            default: '/cabinet/personal-data',
          })}
          className={styles.header_btn}
          onClick={handleAuth}
        >
          <Icon
            type={icons.profile as IconTypes}
            size={24}
            className={styles.header_btn__icon}
          />
          <div className={styles.header_btn__text}>
            {t(userStore.isAuthorized ? 'cabinet' : 'logIn')}
          </div>
        </Link>
        <Link
          to="/cabinet/orders"
          className={styles.header_btn}
          onClick={handleOrders}
        >
          <Icon
            type={icons.orders as IconTypes}
            size={24}
            className={styles.header_btn__icon}
          />
          <div className={styles.header_btn__text}>{t('orders')}</div>
        </Link>
        <Link to="/favs" className={styles.header_btn}>
          <Icon
            type={icons.fav as IconTypes}
            size={24}
            className={styles.header_btn__icon}
          />
          <div className={styles.header_btn__text}>{t('favs')}</div>
        </Link>
        <div
          className={styles.header_btn}
          onMouseEnter={() =>
            catalogStore.totalCartCount > 0 &&
            !['/cart', '/checkout'].includes(location.pathname) &&
            setShowCartModal(true)
          }
          onMouseLeave={() => setShowCartModal(false)}
          onClick={handleNavigateCart}
        >
          <Icon
            type={icons.cart as IconTypes}
            size={24}
            className={styles.header_btn__icon}
          />
          {catalogStore.totalCartCount > 0 && (
            <div className={styles.header_btn__count}>
              {catalogStore.totalCartCount}
            </div>
          )}
          <div className={styles.header_btn__text}>{t('cart')}</div>
          {showCartModal && !!catalogStore.cart.length && (
            <ModalCart onClose={() => setShowCartModal(false)} />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.header_btn_group} data-company={company.name}>
      <Link to="/favs" className={styles.header_btn}>
        <Icon type="fav" size={24} className={styles.header_btn__icon} />
        <div className={styles.header_btn__text}>{t('favs')}</div>
      </Link>
      <Link to="/cabinet" className={styles.header_btn} onClick={handleAuth}>
        <Icon type="profile" size={24} className={styles.header_btn__icon} />
        <div className={styles.header_btn__text}>
          {t(userStore.isAuthorized ? 'cabinet' : 'logIn')}
        </div>
      </Link>
      <div
        className={styles.header_btn}
        onMouseLeave={() => setShowCartModal(false)}
        onClick={handleNavigateCart}
      >
        <div
          onMouseEnter={() =>
            catalogStore.totalCartCount > 0 &&
            !['/cart', '/checkout'].includes(location.pathname) &&
            setShowCartModal(true)
          }
        >
          <Icon type="bag" size={24} className={styles.header_btn__icon} />
          {catalogStore.totalCartCount > 0 && (
            <div className={styles.header_btn__count}>
              {catalogStore.totalCartCount}
            </div>
          )}
        </div>
        <div className={styles.header_btn__text}>{t('cart')}</div>
        {showCartModal && !!catalogStore.cart.length && (
          <ModalCart onClose={() => setShowCartModal(false)} />
        )}
        <CartNotification />
      </div>
    </div>
  );
});
