import { parse } from 'date-fns';
import { format } from 'date-fns-tz';
import { classes } from 'html-classes';
import { uniq } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { RecommendationSource } from '~/api/Catalog';
import { MechanicType } from '~/api/ETA';
import { KYCStatus } from '~/api/KYCStatus';
import { DEFAULT_COMPANIES, company } from '~/company/Company';
import CartCard from '~/components/CartCard/CartCard';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import Price from '~/components/Price';
import { Recommendations } from '~/components/Recommendations';
import Section from '~/components/Section';
import Text from '~/components/Text';
import { VERIFICATION } from '~/constants/verification';
import { useDateLang } from '~/hooks/useDateLang';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import styles from '~/pages/Cart/Cart.module.scss';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { catalogStore } from '~/stores/CatalogStore';
import { StartVerificationLocation, mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';
import { isProductBundle } from '~/types/Product';

import CLOSED_CLOCK from '../../assets/img/closed_clock.png';
import Checkbox from '../../components/CheckboxCustom/Checkbox';

import { CartCostItems } from './components/CartCostItems';
import SubtotalInfo from './components/Subtotal/SubtotalInfo';
import { useStickyWrapper } from './hooks';

const CartContent = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { isMobile } = useGlobal();
  const { locale } = useDateLang();

  const subtotalWrapperRef = useRef<HTMLDivElement | null>(null);
  const cartContentRef = useRef<HTMLDivElement | null>(null);
  const unauthorizedContentRef = useRef<HTMLDivElement | null>(null);

  const { style: stickyWrapperStyles } = useStickyWrapper({
    elementRef: subtotalWrapperRef,
    containerRef: cartContentRef,
    containerPaddingBottom: 120,
    containerPaddingTop: 30,
    headerGap: DEFAULT_COMPANIES.includes(company.name) ? 0 : 30,
  });

  useEffect(() => {
    analyticsEventsEmitter.emit(EventsName.VIEW_CART);
    catalogStore.debouncedCalculateCart();

    if (catalogStore.cart.length) {
      const skus = catalogStore.cart
        .map((cartItem) => {
          const isBundle = isProductBundle(cartItem);

          if (!isBundle) {
            return cartItem.sku;
          }

          return cartItem.offers.map((offer) => offer.sku);
        })
        .flat();

      catalogStore.getProductRecommendationDebounced(uniq(skus), [
        RecommendationSource.CART,
      ]);
    }

    return () => {
      catalogStore.clearProductRecommendations(RecommendationSource.CART);
    };
  }, []);

  const handleEmptyCart = () => {
    mainStore.sendAnalytics(['BI', 'analytics', 'firebase'], {
      name: 'Purchase: cart deleted',
      params: {
        cart_id: undefined,
        products_amount: catalogStore.cart.reduce(
          (sum, item) => sum + item.count,
          0,
        ),
        items_amount: catalogStore.cart.length,
        price: catalogStore.totalCartPrice.base,
        final_price: catalogStore.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min ?? 0,
        eta_max: orderStore.etaCalculation?.duration.max ?? 0,
        delivery_fee: orderStore.fee.shippingPounds ?? 0,
        threshold: orderStore.fee.thresholdPounds ?? 0,
        is_surger: orderStore.etaCalculation?.highDemand ?? false,
      },
    });
    catalogStore.emptySelectedItemsFromCart();
  };

  const handleCheckout = () => {
    if (!userStore.isAuthorized) {
      openModal(ModalType.Auth);
      return;
    }

    if (
      userStore.isAuthorized &&
      userStore.personalData.kycStatus !== KYCStatus.Verified
    ) {
      mainStore.setStartVerificationLocation(StartVerificationLocation.CART);
      userStore
        .requestKYCSession()
        .then(({ data }) => {
          const shouldOpenInAppBrowser =
            mainStore.isRN &&
            Number(mainStore.appVersion.replaceAll('.', '')) >= 1029;

          if (shouldOpenInAppBrowser) {
            mainStore.sendToRN('openInAppBrowser', { url: data.url });
          } else {
            window.location.href = data.url;
          }
        })
        .catch(() => void 0);
      return;
    }
  };

  const handleNavigateToCheckout = async () => {
    const cartCountChanged = await catalogStore.calculateCart();

    if (cartCountChanged) {
      return;
    }

    navigate('/checkout');
  };

  const isLoading =
    orderStore.isLoading ||
    orderStore.isETALoading ||
    catalogStore.calculationProcess.isLoading;

  const expressMechanic =
    orderStore.etaCalculation?.warehouse?.availability?.deliveryMechanics?.[
      MechanicType.ON_DEMAND
    ];
  const isWarehouseClosed =
    orderStore.etaCalculation?.warehouse.availability.availableNow === false;
  const warehouseOpeningTime = orderStore.etaCalculation?.warehouse.availability
    .opening
    ? format(
        parse(
          orderStore.etaCalculation?.warehouse.availability.opening
            .split(':', 2)
            .join(':'),
          'HH:mm',
          new Date(),
        ),
        'hh:mm aaaa',
        {
          timeZone: company.config.timeZone,
          locale,
        },
      )
    : '';
  const expressOpening = expressMechanic?.opening;
  const expressOpeningTime = expressOpening
    ? format(
        parse(expressOpening.split(':', 2).join(':'), 'HH:mm', new Date()),
        'hh:mm aaaa',
        {
          timeZone: company.config.timeZone,
          locale,
        },
      )
    : '';

  const showExpressUnavailableBlock =
    !!expressMechanic && !expressMechanic.availableNow;

  const renderMobileGoToCheckoutButton = () => {
    const goToCheckout = (
      <button
        type="button"
        onClick={handleNavigateToCheckout}
        className={classes([
          'button _primary _med',
          (catalogStore.selectedCartItems.length === 0 ||
            isLoading ||
            isWarehouseClosed) &&
            '_disabled',
        ])}
      >
        {t('goToCheckout')}
        {isLoading ? (
          <span className="spinner _white" />
        ) : (
          catalogStore.selectedCartItems.length > 0 && (
            <Price price={catalogStore.totalCartPrice.paidWithDiscount} />
          )
        )}
      </button>
    );

    // TODO: hide temporaryly in cart - JS-7631
    // if (
    //   (!company.needVerification ||
    //     (userStore.isAuthorized &&
    //       userStore.personalData.kycStatus === KYCStatus.Verified)) &&
    //   catalogStore.totalCartPrice.minimal_order_value_error?.length &&
    //   catalogStore.totalCartPrice.minimal_order_value_error[0]
    // ) {
    //   return (
    //     <>
    //       <div
    //         onClick={handleCheckout}
    //         className={classes([
    //           'button _primary _med _disabled _center',
    //           styles.subtotalInfo__button,
    //         ])}
    //       >
    //         {t('errors:minCartError', {
    //           amount: formatPriceWithCurrency(
    //             catalogStore.totalCartPrice.minimal_order_value_error[0]
    //               ?.minimalOrderValue / 100,
    //           ),
    //         })}
    //         <br />
    //         {t('errors:minCartErrorDetails', {
    //           amount: formatPriceWithCurrency(
    //             (catalogStore.totalCartPrice.minimal_order_value_error[0]
    //               ?.minimalOrderValue -
    //               catalogStore.totalCartPrice.minimal_order_value_error[0]
    //                 ?.orderPriceWithoutDelivery) /
    //               100,
    //           ),
    //         })}
    //       </div>
    //     </>
    //   );
    // }
    if (!company.needVerification && !userStore.isAuthorized) {
      return (
        <>
          <div
            onClick={handleCheckout}
            className="button _primary subtotal-info__button _med _center"
          >
            {t('logInToCheckout')}
          </div>
          {company.showCartAdditionalTextMobile && (
            <Text size="12" weight="500" className="subtotal-info__additional">
              {t('phrases:verifyIdDescription')}
            </Text>
          )}
        </>
      );
    }

    if (!company.needVerification && userStore.isAuthorized) {
      return goToCheckout;
    }
    switch (userStore.personalData.kycStatus) {
      case KYCStatus.Verified:
        return goToCheckout;
      case KYCStatus.InProgress:
      case KYCStatus.ResubmissionRequested:
      case KYCStatus.Started: {
        const statusConfig = VERIFICATION[userStore.personalData.kycStatus];
        const isDisabled =
          (userStore.personalData.kycStatus === KYCStatus.InProgress ||
            userStore.personalData.kycStatus === KYCStatus.Started) &&
          '_disabled';

        return (
          <>
            <div
              onClick={handleCheckout}
              className={`button _primary subtotal-info__button _med _center ${isDisabled}`}
            >
              {statusConfig.title}
            </div>
            <Text size="12" weight="500" className="subtotal-info__additional">
              {statusConfig.description || ''}
            </Text>
          </>
        );
      }
      default:
        return (
          <>
            <div
              onClick={handleCheckout}
              className="button _primary subtotal-info__button _med _center"
            >
              {userStore.isAuthorized ? t('verifyId') : t('logInToCheckout')}
            </div>
            <Text size="12" weight="500" className="subtotal-info__additional">
              {t('phrases:verifyIdDescription')}
            </Text>
          </>
        );
    }
  };

  const matchingProducts =
    catalogStore.productRecommendations[RecommendationSource.CART];
  const hasRecommendations = Boolean(matchingProducts?.data.length);

  const recommendationsBlock = catalogStore.productRecommendations[
    RecommendationSource.CART
  ]?.data.length ? (
    <div
      style={
        {
          '--value': `${unauthorizedContentRef?.current?.offsetHeight || 0}px`,
        } as CSSProperties
      }
      className={
        hasRecommendations || catalogStore.productRecommendationsMeta.isLoading
          ? styles.recommendationsWr
          : undefined
      }
    >
      <Recommendations
        className="carousel-scroll1"
        source={RecommendationSource.CART}
      />
    </div>
  ) : null;

  return (
    <>
      <div
        className={classes([
          'cart-content__container',
          userStore?.personalData?.kycStatus !== KYCStatus.Verified &&
            'cart-content__not-verified',
          hasRecommendations && styles.hasRecommendations,
        ])}
        ref={cartContentRef}
      >
        <div data-company={company.name} className="content-layout__contents">
          <Link
            className="button _no-color _no-padding continue-shopping"
            to="/"
          >
            <Icon type="chevron" size={24} className="icon__rotate-90" />
            <p className="continue-shopping__text">{t('continueShopping')}</p>
          </Link>
          {(showExpressUnavailableBlock || isWarehouseClosed) && (
            <div className={styles.expressUnavailable}>
              <img
                className={styles.expressUnavailableImage}
                src={CLOSED_CLOCK}
              />
              <div className={styles.textContainer}>
                {isWarehouseClosed ? (
                  <p className={styles.expressUnavailableHeader}>
                    <Trans
                      i18nKey="warehouseUnavailableTime"
                      values={{ time: warehouseOpeningTime }}
                      t={t}
                    />
                  </p>
                ) : (
                  <>
                    <p className={styles.expressUnavailableHeader}>
                      {t('expressUnavailableTime', {
                        time: expressOpeningTime,
                      })}
                    </p>
                    <p className={styles.expressUnavailableDescription}>
                      {t('expressUnavailableTimeExplanation')}
                    </p>
                  </>
                )}
              </div>
            </div>
          )}

          {isMobile && catalogStore.cart.length > 0 && recommendationsBlock}

          <div className="cart-header">
            <p className="cart-header__title">
              {t('myCart')}
              <p className="cart-header__title__count">
                {t('phrases:itemsCount', {
                  count: catalogStore.selectedItemsTotalCount,
                })}
              </p>
            </p>
            <div className="cart-header__buttons">
              <button
                className="cart-header__btn button _no-color"
                onClick={handleEmptyCart}
              >
                <Icon size={24} type="close" />
                <Text size="15" weight="500">
                  {t('deleteSelected')}{' '}
                  {company.showCartCoutToRemove &&
                    `(${catalogStore.selectedItemsTotalCount})`}
                </Text>
              </button>

              <Checkbox
                onChange={catalogStore.handleSelectAllCartItems}
                checked={catalogStore.isSelectedAll}
              >
                <div className="cart-header__selectAll">{t('selectAll')}</div>
              </Checkbox>
            </div>
          </div>
          <Section className="cart-content">
            <ul className="cart-content__list">
              {catalogStore.cart.map((offer) => (
                <li className="cart-content__list-item" key={offer.sku}>
                  <CartCard item={offer} showCheckbox size="m" />
                </li>
              ))}
            </ul>

            {company.showCartMobileSubtotal ? (
              <div className={styles.mobileSubtotalInfoWr}>
                <CartCostItems />
              </div>
            ) : (
              <div className="cart-content__subtotal">
                <p className="cart-content__subtotal__title">{t('subtotal')}</p>
                <Price
                  price={catalogStore.totalCartPrice.paidWithoutDiscount}
                  classNameCurrentPrice="cart-content__subtotal__price"
                />
              </div>
            )}
          </Section>
        </div>

        <SubtotalInfo
          onNavigateToCheckout={handleNavigateToCheckout}
          onCheckout={handleCheckout}
          isLoading={isLoading}
          isWarehouseClosed={isWarehouseClosed}
          subtotalWrapperRef={subtotalWrapperRef}
          stickyWrapperStyles={stickyWrapperStyles}
        />

        <div
          ref={unauthorizedContentRef}
          className="mobile-bottom-button-container"
        >
          {renderMobileGoToCheckoutButton()}
        </div>
      </div>

      {!isMobile && recommendationsBlock}
    </>
  );
};

export default observer(CartContent);
