import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SortingField, SortingFieldName, SortingOrder } from '~/api/Catalog';
import { company } from '~/company/Company';

import Select from '../Select/Select';

import { SORTING_OPTIONS, CIRCLEK_SORTING_OPTIONS } from './constants';

import './styles.scss';

interface Props {
  value?: SortingField;
  onChange?: (value: [SortingFieldName, SortingOrder]) => void;
}

const ProductSorting = ({ value = 'salesQuantity.DESC', onChange }: Props) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      (company.isReviewPanelHidden ? CIRCLEK_SORTING_OPTIONS : SORTING_OPTIONS)
        .filter((v) => v !== value)
        .map((v) => ({
          label: t(`sorting:${v}`),
          value: v,
        })),
    [value],
  );

  const onChangeSelectedValue = (value: string) => {
    const [field, order] = value.split('.') as [SortingFieldName, SortingOrder];

    onChange?.([field, order]);
  };

  return (
    <Select
      initialValue={t(`sorting:${value}`)}
      onChange={onChangeSelectedValue}
      className="select select-sorting product-sorting"
      data={options.map(({ value, label }) => ({
        value,
        text: label,
      }))}
    />
  );
};

export default observer(ProductSorting);
