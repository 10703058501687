import { CircleKConfig } from './configs/CircleK';
import { CityDrinksConfig } from './configs/CityDrinks';
import { JiffyConfig } from './configs/Jiffy';
import { KidsConfig } from './configs/Kids';
import { LocaleeConfig } from './configs/Localee';
import { ViloConfig } from './configs/Vilo';
import { CompanyConfig, CompanyName } from './interface';

export const DEFAULT_COMPANIES = [
  CompanyName.Localee,
  CompanyName.CircleK,
  CompanyName.Vilo,
  CompanyName.Jiffy,
  CompanyName.Kids,
];

class Company {
  private readonly currentCompany: CompanyName;
  private readonly defaultCompany: CompanyName = CompanyName.CityDrinks;
  private readonly configs: Record<CompanyName, CompanyConfig> = {
    [CompanyName.CityDrinks]: CityDrinksConfig,
    [CompanyName.Localee]: LocaleeConfig,
    [CompanyName.CircleK]: CircleKConfig,
    [CompanyName.Vilo]: ViloConfig,
    [CompanyName.Jiffy]: JiffyConfig,
    [CompanyName.Kids]: KidsConfig,
  };

  constructor() {
    let companyToSet: CompanyName | null;

    if (import.meta.env.REACT_APP_ENV === 'production') {
      companyToSet = import.meta.env.REACT_APP_COMPANY;
      this.currentCompany = import.meta.env.REACT_APP_COMPANY as CompanyName;
    } else {
      companyToSet =
        typeof window !== 'undefined'
          ? (sessionStorage?.getItem('companyName') as CompanyName)
          : null;

      if (!companyToSet || !this.configs[companyToSet]) {
        companyToSet = import.meta.env.REACT_APP_COMPANY as CompanyName;
      }
      if (!companyToSet || !this.configs[companyToSet]) {
        companyToSet = this.defaultCompany;
      }
      this.currentCompany = companyToSet;
    }

    if (import.meta.env.SSR) {
      return;
    }

    const rootDiv = document && document?.getElementById('app');
    rootDiv?.setAttribute('data-company', `${companyToSet}`);
  }

  get config(): CompanyConfig {
    return this.configs[this.currentCompany];
  }

  get name(): CompanyName {
    return this.currentCompany;
  }

  get currency() {
    return this.config.currency;
  }

  get isHidePaymentMethodsPage(): boolean {
    return [''].includes(this.currentCompany);
  }

  get isDisableFreshChat(): boolean {
    return [''].includes(this.currentCompany);
  }

  get isHideFavourites(): boolean {
    return [''].includes(this.currentCompany);
  }

  get isDisableLoyaltyProgram(): boolean {
    return [''].includes(this.currentCompany);
  }

  get isHideMapLegend(): boolean {
    return ![''].includes(this.currentCompany);
  }

  get isShowTaxes(): boolean {
    return [''].includes(this.currentCompany);
  }

  get isExceptionAlcohol(): boolean {
    return ![''].includes(this.currentCompany);
  }

  get isHideTracking(): boolean {
    return [''].includes(this.currentCompany);
  }

  get hasAddress2(): boolean {
    return [''].includes(this.currentCompany);
  }

  get isTrimTrailingZeros(): boolean {
    return ![''].includes(this.currentCompany);
  }

  get isShowRateExperience(): boolean {
    return ![''].includes(this.currentCompany);
  }

  get hasWelcomeBlock(): boolean {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get isAvailableCartInMobileHeader(): boolean {
    return ![...DEFAULT_COMPANIES].includes(this.currentCompany);
  }

  get showETAInfoMobileHeader(): boolean {
    return [...DEFAULT_COMPANIES].includes(this.currentCompany);
  }

  get showPersonalDataButtonMobileCabinetPage() {
    return [...DEFAULT_COMPANIES].includes(this.currentCompany);
  }

  get showLocationIconMobileCabinetPage() {
    return DEFAULT_COMPANIES.includes(this.currentCompany);
  }

  get showSectionsMobileProductPage() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get showSearchHeaderProductPage(): boolean {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get showShowShareButtonsProductPage(): boolean {
    return [...DEFAULT_COMPANIES].includes(this.currentCompany);
  }

  get showActionButtonsMobileProductPage() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get showShortReviewListMobileProductPage() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get showDescriptionReviewProductPage() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get customMobileRatingProductPage() {
    return [...DEFAULT_COMPANIES].includes(this.currentCompany);
  }

  get showExpressBadgeInSliderProductPage() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get isVerticalSwiperPageDesktop() {
    return [...DEFAULT_COMPANIES].includes(this.currentCompany);
  }

  get hideAboutUs() {
    return [...DEFAULT_COMPANIES].includes(this.currentCompany);
  }

  get nonCloseableNavMobileShopPage() {
    return DEFAULT_COMPANIES.includes(this.currentCompany);
  }

  get showCardDisclaimer() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get promocodeInInputResetCheckout() {
    return [CompanyName.Localee, CompanyName.CityDrinks].includes(
      this.currentCompany,
    );
  }

  get buttonOutPanelCheckout() {
    return DEFAULT_COMPANIES.includes(this.currentCompany);
  }

  get postfixPrice() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get needVerification() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }
  get showButtonInTitleAddress() {
    return [...DEFAULT_COMPANIES].includes(this.currentCompany);
  }

  get showCartMobileSubtotal() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get showMobileDowloadPanel() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get showExpressDeliveryBannerMainPage() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get useAwsPaymentMethod() {
    return this.isIncludedIn([CompanyName.CircleK]);
  }

  get isSommelierEnabled() {
    return this.isIncludedIn([CompanyName.CityDrinks]);
  }

  get isDeliveryInfoModalEnabled() {
    return this.isIncludedIn([CompanyName.CityDrinks]);
  }

  get showCompanyLogoOnMainMobile() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get showAdditionalTextEmptyPayments() {
    return [CompanyName.Localee].includes(this.currentCompany);
  }

  get deliveryMethodSelectionAvailable() {
    return this.isIncludedIn([
      CompanyName.Localee,
      CompanyName.Vilo,
      CompanyName.CircleK,
    ]);
  }

  get isShareBlockCaptionVisible() {
    return this.isIncludedIn([CompanyName.CityDrinks]);
  }

  get isShareBlockButtonIconVisible() {
    return this.isIncludedIn([CompanyName.Localee]);
  }

  get needKycCheck() {
    return this.match({
      citydrinks: true,
      localee: false,
      vilo: false,
      jiffy: false,
      ourkids: false,
      default: false,
    });
  }

  get hasGradientTile() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get hasOnboardingAutoplay() {
    return ![CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get hasOnboardingCloseButton() {
    return ![CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get hasOnboardingDotsInHeader() {
    return ![CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get hasNewOnboardingDesign() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get hasOnboardingDotsAboveButtons() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get showStartShopping() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get showCartMobileFavsPage() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get showCartModalCount() {
    return this.isIncludedIn([CompanyName.Localee, CompanyName.CircleK]);
  }

  get showCartAdditionalTextMobile() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get missAgeRestrictionModal() {
    return this.isIncludedIn([
      CompanyName.CircleK,
      CompanyName.Vilo,
      CompanyName.Kids,
    ]);
  }

  get hideReviews() {
    return this.currentCompany === CompanyName.CircleK;
  }

  get hideFreeDeliveryProgressBar() {
    return this.currentCompany === CompanyName.CityDrinks;
  }

  get showCartCoutToRemove() {
    return this.currentCompany === CompanyName.CityDrinks;
  }

  get combineItemsByDefaut() {
    return [CompanyName.CityDrinks].includes(this.currentCompany);
  }

  get isDefaultCompany() {
    return [...DEFAULT_COMPANIES].includes(this.currentCompany);
  }

  get showPartiallyPickedInfo() {
    return ![CompanyName.Vilo].includes(this.currentCompany);
  }

  get hideFreezeMoneyMessage() {
    return this.isIncludedIn([CompanyName.Vilo]);
  }

  get hideDownloadAppPanel() {
    return this.isIncludedIn([CompanyName.CircleK]);
  }

  get isEnabledRecaptcha() {
    return this.isIncludedIn([CompanyName.CityDrinks]);
  }

  get isReviewPanelHidden() {
    return this.currentCompany === CompanyName.CircleK;
  }

  get showLinkedinIcon() {
    return this.isIncludedIn([CompanyName.CircleK]);
  }

  isCompanyNameExist(name: string): boolean {
    return this.configs[name as CompanyName] !== undefined;
  }

  isIncludedIn(nameList: string[]): boolean {
    return nameList.includes(this.currentCompany);
  }

  // Return type: T if passed all companies or default, else T | undefined
  match<T>(record: { default?: T } & Record<CompanyName, T>): T;
  match<T>(record: { default: T } & Partial<Record<CompanyName, T>>): T;
  match<T>(record: Partial<Record<CompanyName | 'default', T>>): T | undefined;
  match<T>(
    record: { default?: T } & Partial<Record<CompanyName, T>>,
  ): T | undefined {
    return this.currentCompany in record
      ? record[this.currentCompany]
      : record.default;
  }

  // Return type: T if passed all design variants or default, else T | undefined
  variant<T>(record: { default?: T } & Record<'1' | '2', T>): T;
  variant<T>(record: { default: T } & Partial<Record<'1' | '2', T>>): T;
  variant<T>(record: Partial<Record<'1' | '2' | 'default', T>>): T | undefined;
  variant<T>(
    record: { default?: T } & Partial<Record<'1' | '2', T>>,
  ): T | undefined {
    return this.currentCompany === CompanyName.CityDrinks
      ? record['1']
      : record['2'];
  }
}

export const company = new Company();
