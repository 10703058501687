import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { IMAGES } from '~/constants/images';

const EmptyPurchased = () => {
  const { t } = useTranslation();

  return (
    <div className="page-empty">
      <img
        src={
          company.match({
            citydrinks: IMAGES.order?.empty,
            default: IMAGES.favs?.empty,
          }) ?? ''
        }
        className="page-empty__image"
        alt=""
      />
      <p className="page-empty__title">{t('phrases:emptyPurchased')}</p>
    </div>
  );
};

export default EmptyPurchased;
