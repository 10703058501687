import { CSSProperties } from 'react';

import {
  SOMMELIER_CONFIG,
  SOMMELIER_BOT_ELEMENT_IDENTIFIER,
} from './constants';
import {
  ProductClickEventDetail,
  SommelierScriptAttributes,
} from './interfaces';

export const renderScriptElement = (
  ref: HTMLElement,
  { popup }: SommelierScriptAttributes = {},
) =>
  new Promise<Event>((resolve, reject) => {
    if (typeof document === 'undefined') {
      return reject('Not available on SSR');
    }

    if (!ref) {
      return reject('Reference not found');
    }

    setTimeout(() => {
      const { lang, client, url } = SOMMELIER_CONFIG;

      const script = document.createElement('script');
      script.setAttribute('lang', lang);
      script.setAttribute('client', client);
      script.setAttribute('event-js', 'true');
      if (popup) {
        script.setAttribute('popup', popup);
      }
      script.setAttribute('src', url);
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);
      ref.appendChild(script);
    }, 3000);
  });

export const isScriptExists = (ref: HTMLElement): boolean => {
  const scripts = ref.querySelectorAll('script');

  for (const script of scripts) {
    const src = script.getAttribute('src');

    if (src === SOMMELIER_CONFIG.url) {
      return true;
    }
  }

  return false;
};

export const adjustSommelierBotElement = () => {
  if (typeof document === 'undefined') {
    return;
  }

  const element = document.getElementById(SOMMELIER_BOT_ELEMENT_IDENTIFIER);

  if (!element) {
    return;
  }

  const styles: CSSProperties = {
    height: '100%',
    maxHeight: '100%',
  };

  for (const [key, value] of Object.entries(styles)) {
    if (key in element.style) {
      const targetKey = key as keyof Omit<
        CSSStyleDeclaration,
        'length' | 'parentRule'
      >;
      element.style[targetKey] = value;
    }
  }
};

export const getProductSlugFromSommelierEvent = (
  detail: ProductClickEventDetail,
) => {
  const { url } = detail;

  const regex = new RegExp(/^http[s?]:\/\/citydrinks.com\/p\/(?<slug>.*?)$/gi);
  const groups = regex.exec(url);

  if (!groups) {
    return;
  }

  const { slug } = groups.groups as { slug: string };

  return slug;
};
