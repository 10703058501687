import { classes } from 'html-classes';

import { Variant } from '~/api/Catalog';

import styles from './PropVariants.module.scss';

type Props = {
  colorVariants: Variant[];
  classNames?: string;
};

const MAX_COLORS_PROP_VALUES = 4;

export const PropVariants = ({ colorVariants, classNames }: Props) => {
  return (
    <div className={classes([styles.root_colors, classNames])}>
      <div className={styles.colorsList}>
        {colorVariants.slice(0, 4).map((item, id) => (
          <div
            className={classes([
              styles.colorItem,
              !(item.sellable || item.isBackorderAvailable) && styles.empty,
            ])}
            style={{ backgroundColor: item.value }}
            key={id}
          />
        ))}
      </div>
      {colorVariants.length > MAX_COLORS_PROP_VALUES && (
        <div className={styles.number}>
          +{colorVariants.length - MAX_COLORS_PROP_VALUES}
        </div>
      )}
    </div>
  );
};
