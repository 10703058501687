import { omit } from 'lodash-es';
import isNil from 'lodash-es/isNil';
import { makeAutoObservable, runInAction } from 'mobx';

import { CatalogRequests, CategoryListAPI } from '~/api/Catalog';
import { catalogStore, Filter } from '~/stores/CatalogStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { Task } from '~/stores/shared/Task';

import { Product } from './Product';

export class Category {
  public readonly numberId: number;
  public readonly id: string | number;
  public readonly name: string;
  public readonly previewText: string;
  public readonly parentId: Nullable<number>;
  public readonly landingPage: Nullable<string>;
  public readonly displayType: Nullable<string>;
  public readonly backgroundColor: Nullable<string>;
  public readonly previewImage: string;
  public readonly previewImageThumb: string;
  public readonly metadataTitle: string;
  public readonly metadataDescription: string;
  public readonly description: string;
  public readonly subcategory: Category[];
  public readonly externalLink?: string;
  public readonly filters: Filter[];
  public readonly productsRequest = new Task(this.loadProducts.bind(this));
  public readonly ownProducts: Product[];
  public readonly serverLang: Nullable<string>;

  constructor(
    { category, children, filters, prices }: CategoryListAPI,
    serverLang?: string,
  ) {
    this.numberId = category.id;
    // TODO replace all category id with slug
    this.id = category.slug || category.id;
    this.name = category.name;
    this.previewText = category.previewText || '';
    this.parentId = category.parentId;
    this.landingPage = category.landingPage;
    this.displayType = category.displayType;
    this.backgroundColor = category.backgroundColor;
    this.previewImage =
      category.previewImage?.url || (category.images || [])[0]?.url || '';
    this.previewImageThumb =
      category.previewImageThumb?.url ||
      (category.images || [])[1]?.url ||
      (category.images || [])[0]?.url ||
      '';
    this.metadataTitle = category.metadataTitle || '';
    this.metadataDescription = category.metadataDescription || '';
    this.filters = filters || [];
    this.description = category.description || '';
    this.serverLang = serverLang || '';

    this.subcategory = (children || [])
      .filter(Category.validate)
      .map((data) => new Category(data));

    this.ownProducts = (category.products || [])
      .filter(Product.validate)
      .map((data) => new Product(data));

    if (prices) {
      catalogStore.setPrices(prices);
    }

    makeAutoObservable(this);
  }

  public static validate({ category, children }: CategoryListAPI): boolean {
    const { id, parentId } = category;
    const filteredChildren = (children || []).filter(Category.validate);
    const isInvalid = isNil(parentId) && !filteredChildren.length;

    if (isInvalid) {
      const message = `VALIDATE CATEGORY LIST - Missing active subcategories in the first level category (the category ignored)\n\tcategoryId: ${id}`;

      try {
        mainStore?.consoleLog(message, 'error');
      } catch (err) {
        /* eslint-disable no-console */
        console.log(message, err);
        /* eslint-enable no-console */
      }
    }

    return !isInvalid;
  }

  public get products() {
    return catalogStore.prepareProducts(this);
  }

  public get breadcrumbs() {
    const breadcrumbs = [{ text: this.name || '', link: `/c/${this.id}` }];
    const type = catalogStore.currentFilters.properties?.['type'] as string;
    const subcategories =
      type && this.subcategory.filter(({ id }) => type.includes(String(id)));

    if (subcategories && subcategories.length < this.subcategory.length) {
      const text = subcategories.map((i: Category) => i.name).join(' & ');
      breadcrumbs.push({
        text,
        link: '',
      });
    }

    return breadcrumbs;
  }

  private async loadProducts(): Promise<void> {
    const { data } = await CatalogRequests.getProducts(String(this.id), {
      warehouseCode: orderStore.etaWarehouseCode,
      isSafeRequest: mainStore.isSafeRequest || undefined,
    });

    runInAction(() => {
      catalogStore.setPrices(data.prices);
      Object.assign(
        this,
        omit(new Category(data), 'loadProducts', 'productsRequest'),
      );
    });
  }
}
