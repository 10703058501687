import { JSX, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import { useGlobal } from '~/hooks/useGlobal';

import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../../stores/CatalogStore';

import MobileHeaderLayout from '../../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../../Main/MobileHeader/MobileHeaderTitle';

import styles from '../Legals.module.scss';

import mdStyles from './LegalsCirclek.module.scss';

import '../styles.scss';

type Props = {
  handleLink: (anchor: string) => void;
};

const LEGALS_NAV = [
  {
    link: 'terms-and-conditions',
    title: 'Terms & Conditions',
  },
];

const text = `
<h2 id="terms-and-conditions">Terms & Conditions</h2>

Effective Date: 5.09.2024

Welcome to Circle K! By using our mobile application (the "App"), you agree to these Terms and Conditions (the "Terms"). Please read them carefully.

1. ### Acceptance of Terms

   By accessing or using the App, you agree to be bound by these Terms, including any additional terms and conditions that may apply. If you do not agree with these Terms, please do not use the App.

2. ### Changes to Terms

   Circle K reserves the right to modify these Terms at any time. Changes will be effective immediately upon posting. Your continued use of the App after changes are posted constitutes your acceptance of the revised Terms.

3. ### Account Registration

   To access certain features of the App, you may be required to register for an account. You agree to provide accurate and complete information and to keep your account information up to date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.

4. ### Use of the App
   
   You agree to use the App only for lawful purposes and in accordance with these Terms. You agree not to:
     - Use the App in any way that could damage, disable, or impair the App.
     - Attempt to gain unauthorized access to any part of the App or any related systems or networks.
     - Use the App to transmit any content that is unlawful, harmful, defamatory, or otherwise objectionable.

5. ### Privacy Policy

   Your use of the App is also governed by our Privacy Policy. By using the App, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.

6. ### Intellectual Property

   The content and materials available on the App, including but not limited to text, graphics, logos, and software, are the property of Circle K or its licensors and are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any content obtained from the App without our prior written permission.

7. ### Third-Party Links

   The App may contain links to third-party websites or services. Circle K is not responsible for the content or practices of any third-party sites or services. You access such third-party sites at your own risk.

8. ### Disclaimers

   The App is provided "as is" and "as available" without warranties of any kind, either express or implied. Circle K does not warrant that the App will be error-free, uninterrupted, or free of viruses or other harmful components.

9. ### Limitation of Liability

   To the fullest extent permitted by Egyptian law, Circle K shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the App. Our total liability for any claim arising out of these Terms or your use of the App shall not exceed the amount you paid, if any, for accessing the App.

10. ### Indemnification

    You agree to indemnify and hold Circle K, its affiliates, officers, directors, employees, and agents harmless from any claims, liabilities, damages, losses, or expenses, including legal fees, arising out of or related to your use of the App or any violation of these Terms.

11. ### Termination

    Circle K reserves the right to terminate or suspend your account and access to the App at our sole discretion, without notice, for any reason, including if we believe you have violated these Terms.

12. ### Governing Law

    These Terms are governed by and construed in accordance with the laws of the Arab Republic of Egypt. Any disputes arising from these Terms or your use of the App shall be subject to the exclusive jurisdiction of the courts located in Cairo, Egypt.

13. ### Contact Us

    If you have any questions about these Terms, please contact us at:  
    Circle K Customer Service  
    Cairo, Egypt`;

const LegalsCirclek = ({ handleLink }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();

  useEffect(() => {
    setTimeout(() => {
      const anchor = window.location.hash.slice(1);
      handleLink(anchor);
    }, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      <MobileHeaderLayout
        showTopCart
        content={<MobileHeaderTitle text={t('legals')} />}
      />
      <div className="content-layout legals">
        <div className={styles.root}>
          {!isTablet && (
            <>
              <Breadcrumbs
                items={
                  [
                    {
                      text: t('home'),
                      link: '/',
                    },
                    {
                      text: t('legals'),
                      link: '/legals',
                    },
                  ].filter(Boolean) as BreadcrumbsItem[]
                }
              />

              <h2 className={styles.title}>{t('legals')}</h2>
            </>
          )}

          <div className={styles.page}>
            <div className={styles.page__links_container}>
              <ul className={styles.page__links}>
                {LEGALS_NAV.map((i) => (
                  <li className={styles.page__links_item} key={i.link}>
                    <a
                      href={`#${i.link}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleLink(i.link);
                      }}
                    >
                      {i.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.page__content}>
              <ReactMarkdown
                className={mdStyles.mdStyles}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              >
                {text}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalsCirclek;
