import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ModalType } from '~/components/Modal/interface';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';

let reopenModal: () => ModalType | null = () => null;

export const MODAL_EDIT_ADDRESS_PARAM = 'editAddress';

export const useModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const modalType = searchParams.get('modal');

  useEffect(() => {
    if (modalType) {
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }

    return () => {
      document.body.classList.remove('body-no-scroll');
    };
  }, [modalType]);

  const openModal = (
    name: ModalType,
    source?: string,
    additionalParams?: Record<string, string>,
    redirectToMap?: boolean,
  ) => {
    if (
      name === ModalType.Auth &&
      redirectToMap &&
      (!userStore.deliveryAddress || !orderStore.etaCalculation)
    ) {
      name = ModalType.DeliveryAddress;
      reopenModal = () =>
        orderStore.etaCalculation &&
        userStore.deliveryAddress &&
        ModalType.Auth;
    }
    if (name === ModalType.DeliveryInfo) {
      mainStore.sendToRN('logAmplitudeEvent', {
        name: 'Delivery terms open',
        params: { source },
      });
    }
    searchParams.set('modal', name);
    if (additionalParams) {
      Object.entries(additionalParams).forEach(([key, val]) => {
        searchParams.set(key, val);
      });
    }
    setSearchParams(searchParams, { replace: true });
    return searchParams;
  };

  const closeModal = (paramsToRemove?: string[]) => {
    const reopenModalName = reopenModal();
    if (reopenModalName) {
      searchParams.set('modal', reopenModalName);
      reopenModal = () => null;
      setSearchParams(searchParams, { replace: true });
    } else {
      searchParams.delete('modal');
      // TODO: make it more generic
      searchParams.delete(MODAL_EDIT_ADDRESS_PARAM);
      searchParams.delete('source');
      if (paramsToRemove?.length) {
        for (const param of paramsToRemove) {
          if (searchParams.has(param)) {
            searchParams.delete(param);
          }
        }
      }
      setSearchParams(searchParams, { replace: true });
    }
    return searchParams;
  };

  return {
    openModal,
    closeModal,
  };
};
