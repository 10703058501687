import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { OrderItemBundle } from '~/api/Catalog';
import { OrderCartItem } from '~/api/Order';
import { company } from '~/company/Company';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import Counter from '~/components/Counter';
import Icon from '~/components/Icon/Icon';
import { formatVolume } from '~/components/ProductCard/utils';
import { useGlobal } from '~/hooks/useGlobal';
import { useProductLimit } from '~/hooks/useProductLimit';
import { catalogStore } from '~/stores/CatalogStore';
import { Product } from '~/stores/CategoriesStore';
import { mainStore } from '~/stores/MainStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import styles from './Order.module.scss';

const OrderProductCard = ({
  item,
  product,
  isBundle,
  handleFavorite,
}: {
  item: OrderCartItem | OrderItemBundle;
  product?: Product;
  isBundle?: boolean;
  handleFavorite: (productId: string) => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();

  const currentItem = isBundle
    ? product
    : product?.offers.find(({ sku }) => sku === item.sku || sku === item.id) ||
      product;
  const { limit } = useProductLimit({ offer: currentItem });
  const count = currentItem
    ? catalogStore.getCartItemCountById(currentItem)
    : 0;
  const quantity = isBundle
    ? (item as OrderItemBundle).quantity
    : (item as OrderCartItem).requested_quantity;
  const handleChangeCount = (count: number, action: 'add' | 'remove') => {
    if (currentItem) {
      catalogStore.setCartItemCountByProduct(
        {
          ...currentItem,
          ratingAverage: currentItem.ratingAverage ?? 0,
          ratingMarksCount: currentItem.ratingMarksCount ?? 0,
        },
        count,
        action,
        'product_main',
      );
    }
  };

  const showPartiallyPickedInfo = Boolean(
    company.showPartiallyPickedInfo &&
      (isBundle
        ? (item as OrderItemBundle).is_picked_partialy
        : (item as OrderCartItem).requested_quantity >
          (item as OrderCartItem).actual_quantity),
  );

  const partiallyPickedMessage = isBundle ? (
    <div className={styles.quantityMessage}>
      {t('bundleWasPickedPartially')}
    </div>
  ) : (
    <div className={styles.quantityMessage}>
      {t('actualQuantity', {
        actual: (item as OrderCartItem).actual_quantity,
        requested: (item as OrderCartItem).requested_quantity,
      })}
    </div>
  );

  const actualPrice = formatPriceWithCurrency(
    isBundle
      ? mainStore.convertPenceToPounds(
          (item as OrderItemBundle).actual_paid_price || 0,
        )
      : mainStore.convertPenceToPounds(
          (item as OrderCartItem).paid_price *
            (item as OrderCartItem).actual_quantity || 0,
        ),
  );

  const oldPrice = showPartiallyPickedInfo && (
    <p className={styles.oldPrice}>
      {formatPriceWithCurrency(
        isBundle
          ? mainStore.convertPenceToPounds(
              (item as OrderItemBundle).price *
                (item as OrderItemBundle).quantity || 0,
            )
          : mainStore.convertPenceToPounds(
              (item as OrderCartItem).paid_price *
                (item as OrderCartItem).requested_quantity || 0,
            ),
      )}
    </p>
  );

  return (
    <div className="order__items-list-item">
      <div className="order__items-list-item-image">
        {item?.image && (
          <CloudflareResponseImage
            src={item.image}
            srcParams={{ width: 100, height: 140 }}
            srcSetParams={defaultSrcSetParams}
            alt=""
            loading="lazy"
          />
        )}
        {Boolean(currentItem?.promoRequiredQuantity) &&
          (currentItem?.promoRequiredQuantity ?? 0) > 0 && (
            <span className="order__items-list-item-image-multiBuyBadge">
              +{currentItem?.promoRequiredQuantity}
            </span>
          )}
      </div>
      <div className="order__items-list-item-content">
        <div className="order__items-list-item-content-title">
          {item.name}
          {showPartiallyPickedInfo ? (
            isMobile && partiallyPickedMessage
          ) : (
            <div className="order__items-list-item-content-quantity _d-hide">
              {mainStore.addPcs(quantity)}{' '}
              {currentItem?.properties.volume &&
                formatVolume(currentItem?.properties)}
              {quantity > 1 &&
                t('phrases:each', {
                  cost: formatPriceWithCurrency(
                    mainStore.convertPenceToPounds(
                      isBundle
                        ? (item as OrderItemBundle).price
                        : (item as OrderCartItem).paid_price,
                    ),
                  ),
                })}
            </div>
          )}
        </div>
        {showPartiallyPickedInfo ? (
          !isMobile && partiallyPickedMessage
        ) : (
          <div className="order__items-list-item-content-info">
            <div className="order__items-list-item-content-info-quantity">
              {isBundle
                ? mainStore.addPcs((item as OrderItemBundle).quantity)
                : mainStore.addPcs(
                    (item as OrderCartItem).requested_quantity,
                  )}{' '}
              {currentItem?.properties.volume &&
                formatVolume(currentItem?.properties)}
            </div>
          </div>
        )}
        <div
          className={classes([
            'order__items-list-item-content-price',
            styles.contentPrice,
          ])}
        >
          <p>{actualPrice}</p>
          {oldPrice}
        </div>
      </div>
      <div className="order__items-list-item-buttons">
        {company.match({
          citydrinks: (
            <button
              className="button _bordered"
              onClick={() =>
                handleFavorite(
                  isBundle
                    ? (item as OrderItemBundle).catalog_bundle_id
                    : (item as OrderCartItem).product_id,
                )
              }
            >
              <Icon
                type={
                  catalogStore.favorites[
                    isBundle
                      ? (item as OrderItemBundle).catalog_bundle_id
                      : (item as OrderCartItem).product_id
                  ]
                    ? 'fav-fill'
                    : 'fav'
                }
                className={classes([
                  catalogStore.favorites[
                    isBundle
                      ? (item as OrderItemBundle).catalog_bundle_id
                      : (item as OrderCartItem).product_id
                  ] && 'faved',
                ])}
                size={24}
              />
            </button>
          ),
        })}
        {count > 0 ? (
          <Counter
            size="m"
            count={count}
            onClick={handleChangeCount}
            limit={limit}
          />
        ) : (
          <button
            className={classes(['button _primary', limit === 0 && '_disabled'])}
            onClick={() => handleChangeCount(count + 1, 'add')}
          >
            {company.variant({ '1': <Icon type="bag" size={24} /> })}
            {limit > 0 ? t('addToCart') : t('soldOut')}
          </button>
        )}
      </div>
    </div>
  );
};

export default observer(OrderProductCard);
