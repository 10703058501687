import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';

import { ProductPropertiesValue } from '~/types/Product/interface';

import styles from './ProductPropertyFilter.module.scss';

type Props = {
  filter: ProductPropertiesValue;
  handleChangeOffer: (sku: string) => () => void;
  name?: string;
};

const ProductPropertyFilter = ({ filter, handleChangeOffer, name }: Props) => {
  const selectedChoice = filter.choices.find((i) => i.selected);

  return (
    <div className={styles.filterListContainer}>
      <p className={styles.filterListTitle}>
        {filter.name}:{' '}
        <span className={styles.filterListValue}>{selectedChoice?.label}</span>
      </p>
      <div className={styles.filterList}>
        {filter.choices.map((choice) => {
          return (
            <label
              className={classes([
                styles.filterListItem,
                !choice.sellable && styles.passed,
                !choice.sku && styles.empty,
                filter.type === 'colorList' && styles.round,
              ])}
              style={
                filter.type === 'colorList'
                  ? { backgroundColor: choice.value.toString() }
                  : {}
              }
              key={choice.value}
            >
              <input
                type="radio"
                name={`${filter.code}_${name ?? ''}`}
                id={`${choice.value.toString()}`}
                value={`${choice.value.toString()}`}
                checked={choice.selected}
                onChange={() => {
                  if (choice.sku) {
                    handleChangeOffer(choice.sku)();
                  }
                }}
              />
              {filter.type !== 'colorList' && <span>{choice.label}</span>}
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default observer(ProductPropertyFilter);
