import classes from 'html-classes';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon/Icon';

import styles from './PartiallyPicked.module.scss';

type Props = {
  classNames?: string;
};

export const PartiallyPicked = ({ classNames }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classes([styles.partiallyPicked, classNames])}>
      <Icon type="warning" size={24} className={styles.partiallyPicked_icon} />
      <span className={styles.partiallyPicked_message}>
        {t('orderPickedPartially')}
      </span>
    </div>
  );
};
