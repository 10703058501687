import { observer } from 'mobx-react-lite';

import { company } from '~/company/Company';
import { useStores } from '~/hooks/useStore';
import ShopSidebarItem from '~/pages/Shop/ShopSidebarItem';

import styles from './ShopSidebar.module.scss';

const ShopSidebar = () => {
  const { shopCategories } = useStores();
  const categories = shopCategories.list.flatMap(
    ({ subcategory }) => subcategory,
  );

  return (
    <div className={styles.container} data-company={company.name}>
      {categories.map((category) => {
        return <ShopSidebarItem key={category.id} category={category} />;
      })}
    </div>
  );
};

export default observer(ShopSidebar);
