import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import Section from '~/components/Section';
import { Offer, Product } from '~/stores/CategoriesStore';
import { filterNutritionFields, parseNutrition } from '~/types/Product';
import { ProductPropertiesValue } from '~/types/Product/interface';

import nutritionStyles from '../../Nutrition.module.scss';

import styles from '../Product.module.scss';

import '../styles.scss';

type Props = {
  propertyList: ProductPropertiesValue[];
  currentItem?: Product | Offer;
  isDesktop?: boolean;
};

const ProductCharacteristicsLocalee = ({
  propertyList,
  currentItem,
  isDesktop,
}: Props) => {
  const { t } = useTranslation();

  const characteristicsList = filterNutritionFields(propertyList);
  const nutritionList = parseNutrition(propertyList);
  const characteristicsBox = characteristicsList.length > 0 && (
    <ul className={styles.characteristicList} data-company={company.name}>
      {characteristicsList.map((prop) => (
        <li className={styles.characteristicListItem} key={prop.name}>
          <div className={styles.characteristicListItem__name}>
            <p className="">{prop.name}</p>
          </div>
          <div className={styles.characteristicListItem__value}>
            <p className="">{prop.value}</p>
          </div>
        </li>
      ))}
    </ul>
  );
  const nutritionBox = nutritionList.length > 0 && (
    <table
      className={nutritionStyles.nutritionList}
      data-company={company.name}
    >
      <tr>
        <th>{t('productPage:typicalValues')}</th>
        <th>
          <div>{t('productPage:per100g')}</div>
          <div>{t('productPage:ofProduct')}</div>
        </th>
        <th>
          <div>{t('productPage:RI')}</div>
          <div>{t('productPage:per100g')}</div>
        </th>
      </tr>
      {nutritionList.map((prop) => (
        <tr
          className={classes([
            nutritionStyles.nutritionListItem,
            prop.className,
            prop.name === 'fat' || prop.name === 'carbohydrates'
              ? 'double_start'
              : '',
            prop.name === 'fat_saturates' || prop.name === 'sugar'
              ? 'double_end'
              : '',
          ])}
          key={prop.name}
        >
          <td className={nutritionStyles.nutritionListItem__name}>
            <p className="">
              {prop.name === 'fat_saturates' || prop.name === 'sugar'
                ? t(`nutritionTableNames:${prop.name}`)
                : t(`productProperties:${prop.name}`)}
            </p>
          </td>
          <td className={nutritionStyles.nutritionListItem__value}>
            <p className="">
              {prop.additionalValue && (
                <>
                  {prop.additionalValue}
                  <br />
                </>
              )}
              {prop.value}
            </p>
          </td>
          <td className={nutritionStyles.nutritionListItem__value}>
            <p className="">{prop.ratio}</p>
          </td>
        </tr>
      ))}
    </table>
  );
  if (isDesktop) {
    return (
      <>
        {characteristicsBox && (
          <Section title={t('characteristics')}>{characteristicsBox}</Section>
        )}
        {nutritionBox && (
          <Section title={t('productPage:nutritionInfo')}>
            {nutritionBox}
          </Section>
        )}
      </>
    );
  }
  return (
    <>
      {characteristicsList.length > 0 && (
        <div
          className={classes([styles.productContent, styles.characteristic])}
          data-company={company.name}
        >
          <Section title={t('characteristics')}>
            {characteristicsBox}
            {currentItem?.description &&
              company.showDescriptionReviewProductPage && (
                <p
                  className="description"
                  dangerouslySetInnerHTML={{ __html: currentItem.description }}
                />
              )}
          </Section>
        </div>
      )}
      {nutritionBox && (
        <div
          className={classes([styles.productContent, styles.characteristic])}
          data-company={company.name}
        >
          <Section title={t('productPage:nutritionInfo')}>
            {nutritionBox}
          </Section>
        </div>
      )}
    </>
  );
};

export default observer(ProductCharacteristicsLocalee);
