import { CLOUDFLARE_IMAGE_RESIZE_ENABLED } from '~/api/constants';

import { CompanyConfig } from '../interface';

export const CityDrinksConfig: CompanyConfig = {
  id: '1eb53a13-5f9e-4deb-92d7-090a4b53fd21',
  lang: 'en',
  langs: ['en'],
  name: 'CityDrinks',
  currency: 'AED',
  officialName: 'CityDrinks',
  email: 'info@citydrinks.com',
  feedbackEmail: 'info@citydrinks.com',
  marketingEmail: 'marketing@citydrinks.com',
  phonePlaceholder: '+971',
  defaultPhoneCode: '+971',
  phoneNumber: '+971 5 015 15042',
  ageRestricted: 21,
  timeZone: 'Asia/Qatar',
  minimalOrderFeeThreshold: 700,
  address: 'KLP02 C-32 KEZAD - Khalifa Industrial Zone - Abu Dhabi, UAE',
  canonical: 'https://citydrinks.com',
  warehouse: {
    default: 'DSKEZ',
    minimalOrderFeeWarehouseCodes: [],
    location: {
      lat: 24.78144344264379,
      lng: 54.87975618443315,
    },
  },
  map: {
    center: { lat: 24.4539862, lng: 54.3775843 },
    zoom: 12,
  },
  links: {
    cookie: '',
    deliveryZones: '',
    forRiders: '',
    whatsapp: 'https://wa.me/971501515042',
    facebook: 'https://www.facebook.com/cheers.citydrinksuae/',
    instagram: 'https://www.instagram.com/citydrinksuae/',
    tiktok: 'https://www.tiktok.com/@citydrinksuae/',
    youtube: 'https://www.youtube.com/channel/UCzwC746u4WxzAs0fPOMfSMA/',
    linkedin: 'https://ae.linkedin.com/company/citydrinksuae',
    affiliateProgram: import.meta.env.REACT_APP_AFFILIATE_PROGRAM_LINK,
    blog: 'https://citydrinks.com/blog',
    legals: {
      cookiePolicy: {
        label: 'cookiePolicy',
        link: '/legals#cookie-policy',
        // link: 'https://s3.me-central-1.amazonaws.com/catalog.citydrinks.com/CD+Documents/Website+policies/Cookie+Policy.pdf',
      },
      paymentAndDeliveryPolicy: {
        label: 'paymentAndDeliveryPolicy',
        link: '/legals#payment-and-delivery',
        // link: 'https://s3.me-central-1.amazonaws.com/catalog.citydrinks.com/CD+Documents/Website+policies/Payment+%26+Delivery+Terms.pdf',
      },
      privacyPolicy: {
        label: 'privacyPolicy',
        link: '/legals#privacy-policy',
        // link: 'https://docs.google.com/document/d/1sqEMzpxEw58v51RXxcFXJHC2TSOhG3iC0mXBMLfk2QM',
      },
      salesAndReturnPolicy: {
        label: 'salesAndReturnPolicy',
        link: '/legals#sales-and-returns-policies-and-consumer',
        // link: 'https://s3.me-central-1.amazonaws.com/catalog.citydrinks.com/CD+Documents/Website+policies/Sales+%26+Return+Policy.pdf',
      },
      generalTradingPolicy: {
        label: 'generalTradingPolicy',
        link: '/legals#terms-of-service',
        // link: 'https://s3.me-central-1.amazonaws.com/catalog.citydrinks.com/CD+Documents/Website+policies/Terms+%26+Conditions.pdf',
      },
    },

    // https://jiffygrocery.atlassian.net/browse/JS-1854 -- requires to delete this links.
    // I will keep them commented for a while in case of customer change their mind
    // {
    //   label: 'tradeLicense',
    //   link: 'https://s3.me-central-1.amazonaws.com/catalog.citydrinks.com/CD+Documents/Website+policies/Trade+License+CD+General.pdf',
    // },
    // {
    //   label: 'trnCertificates',
    //   link: 'https://s3.me-central-1.amazonaws.com/catalog.citydrinks.com/CD+Documents/Website+policies/TRN+Certificate_CD+General.pdf',
    // },
  },
  referral: {
    code: 'SWIFFTUCDAVIS',
    discount: '15%',
    minBasket: '15',
    downloadAppLink: 'https://click.jiffy.shop/PZKk/f11c621',
  },
  points: {
    value: '1=1c.',
    pointsPerUnit: 15,
    icon: 'coins',
  },
  appStoreUrl: {
    ios: 'https://apps.apple.com/us/app/citydrinks/id6467869897',
    android: 'https://play.google.com/store/apps/details?id=com.citydrinks.app',
  },
  apis: {
    yaMetrika: {
      token: 96706507,
    },
  },
  countryCode: 'AE',
  cloudflareImages: {
    enabled: CLOUDFLARE_IMAGE_RESIZE_ENABLED,
    domain: import.meta.env.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
  },
  license: {
    name: 'C D General Trading LLC',
    numberPrinary: 'License Number CN-4854791',
    numberSecondary: 'TRN 104077092500003',
  },
};
