import { classes } from 'html-classes';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';

import styles from './AddressItem.module.scss';
import { AddressItemProps } from './types';

const AddressItem = ({
  address,
  isActive,
  isShowDeleteBtn,
  onDelete,
  onEdit,
}: AddressItemProps) => {
  const { t } = useTranslation();
  const [, setErrorAddressNotCovered] = useState('');

  const handleApplyAddress = async () => {
    if (isActive) {
      mainStore.setIsChangeAddressPopover(false);
      return;
    }
    try {
      const etaCalculation = await orderStore.fetchETA(
        `${address.latitude},${address.longitude}`,
      );
      if (!etaCalculation) {
        setErrorAddressNotCovered(
          t('deliveryAddressPage:errorAddressNotCovered'),
        );
        return;
      }
      userStore.setDeliveryAddress({
        zip: address.postcode || '',
        country: 'GB',
        city: address.city || 'London',
        region: 'Greater London',
        address1: address.street_address_1 || '',
        address2: address.street_address_2 || '',
        type: address.type ?? 'home',
        shortAddress: address.street_address_1 || '',
        coordinates: {
          lat: address.latitude,
          lng: address.longitude,
        },
        placeId: '',
        addressId: address.id,
        comment: address.comment || '',
        instructions: toJS(address.instructions || []),
      });
      orderStore.setEtaCalculation(etaCalculation);
      mainStore.setIsChangeAddressPopover(false);
      checkoutStore.setDeliverySlots(etaCalculation.deliverySlots || null);
    } catch (e) {
      setErrorAddressNotCovered(
        t('deliveryAddressPage:errorAddressNotCovered'),
      );
    }
  };

  return (
    <div
      className={classes([styles.itemContainer, 'item-container'])}
      data-company={company.name}
    >
      <input
        type="radio"
        className={classes(['radio', styles.radioInput])}
        name="address"
        id={`address-${address.id}`}
        value={address.id}
        checked={isActive}
        onChange={handleApplyAddress}
      />
      <label
        className={classes([styles.item, 'item'])}
        htmlFor={`address-${address.id}`}
      >
        <div className="left-block">
          <div className="radio-wrapper">
            <span className="mark" />
            <div className="text-wrapper">
              <div className="name">{t(address.type ?? '')}</div>
              <div className="address">{address.street_address_1}</div>
            </div>
          </div>
        </div>
        <div className="right-block">
          <div className={classes(['icon-wrapper', styles.iconButtons])}>
            <button
              className={classes(['button _no-padding', styles.addressButton])}
              onClick={onEdit}
            >
              <Icon type="edit" size={24} />
            </button>
            {isShowDeleteBtn && (
              <button
                className={classes([
                  'button _no-padding',
                  styles.addressButton,
                ])}
                onClick={onDelete}
              >
                <Icon type="trash" size={24} />
              </button>
            )}
          </div>
        </div>
      </label>
    </div>
  );
};

export default observer(AddressItem);
