import { CategoryDisplayType } from '~/components/CategoryBlocks/CategoryBlock';

import {
  PaginationRequest,
  ProductFilters,
  ProductSorting,
} from '../stores/CatalogStore';

import { ENDPOINT } from './constants';
import { ETADeliveryMethodType } from './ETADeliveryMethodType';
import { RequestAPI } from './Requests';
import { getLangHeaders } from './utils';

export interface ProductAPI {
  categoryId: number;
  categoryName: string;
  commodityGroup: { name: ProductCommodityGroupAPI };
  description: string | null;
  discountPrice: Nullable<NumberInt>;
  id: number;
  slug: string;
  images: Nullable<ImageAPI[]>;
  isFavourite: boolean;
  name: string;
  offerProperties: Nullable<ProductPropertiesAPI[]>;
  offers: ProductOfferAPI[];
  parentCategoryId: number;
  parentCategoryName: string;
  previewImage: ImageAPI;
  previewImageThumb: ImageAPI;
  price: Nullable<NumberInt>;
  sellable: number;
  sellableWarehouses: Record<string, string>;
  code: string;
  isBackorderAvailable: Nullable<boolean>;
  backorderLeadTime: Nullable<number>;
  minBasketDiscountPrice: Nullable<number>;
  minBasketDiscountQuantity: Nullable<number>;
  variants: Variant[];

  // Ответ от Константина: это количество доступное для продажи, относится к бестеллерам, судя по коду
  salesQuantity: number;
  createdAt: string;
  updatedAt: string;
  ratingAverage?: number;
  ratingMarksCount?: number;
  sortingCategoryPosition: number;
  productType: string;
  metadataTitle: string | null;
  metadataDescription: string | null;
}

export interface Variant {
  offerId: number;
  price: number;
  discountPrice: Nullable<number>;
  isBackorderAvailable: boolean;
  property: string;
  sku: string;
  value: string;
  images: Nullable<ImageAPI[]>;
  sellable: Nullable<number>;
  sorting: number;
}

export interface ProductOfferAPI {
  active: boolean;
  backorderLeadTime: number;
  bundleId: number | null;
  bundleQuantity?: number;
  code: string; // SKU
  description: string | null;
  discountPrice: Nullable<NumberInt>;
  id: number;
  images: Nullable<ImageAPI[]>;
  isBackorderAvailable: boolean;
  minBasketDiscountPrice: number | null;
  minBasketDiscountQuantity: number | null;
  name: string;
  price: Nullable<NumberInt>;
  productId: number;
  properties: ProductPropertiesAPI[];
  sellable: number;
  sellableWarehouses: Record<string, string>;
  salesQuantity: number;
  createdAt: string;
  updatedAt: string;
  metadataTitle: string | null;
  metadataDescription: string | null;
  productSlug?: string;
}

export type ProductPropertiesKeyAPI =
  | 'RRP'
  | 'abv'
  | 'age_restriction'
  | 'alcohol_units'
  | 'allergen_free'
  | 'backorder_lead_time'
  | 'best_before_text'
  | 'carbohydrates'
  | 'color'
  | 'color_text'
  | 'country_of_origin'
  | 'dairy_free'
  | 'fairtrade'
  | 'fat'
  | 'fat_saturates'
  | 'fibre'
  | 'flavour'
  | 'food_pairing'
  | 'for_halal'
  | 'for_kosher'
  | 'for_vegan'
  | 'for_vegetarians'
  | 'freezing_suitable'
  | 'gluten_free'
  | 'gmo_free'
  | 'ingredients'
  | 'is_backorder_available'
  | 'is_gift'
  | 'is_icecream'
  | 'is_organic_food'
  | 'items_per_outer_retail_pack'
  | 'kcal'
  | 'limited_stock'
  | 'low_fat'
  | 'manufacturer'
  | 'may_contain_nuts'
  | 'no_added_sugar'
  | 'other_nutrition_info'
  | 'other_parameters'
  | 'out_of_circulation'
  | 'own_bakery'
  | 'own_kitchen'
  | 'own_trademark'
  | 'region'
  | 'packing'
  | 'price_lock_tag'
  | 'product_size'
  | 'promo_quantity_discount_price'
  | 'promo_required_quantity'
  | 'proteins'
  | 'safery_allergen'
  | 'salt'
  | 'size'
  | 'storage'
  | 'sugar'
  | 'sugar_free'
  | 'taste'
  | 'tesco_price_match'
  | 'type_of_packaging'
  | 'uom'
  | 'vintage/year'
  | 'with_soya'
  | 'with_sulphites'
  | CityDrinksProductPropertiesKeyAPI;

export type CityDrinksProductPropertiesKeyAPI =
  | 'express_delivery'
  | 'price'
  | 'cooled'
  | 'packaging'
  | 'can_or_glass_bottle'
  | 'cask_type'
  | 'flavour'
  | 'bio_dynamic'
  | 'limited_edition'
  | 'on_offer'
  | 'food_pairing'
  | 'award_winning'
  | 'grape_or_variety'
  | 'vintage_or_year'
  | 'producer'
  | 'style'
  | 'sweetness'
  | 'unit_of_measurement'
  | 'bottle_size'
  | 'region'
  | 'country'
  | 'type'
  | 'bottling_status'
  | 'best_served'
  | 'volume';

export type ProductPropertiesTypeAPI =
  | 'checkboxGroup'
  | 'color'
  | 'colorList'
  | 'date'
  | 'dateTime'
  | 'file'
  | 'image'
  | 'number'
  | 'radioGroup'
  | 'selector'
  | 'sizeList'
  | 'tagList'
  | 'text'
  | 'textarea'
  | 'time'
  | 'toggle'
  | 'badge';

export type ProductPropertiesAPI = {
  additionalPropertyId: Unnecessary<number>;
  choices: Unnecessary;
  code: ProductPropertiesKeyAPI;
  isOfferGenerator: boolean;
  name: string;
  type: ProductPropertiesTypeAPI;
  value?: boolean | string | number;
  values: string | string[];
};

export enum ProductCommodityGroupAPI {
  GROCERY = 'GROCERY',
  ALCOHOL = 'ALCOHOL',
  TOBACCO = 'TOBACCO',
  PHARMA = 'PHARMA',
  CHEMICAL = 'CHEMICAL',
  FROZEN = 'FROZEN',
  OWN_KITCHEN = 'OWN_KITCHEN',
  CLOTHING = 'CLOTHING',
  SHOES = 'SHOES',
  ACCESSORIES = 'ACCESSORIES',
}

type ProductImageAPI = { path?: string };

type ImageAPI = {
  url?: string;
  thumb?: string;
};

export enum LandingPage {
  HOME = 'home',
  SHOP = 'shop',
}

export interface CategoryAPI {
  id: number;
  parentId: number;
  name: string;
  slug: string;
  deepLink: string;
  tileSize: number;
  previewImage: Nullable<ImageAPI>;
  previewImageThumb: Nullable<ImageAPI>;
  description: Nullable<string>;
  previewText: Nullable<string>;
  landingPage: Nullable<LandingPage>;
  displayType: Nullable<CategoryDisplayType>;
  fontColor: Nullable<string>;
  backgroundColor: Nullable<string>;
  images: ImageAPI[];
  products: ProductAPI[];
  metadataTitle: string | null;
  metadataDescription: string | null;
}

export interface ICatalogPrices {
  discountPriceMax?: number;
  discountPriceMin?: number;
  priceMax: number;
  priceMin: number;
}

export interface CategoryListAPI {
  category: CategoryAPI;
  children?: CategoryListAPI[];
  filters?: FilterAPI[];
  prices?: ICatalogPrices;
}

export interface CategoryPagedListAPI {
  category: CategoryAPI;
  products?: ProductAPI[];
  filters?: FilterAPI[];
  prices?: ICatalogPrices;
  children?: CategoryListAPI[];
}

export interface SearchCatsResponse {
  name: string;
  id: string;
  parentCatName?: string | null;
  parentCatID?: string | null;
}

export interface SearchResultsResponse {
  data: {
    categories: SearchCatsResponse[];
    products: ProductAPI[];
    totalCount: number;
    suggestions?: unknown[];
  };
  pagination: PaginationResponse;
}

export interface Banner {
  active: boolean;
  code: string;
  coupon_group: Record<string, unknown>;
  created_at: string;
  date_begin: string;
  date_end: null;
  deep_link: string;
  deleted_at: null;
  description: string;
  display_mode: string;
  hidden: boolean;
  id: number;
  images: unknown;
  name: string;
  offer: number[];
  preview_image: ProductImageAPI | null;
  preview_text: string;
  schedule: Record<string, unknown>;
  slug: string;
  sort_order: number;
  tile_size: number;
  type: string;
  updated_at: string;
}

export interface PaginationResponse {
  current: number;
  size: number;
  total: number;
}

export interface ProductsPagedResponse {
  data: CategoryPagedListAPI;
  pagination: PaginationResponse;
}

export interface OffersRequest {
  warehouseCode: string;
  sku: string;
  productId?: number;
}

export interface OffersResponse {
  data: ProductOfferAPI[];
}

interface ProductsResponse {
  data: CategoryListAPI;
}

interface ProductResponse {
  data: ProductAPI;
  error?: boolean;
  message?: string;
}

export interface SearchHistoryResponse {
  data: { search_phrase: string | null }[];
}

export interface CategoriesResponse {
  data: CategoryListAPI[];
  pagination?: PaginationResponse;
}

export interface ProductRequest {
  warehouseCode: string;
  deviceId: string;
  customerId?: string;
  id?: string;
  deepLink?: string;
  isSafeRequest?: boolean;
}

export type RecommendProductsSource =
  | 'cart'
  | 'card'
  | 'search'
  | 'purchased_items';

export type CartCalculationItemRequest = {
  sku: string;
  requested_quantity: number;
};

export type CartBundlesCalculationItemRequest = {
  id: number;
  requested_quantity: number;
};

export interface CartCalculationRequest {
  warehouse_code: string;
  seller: string;
  delivery_method: ETADeliveryMethodType;
  deliveries: Array<{
    items: (CartCalculationItemRequest | CartBundlesCalculationItemRequest)[];
    slot_delivery_details?: {
      schedule_slot_id: string;
      current_date: number; // seconds here
    };
  }>;
  address: {
    latitude: number;
    longitude: number;
  };
  should_use_bonuses: boolean;
  promocode?: string;
  tips_amount: number;
  slot_delivery_details?: {
    schedule_slot_id: string;
    current_date: number;
  };
}

export type CartCalculationItemResponse = {
  actual_quantity: NumberInt;
  image: string;
  is_gift: boolean;
  name: string;
  paid_bonuses: NumberInt;
  parent_category_id: number;
  product_id: number;
  promo_quantity_discount_price: NumberInt;
  promo_required_quantity: number;
  requested_quantity: number;
  sku: string;
  weight: number;
  sellable: number;
  sellableWarehouses: Record<string, string>;
  base_price: NumberInt;
  discount_amount: NumberInt;
  promocode_discount_amount: NumberInt;
  paid_price: NumberInt;
  is_discount_forbidden: boolean;
  discount_price: NumberInt;
  items?: CartCalculationItemResponse[];
  slug?: string;
};

export type CartCalculationBundleResponse = {
  id: number;
  requested_quantity: number;
  price: number;
  images: Nullable<ImageAPI[]>;
  items?: CartCalculationItemResponse[];
  name: string;
};

export type OrderItemBundle = {
  id: string;
  order_id: string;
  created_at: string;
  catalog_bundle_id: string;
  name: string;
  image: string | null;
  quantity: number;
  company_id: string;
  price: number;
  is_picked_partialy: boolean;
  actual_paid_price: number;
};

export interface CartCalculationOrderResponse {
  warehouse_code: string;
  items: CartCalculationItemResponse[];
  bundles: CartCalculationBundleResponse[];
  is_delivery_free: boolean;
  seller: string;
  address: {
    latitude: number;
    longitude: number;
  };
  received_bonuses: number;
  base_received_bonuses: number;
  bonuses_to_spend: number;
  is_first_order: boolean;
  should_use_bonuses: boolean;
  tax_percent: number;
  tax_amount: number;
  delivery_info: {
    base_price: number;
    discount_amount: number;
    paid_bonuses: number;
    paid_price: number;
    promocode_discount_amount: number;
    slot_delivery_details: {
      schedule_slot_id: string;
      current_date: number;
    };
    threshold: number;
  };
  service_fee: number;
  should_add_tax_to_paid_total: boolean;
  base_total: number; // just total price
  discount_total: number;
  promocode_discount: number;
  paid_bonuses: number;
  paid_total: number; // total price + all discounts + delivery
  paid_without_delivery: number; // total price + all discounts
  promocode: string | null;
  promocodeError: string | null;
}

export interface CartCalculationOrderError {
  error: boolean;
  minimalOrderValue: number;
  orderPriceWithoutDelivery: number;
}

export interface CartCalculationResponse {
  base_total: number;
  discount_total: number;
  promocode_discount: number;
  paid_bonuses: number;
  paid_total: number;
  orders: CartCalculationOrderResponse[];
  tax_amount: number;
  tax_percent: number;
  minimal_order_value_error: CartCalculationOrderError[];
}

export type FilterChoiceAPI = {
  label: string;
  value: string;
  count?: number;
};

export interface FilterAPI {
  code: ProductPropertiesKeyAPI;
  name: string;
  type: ProductPropertiesTypeAPI;
  values: FilterChoiceAPI[];
  showExpanded: boolean;
}

export enum RecommendationSource {
  CARD_ASSOCIATIONS = 'card_associations',
  CARD_SIMILAR = 'card_similar',
  CART = 'cart',
}

export interface Paginated<T> {
  data: T[];
  pagination: PaginationResponse;
}

export interface RecommendationRequestPayload {
  skus: string[];
  warehouseCode: string;
  source: RecommendationSource;
}

export type SortingFieldName =
  | 'salesQuantity'
  | 'price'
  | 'createdAt'
  | 'reviewsCount';
export type SortingOrder = 'ASC' | 'DESC';
export type SortingField = `${SortingFieldName}.${SortingOrder}`;

interface CatalogRequestsInterface {
  calculateCart(data: CartCalculationRequest): Promise<CartCalculationResponse>;

  getCategories(
    data: {
      warehouseCode: string;
      landingPage?: LandingPage[];
      depth?: number;
      parentId?: string | number;
      includes?: { products?: number };
      page?: PaginationRequest['page'];
    },
    lang?: string,
  ): Promise<CategoriesResponse>;

  getOffers(data: OffersRequest): Promise<OffersResponse>;

  getProducts(
    categoryId: string,
    data: {
      warehouseCode: string;
      categoryId?: string;
      deepLink?: string;
      isSafeRequest?: boolean;
    } & Partial<ProductFilters> &
      ProductSorting,
  ): Promise<ProductsResponse>;

  getProductsPaged(
    categoryId: string,
    data: {
      warehouseCode: string;
      categoryId?: string;
      deepLink?: string;
      isSafeRequest?: boolean;
    } & Partial<ProductFilters> &
      ProductSorting &
      PaginationRequest,
    lang?: string,
  ): Promise<ProductsPagedResponse>;

  /**
   * Gets category tree (parent category and their sub categories) products count (filters allowed)
   *
   * Catalogue service V2 API
   *
   * @param categoryId
   * @param data
   */
  getProductsCount(
    categoryId: string,
    data: {
      warehouseCode: string;
      categoryId?: string;
      deepLink?: string;
      isSafeRequest?: boolean;
    } & Partial<ProductFilters>,
  ): Promise<ProductsResponse>;

  getProduct(
    productId: string,
    data: ProductRequest,
    lang?: string,
  ): Promise<ProductResponse>;

  getSearch(data: {
    query: string;
    warehouseCode: string;
    deviceId: string;
    page: PaginationRequest['page'];
  }): Promise<SearchResultsResponse>;

  getSearchHistory(data: { device_id: string }): Promise<SearchHistoryResponse>;

  deleteSearchHistory(data: { device_id: string }): Promise<any>;

  getBestsellers(data: {
    warehouse_code: string;
    isSafeRequest?: boolean;
  }): Promise<{ data: ProductAPI[] }>;

  getRecommendProducts(
    warehouse_code: string,
    data: {
      sku: string[];
      source: RecommendProductsSource;
      isSafeRequest?: boolean;
    },
  ): Promise<{ data: ProductAPI[] }>;

  getFilters(): Promise<{ data: FilterAPI[] }>;

  getRecommendations(
    params: RecommendationRequestPayload,
  ): Promise<Paginated<ProductAPI>>;
}

export const CatalogRequests: CatalogRequestsInterface = {
  calculateCart: (data) =>
    RequestAPI.post(ENDPOINT.orders.calculate, data, getLangHeaders()),

  getCategories: (data, lang) =>
    RequestAPI.get(ENDPOINT.catalog.category.list, data, getLangHeaders(lang)),

  getOffers: (data) => RequestAPI.get(ENDPOINT.catalog.product.offers, data),

  getProducts: (categoryId, data) =>
    RequestAPI.get(
      ENDPOINT.catalog.product.listByCategory + categoryId,
      data,
      getLangHeaders(),
    ),

  getProductsPaged: (categoryId, data, serverlang) =>
    RequestAPI.get(
      ENDPOINT.catalog.product.listByCategoryPages + categoryId + '/products',
      data,
      getLangHeaders(serverlang),
    ),

  getProductsCount: (categoryId, data) =>
    RequestAPI.get(
      ENDPOINT.catalog.product.listByCategory + categoryId + '/count',
      data,
      getLangHeaders(),
    ),

  getProduct: (productId, data, lang) =>
    RequestAPI.get(
      ENDPOINT.catalog.product.request + productId,
      data,
      getLangHeaders(lang),
    ),

  getSearch: (data) =>
    RequestAPI.get(ENDPOINT.catalog.search.request, data, getLangHeaders()),

  getSearchHistory: (data) =>
    RequestAPI.get(
      ENDPOINT.catalog.search.history.list,
      data,
      getLangHeaders(),
    ),

  deleteSearchHistory: (data) =>
    RequestAPI.post(
      ENDPOINT.catalog.search.history.delete,
      data,
      getLangHeaders(),
    ),

  getBestsellers: (data) =>
    RequestAPI.get(ENDPOINT.catalog.product.bestsellers, data),

  getRecommendProducts: (warehouse_code, data) =>
    RequestAPI.post(
      ENDPOINT.catalog.product.recommendations + warehouse_code,
      data,
      getLangHeaders(),
    ),

  getFilters: () =>
    RequestAPI.get(ENDPOINT.catalog.filters.list, getLangHeaders()),

  getRecommendations: (params) =>
    RequestAPI.get(
      ENDPOINT.catalog.recommendations.list,
      params,
      getLangHeaders(),
    ),
};
