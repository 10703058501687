import { useElements, useStripe } from '@stripe/react-stripe-js';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { CartCalculationOrderResponse } from '~/api/Catalog';
import { ETAPaymentMethod } from '~/api/ETA';
import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { PaymentSystem } from '~/api/Order';
import { company } from '~/company/Company';
import AwsPayment, { AwsPaymentRef } from '~/components/AwsPayment/AwsPayment';
import Collapsible from '~/components/Collapsible/Collapsible';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import Price from '~/components/Price';
import Promocode from '~/components/Promocode/Promocode';
import Spinner from '~/components/Spinner/Spinner';
import { useHeaderSize } from '~/hooks/useHeaderSize';
import { MODAL_EDIT_ADDRESS_PARAM, useModal } from '~/hooks/useModal';
import { useSticky } from '~/hooks/useSticky';
import CheckoutContentMobile from '~/pages/Checkout/CheckoutContentMobile';
import { PaymentMethods } from '~/pages/Checkout/PaymentMethods';
import { useIsTablet } from '~/pages/Order/hooks';
import { awsPaymentStore } from '~/stores/AwsPaymentStore';
import { CartItem, CartItemOffer, catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore, RequestETAResponse } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';
import { formatPriceWithCurrency } from '~/utils/formaters';
import { clearStoredPromocode, getStoredPromocode } from '~/utils/referralLink';

import styles from './CheckoutContent.module.scss';
import { ActiveDeliveryMethodProductList } from './components/ActiveDeliveryMethodProductList';
import { DeliveryMethodsTab } from './components/DeliveryMethodsTab';
import InfoItem from './components/InfoItem/InfoItem';

const CheckoutContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const stripe = useStripe();
  const elements = useElements();
  // FIXME: Why would we use state without value?
  const [, setDeliveryPrices] = useState<RequestETAResponse | null>(null);
  const isTablet = useIsTablet();
  const [, setSearchParams] = useSearchParams();
  const innerAsideRef = useRef<HTMLDivElement>(null);
  const outerAsideRef = useRef<HTMLDivElement>(null);
  const headerSize = useHeaderSize();
  const awsPaymentRef = useRef<AwsPaymentRef>(null);
  const isLoadingConfirm =
    checkoutStore.isLoading ||
    orderStore.isLoading ||
    awsPaymentStore.isIframeLoading;
  const confirmOrderButtonTitle =
    company.useAwsPaymentMethod &&
    !checkoutStore?.payments?.length &&
    checkoutStore.activePaymentMethod === ETAPaymentMethod.Card
      ? t('addNewCard')
      : t('confirmOrder');

  useSticky({
    topSpacing: headerSize,
    outerRef: outerAsideRef,
    innerRef: innerAsideRef,
  });

  const handleSubmit = () => {
    let isValid = true;
    if (checkoutStore.nameVal.trim().length === 0) {
      isValid = false;
      mainStore.pushAlert('error', t('pleaseEnterName'));
    }

    if (!checkoutStore.emailVal.trim().length) {
      isValid = false;
      mainStore.pushAlert('error', t('pleaseEnterEmail'));
    }

    if (!checkoutStore.isEmailValid) {
      isValid = false;
      mainStore.pushAlert('error', t('emailInvalid'));
    }

    if (isValid) {
      mainStore.sendToRN('hapticFeedback', {
        count: 1,
      });

      if (company.useAwsPaymentMethod && awsPaymentRef.current) {
        awsPaymentRef.current.handleSubmitForm();
        return;
      }

      checkoutStore.orderCheckout(stripe, elements).then(() => {
        const storedPromocode = getStoredPromocode();
        if (
          catalogStore.promocode.success &&
          catalogStore.promocode.value === storedPromocode?.code
        ) {
          clearStoredPromocode();
        }
      });
    }
  };

  const minimalOrderValueError =
    catalogStore.totalCartPrice.minimal_order_value_error?.length &&
    catalogStore.totalCartPrice.minimal_order_value_error[0] ? (
      <>
        {t('errors:minCartError', {
          amount: formatPriceWithCurrency(
            catalogStore.totalCartPrice.minimal_order_value_error[0]
              ?.minimalOrderValue / 100,
          ),
        })}
        <br />
        {t('errors:minCartErrorDetails', {
          amount: formatPriceWithCurrency(
            (catalogStore.totalCartPrice.minimal_order_value_error[0]
              ?.minimalOrderValue -
              catalogStore.totalCartPrice.minimal_order_value_error[0]
                ?.orderPriceWithoutDelivery) /
              100,
          ),
        })}
      </>
    ) : null;

  const isDisabledForm = (): boolean => {
    if (
      catalogStore.calculationProcess.isLoading ||
      catalogStore.calculationProcess.isError
    ) {
      return true;
    }
    if (checkoutStore.isLoading) {
      return true;
    }

    if (awsPaymentStore.isIframeLoading) {
      return true;
    }

    if (orderStore.isETALoading) {
      return true;
    }

    if (
      checkoutStore.activePaymentMethod !== ETAPaymentMethod.Cash &&
      (stripe === null || elements === null)
    ) {
      return true;
    }

    if (
      checkoutStore.selectedDeliveryDate &&
      checkoutStore.selectedDeliveryDate !== '0' &&
      checkoutStore.selectedDeliverySlot
    ) {
      const selectedDate =
        checkoutStore.deliverySlots[checkoutStore.selectedDeliveryDate];

      if (!selectedDate) {
        throw new Error('Something went wrong');
      }

      const slot = selectedDate.find(
        ({ schedule_slot_id }) =>
          schedule_slot_id ===
          checkoutStore.selectedDeliverySlot?.schedule_slot_id,
      );
      if (!slot) {
        return true;
      }
    }

    if (
      checkoutStore.activePaymentMethod === ETAPaymentMethod.Card &&
      orderStore.paymentSystem !== PaymentSystem.myfatoorah &&
      orderStore.paymentSystem !== PaymentSystem.midtrans &&
      orderStore.paymentSystem !== PaymentSystem.amazon
    ) {
      return checkoutStore.isDisabled;
    }

    return false;
  };

  useEffect(() => {
    if (!stripe || !elements) {
      mainStore.pushAlert('error', t('errors:paymentSystemNotLoaded'));
      throw new Error('Something went wrong');
    }
    checkoutStore.requestPayments().finally(() => {
      if (
        checkoutStore.isRefreshed &&
        !isDisabledForm() &&
        !minimalOrderValueError
      ) {
        checkoutStore.orderCheckout(stripe, elements);
      }
    });
  }, []);

  useEffect(() => {
    if (checkoutStore.orderId) {
      return;
    }

    if (
      catalogStore.promocode.success &&
      mainStore.isZero(catalogStore.totalCartPrice.promocodeDiscount) &&
      catalogStore.promocode.coupon?.minimumPurchase
    ) {
      mainStore.setIsInvalidPromocodePopover(true);
    }
  }, [catalogStore.totalCartPrice]);

  useEffect(() => {
    if (checkoutStore.orderId || catalogStore.cart.length) {
      return;
    }
    navigate('/cart', { replace: true });
  }, [catalogStore.cart.length]);

  useEffect(() => {
    checkoutStore.setAddressVal(userStore.deliveryAddress?.address1 || '');
    checkoutStore.setAddress2Val(userStore.deliveryAddress?.address2 || '');
  }, [userStore.deliveryAddress]);

  useEffect(() => {
    if (!checkoutStore.orderId) {
      return;
    }
    navigate('/cabinet/orders', {
      replace: true,
      state: { linkBack: '/' },
    });
  }, [checkoutStore.orderId]);

  useEffect(() => {
    setDeliveryPrices(checkoutStore.deliveryPrices);
  }, [checkoutStore.isUpdatedDeliveryPopover]);

  const handleCommentChange = (val: string) => {
    checkoutStore.setCommentVal(val);
  };

  useEffect(() => {
    if (userStore.deliveryAddress?.comment) {
      checkoutStore.setCommentVal(userStore.deliveryAddress?.comment || '');
    }
  }, [userStore.deliveryAddress?.addressId]);

  const handleChangeEmailModal = () => openModal(ModalType.ChangeEmail);

  const handleSelectDateModal = (
    index: number,
    order: CartCalculationOrderResponse,
  ) => {
    const delivery = checkoutStore.deliveries?.[index];

    if (!delivery) {
      return;
    }

    checkoutStore.slotSelectionModalStore.setSelectedSlot(delivery, order);
    openModal(ModalType.SelectDate);
  };

  const hasExpressDelivery = checkoutStore.deliveries?.some(
    (delivery) => !delivery.slotDeliveryDetails,
  );

  const totalDeliveryFee = catalogStore.deliveriesOrders.reduce(
    (acc, { is_delivery_free, delivery_info: { paid_price } }) => {
      if (is_delivery_free) {
        return acc;
      }
      return acc + paid_price;
    },
    0,
  );

  const handleDeliveryAddress = () => {
    const searchParams = openModal(ModalType.DeliveryAddress);
    searchParams.set(
      MODAL_EDIT_ADDRESS_PARAM,
      userStore?.deliveryAddress?.addressId || '',
    );
    setSearchParams(searchParams, { replace: true });
  };

  const handleOpenRecieverData = () => {
    openModal(ModalType.CheckoutReciever);
  };

  const personalInfo = checkoutStore.phoneVal
    ? `, ${mainStore.prettyPhoneNumber(checkoutStore.phoneVal ?? '')}`
    : '';

  const isDeliveryMethodSelectionAvailable =
    company.deliveryMethodSelectionAvailable &&
    checkoutStore.availableDeliveryMethods.length > 1;

  const isPromoLoading =
    catalogStore.promotionStore.applyPromocodeMeta.isLoading;
  const openDeliveryModal = () => {
    openModal(ModalType.DeliveryInfo, 'checkout');
  };

  const propsStirng = (i: CartItem) => {
    return (i as CartItemOffer).selectableProperties &&
      Object.keys((i as CartItemOffer).selectableProperties).length > 0
      ? `, ${Object.values((i as CartItemOffer).selectableProperties)
          .map((i) => i.value)
          .join(', ')}`
      : '';
  };

  return (
    <>
      {isTablet ? (
        <CheckoutContentMobile
          isRestrictedPayment={isDisabledForm() || !!minimalOrderValueError}
          handleSelectDateModal={handleSelectDateModal}
          handleCommentChange={handleCommentChange}
          totalDeliveryFee={totalDeliveryFee}
          handleDeliveryAddress={handleDeliveryAddress}
          handleOpenRecieverData={handleOpenRecieverData}
          handleChangeEmailModal={handleChangeEmailModal}
        />
      ) : null}

      <div className="checkout desktop" data-company={company.name}>
        <div className="content-layout">
          <Link to="/cart" className={styles.backButton}>
            <Icon type="chevron" size={24} className={styles.backButtonIcon} />
            {t('backToCart')}
          </Link>
          <div className="checkout-layout">
            <div className="checkout-layout__content">
              <div className={styles.panel}>
                <div className={styles.panelSection}>
                  <div className={styles.panelTitleContainer}>
                    <p
                      className={classes([
                        styles.panelTitle,
                        styles.deliverySelectionTitle,
                      ])}
                    >
                      {t('checkout')}
                    </p>
                    {isDeliveryMethodSelectionAvailable && (
                      <span
                        className={classes([
                          styles.panelCaption,
                          styles.deliverySelectionCaption,
                        ])}
                      >
                        {t('deliveryMethods:caption')}
                      </span>
                    )}
                  </div>

                  {isDeliveryMethodSelectionAvailable && (
                    <DeliveryMethodsTab
                      activeTab={checkoutStore.deliveryMethod}
                      onTabClick={(method) =>
                        checkoutStore.setDeliveryMethod(method)
                      }
                      className={styles.deliveryMethodSelection}
                    />
                  )}

                  {checkoutStore.deliveryMethod ===
                  ETADeliveryMethodType.ClickAndCollect ? (
                    orderStore.etaCalculation && (
                      <InfoItem
                        name={t('deliveryMethods:pickupFrom')}
                        value={orderStore.etaCalculation.warehouse.address}
                        onClick={handleDeliveryAddress}
                      />
                    )
                  ) : (
                    <InfoItem
                      name={t('deliverTo')}
                      value={userStore.deliveryAddress?.address1}
                      onClick={handleDeliveryAddress}
                    />
                  )}
                  {!checkoutStore.emailVal &&
                  checkoutStore.nameVal &&
                  personalInfo ? (
                    <InfoItem
                      name={t(checkoutStore.emailVal ? 'email' : 'enterEmail')}
                      value={checkoutStore.emailVal}
                      required={true}
                      onClick={handleOpenRecieverData}
                    />
                  ) : (
                    <InfoItem
                      name={t(
                        personalInfo ? 'receiver' : 'fillOutPersonalDetails',
                      )}
                      value={checkoutStore.nameVal}
                      additionalValue={personalInfo}
                      required={true}
                      onClick={handleOpenRecieverData}
                    />
                  )}
                </div>
              </div>

              <ActiveDeliveryMethodProductList
                isDeliveryMethodSelectionAvailable={
                  isDeliveryMethodSelectionAvailable
                }
                hasExpressDelivery={hasExpressDelivery}
                handleCommentChange={handleCommentChange}
                handleSelectDateModal={handleSelectDateModal}
              />
            </div>
            <div ref={outerAsideRef}>
              <div className="checkout-layout__summary" ref={innerAsideRef}>
                <div className={classes(['panel'])}>
                  {checkoutStore.isLoading ||
                    orderStore.isLoading ||
                    (isPromoLoading && <Spinner layout={true} />)}
                  <div className={styles.checkoutSummaryItemContainer}>
                    <div className="checkout-summary__item">
                      <Collapsible
                        titleElem={
                          <div className="checkout-summary__header">
                            <div className="checkout-summary__header-text">
                              <p className="checkout-summary__header-text-title">
                                {t('orderValue')}
                                <Icon type="chevron" size={16} />
                              </p>
                            </div>
                            <Price
                              price={
                                catalogStore.totalCartPrice.paidWithoutDiscount
                              }
                              classNameCurrentPrice="checkout-summary__header-price"
                            />
                          </div>
                        }
                        isCollapsed={true}
                      >
                        <ul className="checkout-summary__list">
                          {catalogStore.selectedCartItems.map((i) => (
                            <li
                              key={i.id}
                              className="checkout-summary__list-item"
                            >
                              <div className="checkout-summary__list-item-info">
                                {i.name}
                                {propsStirng(i)}{' '}
                                {i.count > 1 && (
                                  <span className="checkout-summary__list-item-info-product-count">
                                    {i.count}x
                                  </span>
                                )}
                              </div>
                              <div className="checkout-summary__list-item-price">
                                {formatPriceWithCurrency(
                                  (i.discountPrice
                                    ? i.count * i.discountPrice
                                    : i.count * i.price) / 100,
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Collapsible>
                    </div>
                    {checkoutStore.deliveryMethod !==
                      ETADeliveryMethodType.ClickAndCollect && (
                      <div className="checkout-summary__item">
                        <Collapsible
                          titleElem={
                            <div className="checkout-summary__header">
                              <div className="checkout-summary__header-text">
                                <p className="checkout-summary__header-text-title">
                                  {t('deliveryFeeLong')}{' '}
                                  {company.isDeliveryInfoModalEnabled && (
                                    <div
                                      onClick={openDeliveryModal}
                                      className={styles.deliveryTooltip}
                                    >
                                      <Icon
                                        className="static"
                                        type="info"
                                        size={16}
                                      />
                                    </div>
                                  )}
                                  <Icon type="chevron" size={16} />
                                </p>
                              </div>
                              <Price
                                price={mainStore.convertPenceToPounds(
                                  totalDeliveryFee,
                                )}
                                classNameCurrentPrice="checkout-summary__header-price"
                              />
                            </div>
                          }
                          isCollapsed={true}
                        >
                          <ul className="checkout-summary__list">
                            {checkoutStore.deliveries?.map(
                              (delivery, index) => {
                                const key = delivery.slotDeliveryDetails
                                  ? delivery.slotDeliveryDetails
                                      ?.scheduleSlotId +
                                    delivery.slotDeliveryDetails?.currentDate
                                  : 'express';

                                const order =
                                  catalogStore.deliveriesOrders[index];
                                const isDeliveryFree = order?.is_delivery_free;
                                const deliveryPrice = formatPriceWithCurrency(
                                  mainStore.convertPenceToPounds(
                                    order?.delivery_info.paid_price ?? 0,
                                  ),
                                );
                                const slotNumber = hasExpressDelivery
                                  ? index
                                  : index + 1;

                                if (!order) {
                                  return <Fragment key={key} />;
                                }

                                const items = delivery.itemsIds.reduce(
                                  (acc, i) =>
                                    acc +
                                    (catalogStore.cart.find((c) => c.sku === i)
                                      ?.count ?? 0),
                                  0,
                                );

                                return (
                                  <li
                                    key={key}
                                    className="checkout-summary__list-item"
                                  >
                                    <div className="checkout-summary__list-item-info">
                                      {!slotNumber
                                        ? t('expressDelivery')
                                        : t('slot', { num: slotNumber })}{' '}
                                      <span className="checkout-summary__list-item-info-item-count">
                                        {items}{' '}
                                        {t(items > 1 ? 'items' : 'item')}
                                      </span>
                                    </div>
                                    <div className="checkout-summary__list-item-price">
                                      {isDeliveryFree ? (
                                        <p className="free">
                                          {t('deliveryFreeShort')}
                                        </p>
                                      ) : (
                                        <p className="slot-list__slot-fee">
                                          {deliveryPrice}
                                        </p>
                                      )}
                                    </div>
                                  </li>
                                );
                              },
                            )}
                          </ul>
                        </Collapsible>
                      </div>
                    )}
                    <div className="checkout-summary__item">
                      <ul className="checkout-taxes__list">
                        {catalogStore.totalCartPrice.taxPercent > 0 && (
                          <li className="checkout-taxes__list-item">
                            <div className="checkout-taxes__list-item-title">
                              <p className="">
                                {t('phrases:vatCheckout', {
                                  percent:
                                    catalogStore.totalCartPrice.taxPercent,
                                })}
                              </p>
                            </div>
                            <Price
                              price={mainStore.convertPenceToPounds(
                                catalogStore.totalCartPrice.taxAmount,
                              )}
                              classNameCurrentPrice="checkout-taxes__list-item-price"
                            />
                          </li>
                        )}
                        {catalogStore.promocode.success === true && (
                          <li className="checkout-taxes__list-item">
                            <div className="checkout-taxes__promocode-code">
                              <p className="checkout-taxes__promocode-code-title">
                                {t('promocode')}
                              </p>
                              <p className="checkout-taxes__promocode-code-value">
                                {catalogStore.promocode.value}
                              </p>
                            </div>
                            <Price
                              price={`-${catalogStore.totalCartPrice.promocodeDiscount}`}
                              classNameCurrentPrice="checkout-taxes__promocode-discount"
                            />
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <Promocode />
                  <div className="checkout-summary__description-total">
                    <p className="checkout-summary__description-total-text">
                      {t('total')}
                    </p>
                    <Price
                      price={catalogStore.finalPrice}
                      classNameCurrentPrice="checkout-summary__description-total-price"
                    />
                  </div>
                </div>

                {!isTablet && (
                  <>
                    <div className="panel">
                      <PaymentMethods hideDisclaimer={true} />
                      {checkoutStore.activePaymentMethod ===
                        ETAPaymentMethod.Card &&
                        company.useAwsPaymentMethod && (
                          <AwsPayment ref={awsPaymentRef} />
                        )}
                      {!company.buttonOutPanelCheckout &&
                        !awsPaymentStore.isActiveAwsIframe && (
                          <button
                            className="button _primary checkout-summary__button"
                            disabled={
                              isDisabledForm() || !!minimalOrderValueError
                            }
                            onClick={handleSubmit}
                          >
                            {isLoadingConfirm && (
                              <span className="spinner _white" />
                            )}
                            {!minimalOrderValueError
                              ? confirmOrderButtonTitle
                              : minimalOrderValueError}
                          </button>
                        )}
                      {checkoutStore.activePaymentMethod !==
                        ETAPaymentMethod.Cash &&
                        !company.hideFreezeMoneyMessage && (
                          <p className="checkout-summary__description-text-conditions">
                            {t('phrases:moneyFreeze')}
                          </p>
                        )}
                      {!company.buttonOutPanelCheckout && (
                        <p className="checkout-summary__description-text-terms">
                          {t('phrases:byOrdering')}{' '}
                          <Link to={'/legals'} state={{ source: 'checkout' }}>
                            {t('termsAndConditions')}
                          </Link>
                        </p>
                      )}
                    </div>
                    {company.buttonOutPanelCheckout &&
                      !awsPaymentStore.isActiveAwsIframe && (
                        <div>
                          <button
                            className="button _primary checkout-summary__button"
                            disabled={
                              isDisabledForm() || !!minimalOrderValueError
                            }
                            onClick={handleSubmit}
                          >
                            {isLoadingConfirm && (
                              <span className="spinner _white" />
                            )}
                            {!minimalOrderValueError
                              ? confirmOrderButtonTitle
                              : minimalOrderValueError}
                          </button>
                          <p className="checkout-summary__description-text-terms">
                            {t('phrases:byOrdering')}{' '}
                            <Link to={'/legals'} state={{ source: 'checkout' }}>
                              {t('termsAndConditions')}
                            </Link>
                          </p>
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {company.useAwsPaymentMethod &&
      checkoutStore.activePaymentMethod !== ETAPaymentMethod.Cash
        ? null
        : catalogStore.cart.length > 0 && (
            <div className="mobile-bottom-button-container">
              <button
                className={`button _primary _med ${
                  minimalOrderValueError ? '_center ' : ''
                }`}
                disabled={isDisabledForm() || !!minimalOrderValueError}
                onClick={handleSubmit}
              >
                {!minimalOrderValueError
                  ? confirmOrderButtonTitle
                  : minimalOrderValueError}
                {!minimalOrderValueError && (
                  <>
                    {isLoadingConfirm ? (
                      <span className="spinner _white" />
                    ) : (
                      <Price price={catalogStore.finalPrice} />
                    )}
                  </>
                )}
              </button>
            </div>
          )}
    </>
  );
};

export default observer(CheckoutContent);
