import { CLOUDFLARE_IMAGE_RESIZE_ENABLED } from '~/api/constants';

import { CompanyConfig } from '../interface';

// TODO: need update!!!
export const LocaleeConfig: CompanyConfig = {
  id: 'd05f30ba-ceb5-4633-a3b6-aa0f9463954f',
  lang: 'en',
  langs: ['en'],
  name: 'Localee',
  currency: '$',
  officialName: 'Localee',
  email: 'support@buylocalee.com',
  feedbackEmail: 'support@buylocalee.com',
  phonePlaceholder: '+1',
  defaultPhoneCode: '+1',
  phoneNumber: '+15514048297',
  ageRestricted: 21,
  timeZone: 'America/New_York',
  minimalOrderFeeThreshold: 700,
  address: 'Teaneck Rd, Ridgefield Park, NJ 07660, USA',
  canonical: 'https://buylocalee.com',
  warehouse: {
    default: 'NJSB1',
    minimalOrderFeeWarehouseCodes: [],
    location: { lat: 40.8537291, lng: -74.0197964 },
  },
  map: {
    center: { lat: 40.8537291, lng: -74.0197964 },
    zoom: 12,
  },
  links: {
    cookie: '',
    deliveryZones: '',
    forRiders: '',
    // whatsapp: 'https://wa.me/971501515042',
    // facebook: 'https://www.facebook.com/cheers.citydrinksuae/',
    // instagram: 'https://www.instagram.com/citydrinksuae/',
    // tiktok: 'https://www.tiktok.com/@citydrinksuae/',
    // youtube: 'https://www.youtube.com/channel/UCzwC746u4WxzAs0fPOMfSMA/',
    affiliateProgram: import.meta.env.REACT_APP_AFFILIATE_PROGRAM_LINK,
    legals: {
      cookiePolicy: {
        label: 'cookiePolicy',
        link: '/legals#cookie-policy',
      },
      paymentAndDeliveryPolicy: {
        label: 'paymentAndDeliveryPolicy',
        link: '/legals#payment-and-delivery',
      },
      privacyPolicy: {
        label: 'privacyPolicy',
        link: '/legals#privacy-policy',
      },
      salesAndReturnPolicy: {
        label: 'salesAndReturnPolicy',
        link: '/legals#sales-and-returns-policies-and-consumer',
      },
      generalTradingPolicy: {
        label: 'generalTradingPolicy',
        link: '/legals#terms-of-service',
      },
    },
  },
  referral: {
    code: 'SWIFFTUCDAVIS',
    discount: '15%',
    minBasket: '15',
    downloadAppLink: 'https://click.jiffy.shop/PZKk/f11c621',
  },
  points: {
    value: '1=1c.',
    pointsPerUnit: 15,
    icon: 'coins',
  },
  appStoreUrl: {
    ios: 'https://apps.apple.com/us/app/citydrinks/id6467869897__',
    android:
      'https://play.google.com/store/apps/details?id=com.citydrinks__.app',
  },
  apis: {},
  countryCode: 'US',
  license: {
    name: 'Localee',
    numberPrinary: 'License Number 99999',
  },
  cloudflareImages: {
    enabled: CLOUDFLARE_IMAGE_RESIZE_ENABLED,
    domain: import.meta.env.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
  },
};
